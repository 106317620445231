@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.files-notes-tabs {
    position: relative;
    margin-bottom: 30px;

    .files-actions {
        position: absolute;
        right: 0px;
        top: 18px;

        @include mobile-only {
            @include flexbox;
            position: initial;
            padding: 0 8px;
            width: 100%;
            height: 40px;
            background: $white-light-grey;
            justify-content: space-between;
            align-items: center;

            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
                border-bottom: none;
            }

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: $sightline-white-light-grey;
            }
        }

        .icon-with-label:not(:last-child) {
            margin-right: 10px;
        }
    }

    .tabs-content {
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
    }

    .tab {
        @include mobile-only {
            width: 50%;
        }
    }

    .file-upload {
        @include expanded-panel;
        margin: -2px 0 10px !important;

        @include mobile-only {
            @include expanded-panel-mobile;
        }

        .json-form {
            @include expanded-panel-json-form;
        }
    }

    .notes-grid {
        .grid-list-row-container {
            .grid-list-row {
                height: auto;

                &.details {
                    align-items: flex-start;

                    @include mobile-only {
                        height: 100%;
                    }
                }

                .grid-list-column {
                    white-space: normal;
                    height: auto;
                    word-break: break-word;

                    @include ie {
                        word-wrap: break-word;
                    }

                    &.actions {
                        align-self: stretch;
                    }
                }
            }
        }
    }
}
