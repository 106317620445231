@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.description {
    position: relative;

    ul,
    dl {
        list-style: initial;
    }

    .title {
        margin-bottom: 10px;
        font-size: em(12);
        font-weight: 500;
        text-transform: uppercase;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        .font-size-large & {
            font-size: em(14);
        }
    }

    .content-block {
        &:not(.raw-html) {
            position: relative;
            @include expanded-panel;
            margin: -2px 0 0 0 !important;
        }

        &.raw-html {
            padding: 15px 0;

            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
                border-top: 1px solid themed("themeDirtyColor");
            }

            h1 {
                font-size: em(18);
            }

            h2 {
                font-size: em(16);
            }
        }
    }

    .edit-description {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .actions {
        padding: 20px;

        .btn {
            @include mobile-only {
                width: 50%;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
