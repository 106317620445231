.form-field {
    max-width: 200px;
    margin-bottom: 5px;
}

.form-field label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);

    .font-size-large & {
        font-size: 14pz;
    }
}

.form-field input {
    font-size: 12px;
    min-height: 25px;

    .font-size-large & {
        font-size: 14px;
    }
}

.form-field select {
    font-size: 12px;
    min-height: 26px;
    padding: 5px;

    .font-size-large & {
        font-size: 14px;
    }
}
