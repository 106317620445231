@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.lead-image-view {
    .image-properties {
        margin-bottom: 0;
        .property-list-item-inner {
            .text-label {
                font-weight: 400;
                text-transform: none;
            }
        }
    }
}
