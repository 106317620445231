@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-widgets-section {
    .tab-list.tab-list-ghost {
        padding: 0 15px;
    }

    .widgets-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 120px;
        grid-gap: 15px;
        padding: 15px;
    }
}

.widgets-list-item {
    position: relative;
    text-transform: uppercase;
    border-radius: 4px;

    @include themify($themes) {
        background-color: themed("themeBaseColorDesaturatedSemiTransparent7filled");
        border: 1px solid $widget-list-item-border-color;
        color: themed("themeBaseColorDesaturatedSemiTransparent70");
    }

    &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &--selected {
        height: 120px;
        width: 140px;
        margin-left: 18px;
    }

    &--clickable {
        cursor: pointer;
    }

    .close-circle {
        position: absolute !important;
        top: -8px !important;
        right: -6px !important;
        display: initial !important;
    }

    .text-label {
        padding: 15px 0;
        margin-top: auto;
    }

    .icon-wrap {
        position: absolute;
        top: 5px;
        right: 5px;
        display: none;
    }

    &:hover {
        .icon-wrap {
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%) !important;
            background-color: white !important;
            border: none !important;
            cursor: pointer;
            display: initial;
        }
    }
}
