@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.ReactModal__Overlay {
    z-index: 100;
    @include flexbox;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.54) !important;

    &.modal-sidebar {
        justify-content: flex-end;

        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent30") !important;
        }

        .modal {
            align-self: inherit;
            margin: 0;
            min-width: inherit;
            max-width: inherit;
            min-height: 100%;
            max-height: 100%;
            width: 100%;
            @include box-shadow-common($shadow-outer);

            @include tablet {
                width: 340px;
            }

            .modal-header {
                font-size: 19px;
                font-weight: 500 !important;

                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }
        }
    }

    &.modal-styled {
        @include themify($themes) {
            background-color: themed("themeDarkColorSemiTransparent70") !important;
        }

        .modal {
            width: 100%;
            min-width: inherit;
            max-width: inherit;

            @include mobile-only {
                min-height: 100%;
                max-height: 100%;
            }

            @include tablet {
                @include themify($themes) {
                    @include box-shadow(0, 0, 12px, 0 themed("themeDarkModalBoxShadowColor"));
                }

                @include border-radius(8px);

                &.modal-sm {
                    width: 450px;
                }

                &.modal-md {
                    width: 650px;
                }

                &.modal-lg {
                    max-width: 650px;
                }

                &.modal-auto {
                    width: auto;
                }
            }

            @include tablet-large {
                &.modal-lg {
                    max-width: 850px;
                }
            }

            &.modal-no-footer .modal-content .modal-body {
                @include themify($themes) {
                    border-top: 2px solid themed("themeBaseColorSemiTransparent30");
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }

                @include tablet {
                    @include border-radius(0 0 8px 8px);
                }
            }

            &.modal-with-grid {
                @include ie {
                    overflow: auto;
                }

                .modal-body {
                    @include tablet {
                        overflow: hidden;
                    }

                    > .flex-column {
                        height: 100%;
                    }

                    .grid-list {
                        @include data-grid-sticky-header;

                        @include ie {
                            overflow: hidden;
                        }
                    }

                    .grid-list-row.details {
                        @include tablet {
                            margin-left: 15px;
                            margin-right: 15px;
                            @include calc(width, "100% - 30px");
                        }

                        .fill-width {
                            width: 100%;

                            @include mobile-only {
                                @include expanded-panel-mobile;
                            }
                        }
                    }
                }
            }

            &.delete-attribute-confirmation-modal,
            &.delete-file-confirmation-modal {
                .modal-body {
                    b {
                        word-break: break-word;

                        @include ie {
                            word-wrap: break-word;
                        }
                    }
                }
            }

            &.attributes-lookup-values-modal {
                .grid-list {
                    overflow: hidden;
                }
            }

            .modal-content {
                &.image-property-modal {
                    .file-tile {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: calc(50% - 20px);
                        height: 150px;
                        margin-left: 10px;
                        margin-top: 15px;
                        position: relative;
                        border-radius: 5px;
                        padding-bottom: 20px;
                        cursor: pointer;

                        @include themify($themes) {
                            background: themed("themeWhiteColor") !important;
                            border: 1px solid themed("themeWhiteColor");
                        }

                        .file-preview-container {
                            .document-icon {
                                position: relative;
                            }
                        }

                        .file-viewer-container {
                            .file-viewer {
                                max-height: 90px;
                            }
                        }
                    }

                    .image-widget-file {
                        overflow-y: scroll;
                    }

                    .file-name {
                        max-width: 30ch;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                    }

                    .action-buttons {
                        justify-content: end !important;
                    }
                }

                padding: 10px;

                @include themify($themes) {
                    background: themed("themeSemiWhiteDiagonalColor");
                }

                @include tablet {
                    padding: 30px 20px 20px;
                    @include border-radius(8px);
                }

                .modal-header {
                    display: block;
                    padding: 0 50px;
                    font-weight: 500;
                    font-size: em(16);
                    text-align: center;

                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }

                    @include mobile-only {
                        padding-top: 10px;
                    }

                    .icon-wrap,
                    svg {
                        &:not(.clear-close) {
                            margin-right: 7px;

                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }

                        &.cancel-clear-circle-highlight-off-filled {
                            color: $red !important;
                        }

                        &.clear-close {
                            top: 2px;
                            right: 2px;
                        }
                    }
                }

                .modal-body {
                    margin: 15px 0 0;
                    padding: 20px;
                    background: white;
                    overflow-wrap: break-word;

                    @include themify($themes) {
                        border: 1px solid themed("themeLighterDirtyColor");
                    }

                    @include mobile-only {
                        overflow-y: auto;
                    }

                    @include themify($themes) {
                        border-top: 2px solid themed("themeBaseColorSemiTransparent30");
                        border-bottom: 2px solid themed("themeBaseColorSemiTransparent30");
                    }

                    > div {
                        padding: 0;

                        p {
                            font-size: 15px;

                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                .modal-footer {
                    margin: 15px 0 10px;
                    padding: 0;

                    @include tablet {
                        margin-bottom: 0;
                    }

                    &.center {
                        justify-content: center;
                    }

                    &.show-dont-ask-option {
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }

            &.modal-centered-action-buttons {
                .action-buttons {
                    justify-content: center;
                }
            }
        }

        &.modal-simple .modal {
            .modal-content {
                padding: 40px 20px;
                overflow: hidden;

                @include tablet {
                    padding: 45px 50px;
                }

                > .icon-wrap {
                    position: absolute;
                    left: -82px;
                    top: -40px;
                    width: initial;
                    height: initial;

                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }

                    opacity: 0.04;

                    @include tablet {
                        opacity: 0.1;
                        @include calc(top, "50% - 110px");
                    }

                    &:before {
                        font-size: em(680);
                        line-height: initial;

                        @include tablet {
                            font-size: em(200);
                        }
                    }
                }

                .modal-body {
                    position: relative;
                    margin-top: 0;
                    text-align: center;

                    &.left-aligned-text {
                        text-align: left;
                    }

                    .flex-column > strong + p {
                        margin-top: 8px;
                    }
                }
            }
        }

        &.modal-error .modal {
            .modal-content {
                background-color: $red-light;

                > .icon-wrap {
                    color: red !important;
                }

                .modal-body {
                    border-color: $red-medium;

                    .flex-column > strong + p {
                        margin-top: 8px;
                    }
                }
            }
        }
    }

    .modal {
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.54);
        outline: none;
        display: flex;
        align-self: center;
        margin: auto;
        width: auto;
        min-width: 20vw;
        max-width: 80vw;
        min-height: auto;
        max-height: 90vh;

        // Need for Dropdowns to go over modal borders
        overflow: visible;

        &.modal-with-grid,
        &.modal-wide {
            min-width: 50vw;
        }

        .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 20px 0;
            width: 100%;
            max-height: 100%;

            .modal-header {
                font-weight: 700;
                text-align: left;
                display: flex;
                align-items: center;
                padding: 0 20px;

                .icon-wrap.clear-close {
                    position: absolute;
                    top: 14px;
                    right: 14px;
                }
            }

            .modal-body {
                margin: 1rem 0;
                white-space: pre-line;
                font-size: $base-font-size;
                max-height: 100%;
                line-height: 150%;

                // Need for Dropdowns to go over modal borders
                overflow: visible;

                > div {
                    padding: 0 20px;
                }
            }

            .modal-footer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 0 20px;

                .btn:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &.with-scroll {
            overflow: auto;

            .modal-body {
                overflow: auto;
            }
        }

        &.modal-no-footer {
            .modal-body {
                margin-bottom: 0;
                padding-bottom: 2px;
            }
        }
    }

    .modal-list {
        .custom-list {
            max-height: 320px;

            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .list-header-row {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .list-body {
            overflow: auto;

            .list-item-row:last-child {
                border-bottom: none;
            }

            .list-item-column + .list-item-column {
                margin-left: 10px;
            }
        }
    }

    .plain-dialog {
        @include themify($themes) {
            background-color: themed("themeDarkColorSemiTransparent70") !important;
        }

        .modal-content {
            padding: 0 !important;
            background-color: white;
        }
    }
}

.help-modal {
    .modal-content {
        .modal-header {
            text-align: left !important;
            padding: 0 0 20px 0 !important;
        }

        .modal-body {
            overflow: auto !important;
        }
    }

    .raw-html {
        line-height: initial;

        ul {
            list-style: initial;
        }
    }
}
