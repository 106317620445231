.sidenav.program-copy-form-sidenav-panel,
.add-new-program {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
