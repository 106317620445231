@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.workflow-tasks-counter {
    margin: 0 -10px 20px -10px;

    .workflow-tasks-counter-group {
        margin: 5px 10px;
        font-size: 10px;
        text-transform: uppercase;

        strong {
            @include themify($themes) {
                color: themed("themeDarkColor");
            }
        }
    }

    .workflow-tasks-counter-group--todo {
        .workflow-tasks-counter-total {
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
                border: 1px solid themed("themeBaseColorSemiTransparent20");
                color: themed("themeBaseColor");
            }
        }

        .workflow-tasks-counter-title {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeBaseColorSemiTransparent20");
            }
        }

        .workflow-tasks-counter-details-item {
            .workflow-tasks-counter-group--skipped & .workflow-tasks-indicator {
                @include themify($themes) {
                    border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent");
                }
            }
        }
    }

    .workflow-tasks-counter-group--completed {
        .workflow-tasks-counter-total {
            background: $green-light-semi-transparent-60;
            border: 1px solid $green-medium;
            color: $green;
        }

        .workflow-tasks-counter-title {
            border-bottom: 1px solid $green-medium;
        }
    }

    .workflow-tasks-counter-group--skipped {
        .workflow-tasks-counter-total {
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .workflow-tasks-counter-title {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }
    }

    .workflow-tasks-counter-total {
        font-size: initial;
        min-width: 32px;
        height: 32px;
        border: 1px solid;
        border-radius: 3px;
        margin-right: 10px;
        padding: 0 5px;
        font-weight: 500;
    }

    .workflow-tasks-counter-details-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 10px;
        padding-top: 5px;
        align-items: center;

        & + .workflow-tasks-counter-details-item {
            margin-left: 10px;
        }
    }

    .workflow-tasks-counter-details-item-title {
        margin-left: 4px;
    }

    @include mobile-only {
        .workflow-tasks-counter-details {
            display: none;
        }

        .workflow-tasks-counter-group {
            > .flex-column {
                justify-content: center;
            }

            .workflow-tasks-counter-title {
                @include themify($themes) {
                    border-bottom: none;
                    display: contents;
                }
            }
        }
    }
}
