@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.with-hand-made-icon {
    &.document-icon {
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        width: 16px;
        height: 21px;
        @include themify($themes) {
            border: 2px solid themed("themeBaseColor");
        }
        @include border-radius(3px);

        &.document-icon-grid {
            margin-top: 4px;
        }

        &.hidden {
            display: none;
        }

        span {
            position: absolute;
            left: -4px;
            top: 4px;
            padding: 0 2px;
            @include calc(width, "100% + 8px");
            @include themify($themes) {
                background: themed("themeBaseColor");
            }
            font-size: em(6);
            font-weight: 700;
            line-height: 10px;
            text-transform: uppercase;
            text-align: center;
            color: white;
        }
    }

    &.big {
        top: -6px;
        width: 48px;
        height: 63px;
        @include themify($themes) {
            border: 6px solid themed("themeBaseColor");
        }
        @include border-radius(9px);

        &.document-icon-grid {
            margin-top: 12px;
        }

        span {
            left: -12px;
            top: 12px;
            padding: 0 6px;
            @include calc(width, "100% + 24px");
            font-size: em(18);
            line-height: 30px;
        }
    }
}
