@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.property-list-item--select-box {
    .property-list-item-inner.full-width-property {
        .flex-row {
            .select-box {
                flex: 1;
                height: 30px;
            }
        }
    }
}

.property-list-item--gradient-slider {
    .property-list-item-inner {
        .align-end {
            width: 30px;
        }

        .align-start {
            width: 30px;

            .text-label {
                width: 30px;
            }
        }

        .text-label {
            margin-right: 0;
        }

        .noUi-horizontal {
            width: 100%;
        }
    }
}

.gradient-slider {
    height: 6px;
    margin-top: 32px;

    .noUi-connects {
        @include themify($themes) {
            background: themed("themeBaseColor");
        }
    }
    .noUi-target {
        border: none;
    }

    .noUi-handle {
        height: 16px;
        width: 16px;
        top: -7px;
        right: -9px;
        border-radius: 9px;
        background-color: #bedaf0;

        &:before,
        &:after {
            content: none !important;
        }
    }
}
