@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.all-utilities {
    padding: 20px 0;

    .data-grid-controls {
        @include flexbox;
        justify-content: space-between;

        @include tablet {
            justify-content: flex-end;
        }
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    &__add-form {
        position: relative;
        @include expanded-panel;
        margin-top: -5px;

        @include mobile-only {
            @include expanded-panel-mobile;
        }
    }

    .json-form {
        @include expanded-panel-json-form;

        @include mobile-only {
            overflow-y: auto;
            @include calc(height, "100% - 52px");
        }

        .object-properties {
            > .schema-container {
                @include mobile-only {
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }

                @include tablet-only {
                    @include calc(width, "(100% / 3) - 20px");
                }
            }
        }
    }
}

.sidenav.add-new-utility {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
