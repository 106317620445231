@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_functions";
@import "../../../../assets/sass/_mixins";

.sidebar-header {
    position: relative;
    padding: 15px 15px 32px;
    @include border-radius(8px 8px 0 0);

    .sidebar-header-title {
        font-size: em(18);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }
    }
}
