@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

#_dnd_portal_draggables_ > li {
    @include flexbox;
    align-items: center;
    padding: 5px 32px 5px 10px;
    min-height: 40px;
    font-size: em(13);
    padding: 0 44px 0 6px;
    word-wrap: break-word;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }
    cursor: pointer;

    .font-size-large & {
        font-size: em(14);
    }

    @include mobile-only {
        padding: 5px 32px 5px 15px;
    }

    @include ie {
        word-wrap: break-word;
    }

    @include dragging-item-style;

    &.fiber-smart-record-empty,
    &.fiber-smart-record-filled {
        .drag-handle-icon {
            position: absolute;
            right: 3px;
            top: 0;
            line-height: 40px;
        }
    }
}

.dropdown-list-item {
    padding: 0 32px 0 10px;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeDirtyColor");
    }
    word-wrap: break-word;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }
    cursor: pointer;

    @include ie {
        word-wrap: break-word;
    }

    > div {
        width: 100%;
    }

    .drag-handle-icon span {
        padding: 0 2px;
        font-size: 19px;
        font-weight: 700;
        @include themify($themes) {
            color: themed("themeDirtyColor");
        }
    }

    .with-options & {
        &.dropdown-list-item-checked {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            .drag-handle-icon {
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
            }

            &.disabled {
                color: $light-grey;
            }
        }
    }

    .dropdown-list.with-parent & {
        &:last-child {
            @include border-radius(0 0 4px 4px);
        }
    }

    .dropdown-list-wrap.with-parent & {
        @include flexbox;
        padding: 0;
        font-size: em(13);

        .font-size-large & {
            font-size: em(14);
        }

        > div {
            @include flexbox;
            align-items: center;
            padding: 5px 32px 5px 10px;
            min-height: 40px;
            word-break: break-word;

            @include mobile-only {
                padding: 5px 32px 5px 15px;
            }

            @include ie {
                word-wrap: break-word;
            }
        }
    }

    &.multi-select > div {
        padding-right: 10px !important;

        > .icon-wrap {
            flex-shrink: 0;
        }
    }

    .dropdown-list-wrap:not(.with-parent) & {
        @include text-overflow;
        height: 40px;
        line-height: 40px;
    }

    &:before {
        vertical-align: middle;
        margin: -1px 7px 0 0;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
    }

    &:last-of-type {
        @include tablet {
            border-bottom: none;
        }
    }

    &:hover,
    &:focus,
    &.focused {
        @include tablet-large {
            @include themify($themes) {
                background: themed("themeWhiteColor");
                color: themed("themeBaseColor");
            }

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            &.selected:after {
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
            }
        }
    }

    &.selected {
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            @include calc(top, "50% - 3px");
            right: 12px;
            width: 8px;
            height: 8px;
            @include themify($themes) {
                background: themed("themeBaseColorDesaturated");
            }
            @include border-radius(50%);
        }
    }

    &.disabled {
        cursor: default;
        color: $light-grey;
    }

    &--empty {
        font-style: italic;
        color: $light-grey;
    }

    &--select-all > div {
        justify-content: flex-end;

        .select-all-label {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        .checkbox-wrap {
            margin: 0 0 0 10px;
        }
    }
}
