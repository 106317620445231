@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.dashboard-settings-dropdown {
    .icon-dropdown {
        .icon-wrap-dropdown::after {
            color: transparent;
        }

        .icon-wrap-active::before {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        .dropdown-list-wrap {
            @include tablet {
                position: fixed;
                top: 48px;
                width: auto;
                @include dropdown-semi-transparent-background;
                max-width: 300px;

                @include ie {
                    width: 100%;
                }
            }

            @include tablet-only {
                right: 14px;
            }

            @include tablet-large-and-desktop {
                right: 34px;
            }

            @include desktop {
                right: 44px;
            }

            @include desktop-large {
                position: absolute;
                top: -12px;
                right: -6px;
            }

            .dropdown-list-title {
                padding: 0;
            }

            .dropdown-list-item {
                display: flex;
                padding: 0 7px 0 5px;
                cursor: default;

                .item-active {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }

                .edit-empty {
                    @include tablet-and-mobile {
                        @include themify($themes) {
                            color: themed("themeBaseColor") !important;
                        }
                    }
                }

                .pinner {
                    visibility: visible;
                }

                .pinner.pin-empty,
                .delete-trash-empty,
                .edit-empty {
                    @include tablet-large {
                        visibility: hidden;
                    }
                }

                .icon-wrap-disabled {
                    color: $grey !important;

                    + .flex-one span {
                        cursor: default;
                    }
                }

                .flex-one {
                    @include text-overflow;
                    margin-right: 20px;

                    @include ie {
                        width: 0;
                    }

                    span {
                        cursor: pointer;
                    }
                }

                &:hover {
                    .pinner.pin-empty,
                    .delete-trash-empty,
                    .edit-empty {
                        visibility: visible;
                    }
                }
            }
        }
    }

    .dropdown-sub-block {
        padding: 10px;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }

    .icon-with-label.backup-restore-empty {
        position: absolute;

        @include mobile-only {
            z-index: 30;
            left: 175px;
            top: 17px;
        }

        @include tablet {
            right: 30px;
            top: 16px;
            font-size: 0;
        }
    }
}

.dashboard-settings-form {
    &__header {
        padding: 10px 20px 0px;
        @include themify($themes) {
            background: themed("themeSemiWhiteDiagonalColor");
        }

        .sidenav-header__lead {
            padding: 10px 30px 20px;
            text-align: center;
        }

        .json-form {
            .object-properties {
                @include flexbox;
                @include flex-wrap(wrap);
                justify-content: space-between;

                > .schema-container {
                    input.text,
                    .control-label {
                        text-align: center;
                    }

                    .control-label {
                        display: block;
                    }

                    &:first-child {
                        margin-bottom: 10px;
                        width: 100%;
                    }

                    &:last-child {
                        margin-left: 14px;
                    }
                }
            }
        }
    }

    &__body {
        .json-form {
            height: auto;

            .quill {
                background: white;
            }
        }
    }

    .dashboard-settings-btn-group {
        @include flex-wrap(wrap);
        padding: 15px 20px;

        > div {
            &:first-child {
                margin: 0;
                order: 1;
                flex: 1;
            }

            &.delete-trash {
                order: 2;
            }

            &.btn-light-blue:not(.delete-trash):not(:last-child) {
                order: 3;
                margin: 28px auto 0;
                width: auto;
                @include landscape-view {
                    margin: 0 0 0 14px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 61px;
                    left: 20px;
                    @include calc(width, "100% - 40px");
                    height: 1px;
                    @include themify($themes) {
                        background: themed("themeLighterDirtyColor");
                    }
                }
            }

            &.btn-light-blue:not(.delete-trash):last-child {
                order: 2;
            }
        }
    }

    .dashboard-settings-panels {
        padding: 20px 20px 0;

        .add-new-item-panel {
            margin: 10px 0 25px;
            padding: 20px;
        }
    }

    .dashboard-settings-panel-form {
        position: relative;
        margin-top: 10px;
        padding: 15px 20px;
        @include themify($themes) {
            background: themed("themeWhiteDiagonalColor");
        }

        .dashboard-settings-panel-controls {
            position: absolute;
            right: 3px;
            top: 3px;
        }
    }
}
