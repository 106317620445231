@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.counter-box {
    padding: 0 5px;
    min-width: 22px;
    @include themify($themes) {
        background: themed("themeLighterDirtyColor");
    }
    @include border-radius(4px);
    font-weight: 700;
    text-align: center;
    line-height: 22px;
}
