@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.user-profile-form {
    height: 100%;

    .fields-container {
        height: 100%;
        background-color: white;
    }

    .page-lead-block {
        padding-bottom: 45px;

        .background-img {
            @include themify($themes) {
                @include box-shadow(inset 0, 2px, 4px, 0 themed("themeBaseColorDesaturatedSemiTransparent"));
            }

            body:not(.theme-mode-light) & {
                box-shadow: none;
            }

            &:before {
                body:not(.theme-sightline) & {
                    opacity: 0.07;
                }

                .theme-sightline &,
                .theme-sightlineHighContrast & {
                    opacity: 0.03;
                }
            }
        }
    }

    .tab-list-ghost {
        width: 100%;

        .tabs {
            justify-content: center;
        }
    }

    .basic-fields {
        margin-top: -31px;
        background-color: white;
    }

    .user-profile-base {
        padding-bottom: 30px;
        width: 100%;
    }

    .user-profile-fields-block {
        margin: 20px auto 0;
        width: 305px;

        .flex-column {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }

        & > .json-form > .rjsf > .action-buttons {
            margin: 0;
        }

        .object-properties {
            & > .schema-container {
                & > .form-group:not(.field-object) {
                    margin-bottom: 20px;
                }
            }

            & > .schema-container:last-of-type > .form-group {
                margin-bottom: 0;
            }
        }
    }
}

.profile-expandable-panel {
    margin: 0 auto;
    padding: 10px 0;
    width: 305px;
    @include themify($themes) {
        border-top: 1px solid themed("themeDirtyColor");
    }
    text-align: center;

    &.opened {
        width: 100%;
        background-color: $white-light-grey;

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        .duplicate-for-mobile {
            @include mobile-only {
                display: inline-block;
            }
        }
    }

    .duplicate-for-mobile {
        display: none;
    }

    &.show-profile-optional-fields {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }
}

.user-profile-change-password-fields {
    margin: 20px auto;
    width: 305px;

    .change-password-actions {
        margin-top: 40px;
        text-align: center;
    }

    .field-group {
        margin-top: 20px;
    }

    .status-msg {
        margin-top: 5px !important;
    }

    .btn {
        display: inline-block;
    }
}

.user-profile-optional-fields {
    padding: 30px 0 0;
    text-align: left;

    @include tablet-large {
        text-align: center;
    }

    .main-grid-wrap {
        @include mobile-only {
            padding: 0 !important;
            width: 305px;
        }

        @include tablet-only {
            @include flexbox;
        }

        @include tablet-large {
            display: inline-block;
        }
    }

    .group-title {
        margin-bottom: 15px;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 9px;
            width: 100%;
            height: 1px;
            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
            }
        }

        span {
            z-index: 1;
            position: relative;
            padding-right: 10px;
            background-color: $white-light-grey;
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorBaseGrey");
            }

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: $sightline-white-light-grey;
            }
        }
    }

    .optional-group {
        @include mobile-only {
            margin-bottom: 30px;
        }

        @include tablet-only {
            flex-basis: 0;
            flex-grow: 1;
        }

        @include tablet-large {
            display: inline-block;
            vertical-align: top;
            width: 305px;
            text-align: left;
        }

        &:nth-of-type(2) {
            @include tablet-only {
                margin: 0 20px;
            }

            @include tablet-large {
                margin: 0 40px;
            }
        }

        & .field-state-code-container {
            @include calc(width, "70% - 10px");
            float: left;
        }

        & .field-zip-code-container {
            width: 30%;
            float: right;

            &::after {
                clear: both;
            }
        }

        .flex-column {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        & > .json-form > .rjsf > .action-buttons {
            margin: 0;
        }
    }
}

.user-profile-actions {
    margin: 40px 0 50px;
    text-align: center;

    .btn {
        display: inline-block;
    }
}
