@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.drag-handle-icon .icon-wrap {
    cursor: grab;

    @include ie {
        cursor: move;
    }

    &:before {
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
    }
}

.drag-handle-disabled {
    opacity: 0;
}
