@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.search-history {
    padding-top: 25px;
    max-height: 600px;
    width: 100%;
    @include transition(max-height 0.3s);

    &--hidden {
        max-height: 0;
    }

    @include tablet {
        margin: 0 auto;
        width: 830px;
    }

    &__criteria-wrapper,
    &__applications-wrapper {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 7px;
            left: 0;
            width: 100%;
            height: 1px;
            @include themify($themes) {
                border-top: 1px dashed themed("themeLighterDirtyColor");
            }
        }
    }

    &__criteria-wrapper {
        margin-bottom: 30px;
    }

    &__criteria-list,
    &__applications-list {
        @include flexbox;
        @include flex-wrap(wrap);
    }

    &__applications-list {
        margin: 10px 0;
    }

    &__section-title {
        z-index: 1;
        margin: 0 auto;
        padding: 0 10px;
        background: white;
        text-transform: uppercase;
        font-size: em(10);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeBaseColorDesaturatedSemiTransparent70");
        }

        .font-size-large & {
            font-size: em(12);
        }
    }

    &__field {
        width: 25%;
    }

    &__item {
        position: relative;
        background: $white-light-grey;

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        @include tablet-large {
            &:hover {
                @include themify($themes) {
                    background: themed("themeSemiWhiteColor");
                }
            }
        }

        &.criteria {
            @include flexbox;
            @include flex-direction(column);
            justify-content: space-between;
            margin-top: 8px;
            padding: 7px 13px 8px;
            @include calc(width, "50% - 5px");
            overflow: hidden;
            cursor: pointer;

            @include mobile-only {
                &:not(:nth-of-type(2n)) {
                    margin-right: 10px;
                }
            }

            @include tablet {
                @include calc(width, "(100% / 5) - 8px");

                &:not(:nth-of-type(5n)) {
                    margin-right: 10px;
                }
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 3px;
                @include transition(all 0.3s);
            }

            &:hover:after {
                height: 100%;
            }

            &:before {
                height: 100%;
                @include themify($themes) {
                    background: themed("themeLighterDirtyColor");
                }
            }

            &:after {
                height: 0;
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
            }

            > .icon-wrap {
                position: absolute;
                right: 23px;
                top: -2px;

                &:before {
                    font-size: 55px;
                    color: white;
                }
            }
        }

        &.application {
            @include flexbox;
            width: 100%;
            font-size: em(12);
            cursor: pointer;

            .font-size-large & {
                font-size: em(14);
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            @include tablet-and-mobile {
                @include flex-direction(column);
            }

            @include tablet-large {
                align-items: center;
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 3px;
                @include transition(all 0.3s);
            }

            &:before {
                height: 100%;
                @include themify($themes) {
                    background: themed("themeLighterDirtyColor");
                }
            }

            &:after {
                height: 0;
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
            }

            &:hover {
                &:after {
                    height: 100%;
                }
            }

            .search-history__field {
                position: relative;
                padding: 10px 13px;

                &:first-child {
                    background: rgba(0, 0, 0, 0.015);

                    .search-history__field__value {
                        font-weight: 500;
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                        }
                    }
                }

                &__title {
                    margin-bottom: 2px;
                    font-size: 10px;
                    @include themify($themes) {
                        color: themed("themeBaseColorDesaturatedSemiTransparent70");
                    }

                    .font-size-large & {
                        font-size: 12px;
                    }
                }
            }
        }

        &__value {
            margin-bottom: 5px;
            font-weight: 500;
            font-size: em(10);
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }

            .font-size-large & {
                font-size: em(12);
            }

            > div {
                &:not(:first-child):before {
                    content: "&";
                    display: block;
                    margin: 4px 0;
                    color: black;
                }
            }

            .link {
                display: block;
                margin-top: 2px;
                font-size: 13px;
                @include text-overflow;

                .font-size-large & {
                    font-size: 14px;
                }
            }

            .criteria & {
                position: relative;
                display: block;
                white-space: normal;
            }

            .application & {
                font-size: em(11);

                .font-size-large & {
                    font-size: em(13);
                }
            }
        }

        &__details {
            @include flexbox;
            font-size: em(10);

            .font-size-large & {
                font-size: em(12);
            }

            &.application {
                @include tablet-and-mobile {
                    margin-top: 2px;
                }

                @include tablet-large {
                    @include flex-direction(column);
                    text-align: right;
                }
            }

            &.criteria {
                position: relative;
                justify-content: flex-end;
            }

            &__time {
                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent70");
                }

                &.application {
                    @include tablet-and-mobile {
                        margin-left: 10px;
                    }
                }

                &.criteria {
                    text-align: right;

                    @include tablet-large {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}
