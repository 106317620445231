@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.field-icon-clickable {
    text-align: center;
    cursor: pointer;

    &.with-icon {
        position: absolute;
    }

    &.password {
        top: 1px;
        right: 1px;
        width: 32px;
        height: 30px;
        line-height: 31px;
        @include border-radius(0 4px 4px 0);

        @include themify($themes) {
            background: themed("themeWhiteColor");
            @include box-shadow(inset -1px, 1px, 2px, 0 themed("themeLighterDirtyColor"));
        }

        &.active {
            top: 0;
            right: 0;
            height: 32px;
            @include themify($themes) {
                background: themed("themeBaseColor");
            }
            @include box-shadow(inset -1px, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
            line-height: 32px;

            &:before {
                color: white;
            }
        }
    }

    &.clean {
        display: none;
        line-height: 30px;

        &:before {
            content: $icon-backspace-alt-empty;
            @include themify($themes) {
                color: themed("themeColorBaseGrey");
            }
        }
    }

    &.arrows {
        right: 5px;
        height: 16px;
        line-height: 16px;

        &:first-of-type {
            top: 1px;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        &:last-of-type {
            bottom: 1px;
        }

        &:not(.error):not(.disabled) {
            input[type="number"].number:focus + &,
            input[type="number"].number:focus + & + &,
            input[type="number"].number.focus + &,
            input[type="number"].number.focus + & + &,
            &:hover {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        &.read-only {
            pointer-events: none;
        }

        &.disabled {
            pointer-events: none;
            color: $grey;
        }

        &.error {
            color: $red;
        }
    }
}
