@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.sticky-sidepanel {
    position: sticky;
    top: 0;
    align-self: flex-start;
    width: 400px;
    flex-shrink: 0;

    @include mobile-only {
        width: 200px;
    }

    &__container {
        overflow: hidden;
    }

    &__header {
        padding: 20px;
        background: $white-light-grey;

        &__title {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &__body {
        padding: 20px;
        overflow: auto;
    }
}
