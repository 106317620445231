@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.grid-list.audit-side-panel-grid {
    border: none;

    .grid-list-row {
        &:not(.grid-list-row-header) {
            height: auto;
            padding: 0 7px;

            &:nth-of-type(even):not(:hover) {
                background: white !important;
            }

            &:hover:not(.filter):not(.selected) {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
            }
        }
    }

    .grid-list-column {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: normal !important;

        &.audittype {
            width: 20px;
        }
    }

    .grid-list-row-header {
        padding: 10px 7px 5px;
        background: white;
        min-height: inherit;
        height: auto;
    }

    .grid-list-paging {
        margin-left: 0px;
        justify-content: space-between;

        .paging {
            justify-content: flex-start;
        }
    }
}

.not-found-msg {
    padding: 15px;
}
