@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.dashboard-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }
    overflow: hidden;

    @include tablet-and-mobile {
        padding-bottom: 30px;
    }

    .panel-header {
        z-index: 1;
        padding: 8px 7px 8px 0;
        min-height: 44px;
        @include box-shadow(0, 2px, 4px, 0 rgba(0, 0, 0, 0.15));
        font-size: em(15);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        @include tablet {
            font-size: em(17);
        }

        .panel-title {
            @include flexbox;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }

        span {
            padding: 0 15px;
            @include themify($themes) {
                border-left: 4px solid themed("themeBaseColor");
            }

            &.no-title {
                font-size: 13px;
                border: none;
                opacity: 0.3;
                text-transform: uppercase;

                .font-size-large & {
                    font-size: 15px;
                }
            }
        }

        .icon-wrap {
            display: none;
            @include themify($themes) {
                color: themed("themeLightColor");
            }
            cursor: grab;

            @include ie {
                cursor: move;
            }

            @include tablet {
                display: block;
            }
        }
    }

    .panel-body {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 15px;

        @include tablet {
            padding: 20px;
        }

        @include ie {
            .grid-list {
                flex-grow: 0 !important;
            }
        }

        @include tablet {
            .grid-list-paging {
                .paging-goto-page .field-group {
                    margin: 0 4px;
                }
            }
        }

        .grid-list .paging {
            min-height: auto;
        }
    }

    &:not(.powerBI) .panel-body {
        @include tablet-and-mobile {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }
        }
    }
}

.app-activity-graph-panel,
.scanned-apps-panel {
    .report-for-dashboard {
        @include ie {
            overflow: hidden;
        }

        .report-title {
            margin: 0 auto 20px;
            padding: 0;
            max-width: 300px;
            text-align: center;
        }

        .report-controls {
            margin: 0 0 20px;

            > .field-group {
                @include calc(width, "50% - 10px");
                margin: 0;
            }
        }
    }
}

.applications-summary-status {
    .sticky-grid-list-panel > .flex-row > .flex-column {
        @include calc(width, "50% - 10px");
    }
}

.panel {
    .with-form {
        &.is-sticky {
            position: sticky;
            top: 20px;
            align-self: flex-start;
            z-index: 1;
        }

        .json-form {
            padding: 20px;
        }
    }

    .with-padding {
        padding: 20px;
    }

    .with-panel-borders {
        @include expanded-panel;
    }

    .data-grid-controls + .with-panel-borders.file-upload {
        margin-top: 10px;

        .json-form {
            @include expanded-panel-json-form;
        }

        @include mobile-only {
            @include expanded-panel-mobile;
        }
    }

    .document-toolbar {
        align-items: center;

        @include mobile-only {
            position: relative;
            @include flex-wrap(wrap);

            .tab-list {
                padding-bottom: 40px;

                .tab {
                    width: 50%;
                }
            }
        }

        .tab-list {
            @include tablet {
                width: auto;
            }
        }

        .file-controls {
            @include mobile-only {
                @include data-grid-controls-mobile;
                z-index: 1;
                position: absolute;
                bottom: 0;
            }

            .icon-with-label {
                &:not(:last-child) {
                    margin-right: 20px;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    .document-toolbar + .flex-column > .file-upload {
        @include expanded-panel;
        margin: -5px 0 10px 0;

        @include mobile-only {
            @include expanded-panel-mobile;
        }

        .json-form {
            @include expanded-panel-json-form;

            @include mobile-only {
                @include calc(height, "100% - 52px");
                overflow-y: auto;
            }

            @include tablet {
                .upload-file-area {
                    min-height: 103px;
                }
            }
        }
    }

    &.sticky-grid-list-panel {
        display: flex;
        flex-direction: column;
        height: 100%;

        .grid-list {
            overflow: hidden;
            @include data-grid-sticky-header;
        }
    }
}
