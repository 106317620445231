.with-icon {
    position: relative;

    &:before,
    &:after {
        @include icon-iconoteka;
    }

    &.icon-wrap-combined {
        &:after {
            position: absolute;
            top: 1px;
            right: 3px;
            width: 12px;
            height: 12px;
            background: white;
            @include border-radius(50%);
            line-height: 12px;
            font-size: 11px;
        }

        &.icon-wrap-big:after {
            padding: 2px;
            font-size: 12px;
        }

        &.remove {
            &:after {
                content: $icon-do-not-disturb-on-remove-circle-empty;
            }
        }

        &.remove-filled {
            &:after {
                content: $icon-do-not-disturb-on-remove-circle-filled;
            }
        }

        &.add {
            &:after {
                content: $icon-plus;
            }
        }

        &.cancel {
            &:after {
                content: $icon-cancel-clear-circle-highlight-off-empty;
            }
        }

        &.edit {
            &:not(.icon-wrap-big):after {
                width: 10px;
                height: 10px;
                font-size: 8px;
                line-height: 10px;
            }

            &:after {
                content: $icon-edit;
                top: 12px;
                @include themify($themes) {
                    border: 1px solid themed("themeBaseColor");
                }
            }

            &.icon-wrap-big:after {
                top: 18px;
                right: -3px;
            }
        }

        &.administrative-field-icon.eye-visibility-empty {
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                font-size: 12px;
                line-height: 27px;
                font-weight: 700;
                opacity: 0.8;
            }

            &:after {
                content: "/";
                top: 4px;
                left: -1px;
                right: 0;
                margin: 0 auto;
                width: 14px;
                height: 14px;
                background: transparent;
                @include themify($themes) {
                    border: 2px solid themed("themeColorBaseGrey");
                }
                @include border-radius(50%);
                @include rotate(15deg);
                font-size: 24px;
                font-weight: 700;
                line-height: 0.6;
                opacity: 0.8;
            }
        }

        &.share-filled-after {
            &:before,
            &:after {
                top: 2px;
                left: initial;
                right: 0;
                width: 12px;
                height: 12px;
                text-align: center;
                line-height: 12px;
            }

            &:after {
                background: transparent;
                font-size: 8px;
                color: white;
                line-height: 11px;
            }

            &:before {
                right: 2px;
                font-size: 16px;
                @include themify($themes) {
                    color: themed("themeTitleColor");
                }
            }

            &.own:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }
    }

    &.icon-wrap-hand-made {
        &.test {
            &:before {
                content: "test";
                padding: 2px 3px 1px;
                width: 20px !important;
                height: initial;
                line-height: initial;
                @include themify($themes) {
                    border: 1px solid themed("themeBaseColor");
                }
                @include border-radius(8px);
                text-transform: uppercase;
                font-size: 7px;
                font-weight: 700;
                font-family: $base-font-family;
            }
        }
    }

    &.star:before {
        content: $icon-star-filled;
    }

    &.star-empty:before {
        content: $icon-star-empty;
    }

    &.upload:before,
    &.upload-file:before {
        content: $icon-upload;
    }

    &.blocks:before {
        content: $icon-view-grid-filled;
    }

    &.lines:before {
        content: $icon-view-stream-view-agenda-filled;
    }

    &.remove:before {
        content: $icon-backspace-alt-empty;
    }

    &.attachment:before {
        content: $icon-attachment;
    }

    &.copy-link:before {
        content: $icon-link;
    }

    &.move:before {
        content: $icon-open-with;
    }

    &.open-new-window:before {
        content: $icon-open-new-window;
    }

    &.clear-close:before {
        content: $icon-clear-close;
    }

    &.check-done:before {
        content: $icon-check-done;
    }

    &.restore-update:before {
        content: $icon-restore-update;
    }

    &.clear-circle {
        &:after {
            content: $icon-cancel-clear-circle-highlight-off-empty;
        }

        &:hover:after {
            content: $icon-cancel-clear-circle-highlight-off-filled;
        }
    }

    &.chart-line:before {
        content: $icon-chart-line;
    }

    &.arrow-right-up:before {
        content: $icon-arrow-right-up;
    }

    &.user:before {
        content: $icon-user-identity-person-empty;
    }

    &.search:before {
        content: $icon-search;
    }

    &.search-off:before {
        content: $icon-search-off;
    }

    &.indeterminate-checkbox-filled:before {
        content: $icon-indeterminate-checkbox-filled;
    }

    &.checkbox-unchecked-empty:before {
        content: $icon-checkbox-unchecked-empty;
    }

    &.checkbox-unchecked-filled:before {
        content: $icon-checkbox-unchecked-filled;
    }

    &.radio-button-checked:before {
        content: $icon-radio-button-checked;
    }

    &.circle-radio-button-unchecked-filled:before {
        content: $icon-circle-radio-button-unchecked-filled;
    }

    &.circle-radio-button-unchecked-empty:before {
        content: $icon-circle-radio-button-unchecked-empty;
    }

    &.checkbox-checked-filled:before {
        content: $icon-checkbox-checked-filled;
    }

    &.credit-card-payment-filled:after {
        content: $icon-credit-card-payment-filled;
    }

    &.warning-report-problem-filled:after {
        content: $icon-warning-report-problem-filled;
    }

    &.warning-report-problem-empty:after {
        content: $icon-warning-report-problem-empty;
    }

    &.check-circle-filled:after {
        content: $icon-check-circle-filled;
    }

    &.info-filled:after {
        content: $icon-info-filled;
    }

    &.view:before,
    &.eye-visibility-filled:before {
        content: $icon-eye-visibility-filled;
    }

    &.eye-visibility-empty:before {
        content: $icon-eye-visibility-empty;
    }

    &.visibility-off-empty:before {
        content: $icon-visibility-off-empty;
    }

    &.visibility-off:before {
        content: $icon-visibility-off;
    }

    &.shevron-small-down-expand-more:after {
        content: $icon-shevron-small-down-expand-more;
    }

    &.shevron-small-right:after {
        content: $icon-shevron-small-right;
    }

    &.shevron-small-left:before {
        content: $icon-shevron-small-left;
    }

    &.shevron-in-circle-up-filled:after {
        content: $icon-shevron-in-circle-up-filled;
    }

    &.shevron-in-circle-up-filled--before:before {
        content: $icon-shevron-in-circle-up-filled;
    }

    &.settings:before {
        content: $icon-settings-empty;
    }

    &.shevron-circle-down-filled:before {
        content: $icon-shevron-in-circle-down-filled;
    }

    &.shevron-in-circle-right-empty:before {
        content: $icon-shevron-in-circle-right-empty;
    }

    &.shevron-in-circle-right-empty--after:after {
        content: $icon-shevron-in-circle-right-empty;
    }

    &.shevron-circle-left-empty:after {
        content: $icon-shevron-in-circle-left-empty;
    }

    &.shevron-in-circle-left-filled:after {
        content: $icon-shevron-in-circle-left-filled;
    }

    &.shevron-in-circle-left-filled--before:before {
        content: $icon-shevron-in-circle-left-filled;
    }

    &.shevron-small-up-expand-less:before {
        content: $icon-shevron-small-up-expand-less;
    }

    &.plus:before {
        content: $icon-plus;
    }

    &.add-create-plus-circle-filled:before {
        content: $icon-add-create-plus-circle-filled;
    }

    &.document-text:before {
        content: $icon-document-text;
    }

    &.flag-empty:before {
        content: $icon-flag-empty;
    }

    &.flag-filled:before {
        content: $icon-flag-filled;
    }

    &.pin-empty:before {
        content: $icon-pin-empty;
    }

    &.target:before {
        content: $icon-target;
    }

    &.double-person:before {
        content: $icon-double-person;
    }

    &.pin-filled:before {
        content: $icon-pin-filled;
    }

    &.fulscreen:before {
        content: $icon-fulscreen;
    }

    &.fulscreen-exit:before {
        content: $icon-fulscreen-exit;
    }

    &.share-filled:before {
        content: $icon-share-filled;
    }

    &.share-filled-after:after {
        content: $icon-share-filled;
    }

    &.circle-exclamation-filled:after {
        content: $icon-circle-exclamation-filled;
    }

    &.circle-exclamation-empty:before {
        content: $icon-circle-exclamation-empty;
    }

    &.voice-filled:before {
        content: $icon-voice-filled;
    }

    &.download:before {
        content: $icon-download;
    }

    &.code:before {
        content: $icon-code;
    }

    &.phone-empty:before {
        content: $icon-phone-empty;
    }

    &.phone-filled:before {
        content: $icon-phone-filled;
    }

    &.email:before,
    &.email-empty:before {
        content: $icon-email-empty;
    }

    &.email-filled:before {
        content: $icon-email-filled;
    }

    &.membership-card-empty:before {
        content: $icon-membership-card-empty;
    }

    &.delete-trash-empty:before {
        content: $icon-delete-trash-empty;
    }

    &.delete-trash-filled:before {
        content: $icon-delete-trash-filled;
    }

    &.update-refresh:before {
        content: $icon-update-refresh;
    }

    &.folder:before {
        content: $icon-folder;
    }

    &.folder-open:before {
        content: $icon-folder-open;
    }

    &.folder-open-filled:before {
        content: $icon-folder-open-filled;
    }

    &.arrow-left:before {
        content: $icon-arrow-left;
    }

    &.arrow-right:before {
        content: $icon-arrow-right;
    }

    &.bookmark-empty:before {
        content: $icon-bookmark-empty;
    }

    &.print:before {
        content: $icon-print;
    }

    &.reader-mode-empty:before {
        content: $icon-reader-mode-empty;
    }

    &.reader-mode-filled:before {
        content: $icon-reader-mode-filled;
    }

    &.shevron-up-keyboard-arrow-up:before {
        content: $icon-shevron-up-keyboard-arrow-up;
    }

    &.shevron-down-keyboard-arrow-down:before {
        content: $icon-shevron-down-keyboard-arrow-down;
    }

    &.shevron-right-keyboard-arrow-right:before {
        content: $icon-shevron-right-keyboard-arrow-right;
    }

    &.shevron-left-keyboard-arrow-left:before {
        content: $icon-shevron-left-keyboard-arrow-left;
    }

    &.shevron-left-down:before {
        content: $icon-shevron-left-down;
    }

    &.settings-empty:before {
        content: $icon-settings-empty;
    }

    &.settings-filled:before {
        content: $icon-settings-filled;
    }

    &.send:before {
        content: $icon-send;
    }

    &.lock-encryption-empty:before {
        content: $icon-lock-encryption-empty;
    }

    &.lock-open-unlock-empty:before {
        content: $icon-lock-open-unlock-empty;
    }

    &.criteria:before {
        content: $icon-criteria;
    }

    &.attributes:before {
        content: $icon-attributes;
    }

    &.fasttags:before {
        content: $icon-fasttags;
    }

    &.city-empty:before {
        content: $icon-city-empty;
    }

    &.dashboard:before {
        content: $icon-dashboard;
    }

    &.zoom-out:before {
        content: $icon-zoom-out;
    }

    &.zoom-in:before {
        content: $icon-zoom-in;
    }

    &.edit:before {
        content: $icon-edit;
    }

    &.edit-empty:before {
        content: $icon-create-empty;
    }

    &.alert-add-empty:before {
        content: $icon-alert-add-empty;
    }

    &.notification-off-filled:before {
        content: $icon-notification-off-filled;
    }

    &.exit:before {
        content: $icon-exit;
    }

    &.add-group-filled:before {
        content: $icon-add-group-filled;
    }

    &.on-off:before {
        content: $icon-on-off;
    }

    &.bookmark-filled:before {
        content: $icon-bookmark-filled;
    }

    &.arrow-in-circle-left-empty:before {
        content: $icon-arrow-in-circle-left-empty;
    }

    &.arrow-in-circle-right-empty:before {
        content: $icon-arrow-in-circle-right-empty;
    }

    &.book-cover:before {
        content: $icon-book-cover;
    }

    &.fiber-smart-record-filled:before {
        content: $icon-fiber-smart-record-filled;
    }

    &.fiber-smart-record-empty:before {
        content: $icon-fiber-smart-record-empty;
    }

    &.user-identity-person-empty:before {
        content: $icon-user-identity-person-empty;
    }

    &.user-identity-person-filled:before {
        content: $icon-user-identity-person-filled;
    }

    &.disk-empty:before {
        content: $icon-disk-empty;
    }

    &.chart-multiple-empty:before {
        content: $icon-chart-multiple-empty;
    }

    &.sort-filter-list:before {
        content: $icon-sort-filter-list;
    }

    &.control-point-duplicate-empty:before {
        content: $icon-control-point-duplicate-empty;
    }

    &.control-point-duplicate-filled:before {
        content: $icon-control-point-duplicate-filled;
    }

    &.cut-scissors-filled:before {
        content: $icon-cut-scissors-filled;
    }

    &.home-empty:before {
        content: $icon-home-empty;
    }

    &.power-empty:before {
        content: $icon-power-empty;
    }

    &.power-filled:before {
        content: $icon-power-filled;
    }

    &.flip-to-front:before {
        content: $icon-flip-to-front;
    }

    &.save-floppy-disk-empty:before {
        content: $icon-save-floppy-disk-empty;
    }

    &.menu-queue-list-playlist:before {
        content: $icon-menu-queue-list-playlist;
    }

    &.import-export-compare:before {
        content: $icon-import-export-compare;
    }

    &.hub-empty:before {
        content: $icon-hub-empty;
    }

    &.hub-filled:before {
        content: $icon-hub-filled;
    }

    &.copy-empty:before {
        content: $icon-copy-empty;
    }

    &.share-empty:before {
        content: $icon-share-empty;
    }

    &.printer-empty:before {
        content: $icon-printer-empty;
    }

    &.group-people-empty:before {
        content: $icon-group-people-empty;
    }

    &.theaters-empty:before {
        content: $icon-theaters-empty;
    }

    &.stars-empty:before {
        content: $icon-stars-empty;
    }

    &.stars-filled:before {
        content: $icon-stars-filled;
    }

    &.stars-filled--after:after {
        content: $icon-stars-filled;
    }

    &.new-empty:before {
        content: $icon-new-empty;
    }

    &.more-in-circle-empty:before {
        content: $icon-more-in-circle-empty;
    }

    &.more-in-circle-filled:before {
        content: $icon-more-in-circle-filled;
    }

    &.layers-empty:before {
        content: $icon-layers-empty;
    }

    &.search-person-empty:before {
        content: $icon-search-person-empty;
    }

    &.alarm-snooze-empty:before {
        content: $icon-alarm-snooze-empty;
    }

    &.alarm-snooze-filled:before {
        content: $icon-alarm-snooze-filled;
    }

    &.clock-alarm-empty:before {
        content: $icon-clock-alarm-empty;
    }

    &.find-replace-empty:before {
        content: $icon-find-replace-empty;
    }

    &.widgets-empty:before {
        content: $icon-widgets-empty;
    }

    &.opacity-filled:before {
        content: $icon-opacity-filled;
    }

    &.scheduled-video-empty:before {
        content: $icon-scheduled-video-empty;
    }

    &.play-empty:before {
        content: $icon-play-empty;
    }

    &.play-filled:before {
        content: $icon-play-filled;
    }

    &.assignment:before {
        content: $icon-assignment;
    }

    &.view-module-empty:before {
        content: $icon-view-module-empty;
    }

    &.view-module-filled:before {
        content: $icon-view-module-filled;
    }

    &.chart-bubble-filled:before {
        content: $icon-chart-bubble-filled;
    }

    &.thumb-down-empty:before {
        content: $icon-thumb-down-empty;
    }

    &.thumb-up-empty:before {
        content: $icon-thumb-up-empty;
    }

    &.shevron-in-circle-down-drop-down-empty:before {
        content: $icon-shevron-in-circle-down-drop-down-empty;
    }

    &.shevron-in-circle-down-drop-down-filled:before {
        content: $icon-shevron-in-circle-down-drop-down-filled;
    }

    &.reorder-empty:before {
        content: $icon-reorder-empty;
    }

    &.view-headline-list-text:before {
        content: $icon-view-headline-list-text;
    }

    &.view-quilt-empty:before {
        content: $icon-view-quilt-empty;
    }

    &.block-not-interested-empty:before {
        content: $icon-block-not-interested-empty;
    }

    &.block-not-interested-filled:after {
        content: $icon-block-not-interested-filled;
    }

    &.work-business-center-bag-empty:before {
        content: $icon-work-business-center-bag-empty;
    }

    &.call-missed-outgoing-empty:before {
        content: $icon-call-missed-outgoing-empty;
    }

    &.schedule-empty:before {
        content: $icon-schedule-empty;
    }

    &.subtitles-empty:before {
        content: $icon-subtitles-empty;
    }

    &.apps-empty:before {
        content: $icon-apps-empty;
    }

    &.overscan-empty:before {
        content: $icon-overscan-empty;
    }

    &.storage-empty:before {
        content: $icon-storage-empty;
    }

    &.library-empty:before {
        content: $icon-library-empty;
    }

    &.voice-empty:before {
        content: $icon-voice-empty;
    }

    &.check-circle-empty:before {
        content: $icon-check-circle-empty;
    }
    // Toolbox icons
    &.applications:before {
        content: $icon-applications;
    }

    &.audit:before {
        content: $icon-audit;
    }

    &.events:before {
        content: $icon-events;
    }

    &.control:before {
        content: $icon-control;
    }

    &.budgets:before {
        content: $icon-budgets;
    }

    &.calculations:before {
        content: $icon-calculations;
    }

    &.campaigns:before {
        content: $icon-campaigns;
    }

    &.catalog:before {
        content: $icon-catalog;
    }

    &.configuration:before {
        content: $icon-configuration;
    }

    &.contacts:before {
        content: $icon-contacts;
    }

    &.content:before {
        content: $icon-content;
    }

    &.financials:before {
        content: $icon-financials;
    }

    &.documents:before {
        content: $icon-documents;
    }

    &.equipment:before {
        content: $icon-equipment;
    }

    &.files:before {
        content: $icon-files;
    }

    &.forecast:before {
        content: $icon-forecast;
    }

    &.forms:before {
        content: $icon-forms;
    }

    &.goals:before {
        content: $icon-goals;
    }

    &.location:before {
        content: $icon-location;
    }

    &.notes:before {
        content: $icon-notes;
    }

    &.programs:before {
        content: $icon-programs;
    }

    &.rebates:before {
        content: $icon-rebates;
    }

    &.snapshot:before {
        content: $icon-snapshot;
    }

    &.usergroups:before {
        content: $icon-usergroups;
    }

    &.workflow:before {
        content: $icon-workflow;
    }

    &.dns-server-empty:before {
        content: $icon-dns-server-empty;
    }

    &.offer-empty:before {
        content: $icon-offer-empty;
    }

    &.view-list-list-bulleted:before {
        content: $icon-view-list-list-bulleted;
    }

    &.check-all-done-all:before {
        content: $icon-check-all-done-all;
    }

    &.dashboard-view-dashboard-empty:before {
        content: $icon-dashboard-view-dashboard-empty;
    }

    &.dashboard-view-dashboard-filled:before {
        content: $icon-dashboard-view-dashboard-filled;
    }

    &.cancel-clear-circle-highlight-off-filled:after {
        content: $icon-cancel-clear-circle-highlight-off-filled;
    }

    &.clock-time-watch-later-filled:after {
        content: $icon-clock-time-watch-later-filled;
    }

    &.clock-timeline-empty:after {
        content: $icon-clock-timeline-empty;
    }

    &.arrow-thin-right-down-empty:after {
        content: $icon-arrow-thin-right-down-empty;
    }

    &.archives-empty:after {
        content: $icon-archives-empty;
    }

    &.archives-empty--before:before {
        content: $icon-archives-empty;
    }

    &.more:before {
        content: $icon-more;
    }

    &.more-vertical:before {
        content: $icon-more-vertical;
    }

    &.search-in-page-empty:before {
        content: $icon-search-in-page-empty;
    }

    &.security-empty:before {
        content: $icon-security-empty;
    }

    &.info-empty:before {
        content: $icon-info-empty;
    }

    &.view-day-empty:before {
        content: $icon-view-day-empty;
    }

    &.view-day-filled:before {
        content: $icon-view-day-filled;
    }

    &.view-array-empty:before {
        content: $icon-view-array-empty;
    }

    &.view-array-filled:before {
        content: $icon-view-array-filled;
    }

    &.low-priority:before {
        content: $icon-low-priority;
    }

    &.arrow-in-circle-up-filled:before {
        content: $icon-arrow-in-circle-up-filled;
    }

    &.arrow-in-circle-up-empty:before {
        content: $icon-arrow-in-circle-up-empty;
    }

    &.arrow-in-circle-down-filled:before {
        content: $icon-arrow-in-circle-down-filled;
    }

    &.arrow-in-circle-down-empty:before {
        content: $icon-arrow-in-circle-down-empty;
    }

    &.touch-empty:before {
        content: $icon-touch-empty;
    }

    &.location-user-empty:before {
        content: $icon-location-user-empty;
    }

    &.location-user-filled:before {
        content: $icon-location-user-filled;
    }

    &.account-in-circle-filled:before {
        content: $icon-account-in-circle-filled;
    }

    &.arrow-thin-right-empty:before {
        content: $icon-arrow-thin-right-empty;
    }

    &.arrow-thin-down-empty:before {
        content: $icon-arrow-thin-down-empty;
    }

    &.backup-restore-empty:before {
        content: $icon-backup-restore-empty;
    }

    &.do-not-disturb-on-remove-circle-empty:after {
        content: $icon-do-not-disturb-on-remove-circle-empty;
    }

    &.do-not-disturb-on-remove-circle-filled:after {
        content: $icon-do-not-disturb-on-remove-circle-filled;
    }

    &.add-user-add-identity-add-person-empty:before {
        content: $icon-add-user-add-identity-add-person-empty;
    }

    &.crop-7x5-empty:before {
        content: $icon-crop-7x5-empty;
    }

    &.view-stream-view-agenda-empty:before {
        content: $icon-view-stream-view-agenda-empty;
        @include rotate(90deg);
    }

    &.view-stream-view-agenda-filled:before {
        content: $icon-view-stream-view-agenda-filled;
        @include rotate(90deg);
    }

    &.restore-undate-empty:before {
        content: $icon-restore-undate-empty;
    }

    &.movie-movies-empty:before {
        content: $icon-movie-movies-empty;
    }

    &.movie-movies-filled:before {
        content: $icon-movie-movies-filled;
    }

    &.timeline-empty:before {
        content: $icon-timeline-empty;
    }

    &.timeline-filled:before {
        content: $icon-timeline-filled;
    }

    &.help-empty:before {
        content: $icon-help-empty;
    }

    &.pause-filled:before {
        content: $icon-pause-filled;
    }

    &.replay-empty:before {
        content: $icon-replay-empty;
    }

    &.brightness-low-empty:before {
        content: $icon-brightness-low-empty;
    }

    &.cloud-empty:before {
        content: $icon-cloud-empty;
    }

    &.star-grade-empty:before {
        content: $icon-star-grade-empty;
    }

    &.text-wrap:before {
        content: $icon-text-wrap;
    }

    &.mailbox-empty:before {
        content: $icon-mailbox-empty;
    }

    &.date-range-empty:before {
        content: $icon-date-range-empty;
    }

    &.view-week-view-columns-empty:before {
        content: $icon-view-week-view-columns-empty;
    }

    &.font-empty:before {
        content: $icon-font-empty;
    }

    &.settings-applications-empty:before {
        content: $icon-settings-applications-empty;
    }

    &.report-empty:before {
        content: $icon-report-empty;
    }

    &.swap-vertical:before {
        content: $icon-swap-vertical;
    }

    &.repeat:before {
        content: $icon-repeat;
    }

    &.play-circle-empty:before {
        content: $icon-play-circle-empty;
    }

    &.play-circle-filled:before {
        content: $icon-play-circle-filled;
    }

    &.doc-removed-empty:before {
        content: $icon-doc-removed-empty;
        @include flip-horizontally;
    }

    &.undo:before {
        content: $icon-undo;
    }

    &.move-out:before {
        content: $icon-move-out;
    }

    &.domain-empty:before {
        content: $icon-domain-empty;
    }

    &.domain-filled:before {
        content: $icon-domain-filled;
    }

    &.aspect-ratio-empty:before {
        content: $icon-aspect-ratio-empty;
    }

    &.aspect-ratio-filled:before {
        content: $icon-aspect-ratio-filled;
    }

    &.screen-rotation-filled:before {
        content: $icon-screen-rotation-filled;
    }

    &.screen-rotation-empty:before {
        content: $icon-screen-rotation-empty;
    }

    &.lock-open-unlock-filled:before {
        content: $icon-lock-open-unlock-filled;
    }

    &.lock-filled:before {
        content: $icon-lock-filled;
    }

    &.show-to-all:before {
        content: $icon-show-to-all;
    }

    &.document-restore-empty:before {
        content: $icon-document-restore-empty;
    }

    &.shevron-in-circle-left-up-filled:before {
        content: $icon-shevron-in-circle-left-up-filled;
    }

    &.shevron-in-circle-right-down-filled:before {
        content: $icon-shevron-in-circle-right-down-filled;
    }

    &.desktop-empty:before {
        content: $icon-desktop-empty;
    }

    &.tablet-empty:before {
        content: $icon-tablet-empty;
    }

    &.smartphone-empty:before {
        content: $icon-smartphone-empty;
    }

    &.align-bottom:before {
        content: $icon-align-bottom;
    }

    &.align-top:before {
        content: $icon-align-top;
    }

    &.align-left:before {
        content: $icon-align-left;
    }

    &.align-right:before {
        content: $icon-align-right;
    }

    &.align-horizontal-center:before {
        content: $icon-align-horizontal-center;
    }

    &.align-vertical-center:before {
        content: $icon-align-vertical-center;
    }

    &.accessibility-filled:before {
        content: $icon-accessibility-filled;
    }

    &.accessibility-empty:before {
        content: $icon-accessibility-empty;
    }

    &.location-empty:before {
        content: $icon-location-empty;
    }

    &.info-type-icon:before {
        content: url("../img/info.svg");
    }

    &.attention-type-icon:before {
        content: url("../img/exclamation-mark.svg");
    }

    &.regular-type-icon:before {
        content: url("../img/tooltip.svg");
    }
}
