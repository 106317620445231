@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.roles-list {
    overflow: hidden;

    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
        background-color: white;
    }
}

.roles-list-body {
    @include themify($themes) {
        border-top: 1px solid themed("themeDirtyColor");
        border-bottom: 1px solid themed("themeDirtyColor");
    }

    .roles-list-scrollable-cells {
        align-items: center;
    }
}

.roles-list-row {
    &:hover {
        @include themify($themes) {
            background-color: themed("themeSemiWhiteColor");
        }
    }

    &--header {
        background-color: $white-light-grey;

        &:hover {
            @include themify($themes) {
                background-color: $white-light-grey;
            }
        }
    }
}

.roles-list-cell {
    padding: 8px;
    flex-shrink: 0;

    &--static {
        width: 20%;
        height: 100%;
        min-width: 240px;
        text-align: right;
    }

    &--clickable {
        cursor: pointer;
    }
}

.roles-list-role-btn {
    position: relative;
    padding: 10px 15px;
    border-radius: 4px;
    text-align: center;

    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }

    &--clickable {
        cursor: pointer;
    }

    &--highlight {
        @include themify($themes) {
            border: 1px solid themed("themBaseColor");
        }
    }

    &--loading {
        .roles-list-role-btn-title {
            visibility: hidden;
            height: 0;
        }
    }

    .btn-badge {
        position: absolute;
        right: 5px;
        top: -10px;
        border-radius: 9px;
        padding: 1px 5px;
        font-size: 12px;

        @include themify($themes) {
            background-color: white;
            border: 1px solid themed("themBaseColor");
        }
    }
}

.roles-list-header-title {
    text-align: center;
    font-weight: 500;

    .roles-list-cell--static & {
        text-align: left;
    }

    .roles-list-item-count {
        padding: 2px 5px;
        @include themify($themes) {
            background-color: themed("themeWhiteColor");
        }
    }
}

.roles-list-item-controls {
    text-align: initial;

    & + .roles-list-item-controls {
        margin-top: 8px;
    }

    .icon-wrap + .icon-wrap {
        margin-right: 5px;
    }
}

.roles-list-role-filter {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
}

.roles-list-scrollable-cells {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    @include themify($themes) {
        border-left: 1px solid themed("themeDirtyColor");
    }

    &--hide-scrollbar {
        @include scrollbar-hide;
    }

    .scroll-control {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.left {
            left: 0;
            @include themify($themes) {
                background-image: linear-gradient(to left, rgba(0, 0, 0, 0), $white-light-grey 50%);
            }
        }

        &.right {
            right: 0;
            @include themify($themes) {
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $white-light-grey 50%);
            }
        }

        .icon-wrap {
            position: initial;
        }
    }
}

.roles-list-scrollable-cells-body {
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
}
