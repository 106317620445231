@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.icon-dropdown {
    font-size: 15px;
    font-weight: 400;

    .dropdown-list-wrap.with-title {
        @include tablet {
            right: -17px;
            @include border-radius(4px);

            @include themify($themes) {
                border: 1px solid themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            }
        }

        .dropdown-list {
            border: none;
            box-shadow: none;

            &__header .dropdown-list-title {
                @include mobile-only {
                    display: none;
                }
            }
        }

        .dropdown-list-item {
            &.is-dragging-item {
                @include dragging-item-style;
            }

            &:focus:not(.dropdown-list-item-checked):not(:hover) {
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }

                .icon-wrap {
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }
                }
            }

            &:focus:not(:hover):not(.is-dragging-item) {
                background: transparent;
            }

            &:not(.dropdown-list-item-checked) {
                &:not(:hover):not(:focus) {
                    .icon-wrap {
                        @include themify($themes) {
                            color: themed("themeColorBaseGrey");
                        }
                    }
                }

                &:not(:hover) {
                    .item-active .fiber-smart-record-filled {
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                        }
                    }
                }

                .pinner {
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }
                }
            }
        }
    }
}

.field-group-dropdown-icon-popup__container {
    @include tablet {
        // Popup is misplaced if using default position with popper
        .dropdown-list-wrap {
            position: static;
        }
    }

    .dropdown-list-wrap.with-title {
        right: initial;
        top: initial;
        position: initial;
    }

    .dropdown-list-title {
        .settings-filled {
            position: absolute;
            right: 4px;
            top: 12px;
        }

        @include mobile-only {
            display: none;
        }
    }

    &[data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    }

    @include mobile-only {
        transform: initial !important;
        position: inherit !important;

        .dropdown-list-wrap.with-title {
            position: fixed !important;
            top: 0;
        }
    }
}
