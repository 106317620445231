@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.utility-financials {
    &__list {
        margin: 0 -10px;
        @include calc(width, "100% + 20px");
    }

    .list-body {
        @include flex-direction(row);
        @include flex-wrap(wrap);
        flex: 1;
        overflow: auto;
        justify-content: center;
        padding: 4px 0;

        @include ie {
            flex: 1 0 auto;
        }
    }

    .list-filter {
        z-index: 2;
        margin: 8px 10px 12px;
        padding: 0;
        border-bottom: none !important;

        @include tablet {
            margin: 15px auto;
            @include calc(width, "(100% / 2) - 20px");
        }

        @include tablet-large {
            .split-view & {
                .field-group {
                    margin-left: -5px;
                }
            }
        }
    }

    .custom-list {
        overflow: hidden;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            left: 0;
            top: 135px;
            width: 100%;
            height: 5px;
            background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.15));

            @include tablet-only {
                top: 128px;
            }

            @include tablet-large {
                left: 10px;
                top: 61px;
                @include calc(width, "100% - 20px");
            }
        }
    }

    .tile-item {
        @include flex-direction(row);
        justify-content: flex-start;
        margin: 10px;
        padding: 15px 40px 15px 15px;
        white-space: normal;
        border: 10px solid white;
        width: 100%;

        @include mobile-only {
            margin: 5px 10px;
        }

        @include tablet-and-mobile {
            padding-right: 70px;
            text-align: left;
        }

        @include tablet-only {
            @include calc(width, "(100% / 2) - 20px");
        }

        @include tablet-large {
            @include calc(width, "(100% / 4) - 20px");

            .split-view & {
                @include calc(width, "(100% / 2) - 20px");
            }
        }

        .title {
            margin: 0;
            padding: 0;

            .icon-wrap,
            &:after {
                display: none;
            }
        }

        .tile-action-icons {
            position: absolute;
            @include calc(top, "50% - 14px");

            @include tablet-and-mobile {
                right: 5px;
                @include flex-direction(row);
            }

            @include tablet-large {
                right: 0;
                @include calc(top, "50% - 28px");
                opacity: 0;
            }
        }

        &:not(:hover) {
            background: rgba(255, 255, 255, 0);
        }

        &:hover {
            .tile-action-icons {
                opacity: 1;
            }
        }
    }

    .nothing-found-block .icon-wrap:before {
        content: "0";
    }
}

.sidenav.contract-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
