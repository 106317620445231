@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.system-user-groups-panel {
    .grid-controls {
        margin-top: 0;
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    &__form {
        .bulk-edit-form {
            @include mobile-only {
                @include calc(height, "100vh - 52px");
                overflow-y: auto;
            }

            .system-user-groups {
                &--selected {
                    padding: 10px;

                    @include themify($themes) {
                        background: themed("themeWhiteColor");
                        border-top: 1px solid themed("themeDirtyColor");
                        border-bottom: 1px solid themed("themeDirtyColor");
                    }

                    @include mobile-only {
                        @include flex-direction(row);
                        overflow-x: auto;
                        @include themify($themes) {
                            border-left: 1px solid themed("themeDirtyColor");
                        }
                    }

                    @include tablet {
                        padding: 15px 20px;
                        overflow-y: auto;
                    }

                    > .flex-row {
                        background: rgba(255, 255, 255, 0.8);
                        @include border-radius(4px);

                        @include mobile-only {
                            @include flex-direction(column);
                            margin-right: 10px;
                            padding: 0 15px 5px;
                            align-items: flex-start;
                        }

                        &:not(:last-child) {
                            @include tablet {
                                margin-bottom: 8px;
                            }
                        }
                    }
                }

                &--column-names {
                    margin: 0 20px;

                    @include mobile-only {
                        display: none;
                    }
                }

                &__column-name,
                &__item {
                    font-size: em(12);

                    .font-size-large & {
                        font-size: em(14);
                    }
                }

                &__column-name {
                    padding: 0 10px 8px;
                    max-width: 25%;
                    font-weight: 500;

                    &:first-child {
                        padding-left: 0;
                    }
                }

                &__item {
                    @include mobile-only {
                        margin: 5px 0;
                        white-space: nowrap;
                        flex: auto;
                        align-items: flex-start;

                        &.flex-row {
                            @include flex-direction(column);
                        }
                    }

                    @include tablet {
                        padding: 3px 10px;

                        @include ie {
                            width: 25%;
                        }
                    }

                    .icon-wrap {
                        opacity: 0.5;

                        @include mobile-only {
                            left: -4px;
                        }

                        @include tablet {
                            margin-right: 5px;
                        }
                    }
                }

                &__item-label {
                    display: block;
                    font-size: em(10);
                    opacity: 0.6;

                    .font-size-large & {
                        font-size: em(12);
                    }

                    @include tablet {
                        display: none;
                    }
                }
            }

            .json-form-title {
                margin: 0 0 10px;
                padding: 20px 20px 0;
                font-weight: 500;

                @include mobile-only {
                    order: 1;
                }

                .icon-with-label {
                    @include mobile-only {
                        display: block;
                        float: left;
                        margin-top: 5px;
                    }

                    @include tablet {
                        margin-left: 15px;
                    }
                }

                b {
                    font-weight: 700;
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }

            .json-form {
                padding: 0 20px;
                height: initial;

                @include mobile-only {
                    padding-top: 15px;
                    overflow: auto;
                    order: 3;
                }

                .form-group {
                    @include mobile-only {
                        margin-bottom: 20px;
                    }

                    @include tablet-only {
                        margin-bottom: 0;
                    }
                }

                .action-buttons {
                    display: none;
                }
            }

            .selected-user-groups {
                @include mobile-only {
                    order: 2;
                    flex-shrink: 0;
                }

                @include tablet {
                    margin-top: 20px;
                }
            }
        }

        &.edit-user-group {
            .json-form .rjsf .form-group:not(.field-object) {
                margin-bottom: 20px;
            }

            .sidenav-body {
                @include ie {
                    height: 0;
                }

                @include firefox {
                    height: 0;
                }
            }
        }

        .sidenav-body {
            @include firefox {
                height: 0;
            }
        }
    }
}
