@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.calculations-modal {
    &.replace-selected-catalog-associations-confirmation-modal,
    &.replace-selected-event-associations-confirmation-modal,
    &.add-catalog-items-to-the-calc-associations,
    &.add-event-items-to-the-calc-associations,
    &.create-new-lookup-table-modal {
        .btn-group {
            padding-top: 20px;
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }
            text-align: center;
        }
    }

    &.create-new-lookup-table-modal {
        .field-group {
            margin-bottom: 10px;
        }

        .add-lookup-error {
            margin-bottom: 5px;
        }
    }

    .modal-body {
        .calculations-assign-form {
            .selected-items-expandable-panel {
                max-height: 30%;
                margin-bottom: 20px;
            }

            .status-msg {
                margin-bottom: 20px;
            }

            .calculation-uploaded-items-grid {
                .grid-list-row-container {
                    overflow: auto;
                    max-height: 440px;
                }
            }

            .grid-controls {
                margin-top: 0;
            }

            .grid-list {
                @include data-grid-sticky-header;
                overflow-y: auto;
            }
        }

        .lookup-table-after-add-controls {
            margin-top: 20px;
        }

        .btn-group.transparent {
            margin-top: 20px;
        }
    }
}
