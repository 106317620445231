@import "../../../assets/sass/_mixins";

.audit-types {
    @include tablet {
        align-items: center;
    }

    &.flex-row {
        @include mobile-only {
            @include flex-wrap(wrap);
        }
    }

    > span {
        display: inline-block;
        margin-right: 15px;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }

        @include mobile-only {
            width: 100%;
        }
    }

    .radio-wrap {
        margin-top: 6px;

        @include tablet {
            margin-top: 0;
        }

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}
