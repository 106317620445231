@import "../../../../assets/sass/_functions";

.paging-wrap {
    padding: 12px 13px 12px 36px;
    font-size: em(12);
    font-weight: 500;
    text-align: left;

    .font-size-large & {
        font-size: em(14);
    }

    .icon-wrap {
        top: -5px;
    }
}
