@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

@mixin formGrid($columns) {
    > fieldset > .object-properties {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat($columns, 1fr);

        @for $i from 1 through $columns {
            .span-columns-#{$i}-container {
                grid-column: span $i;
            }
        }
    }

    .fill-width-container {
        grid-column: 1 / -1;
    }
}
.json-form .rjsf .qc-grid-container fieldset:first-child:first-of-type {
    display: flex;
    flex-direction: column-reverse;
}
.qc-grid-container {
    .grid-columns-3 {
        > fieldset > .object-properties {
            display: flex;
            .fill-width-container {
                width: 70%;
                border-right: 1px solid #dae2e4;
                .fill-width {
                    width: 97%;
                }
            }
        }
    }
}

.qc-grid-container {
    .dropzone-text {
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .dropzone-file-list {
        max-height: 100%;

        margin-top: 0;
    }
    .upload-file-area {
        justify-content: center;
        height: 15rem;
        align-items: center;
        display: flex;
    }
    .unknown-container {
        width: 30%;
        padding-left: 0.5rem;
    }
    .info-msg {
        margin-top: 25px;
    }
    .info-text {
        color: $base-grey;
        font-style: italic;
        b {
            color: $black;
            font-style: normal;
        }
    }
}
.json-form {
    .rjsf {
        .qc-grid-container ~ .qc-uploads-buttons {
            margin-top: 10px;
        }
    }
}

.json-form {
    width: 100%;
    height: 100%;

    button {
        line-height: normal;
    }

    .rjsf {
        .control-label {
            display: inline-block;
            margin-bottom: 8px;
            font-size: em(12);
            max-width: 100%;
            overflow: hidden;
            overflow-wrap: break-word;

            .font-size-large & {
                font-size: em(14);
            }
        }

        .dropzone-area {
            padding: 15px;
            @include border-radius(8px);
            @include themify($themes) {
                border: 2px dashed themed("themeBaseColor");
            }

            .dropzone-text {
                @include flexbox;

                .icon-wrap {
                    height: 18px;
                    line-height: 18px;

                    &:before {
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                        }
                    }
                }

                > span {
                    font-size: em(12);
                    line-height: 18px;
                    font-weight: 500;

                    .font-size-large & {
                        font-size: em(14);
                    }
                }

                .upload-link {
                    display: inline-block;
                    margin-left: 3px;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                    cursor: pointer;
                }

                + .dropzone-file-list {
                    margin-top: 10px;
                }
            }

            .dropzone-file-list {
                margin-left: 5px;

                li {
                    @include flexbox;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                .status-msg {
                    line-height: 18px;
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }

                .icon-wrap {
                    margin: -4px 0 0 3px;

                    &:before {
                        font-size: 14px;
                    }
                }
            }

            &.file-upload-disabled {
                border-color: $grey;
                pointer-events: none;

                .dropzone-text {
                    > span {
                        color: $light-grey;
                    }

                    .upload-link,
                    .icon-wrap:before {
                        color: $grey;
                    }
                }
            }
        }

        .schema-container ul.error-detail .status-msg {
            margin-top: 10px;
        }
    }
}

//to be reviewed
.json-form .rjsf fieldset:first-child:first-of-type {
    margin-top: 0;
}

.json-form .rjsf fieldset {
    border-width: 1px 0 0;
    border-style: solid;
    min-inline-size: auto;
}

.json-form .rjsf legend {
    padding: 10px 16px 10px 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;

    .font-size-large & {
        font-size: 15px;
    }
}

.json-form .rjsf .field-description {
    white-space: pre-line;
    padding-bottom: 10px;
    font-size: $base-font-size;
    overflow-wrap: break-word;
    overflow: hidden;
}

.json-form .rjsf .field-description:empty {
    height: 0;
    padding: 0;
}

.json-form .rjsf .form-group {
    &:not(.field-object) {
        margin-bottom: 10px;
    }

    .unsupported-field {
        pre {
            white-space: pre-wrap;
        }
    }

    textarea {
        resize: vertical;
        position: relative;
    }
}

.json-form .rjsf .form-group.fill-width {
    input,
    textarea,
    .field-group.with-dropdown {
        max-width: 100%;
    }

    .field-group.inline {
        width: 100%;

        .numeric-wrap,
        .number {
            width: 100%;
        }
    }
}

.json-form .rjsf .form-group.field-number {
    .field-group.fill-width {
        .numeric-wrap,
        .number {
            width: 100%;
        }
    }
}

.json-form .rjsf .help-block,
.json-form .rjsf .file-info {
    font-size: 13px;
    list-style: none;
    color: rgba(0, 0, 0, 0.54);
    line-height: 1;
    margin-top: 9px;
    display: flex;
    align-items: center;

    .font-size-large & {
        font-size: 15px;
    }
}

.json-form .rjsf .help-block span {
    z-index: 1;
}

.json-form .rjsf .text-danger {
    display: flex;
    align-items: center;
}

.json-form .rjsf .field-radio-group label span {
    display: flex;
    align-items: center;
}

.json-form .rjsf .field-radio-group .radio {
    margin-bottom: 10px;
}

.json-form .rjsf .field-radio-group .radio-inline,
.json-form .rjsf .checkboxes .checkbox-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: pointer;

    &:not(:first-child) {
        padding-left: 20px;
    }
}

.json-form .rjsf .action-buttons {
    margin-top: 30px;

    .btn:not(:last-child) {
        margin-right: 10px;
    }
}

.json-form .rjsf .required {
    font-size: 12px;
    padding: 0 2px;

    .font-size-large & {
        font-size: 14px;
    }
}

.json-form .page {
    padding: 20px;
}

.json-form .page > fieldset > .object-properties > .schema-container:not(:last-child) {
    margin-bottom: 5px;
}

.json-form .section {
    padding: 20px 0;
}

.json-form .section > fieldset > .object-properties,
.json-form .row > fieldset > .object-properties {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .schema-container.unknown-container {
        display: flex;

        .field-boolean,
        .field-radio-group {
            padding-bottom: 7px;
        }
    }
}

.json-form .row-container {
    min-width: 100%;

    .field-container:not(.fill-width):not(:last-child),
    .unknown-container:not(.fill-width):not(:last-child) {
        margin-right: 20px;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .form-group.row {
        flex: 1 1;
        align-self: auto;
    }
}

.json-form .column-container {
    width: 0;

    & + .column-container {
        @include tablet {
            margin-left: 20px;
        }
    }
}

.json-form .form-group.column {
    flex: 1 1;
    align-self: auto;
}

.json-form .schema-container .column {
    height: 100%;
}

.json-form .rjsf .column > fieldset,
.json-form .rjsf fieldset.no-title {
    border: none;
}

.json-form .section > fieldset > .object-properties > .schema-container {
    align-self: auto;
    flex: 1 1;
}

.json-form .rjsf .field-group.with-dropdown {
    width: 100%;
    min-width: 234px;
    max-width: 234px;
}

.json-form .rjsf .form-group .field-with-button-right {
    display: flex;
    flex-direction: row;

    .field-group.inline,
    .field-group.inline .numeric-wrap,
    .field-group.inline .number {
        width: 100%;
    }

    .btn {
        margin-left: 10px;
    }
}

/* Array items */

.json-form .field-array .field-array-of-object,
.json-form .field-array .field-array-of-string {
    position: relative;
}

.json-form .field-array .array-item {
    position: relative;
    padding: 10px;
    @include themify($themes) {
        background-color: themed("themeBaseColorSemiTransparent12");
    }
}

.json-form .field-array .field-array-of-string .array-item {
    padding-top: 25px;
}

.json-form .field-array .array-item:last-child {
    margin-bottom: 5px;
}

.json-form .field-array .array-item:not(:last-child) {
    @include themify($themes) {
        border-bottom: 1px solid themed("themeBaseColorSemiTransparent12");
    }
}

.json-form .field-array .form-group.field-object {
    padding: 0;
}

/* Custom array buttons */
.json-form .field-array .array-item-toolbox {
    position: absolute;
    top: 0;
    right: 0;
}

.json-form .field-array .array-item-add button {
    border: none;
    background: inherit;
    flex: 1 1 0%;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 700;
}

.json-form .field-array .btn-group {
    background: none !important;
    padding: 0;
}

.json-form .field-array .array-item-remove {
    border: none;
    background: transparent;
    font-size: 25px;
    line-height: 16px;
    color: black;
    font-weight: normal !important;
    overflow: hidden;
    opacity: 0.21;
    padding: 0 1px !important;
}

.json-form .field-array .array-item-remove:hover {
    color: black;
}

.json-form .field-array i.glyphicon {
    display: none;
}
.json-form .field-array .btn-add::after {
    content: "add";
    text-decoration: underline;
    font-weight: normal;
}
.json-form .field-array .array-item-move-up::after {
    content: "Move Up";
}
.json-form .field-array .array-item-move-down::after {
    content: "Move Down";
}
.json-form .field-array .array-item-remove::after {
    content: "×";
}

.svg-icon {
    display: inline-block;
    margin-right: 6px;

    &.icon-size-18 {
        width: 18px;
        height: 18px;
    }

    path {
        fill: $base-grey;
    }
}

.inline-form {
    > fieldset > .object-properties {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: -10px;
        @include calc(width, "100% + 20px");

        > .schema-container {
            margin: 10px;
        }
    }

    &.form-columns-2 {
        .schema-container {
            @include calc(width, "(100% / 2) - 20px");
        }
    }

    &.form-columns-3 {
        .schema-container {
            @include calc(width, "(100% / 3) - 20px");
        }
    }

    &.form-columns-4 {
        .schema-container {
            @include calc(width, "(100% / 4) - 20px");

            @include mobile-only {
                @include calc(width, "100% + 20px");
            }
        }
    }

    &.form-columns-5 {
        .schema-container {
            @include calc(width, "(100% / 5) - 20px");
        }
    }

    &.form-columns-6 {
        .schema-container {
            @include calc(width, "(100% / 6) - 20px");
        }
    }

    .flex-grow-container {
        flex-grow: 100;
    }

    .schema-container.fill-width {
        width: 100%;
    }
}

.grid-columns-4 {
    @include formGrid(2);

    @include mobile-only {
        @include formGrid(1);
    }

    @include tablet-large {
        @include formGrid(4);
    }
}

.grid-columns-3 {
    @include formGrid(3);
}

.grid-columns-2 {
    @include formGrid(2);
}

.json-form.hide-disabled-fields {
    & .field-hidden {
        display: none;
    }
}

.schema-container {
    &.field-administrative {
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
        }
    }

    &.hidden-attribute-container,
    &.field-administrative {
        position: relative;

        .icon-wrap {
            position: absolute;
            top: 0;

            &.visibility-off-empty,
            &.administrative-field-icon {
                right: 10px;
                @include border-radius(0 0 50% 50%);
            }

            &.administrative-field-icon {
                @include themify($themes) {
                    background: themed("themeBaseColorSemiTransparent12");
                }
            }

            &.visibility-off-empty {
                @include themify($themes) {
                    background: themed("themeBaseColorDesaturatedSemiTransparent10");
                }
            }
        }

        .control-label {
            padding-right: 40px;
        }
    }

    &.hidden-attribute-container {
        margin: 5px -10px 0;
        padding: 15px 10px 10px;
        @include calc(width, "100% + 20px");
        @include themify($themes) {
            background: themed("themeBaseColorDesaturatedSemiTransparent5");
        }
        @include border-radius(8px);

        .json-form .rjsf .object-properties & .form-group {
            margin: 0;
        }

        + .schema-container:not(.hidden-attribute-container) {
            margin-top: 20px;
        }
    }
}

// HTML table styles in forms
.json-form {
    .schema-container table {
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
        width: 100%;
        border-collapse: collapse;
        @include firefox {
            border-collapse: initial;
            border-spacing: 0px;
        }
        @include mobile-only {
            display: block;
            overflow-x: auto;
        }

        td,
        th {
            padding: 5px 10px;
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }

        td {
            @include themify($themes) {
                color: themed("themeColorLocked");
            }
        }

        th {
            background: $white-light-grey;
            text-align: left;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }
    }
}
