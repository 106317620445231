@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.accessibility-controls {
    padding: 7px;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeDirtyColor");
    }
}
