@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.utility-user-groups-selector {
    .list-filter-container {
        padding: 10px 20px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    .column-left .list-filter-container {
        @include themify($themes) {
            border-right: 1px solid themed("themeDirtyColor");
        }
    }

    .group-list {
        overflow: auto;

        .group {
            align-items: center;
            padding: 5px 8px 5px 20px;
            min-height: 44px;
            font-size: em(12);
            font-weight: 500;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }
            cursor: pointer;
            word-break: break-word;

            @include ie {
                word-wrap: break-word;
            }

            .font-size-large & {
                font-size: em(14);
            }

            .icon-wrap {
                margin-left: 10px;
                min-width: 28px;

                @include tablet-large {
                    opacity: 0;
                }
            }

            &:hover {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                    color: themed("themeBaseColor");
                }

                .icon-wrap {
                    opacity: 1;
                }
            }
        }

        &.selected {
            @include themify($themes) {
                border-left: 1px solid themed("themeDirtyColor");
            }

            .group {
                text-align: right;
                padding: 5px 20px 5px 8px;

                .icon-wrap {
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    + .sidenav-footer {
        align-items: flex-end;

        > .btn-light-blue {
            margin: 0;
        }
    }
}
