@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.two-fa-setup-steps {
    margin: 20px 0;

    @include tablet-large {
        @include flexbox;
    }

    ul {
        counter-reset: step;
        padding: 15px;
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
        }

        @include tablet-large {
            width: 59%;
            margin-right: 10px;
        }

        li {
            position: relative;
            padding-left: 25px;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }

            @include tablet-large {
                font-size: em(13);

                .font-size-large & {
                    font-size: em(14);
                }
            }

            &:not(:last-child) {
                margin-bottom: 10px;

                @include tablet-large {
                    margin-bottom: 10px;
                }
            }

            &:before {
                counter-increment: step;
                content: counter(step);
                position: absolute;
                top: 0;
                left: 0;
                font-size: em(30);
                font-weight: 700;
                line-height: 1;
                @include themify($themes) {
                    color: themed("themeLightColor");
                }
            }

            .icon-wrap {
                display: none;
                height: inherit;
                line-height: inherit;

                @include tablet {
                    display: inline-block;
                }

                &:before {
                    font-size: em(14);
                    font-weight: 700;
                }
            }
        }
    }

    .qr-code-wrap {
        @include flexbox;
        align-items: center;

        @include tablet-and-mobile {
            padding: 0 15px 15px 40px;
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }
        }

        .qr-code {
            @include tablet-and-mobile {
                width: auto !important;
                max-width: 100%;
                height: auto !important;
                max-height: 210px;
            }
        }

        @include tablet-large {
            width: 41%;
        }
    }
}

.login-form-wrap .form-btn-wrap.qr-continue {
    padding: 0 !important;
    align-items: flex-start;

    .field-group {
        padding: 0;
    }

    .flex-one,
    .btn {
        margin-top: 24px;
    }

    @include tablet-and-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .field-group {
            width: 100%;
            margin-bottom: 0;
        }

        .flex-one {
            justify-content: flex-start;
            .login-form-link {
                margin: 0;
            }
        }
    }
}
