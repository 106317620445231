@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/mixins";

.application-document-queue {
    &__grid-document-list {
        position: relative;
        background: rgba(255, 255, 255, 0.6);

        &:after,
        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
        }

        &:before {
            top: 0;
            height: 4px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12));
        }

        &:after {
            bottom: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12));
        }

        &-columns {
            padding: 7px 15px 5px 74px;
            background: white;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
            text-transform: uppercase;
            font-size: 10px;

            @include mobile-only {
                font-weight: 500;
            }

            @include tablet {
                font-size: 11px;

                .font-size-large & {
                    font-size: 13px;
                }
            }

            > div {
                width: 25%;

                &:not(:last-child) {
                    padding-right: 10px;
                }
            }

            @include mobile-only {
                &--application,
                &--document {
                    width: initial !important;
                }

                &--document {
                    @include mobile-only {
                        min-width: 65px;
                        max-width: 65px;
                    }
                }

                &--application {
                    min-width: 80px;
                    max-width: 80px;
                }

                &--date {
                    text-align: right;
                }
            }

            .application-document-queue__batch-grid-wrapper &,
            .application-document-queue__archived-batch-grid-wrapper & {
                padding-left: 44px;
            }

            /*            .application-document-queue__grid-entry-row-container.without-scroll + .application-document-queue__grid-document-list & {
                padding-right: 44px;
            }*/
        }
    }
}
