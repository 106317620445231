@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.quality-control-panel-list {
    .grid-list-row {
        &:not(.grid-list-row-header) {
            align-items: flex-start;
            padding: 7px 13px;
            height: auto;

            .grid-list-column {
                padding: 0 0 0 20px;
                flex: 1 1;
                overflow: inherit !important;
                white-space: normal !important;

                &.type {
                    padding-left: 0;
                    flex: unset;
                    width: 132px;
                    font-weight: 500;

                    .subtitle {
                        margin-top: 3px;
                        font-size: 11px;
                        font-weight: 400;

                        .font-size-large & {
                            font-size: 13px;
                        }

                        > div {
                            display: inline-block;
                            margin-right: 4px;
                            @include themify($themes) {
                                color: themed("themeColorDarkGrey");
                            }
                        }

                        .empty {
                            opacity: 0.5;
                        }
                    }
                }

                &:last-child {
                    text-align: right;
                }
            }

            &:hover:not(.filter):not(.selected):not(.details) {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
            }
        }
    }

    .list-header-row {
        padding: 15px 13px 10px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .header {
            width: 132px;

            &:not(:first-child) {
                padding-left: 20px;
                flex: 1 1;
                width: auto;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    .footer {
        justify-content: space-between;
        height: 48px;
        padding-right: 10px;
        background-color: $white-light-grey;
        @include border-radius(0 0 6px 6px);

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        .counter {
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }
    }
}
