@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.txt-viewer-container {
    padding: 8px;

    .txt-viewer {
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}
