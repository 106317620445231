@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item--heading {
    margin-right: 10px;

    .property-list-item {
        &.property-list-item--heading-color-common {
            margin: 0 20px 17px;
        }
    }

    .property-list > .property-list-item--heading-color {
        margin: 0 20px 17px;

        .property-list-item {
            width: 100%;
        }
    }
}
