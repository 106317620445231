@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.workflow-tasks-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid;
    border-radius: 4px;

    &.task {
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
            border-color: themed("themeBaseColor");
        }
    }

    &.ad-hoc {
        @include themify($themes) {
            background: themed("themeBaseColor");
            border-color: themed("themeBaseColor");
        }
    }

    &.automated {
        @include themify($themes) {
            background: themed("themeDarkColor");
            border-color: themed("themeDarkColor");
        }
    }

    &.dependent {
        @include themify($themes) {
            background: themed("themeLighterDirtyColor");
            border-color: themed("themeBaseColorDesaturated");
        }
    }

    &.skipped {
        background: white !important;
        @include themify($themes) {
            border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent");
        }
    }

    &.completed-status-indicator {
        &.task {
            background: $green-light;
            border: 1px solid $green;
        }

        &.ad-hoc {
            background: $green;
            border: 1px solid $green;
        }

        &.automated {
            @include themify($themes) {
                background: themed("themeDarkColor");
                border-color: themed("themeDarkColor");
            }
        }
    }

    &.skipped-status-indicator {
        &.task {
            background: white;
            @include themify($themes) {
                border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent");
            }
        }
    }
}
