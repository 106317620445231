@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_variables";

.add-ad-hoc-task-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            @include mobile-only {
                width: 100%;
            }

            &.term-container,
            &.autocomplete-rule-mobile-container {
                padding-bottom: 20px;
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }

                > .form-group {
                    @include tablet {
                        @include calc(width, "(100% / 2) - 10px");
                    }
                }
            }
        }
    }

    .utility-user-groups-selector {
        + .sidenav-footer {
            @include mobile-only {
                position: relative;
                align-items: flex-start;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 62px;
                    left: 20px;
                    @include calc(width, "100% - 40px");
                    height: 1px;
                    @include themify($themes) {
                        background: themed("themeLighterDirtyColor");
                    }
                }

                > .flex-row {
                    @include flex-direction(column);

                    .btn {
                        margin: 0 0 0 17px;

                        + .btn {
                            margin-top: 30px;
                            align-self: flex-end;
                        }
                    }
                }
            }
        }
    }
}
