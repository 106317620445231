$site-max-width: 1440px;
$site-min-width: 1180px;

$site-max-width-new: 1600px;
$site-min-width-new: 1280px;

$branding-color-white-shadow-bottom-outer: 0 2px 2px 0 rgba(0, 0, 0, 0.25);

$icon-edit: "\EBE4";
$icon-plus: "\EB90";
$icon-add-create-plus-circle-filled: "\EB8F";
$icon-document-text: "\E914";
$icon-folder: "\E91C";
$icon-folder-open: "\E920";
$icon-folder-open-filled: "\E91F";
$icon-earth: "\EB4F";
$icon-receipt: "\E966";
$icon-list-check: "\EC32";
$icon-personal-calendar: "\E8CC";
$icon-flag-empty: "\EC09";
$icon-flag-filled: "\F11A";
$icon-circle-left-up-filled: "\E80C";
$icon-fulscreen: "\E835";
$icon-fulscreen-exit: "\E836";
$icon-octagon-exclamation-filled: "\EB87";
$icon-circle-exclamation-empty: "\EB62";
$icon-circle-exclamation-filled: "\EB61";
$icon-code: "\E82A";
$icon-download: "\E82C";
$icon-assignment-turned: "\E8F5";
$icon-assignment: "\E8EB";
$icon-arrow-left: "\F11D";
$icon-arrow-right: "\F120";
$icon-view-module-empty: "\EC72";
$icon-view-module-filled: "\EC71";
$icon-align-bottom: "\E801";
$icon-align-top: "\E804";
$icon-align-left: "\E802";
$icon-align-right: "\E803";
$icon-align-horizontal-center: "\E82D";
$icon-align-vertical-center: "\E82E";

// Toolbox icons
$icon-programs: "\E8F3";
$icon-snapshot: "\E9B6";
$icon-files: "\E920";
$icon-usergroups: "\EADC";
$icon-calculations: "\E9D9";
$icon-rebates: "\E95A";
$icon-forecast: "\EC0A";

$icon-budgets: "\EB39";
$icon-goals: "\E8E9";
$icon-catalog: "\E94A";
$icon-forms: "\E8EB";
$icon-content: "\E94A";
$icon-documents: "\E930";

$icon-criteria: "\E878";
$icon-attributes: "\EC3A";
$icon-fasttags: "\E92A";
$icon-dashboard: "\E918";

/* -------------------------------------------------------------- 
    UI library
-------------------------------------------------------------- */
// Colors
$dark-grey: #333333;
$base-grey: #666666;
$medium-grey: #85929d;
$light-grey: #b7b7b7;
$light-grey-medium: #d7d7d7;
$grey: #cccccc;
$white-grey: #f3f3f3;
$white-grey-medium: #f6f6f6;
$white-grey-dark: #f0f1f1;
$white-light-grey: #f8f9f9;
$white-grey-light: #f9f9f9;
$sightline-white-light-grey: #fafbfc;
$blue-grey: #868c8f;
$blue-dark-grey: #71777a;
$black: #000000;
$widget-list-item-border-color: #eceded;
$widget-content-wrapper-border-color: #ced4da;

$blue: #0984e3;
$dark-blue: #06508a;
$blue-white: #b3d8f6;

$red: #cc0000;
$red-white: #fdf5f5;
$red-light: #f9e5e5;
$red-medium-light: rgba(204, 0, 0, 0.1);
$red-medium: rgba(204, 0, 0, 0.25);
$red-gradient-to-bottom: linear-gradient(to bottom, rgba(204, 0, 0, 0.01), rgba(204, 0, 0, 0.08));
$red-gradient-to-top: linear-gradient(to top, rgba(204, 0, 0, 0.01), rgba(204, 0, 0, 0.08));
$red-shadow: 0 0 8px 0 rgba(204, 0, 0, 0.65);
$red-field-error-light: rgba(208, 2, 27, 0.25);
$red-field-error: rgb(208, 2, 27);
$bs-link-blue: #0d6bf4;
$bs-link-blue-hover: #0a58ca;

$warning: #eb8100;
$warning-light: #ffedd8;
$warning-gradient: linear-gradient(to bottom, rgba(235, 129, 0, 0.01), rgba(235, 129, 0, 0.08));

$green: #0f9d58;
$green-light: #edf9e1;
$green-light-semi-transparent-60: rgba(237, 249, 225, 0.6);
$green-light-semi-transparent-35: rgba(237, 249, 225, 0.35);
$green-white: #fafef5;
$green-medium: #b5e1be;
$green-medium-light: #e1f5cd;
$green-gradient: linear-gradient(to bottom, rgba(15, 157, 88, 0.01), rgba(15, 157, 88, 0.08));
$green-success: #00cc33;
$green-pale: #f6fcf0;

$shadow-inner: inset 8px 0 9px -8px rgba(0, 0, 0, 0.25);
$shadow-outer: 0 1px 8px 0 rgba(0, 0, 0, 0.25);
$shadow-outer-hover: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
$shadow-outer-center: 0 0 4px 0 rgba(0, 0, 0, 0.25);
$shadow-outer-hover-lighter: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
$shadow-light: 0 1px 2px 0 rgba(45, 52, 54, 0.11);

//fonts
$base-font-family: "Roboto", sans-serif;
$font-family-iconoteka: "iconoteka";
$base-font-size: 15px;
$base-font-size-iframe: 14px;

// Iconoteka font icons
$icon-warning-report-problem-filled: "\EB8D";
$icon-warning-report-problem-empty: "\EB8E";
$icon-cancel-clear-circle-highlight-off-filled: "\EBC3";
$icon-cancel-clear-circle-highlight-off-empty: "\EBC4";
$icon-view-grid-filled: "\EC6D";
$icon-view-stream-view-agenda-filled: "\EC75";
$icon-shevron-small-down-expand-more: "\F135";
$icon-shevron-small-up-expand-less: "\F13C";
$icon-shevron-small-right: "\F139";
$icon-shevron-small-left: "\F136";
$icon-shevron-small-down: "\F13A";
$icon-checkbox-checked-filled: "\EBD6";
$icon-checkbox-unchecked-filled: "\F116";
$icon-checkbox-unchecked-empty: "\EBD9";
$icon-indeterminate-checkbox-filled: "\EC10";
$icon-radio-button-checked: "\EC36";
$icon-circle-radio-button-unchecked-filled: "\F117";
$icon-circle-radio-button-unchecked-empty: "\EBDB";
$icon-link: "\E885";
$icon-open-with: "\E83E";
$icon-open-new-window: "\E83D";
$icon-star-filled: "\F11B";
$icon-star-empty: "\EC5A";
$icon-upload: "\E87F";
$icon-attachment: "\E8F6";
$icon-backspace-alt-empty: "\EB9D";
$icon-shevron-in-circle-down-filled: "\E84D";
$icon-shevron-in-circle-right-empty: "\E856";
$icon-shevron-in-circle-left-empty: "\E850";
$icon-shevron-in-circle-left-filled: "\E84F";
$icon-shevron-in-circle-up-filled: "\E85B";
$icon-clear-close: "\F118";
$icon-restore-update: "\E8C4";
$icon-check-done: "\F115";
$icon-alert-add-empty: "\EB95";
$icon-notification-off-filled: "\EC23";
$icon-settings-empty: "\EC45";
$icon-settings-filled: "\EC44";
$icon-pin-empty: "\EB00";
$icon-target: "\EB0D";
$icon-pin-filled: "\EAFF";
$icon-delete-trash-empty: "\EBF8";
$icon-delete-trash-filled: "\EBF7";
$icon-info-filled: "\EB7C";
$icon-chart-line: "\F114";
$icon-arrow-right-up: "\F122";
$icon-visibility-off: "\EAEE";
$icon-eye-visibility-empty: "\EAC0";
$icon-eye-visibility-filled: "\EABF";
$icon-visibility-off-empty: "\EAEE";
$icon-search: "\EC3C";
$icon-search-off: "\EC3E";
$icon-credit-card-payment-filled: "\E95F";
$icon-check-circle-filled: "\EBD4";
$icon-print: "\EA25";
$icon-reader-mode-empty: "\EC39";
$icon-reader-mode-filled: "\EC38";
$icon-shevron-up-keyboard-arrow-up: "\F13D";
$icon-shevron-down-keyboard-arrow-down: "\F12E";
$icon-shevron-right-keyboard-arrow-right: "\F132";
$icon-shevron-left-keyboard-arrow-left: "\F130";
$icon-shevron-left-down: "\F12F";
$icon-lock-encryption-empty: "\EAFA";
$icon-lock-open-unlock-empty: "\EAFC";
$icon-zoom-out: "\E881";
$icon-zoom-in: "\E880";
$icon-create-empty: "\EBE5";
$icon-exit: "\E832";
$icon-phone-empty: "\E88F";
$icon-phone-filled: "\E88E";
$icon-membership-card-empty: "\E964";
$icon-email-empty: "\E936";
$icon-email-filled: "\E935";
$icon-add-group-filled: "\EAA7";
$icon-voice-filled: "\EAEF";
$icon-on-off: "\EA01";
$icon-bookmark-filled: "\E8F9";
$icon-arrow-in-circle-left-empty: "\E809";
$icon-arrow-in-circle-right-empty: "\E80F";
$icon-book-cover: "\E8F8";
$icon-send: "\E8A3";
$icon-fiber-smart-record-filled: "\EC05";
$icon-fiber-smart-record-empty: "\EC06";
$icon-double-person: "\EACC";
$icon-user-identity-person-empty: "\EAEC";
$icon-user-identity-person-filled: "\EAEB";
$icon-disk-empty: "\E9C0";
$icon-bookmark-empty: "\E8FA";
$icon-city-empty: "\EA62";
$icon-chart-multiple-empty: "\EBCF";
$icon-sort-filter-list: "\EC58";
$icon-update-refresh: "\E87E";
$icon-control-point-duplicate-empty: "\EBE1";
$icon-control-point-duplicate-filled: "\EBE0";
$icon-cut-scissors-filled: "\EBF2";
$icon-home-empty: "\EA68";
$icon-power-empty: "\EA03";
$icon-power-filled: "\EA02";
$icon-flip-to-front: "\EC0B";
$icon-save-floppy-disk-empty: "\EA2B";
$icon-menu-queue-list-playlist: "\EC18";
$icon-import-export-compare: "\E837";
$icon-hub-empty: "\E9D4";
$icon-hub-filled: "\E9D3";
$icon-copy-empty: "\EBE3";
$icon-share-empty: "\EC47";
$icon-share-filled: "\EC46";
$icon-printer-empty: "\EA25";
$icon-group-people-empty: "\EACC";
$icon-theaters-empty: "\EB58";
$icon-stars-empty: "\EC5D";
$icon-stars-filled: "\EC5C";
$icon-new-empty: "\EB7F";
$icon-more-in-circle-empty: "\EC1B";
$icon-more-in-circle-filled: "\EC1A";
$icon-layers-empty: "\EB23";
$icon-search-person-empty: "\EC40";
$icon-alarm-snooze-empty: "\E8B1";
$icon-alarm-snooze-filled: "\E8B0";
$icon-clock-alarm-empty: "\E8BB";
$icon-find-replace-empty: "\EC07";
$icon-widgets-empty: "\EC7A";
$icon-opacity-filled: "\EC27";
$icon-scheduled-video-empty: "\E948";
$icon-play-empty: "\EA8A";
$icon-play-filled: "\EA89";
$icon-chart-bubble-filled: "\EBCB";
$icon-thumb-down-empty: "\EADE";
$icon-thumb-up-empty: "\EAE0";
$icon-shevron-in-circle-down-drop-down-empty: "\E84E";
$icon-shevron-in-circle-down-drop-down-filled: "\E84D";
$icon-reorder-empty: "\EC3A";
$icon-view-headline-list-text: "\EC6F";
$icon-view-quilt-empty: "\EC74";
$icon-block-not-interested-empty: "\EB9F";
$icon-block-not-interested-filled: "\EB9E";
$icon-work-business-center-bag-empty: "\EB5E";
$icon-call-missed-outgoing-empty: "\F12D";
$icon-schedule-empty: "\E8CE";
$icon-subtitles-empty: "\EC5F";
$icon-apps-empty: "\EB99";
$icon-overscan-empty: "\EC2C";
$icon-storage-empty: "\EA48";
$icon-library-empty: "\EA6C";
$icon-voice-empty: "\EAF0";
$icon-settings-applications-empty: "\EC43";
$icon-search-in-page-empty: "\E94A";
$icon-check-circle-empty: "\EBD5";
$icon-dns-server-empty: "\E9C4";
$icon-offer-empty: "\EB43";
$icon-view-list-list-bulleted: "\EC70";
$icon-check-all-done-all: "\EBD3";
$icon-dashboard-view-dashboard-empty: "\EBF5";
$icon-dashboard-view-dashboard-filled: "\EBF4";
$icon-clock-time-watch-later-filled: "\E8B6";
$icon-archives-empty: "\E8E7";
$icon-more: "\EC19";
$icon-more-vertical: "\EC1C";
$icon-security-empty: "\E94E";
$icon-info-empty: "\EB7D";
$icon-view-day-empty: "\EC6C";
$icon-view-day-filled: "\EC6B";
$icon-view-array-empty: "\EC68";
$icon-view-array-filled: "\EC67";
$icon-low-priority: "\EC17";
$icon-arrow-in-circle-up-filled: "\E814";
$icon-arrow-in-circle-up-empty: "\E815";
$icon-arrow-in-circle-down-filled: "\E806";
$icon-arrow-in-circle-down-empty: "\E807";
$icon-touch-empty: "\EAE2";
$icon-location-user-empty: "\EB35";
$icon-location-user-filled: "\EB34";
$icon-account-in-circle-filled: "\EAA5";
$icon-arrow-thin-right-empty: "\F127";
$icon-arrow-thin-right-down-empty: "\F128";
$icon-arrow-thin-down-empty: "\F123";
$icon-backup-restore-empty: "\E826";
$icon-calendar-empty: "\E8B5";
$icon-calendar-filled: "\E8B4";
$icon-do-not-disturb-on-remove-circle-filled: "\EBFF";
$icon-do-not-disturb-on-remove-circle-empty: "\EC00";
$icon-add-user-add-identity-add-person-empty: "\EAAC";
$icon-view-stream-view-agenda-empty: "\EC76";
$icon-view-stream-view-agenda-filled: "\EC75";
$icon-crop-7x5-empty: "\EBEB";
$icon-restore-undate-empty: "\E845";
$icon-movie-movies-empty: "\E93C";
$icon-movie-movies-filled: "\E93B";
$icon-timeline-empty: "\E8D0";
$icon-timeline-filled: "\E8CF";
$icon-help-empty: "\EB77";
$icon-pause-filled: "\EA85";
$icon-replay-empty: "\E842";
$icon-brightness-low-empty: "\EBBA";
$icon-cloud-empty: "\E8FE";
$icon-star-grade-empty: "\EC5A";
$icon-text-wrap: "\EC64";
$icon-mailbox-empty: "\E887";
$icon-date-range-empty: "\E8BD";
$icon-event-note-empty: "\E8C3";
$icon-cash-empty: "\E95E";
$icon-search-on-empty: "\EC3F";
$icon-view-week-view-columns-empty: "\EC78";
$icon-font-empty: "\EB6F";
$icon-report-empty: "\EB88";
$icon-swap-vertical: "\E874";
$icon-repeat: "\E841";
$icon-play-circle-empty: "\EA8C";
$icon-play-circle-filled: "\EA8B";
$icon-doc-removed-empty: "\E9F6";
$icon-undo: "\E87C";
$icon-move-out: "\E84B";
$icon-domain-filled: "\E915";
$icon-domain-empty: "\E916";
$icon-aspect-ratio-filled: "\EB9A";
$icon-aspect-ratio-empty: "\EB9B";
$icon-screen-rotation-filled: "\EA34";
$icon-screen-rotation-empty: "\EA35";
$icon-lock-open-unlock-filled: "\EAFB";
$icon-lock-filled: "\EAF9";
$icon-show-to-all: "\E86D";
$icon-document-restore-empty: "\E912";
$icon-shevron-in-circle-left-up-filled: "\E853";
$icon-shevron-in-circle-right-down-filled: "\E857";
$icon-desktop-empty: "\E9AE";
$icon-tablet-empty: "\EA51";
$icon-smartphone-empty: "\EA40";
$icon-accessibility-filled: "\EA9F";
$icon-accessibility-empty: "\EAA0";
$icon-clock-timeline-empty: "\E8D0";
$icon-location-empty: "\EB27";

// Toolbox icons (mapping to values stored in database)
$icon-applications: $icon-receipt;
$icon-audit: $icon-restore-update;
$icon-budgets: $icon-work-business-center-bag-empty;
$icon-calculations: $icon-calculations;
$icon-campaigns: $icon-voice-empty;
$icon-catalog: $icon-book-cover;
$icon-configuration: $icon-settings-applications-empty;
$icon-contacts: $icon-personal-calendar;
$icon-content: $icon-documents;
$icon-financials: $icon-cash-empty;
$icon-documents: $icon-folder-open;
$icon-equipment: $icon-power-empty;
$icon-files: $icon-storage-empty;
$icon-forecast: $icon-forecast;
$icon-forms: $icon-subtitles-empty;
$icon-goals: $icon-call-missed-outgoing-empty;
$icon-location: $icon-earth;
$icon-notes: $icon-document-text;
$icon-programs: $icon-programs;
$icon-snapshot: $icon-overscan-empty;
$icon-usergroups: $icon-usergroups;
$icon-workflow: $icon-list-check;
$icon-events: $icon-event-note-empty;
$icon-control: $icon-search-on-empty;

$themes: (
    default: (
        themeDarkColor: #1a143a,
        themeDarkModalBoxShadowColor: #1a143a,
        themeWindowTabsBackgroundColor: #1a143a,
        themeDarkColorOneHalf: #1a143a,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #1a143a 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #1a143a 50%),
        themeDarkColorSemiTransparent: rgba(45, 52, 54, 0.2),
        themeDarkColorSemiTransparent70: rgba(45, 52, 54, 0.7),
        themeBaseColor: #1e13a6,
        themeBaseColorDarkVersion: #06508a,
        themeBaseColorLightVersion: #bedaf0,
        themeWindowHeaderBackgroundColor: #1a143a,
        themeSubMenuBackgroundColor: #13007d,
        themeSubMenuBackgroundColorLight: #f5f7fa,
        themeMainNavItemSelectedBackgroundColorLight: #f5f7fa,
        themeSubMenuTextColor: rgba(81, 79, 103, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #1e13a6,
        themeSubMenuSettingsIconBackgroundColorLight: #f5f7fa,
        themeMainNavItemSelectedBackgroundColor: #13007d,
        themeMainNavItemSeparatorColor: #dfe6e9,
        themeMainNavItemSelectedTextColor: #2d3436,
        themeMainNavItemTextColor: rgba(81, 79, 103, 0.9),
        themeBaseColorDesaturated: #6d6c89,
        themeColorLocked: #6d6c89,
        themeBaseColorDesaturatedSemiTransparent5: rgba(109, 108, 137, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(109, 108, 137, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(109, 108, 137, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f5f5f6,
        themeBaseColorDesaturatedSemiTransparent: rgba(109, 108, 137, 0.5),
        themeBaseColorDesaturatedSemiTransparent70: rgba(109, 108, 137, 0.7),
        themeMiddleColor: #1e13a6,
        themeTextSelectionColor: #1e13a6,
        themeLayoutLeftHeaderBackgroundColor: #1e13a6,
        themeBaseColorSemiTransparent5: rgba(30, 19, 166, 0.05),
        themeBaseColorSemiTransparent7: rgba(30, 19, 166, 0.07),
        themeBaseColorSemiTransparent10: rgba(30, 19, 166, 0.1),
        themeBaseColorSemiTransparent12: rgba(30, 19, 166, 0.12),
        themeBaseColorSemiTransparent15: rgba(30, 19, 166, 0.15),
        themeSemiTransparentBackground: rgba(30, 19, 166, 0.15),
        themeBaseColorSemiTransparent20: rgba(30, 19, 166, 0.2),
        themeBaseColorSemiTransparent30: rgba(30, 19, 166, 0.3),
        themeLightColor: #a4b9ff,
        themeBoxShadowColor: #a4b9ff,
        themeBoxShadowBorderColor: #a4b9ff,
        themeSemiWhiteColor: #f1f8ff,
        themeSemiWhiteDiagonalColor: #f1f8ff,
        themeSelectedGridListRowBackgroundColor: #f1f8ff,
        themeHoverGridListRowBackgroundColor: #f2f4f9,
        themeDashboardToolsBackgroundColor: #f1f8ff,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(109, 108, 137, 0.01) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f1f8ff, rgba(109, 108, 137, 0.05) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f1f8ff 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #f1f8ff 25%),
        themeTransparentToSemiWhiteColorMiddle: #f1f8ff,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f7fa 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f5f7fa 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f7fa),
        themeWhiteColor: #f6f6f7,
        themeWhiteDiagonalColor: #f6f6f7,
        themeDirtyColor: #dadfe2,
        themeLighterDirtyColor: #dfe6e9,
        themeBottomTabsBackgroundBase: #1e13a6,
        themeBottomTabsBackgroundLight: rgba(9, 132, 227, 0.07),
        themePrimaryButtonBackgroundColor: #1e13a6,
        themePrimaryButtonBackgroundColorHover: #13007d,
        themeGlobalNavBackgroundLighter: #1e13a6,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f5f7fa, rgba(9, 132, 227, 0.2)),
        themeTitleColor: #1a143a,
        themeSplitViewSyncControlsBackgroundColor: #f1f8ff,
        themeWatermarkColor: #eef0f7,
        themeContactFormLabelColor: rgba(81, 79, 103, 0.5),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #dadfe2,
        themeCISLookupLabelColor: rgba(81, 79, 103, 0.5),
    ),
    blueSky: (
        themeDarkColor: #2d3436,
        themeDarkModalBoxShadowColor: #2d3436,
        themeWindowTabsBackgroundColor: #2d3436,
        themeDarkColorOneHalf: #2d3436,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #2d3436 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #2d3436 50%),
        themeDarkColorSemiTransparent: rgba(45, 52, 54, 0.2),
        themeDarkColorSemiTransparent70: rgba(45, 52, 54, 0.7),
        themeBaseColor: #0984e3,
        themeBaseColorDarkVersion: #06508a,
        themeBaseColorLightVersion: #bedaf0,
        themeWindowHeaderBackgroundColor: #0984e3,
        themeSubMenuBackgroundColor: #0984e3,
        themeSubMenuBackgroundColorLight: #f5f7fa,
        themeMainNavItemSelectedBackgroundColorLight: #f5f7fa,
        themeSubMenuTextColor: rgba(99, 110, 114, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #0984e3,
        themeSubMenuSettingsIconBackgroundColorLight: #f5f7fa,
        themeMainNavItemSelectedBackgroundColor: #0984e3,
        themeMainNavItemSeparatorColor: #dfe6e9,
        themeMainNavItemSelectedTextColor: #2d3436,
        themeMainNavItemTextColor: rgba(99, 110, 114, 0.9),
        themeBaseColorDesaturated: #677785,
        themeColorLocked: #636e72,
        themeBaseColorDesaturatedSemiTransparent5: rgba(99, 110, 114, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(99, 110, 114, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(99, 110, 114, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f5f5f6,
        themeBaseColorDesaturatedSemiTransparent: rgba(99, 110, 114, 0.5),
        themeBaseColorDesaturatedSemiTransparent70: rgba(99, 110, 114, 0.7),
        themeMiddleColor: #0984e3,
        themeTextSelectionColor: #0773c2,
        themeLayoutLeftHeaderBackgroundColor: #0773c2,
        themeBaseColorSemiTransparent5: rgba(9, 132, 227, 0.05),
        themeBaseColorSemiTransparent7: rgba(9, 132, 227, 0.07),
        themeBaseColorSemiTransparent10: rgba(9, 132, 227, 0.1),
        themeBaseColorSemiTransparent12: rgba(9, 132, 227, 0.12),
        themeBaseColorSemiTransparent15: rgba(9, 132, 227, 0.15),
        themeSemiTransparentBackground: rgba(9, 132, 227, 0.15),
        themeBaseColorSemiTransparent20: rgba(9, 132, 227, 0.2),
        themeBaseColorSemiTransparent30: rgba(9, 132, 227, 0.3),
        themeLightColor: #74b9ff,
        themeBoxShadowColor: #74b9ff,
        themeBoxShadowBorderColor: #74b9ff,
        themeSemiWhiteColor: #f1f8ff,
        themeSemiWhiteDiagonalColor: #f1f8ff,
        themeSelectedGridListRowBackgroundColor: #f1f8ff,
        themeHoverGridListRowBackgroundColor: #f2f4f9,
        themeDashboardToolsBackgroundColor: #f1f8ff,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(99, 110, 114, 0.01) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f1f8ff, rgba(9, 132, 227, 0.05) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f1f8ff 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #f1f8ff 25%),
        themeTransparentToSemiWhiteColorMiddle: #f1f8ff,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f7fa 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f5f7fa 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f7fa),
        themeWhiteColor: #f5f7fa,
        themeWhiteDiagonalColor: #f5f7fa,
        themeDirtyColor: #dadfe2,
        themeLighterDirtyColor: #dfe6e9,
        themeBottomTabsBackgroundBase: #0984e3,
        themeBottomTabsBackgroundLight: rgba(9, 132, 227, 0.07),
        themePrimaryButtonBackgroundColor: #0984e3,
        themePrimaryButtonBackgroundColorHover: #097dd5,
        themeGlobalNavBackgroundLighter: #2390ea,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f5f7fa, rgba(9, 132, 227, 0.2)),
        themeTitleColor: #2d3436,
        themeSplitViewSyncControlsBackgroundColor: #f1f8ff,
        themeWatermarkColor: #eef0f7,
        themeContactFormLabelColor: rgba(99, 110, 114, 0.5),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #dadfe2,
        themeCISLookupLabelColor: rgba(99, 110, 114, 0.5),
    ),
    darkSky: (
        themeDarkColor: #1a1f4f,
        themeDarkModalBoxShadowColor: #1a1f4f,
        themeWindowTabsBackgroundColor: #1a1f4f,
        themeDarkColorOneHalf: #1a1f4f,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #1a1f4f 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #1a1f4f 50%),
        themeDarkColorSemiTransparent: rgba(26, 31, 79, 0.3),
        themeDarkColorSemiTransparent70: rgba(26, 31, 79, 0.7),
        themeBaseColor: #3e4abb,
        themeBaseColorDarkVersion: #1f265f,
        themeBaseColorLightVersion: #c4c8f3,
        themeWindowHeaderBackgroundColor: #3e4abb,
        themeSubMenuBackgroundColor: #3e4abb,
        themeSubMenuBackgroundColorLight: #f4f4fb,
        themeMainNavItemSelectedBackgroundColorLight: #f4f4fb,
        themeSubMenuTextColor: rgba(113, 121, 138, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #3e4abb,
        themeSubMenuSettingsIconBackgroundColorLight: #f4f4fb,
        themeMainNavItemSelectedBackgroundColor: #3e4abb,
        themeMainNavItemSeparatorColor: #dfe0e8,
        themeMainNavItemSelectedTextColor: #1a1f4f,
        themeMainNavItemTextColor: rgba(113, 121, 138, 0.9),
        themeBaseColorDesaturated: #6c718c,
        themeColorLocked: #767b85,
        themeBaseColorDesaturatedSemiTransparent5: rgba(113, 121, 138, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(113, 121, 138, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(113, 121, 138, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f6f6f7,
        themeBaseColorDesaturatedSemiTransparent: rgba(113, 121, 138, 0.5),
        themeBaseColorDesaturatedSemiTransparent70: rgba(113, 121, 138, 0.7),
        themeMiddleColor: #3e4abb,
        themeTextSelectionColor: #333e99,
        themeLayoutLeftHeaderBackgroundColor: #333e99,
        themeBaseColorSemiTransparent5: rgba(62, 74, 187, 0.05),
        themeBaseColorSemiTransparent7: rgba(62, 74, 187, 0.07),
        themeBaseColorSemiTransparent10: rgba(62, 74, 187, 0.1),
        themeBaseColorSemiTransparent12: rgba(62, 74, 187, 0.12),
        themeBaseColorSemiTransparent15: rgba(62, 74, 187, 0.15),
        themeSemiTransparentBackground: rgba(62, 74, 187, 0.15),
        themeBaseColorSemiTransparent20: rgba(62, 74, 187, 0.2),
        themeBaseColorSemiTransparent30: rgba(62, 74, 187, 0.3),
        themeLightColor: #838bd6,
        themeBoxShadowColor: #838bd6,
        themeBoxShadowBorderColor: #838bd6,
        themeSemiWhiteColor: #f2f3fb,
        themeSemiWhiteDiagonalColor: #f2f3fb,
        themeSelectedGridListRowBackgroundColor: #f2f3fb,
        themeHoverGridListRowBackgroundColor: #f3f3fc,
        themeDashboardToolsBackgroundColor: #f2f3fb,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(113, 121, 138, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f2f3fb, rgba(62, 74, 187, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f2f3fb 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #f2f3fb 25%),
        themeTransparentToSemiWhiteColorMiddle: #f2f3fb,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f9f9fd 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f9f9fd 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f9f9fd),
        themeWhiteColor: #f9f9fd,
        themeWhiteDiagonalColor: #f9f9fd,
        themeDirtyColor: #d6d8e2,
        themeLighterDirtyColor: #dfe0e8,
        themeBottomTabsBackgroundBase: #3e4abb,
        themeBottomTabsBackgroundLight: rgba(62, 74, 187, 0.1),
        themePrimaryButtonBackgroundColor: #3e4abb,
        themePrimaryButtonBackgroundColorHover: #3945ae,
        themeGlobalNavBackgroundLighter: #505bc5,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f9f9fd, rgba(62, 74, 187, 0.2)),
        themeTitleColor: #1a1f4f,
        themeSplitViewSyncControlsBackgroundColor: #f2f3fb,
        themeWatermarkColor: #f3f4fa,
        themeContactFormLabelColor: rgba(113, 121, 138, 0.5),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d6d8e2,
        themeCISLookupLabelColor: rgba(113, 121, 138, 0.5),
    ),
    lightSeaGreen: (
        themeDarkColor: #092f2b,
        themeDarkModalBoxShadowColor: #092f2b,
        themeWindowTabsBackgroundColor: #092f2b,
        themeDarkColorOneHalf: #092f2b,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #092f2b 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #092f2b 50%),
        themeDarkColorSemiTransparent: rgba(9, 47, 43, 0.2),
        themeDarkColorSemiTransparent70: rgba(9, 47, 43, 0.7),
        themeBaseColor: #1d9c8f,
        themeBaseColorDarkVersion: #0e4b45,
        themeBaseColorLightVersion: #adece6,
        themeWindowHeaderBackgroundColor: #1d9c8f,
        themeSubMenuBackgroundColor: #1d9c8f,
        themeSubMenuBackgroundColorLight: #eff8f7,
        themeMainNavItemSelectedBackgroundColorLight: #eff8f7,
        themeSubMenuTextColor: rgba(83, 101, 99, 0.8),
        themeSubMenuSettingsIconBackgroundColor: #1d9c8f,
        themeSubMenuSettingsIconBackgroundColorLight: #eff8f7,
        themeMainNavItemSelectedBackgroundColor: #1d9c8f,
        themeMainNavItemSeparatorColor: #e0e9e8,
        themeMainNavItemSelectedTextColor: #092f2b,
        themeMainNavItemTextColor: rgba(83, 101, 99, 0.8),
        themeBaseColorDesaturated: #5b7774,
        themeColorLocked: #747e7e,
        themeBaseColorDesaturatedSemiTransparent5: rgba(83, 101, 99, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(83, 101, 99, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(83, 101, 99, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f4f5f5,
        themeBaseColorDesaturatedSemiTransparent: rgba(83, 101, 99, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(83, 101, 99, 0.7),
        themeMiddleColor: #1d9c8f,
        themeTextSelectionColor: #177d73,
        themeLayoutLeftHeaderBackgroundColor: #177d73,
        themeBaseColorSemiTransparent5: rgba(29, 156, 143, 0.05),
        themeBaseColorSemiTransparent7: rgba(29, 156, 143, 0.07),
        themeBaseColorSemiTransparent10: rgba(29, 156, 143, 0.1),
        themeBaseColorSemiTransparent12: rgba(29, 156, 143, 0.12),
        themeBaseColorSemiTransparent15: rgba(29, 156, 143, 0.15),
        themeSemiTransparentBackground: rgba(29, 156, 143, 0.15),
        themeBaseColorSemiTransparent20: rgba(29, 156, 143, 0.2),
        themeBaseColorSemiTransparent30: rgba(29, 156, 143, 0.3),
        themeLightColor: #28d5c4,
        themeBoxShadowColor: #28d5c4,
        themeBoxShadowBorderColor: #28d5c4,
        themeSemiWhiteColor: #e2faf8,
        themeSemiWhiteDiagonalColor: #e2faf8,
        themeSelectedGridListRowBackgroundColor: #e2faf8,
        themeHoverGridListRowBackgroundColor: #eefdfb,
        themeDashboardToolsBackgroundColor: #e2faf8,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(83, 101, 99, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #e2faf8, rgba(29, 156, 143, 0.13) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #e2faf8 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #e2faf8 25%),
        themeTransparentToSemiWhiteColorMiddle: #e2faf8,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fefd 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f8fefd 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fefd),
        themeWhiteColor: #f8fefd,
        themeWhiteDiagonalColor: #f8fefd,
        themeDirtyColor: #d6e0df,
        themeLighterDirtyColor: #e0e9e8,
        themeBottomTabsBackgroundBase: #1d9c8f,
        themeBottomTabsBackgroundLight: rgba(29, 156, 143, 0.1),
        themePrimaryButtonBackgroundColor: #1d9c8f,
        themePrimaryButtonBackgroundColorHover: #1b9286,
        themeGlobalNavBackgroundLighter: #20ac9e,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f8fefd, rgba(29, 156, 143, 0.2)),
        themeTitleColor: #092f2b,
        themeSplitViewSyncControlsBackgroundColor: #e2faf8,
        themeWatermarkColor: #e9fcfa,
        themeContactFormLabelColor: rgba(83, 101, 99, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d6e0df,
        themeCISLookupLabelColor: rgba(83, 101, 99, 0.4),
    ),
    valentine: (
        themeDarkColor: #340123,
        themeDarkModalBoxShadowColor: #340123,
        themeWindowTabsBackgroundColor: #340123,
        themeDarkColorOneHalf: #340123,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #340123 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #340123 50%),
        themeDarkColorSemiTransparent: rgba(52, 1, 35, 0.2),
        themeDarkColorSemiTransparent70: rgba(52, 1, 35, 0.7),
        themeBaseColor: #d60592,
        themeBaseColorDarkVersion: #7c0253,
        themeBaseColorLightVersion: #f3c0e2,
        themeWindowHeaderBackgroundColor: #d60592,
        themeSubMenuBackgroundColor: #d60592,
        themeSubMenuBackgroundColorLight: #fdf5fb,
        themeMainNavItemSelectedBackgroundColorLight: #fdf5fb,
        themeSubMenuTextColor: rgba(121, 99, 113, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #d60592,
        themeSubMenuSettingsIconBackgroundColorLight: #fdf5fb,
        themeMainNavItemSelectedBackgroundColor: #d60592,
        themeMainNavItemSeparatorColor: #ecdfe8,
        themeMainNavItemSelectedTextColor: #340123,
        themeMainNavItemTextColor: rgba(121, 99, 113, 0.9),
        themeBaseColorDesaturated: #796371,
        themeColorLocked: #797577,
        themeBaseColorDesaturatedSemiTransparent5: rgba(121, 99, 113, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(121, 99, 113, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(121, 99, 113, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f6f5f6,
        themeBaseColorDesaturatedSemiTransparent: rgba(121, 99, 113, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(121, 99, 113, 0.7),
        themeMiddleColor: #d60592,
        themeTextSelectionColor: #ac0476,
        themeLayoutLeftHeaderBackgroundColor: #ac0476,
        themeBaseColorSemiTransparent5: rgba(214, 5, 146, 0.05),
        themeBaseColorSemiTransparent7: rgba(214, 5, 146, 0.07),
        themeBaseColorSemiTransparent10: rgba(214, 5, 146, 0.1),
        themeBaseColorSemiTransparent12: rgba(214, 5, 146, 0.12),
        themeBaseColorSemiTransparent15: rgba(214, 5, 146, 0.15),
        themeSemiTransparentBackground: rgba(214, 5, 146, 0.15),
        themeBaseColorSemiTransparent20: rgba(214, 5, 146, 0.2),
        themeBaseColorSemiTransparent30: rgba(214, 5, 146, 0.3),
        themeLightColor: #fb3cbd,
        themeBoxShadowColor: #fb3cbd,
        themeBoxShadowBorderColor: #fb3cbd,
        themeSemiWhiteColor: #ffeefa,
        themeSemiWhiteDiagonalColor: #ffeefa,
        themeSelectedGridListRowBackgroundColor: #ffeefa,
        themeHoverGridListRowBackgroundColor: #fff4fb,
        themeDashboardToolsBackgroundColor: #ffeefa,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(121, 99, 113, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #ffeefa, rgba(214, 5, 146, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #ffeefa 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #ffeefa 25%),
        themeTransparentToSemiWhiteColorMiddle: #ffeefa,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #fff9fd 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #fff9fd 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fff9fd),
        themeWhiteColor: #fff9fd,
        themeWhiteDiagonalColor: #fff9fd,
        themeDirtyColor: #e7d6e2,
        themeLighterDirtyColor: #ecdfe8,
        themeBottomTabsBackgroundBase: #d60592,
        themeBottomTabsBackgroundLight: #ffeefa,
        themePrimaryButtonBackgroundColor: #d60592,
        themePrimaryButtonBackgroundColorHover: #c40486,
        themeGlobalNavBackgroundLighter: #e2059a,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #fff9fd, rgba(214, 5, 146, 0.2)),
        themeTitleColor: #340123,
        themeSplitViewSyncControlsBackgroundColor: #ffeefa,
        themeWatermarkColor: #ffeef9,
        themeContactFormLabelColor: rgba(121, 99, 113, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #e7d6e2,
        themeCISLookupLabelColor: rgba(121, 99, 113, 0.4),
    ),
    violets: (
        themeDarkColor: #221b41,
        themeDarkModalBoxShadowColor: #221b41,
        themeWindowTabsBackgroundColor: #221b41,
        themeDarkColorOneHalf: #221b41,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #221b41 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #221b41 50%),
        themeDarkColorSemiTransparent: rgba(34, 27, 65, 0.25),
        themeDarkColorSemiTransparent70: rgba(34, 27, 65, 0.7),
        themeBaseColor: #6d57bb,
        themeBaseColorDarkVersion: #392e63,
        themeBaseColorLightVersion: #d4ccf1,
        themeWindowHeaderBackgroundColor: #6d57bb,
        themeSubMenuBackgroundColor: #6d57bb,
        themeSubMenuBackgroundColorLight: #f4f3fa,
        themeMainNavItemSelectedBackgroundColorLight: #f4f3fa,
        themeSubMenuTextColor: #78738c,
        themeSubMenuSettingsIconBackgroundColor: #6d57bb,
        themeSubMenuSettingsIconBackgroundColorLight: #f4f3fa,
        themeMainNavItemSelectedBackgroundColor: #6d57bb,
        themeMainNavItemSeparatorColor: #e3e1ea,
        themeMainNavItemSelectedTextColor: #221b41,
        themeMainNavItemTextColor: #78738c,
        themeBaseColorDesaturated: #78738c,
        themeColorLocked: #84848e,
        themeBaseColorDesaturatedSemiTransparent5: rgba(126, 126, 148, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(81, 81, 141, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(126, 126, 148, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f6f6f8,
        themeBaseColorDesaturatedSemiTransparent: rgba(126, 126, 148, 0.5),
        themeBaseColorDesaturatedSemiTransparent70: rgba(126, 126, 148, 0.7),
        themeMiddleColor: #6d57bb,
        themeTextSelectionColor: #5943a5,
        themeLayoutLeftHeaderBackgroundColor: #5943a5,
        themeBaseColorSemiTransparent5: rgba(109, 87, 187, 0.05),
        themeBaseColorSemiTransparent7: rgba(109, 87, 187, 0.07),
        themeBaseColorSemiTransparent10: rgba(109, 87, 187, 0.1),
        themeBaseColorSemiTransparent12: rgba(109, 87, 187, 0.12),
        themeBaseColorSemiTransparent15: rgba(109, 87, 187, 0.15),
        themeSemiTransparentBackground: rgba(109, 87, 187, 0.15),
        themeBaseColorSemiTransparent20: rgba(109, 87, 187, 0.2),
        themeBaseColorSemiTransparent30: rgba(109, 87, 187, 0.3),
        themeLightColor: #b9aedf,
        themeBoxShadowColor: #b9aedf,
        themeBoxShadowBorderColor: #b9aedf,
        themeSemiWhiteColor: #f4f3fa,
        themeSemiWhiteDiagonalColor: #f4f3fa,
        themeSelectedGridListRowBackgroundColor: #f4f3fa,
        themeHoverGridListRowBackgroundColor: #f7f4fb,
        themeDashboardToolsBackgroundColor: #f4f3fa,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(126, 126, 148, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f4f3fa, rgba(109, 87, 187, 0.09) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f4f3fa 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #f4f3fa 25%),
        themeTransparentToSemiWhiteColorMiddle: #f4f3fa,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #faf9fd 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #faf9fd 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #faf9fd),
        themeWhiteColor: #faf9fd,
        themeWhiteDiagonalColor: #faf9fd,
        themeDirtyColor: #dad7e3,
        themeLighterDirtyColor: #e3e1ea,
        themeBottomTabsBackgroundBase: #6d57bb,
        themeBottomTabsBackgroundLight: #f4f3fa,
        themePrimaryButtonBackgroundColor: #6d57bb,
        themePrimaryButtonBackgroundColorHover: #634ab7,
        themeGlobalNavBackgroundLighter: #7a66c1,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #faf9fd, rgba(109, 87, 187, 0.2)),
        themeTitleColor: #221b41,
        themeSplitViewSyncControlsBackgroundColor: #f4f3fa,
        themeWatermarkColor: #f5f2fb,
        themeContactFormLabelColor: rgba(126, 126, 148, 0.5),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #dad7e3,
        themeCISLookupLabelColor: rgba(126, 126, 148, 0.5),
    ),
    olive: (
        themeDarkColor: #353500,
        themeDarkModalBoxShadowColor: #353500,
        themeWindowTabsBackgroundColor: #353500,
        themeDarkColorOneHalf: #353500,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #353500 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #353500 50%),
        themeDarkColorSemiTransparent: rgba(53, 53, 0, 0.3),
        themeDarkColorSemiTransparent70: rgba(53, 53, 0, 0.7),
        themeBaseColor: #868600,
        themeBaseColorDarkVersion: #525201,
        themeBaseColorLightVersion: #f3f3b5,
        themeWindowHeaderBackgroundColor: #868600,
        themeSubMenuBackgroundColor: #868600,
        themeSubMenuBackgroundColorLight: #f8f8f0,
        themeMainNavItemSelectedBackgroundColorLight: #f8f8f0,
        themeSubMenuTextColor: rgba(73, 73, 60, 0.8),
        themeSubMenuSettingsIconBackgroundColor: #868600,
        themeSubMenuSettingsIconBackgroundColorLight: #f8f8f0,
        themeMainNavItemSelectedBackgroundColor: #868600,
        themeMainNavItemSeparatorColor: #ecebe1,
        themeMainNavItemSelectedTextColor: #353500,
        themeMainNavItemTextColor: rgba(73, 73, 60, 0.8),
        themeBaseColorDesaturated: #71715e,
        themeColorLocked: #83837c,
        themeBaseColorDesaturatedSemiTransparent5: rgba(73, 73, 60, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(73, 73, 60, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(73, 73, 60, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f3f3f2,
        themeBaseColorDesaturatedSemiTransparent: rgba(73, 73, 60, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(73, 73, 60, 0.7),
        themeMiddleColor: #868600,
        themeTextSelectionColor: #737300,
        themeLayoutLeftHeaderBackgroundColor: #737300,
        themeBaseColorSemiTransparent5: rgba(134, 134, 0, 0.05),
        themeBaseColorSemiTransparent7: rgba(134, 134, 0, 0.07),
        themeBaseColorSemiTransparent10: rgba(134, 134, 0, 0.1),
        themeBaseColorSemiTransparent12: rgba(134, 134, 0, 0.12),
        themeBaseColorSemiTransparent15: rgba(134, 134, 0, 0.15),
        themeSemiTransparentBackground: rgba(134, 134, 0, 0.15),
        themeBaseColorSemiTransparent20: rgba(134, 134, 0, 0.2),
        themeBaseColorSemiTransparent30: rgba(134, 134, 0, 0.3),
        themeLightColor: #bfbf00,
        themeBoxShadowColor: #bfbf00,
        themeBoxShadowBorderColor: #bfbf00,
        themeSemiWhiteColor: #ffffdf,
        themeSemiWhiteDiagonalColor: #ffffdf,
        themeSelectedGridListRowBackgroundColor: #ffffdf,
        themeHoverGridListRowBackgroundColor: #f9f9f3,
        themeDashboardToolsBackgroundColor: #ffffdf,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(73, 73, 60, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #ffffdf, rgba(134, 134, 0, 0.09) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffdf 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffdf 25%),
        themeTransparentToSemiWhiteColorMiddle: #ffffdf,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #fffff7 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #fffff7 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fffff7),
        themeWhiteColor: #fffff7,
        themeWhiteDiagonalColor: #fffff7,
        themeDirtyColor: #e6e4d7,
        themeLighterDirtyColor: #ecebe1,
        themeBottomTabsBackgroundBase: #868600,
        themeBottomTabsBackgroundLight: rgba(134, 134, 0, 0.1),
        themePrimaryButtonBackgroundColor: #868600,
        themePrimaryButtonBackgroundColorHover: #777700,
        themeGlobalNavBackgroundLighter: #999900,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #fffff7, rgba(134, 134, 0, 0.2)),
        themeTitleColor: #353500,
        themeSplitViewSyncControlsBackgroundColor: #ffffdf,
        themeWatermarkColor: #f9f9f2,
        themeContactFormLabelColor: rgba(73, 73, 60, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #e6e4d7,
        themeCISLookupLabelColor: rgba(73, 73, 60, 0.4),
    ),
    earth: (
        themeDarkColor: linear-gradient(3deg, #221b41, #002445, #002a3f, #002d35, #092f2b),
        themeDarkModalBoxShadowColor: #221b41,
        themeWindowTabsBackgroundColor: linear-gradient(3deg, #221b41, #002445, #002a3f, #002d35, #092f2b),
        themeDarkColorOneHalf: linear-gradient(to top, #221b41, #002445, #002a3f),
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #002445 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #002d35 50%),
        themeDarkColorSemiTransparent: rgba(34, 27, 65, 0.2),
        themeDarkColorSemiTransparent70: rgba(34, 27, 65, 0.7),
        themeBaseColor: #0069b7,
        themeBaseColorDarkVersion: #014070,
        themeBaseColorLightVersion: #c9e3f7,
        themeWindowHeaderBackgroundColor: linear-gradient(3deg, #6d57bb, #0074cb, #0087c2, #0094aa, #1d9c8f),
        themeSubMenuBackgroundColor: #0069b7,
        themeSubMenuBackgroundColorLight: #f2f7fb,
        themeMainNavItemSelectedBackgroundColorLight: #f2f7fb,
        themeSubMenuTextColor: rgba(83, 93, 101, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #0069b7,
        themeSubMenuSettingsIconBackgroundColorLight: #f2f7fb,
        themeMainNavItemSelectedBackgroundColor: #0069b7,
        themeMainNavItemSeparatorColor: #e0e7e9,
        themeMainNavItemSelectedTextColor: #221b41,
        themeMainNavItemTextColor: rgba(83, 93, 101, 0.9),
        themeBaseColorDesaturated: #607686,
        themeColorLocked: #74828d,
        themeBaseColorDesaturatedSemiTransparent5: rgba(83, 93, 101, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(83, 93, 101, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(83, 93, 101, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f4f4f5,
        themeBaseColorDesaturatedSemiTransparent: rgba(83, 93, 101, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(83, 93, 101, 0.7),
        themeMiddleColor: linear-gradient(3deg, #0078c7, #0084bb, #0090ae),
        themeTextSelectionColor: #1d9c8f,
        themeLayoutLeftHeaderBackgroundColor: linear-gradient(3deg, #6d57bb, #0074cb, #0087c2, #0094aa, #1d9c8f),
        themeBaseColorSemiTransparent5: rgba(0, 105, 183, 0.05),
        themeBaseColorSemiTransparent7: rgba(0, 105, 183, 0.07),
        themeBaseColorSemiTransparent10: rgba(0, 105, 183, 0.1),
        themeBaseColorSemiTransparent12: rgba(0, 105, 183, 0.12),
        themeBaseColorSemiTransparent15: rgba(0, 105, 183, 0.15),
        themeSemiTransparentBackground: rgba(0, 105, 183, 0.15),
        themeBaseColorSemiTransparent20: rgba(0, 105, 183, 0.2),
        themeBaseColorSemiTransparent30: rgba(0, 105, 183, 0.3),
        themeLightColor: #0080dd,
        themeBoxShadowColor: #0080dd,
        themeBoxShadowBorderColor: #0080dd,
        themeSemiWhiteColor: linear-gradient(3deg, #f4f3fa, #f0f4fd, #eaf6ff, #e4f8fd, #e2faf8),
        themeSemiWhiteDiagonalColor: linear-gradient(45deg, #f4f3fa, #f0f4fd, #eaf6ff, #e4f8fd, #e2faf8),
        themeSelectedGridListRowBackgroundColor: linear-gradient(to right, #f4f3fa, #f0f4fd, #eaf6ff, #e4f8fd, #e2faf8),
        themeHoverGridListRowBackgroundColor: #f2f7fb,
        themeDashboardToolsBackgroundColor: linear-gradient(3deg, #f4f3fa, #f0f4fd, #eaf6ff, #e4f8fd, #e2faf8),
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(83, 93, 101, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f4f3fa, rgba(0, 105, 183, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f4f3fa 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #e2faf8 25%),
        themeTransparentToSemiWhiteColorMiddle: #eaf6ff,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f7fcff 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f8fefd 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fefd),
        themeWhiteColor: linear-gradient(3deg, #faf9fd, #f8fafe, #f7fcff, #f7fdfe, #f8fefd),
        themeWhiteDiagonalColor: linear-gradient(45deg, #faf9fd, #f8fafe, #f7fcff, #f7fdfe, #f8fefd),
        themeDirtyColor: #d7dee1,
        themeLighterDirtyColor: #e0e7e9,
        themeBottomTabsBackgroundBase: linear-gradient(to right top, #6d57bb, #0074cb, #0087c2, #0094aa, #1d9c8f),
        themeBottomTabsBackgroundLight: linear-gradient(to right top, #f4f3fa, #f0f4fd, #eaf6ff, #e4f8fd, #e2faf8),
        themePrimaryButtonBackgroundColor: linear-gradient(to right top, #0074cb, #0094aa),
        themePrimaryButtonBackgroundColorHover: linear-gradient(to right top, #006fbf, #008a9d),
        themeGlobalNavBackgroundLighter: linear-gradient(3deg, #7a66c1, #2181d3, #0094ce, #00a2b9, #20ac9e),
        themeDashboardToolIconBackground: linear-gradient(to bottom, #faf9fd, rgba(0, 105, 183, 0.2)),
        themeTitleColor: #092f2b,
        themeSplitViewSyncControlsBackgroundColor: linear-gradient(to right top, #f0f4fd, #eaf6ff, #e4f8fd),
        themeWatermarkColor: #f3f8fc,
        themeContactFormLabelColor: rgba(83, 93, 101, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d7dee1,
        themeCISLookupLabelColor: rgba(83, 93, 101, 0.4),
    ),
    sunset: (
        themeDarkColor: linear-gradient(3deg, #360e33, #3f0a27, #430c1b, #431310, #3f1a03),
        themeDarkModalBoxShadowColor: #360e33,
        themeWindowTabsBackgroundColor: linear-gradient(3deg, #360e33, #3f0a27, #430c1b, #431310, #3f1a03),
        themeDarkColorOneHalf: linear-gradient(to top, #360e33, #3f0a27, #430c1b),
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #3f0a27 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #431310 50%),
        themeDarkColorSemiTransparent: rgba(54, 14, 51, 0.3),
        themeDarkColorSemiTransparent70: rgba(54, 14, 51, 0.7),
        themeBaseColor: #8c237c,
        themeBaseColorDarkVersion: #5c1751,
        themeBaseColorLightVersion: #f0d0ba,
        themeWindowHeaderBackgroundColor: linear-gradient(3deg, #782071, #a71c6a, #cd2959, #e74942, #f27121),
        themeSubMenuBackgroundColor: #8c237c,
        themeSubMenuBackgroundColorLight: linear-gradient(to top, #fcf1fc, #fff0f7, #fff0f1),
        themeMainNavItemSelectedBackgroundColorLight: linear-gradient(to top, #fff0f1, #fff3f0, #fef5ef),
        themeSubMenuTextColor: rgba(95, 78, 93, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #8c237c,
        themeSubMenuSettingsIconBackgroundColorLight: #fff0f7,
        themeMainNavItemSelectedBackgroundColor: #8c237c,
        themeMainNavItemSeparatorColor: #ebe4ea,
        themeMainNavItemSelectedTextColor: #360e33,
        themeMainNavItemTextColor: rgba(95, 78, 93, 0.9),
        themeBaseColorDesaturated: #766174,
        themeColorLocked: #797579,
        themeBaseColorDesaturatedSemiTransparent5: rgba(95, 78, 93, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(95, 78, 93, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(95, 78, 93, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f4f3f4,
        themeBaseColorDesaturatedSemiTransparent: rgba(95, 78, 93, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(95, 78, 93, 0.7),
        themeMiddleColor: linear-gradient(3deg, #a71c6a, #c73356, #d73e4c),
        themeTextSelectionColor: #f27121,
        themeLayoutLeftHeaderBackgroundColor: linear-gradient(3deg, #782071, #a71c6a, #cd2959, #e74942, #f27121),
        themeBaseColorSemiTransparent5: rgba(140, 35, 124, 0.05),
        themeBaseColorSemiTransparent7: rgba(140, 35, 124, 0.07),
        themeBaseColorSemiTransparent10: rgba(140, 35, 124, 0.1),
        themeBaseColorSemiTransparent12: rgba(140, 35, 124, 0.12),
        themeBaseColorSemiTransparent15: rgba(140, 35, 124, 0.15),
        themeSemiTransparentBackground: rgba(140, 35, 124, 0.15),
        themeBaseColorSemiTransparent20: rgba(140, 35, 124, 0.2),
        themeBaseColorSemiTransparent30: rgba(140, 35, 124, 0.3),
        themeLightColor: #a85199,
        themeBoxShadowColor: #a85199,
        themeBoxShadowBorderColor: #a85199,
        themeSemiWhiteColor: linear-gradient(3deg, #fcf1fc, #fff0f7, #fff0f1, #fff3f0, #fef5ef),
        themeSemiWhiteDiagonalColor: linear-gradient(45deg, #fcf1fc, #fff0f7, #fff0f1, #fff3f0, #fef5ef),
        themeSelectedGridListRowBackgroundColor: linear-gradient(to right, #fcf1fc, #fff0f7, #fff0f1, #fff3f0, #fef5ef),
        themeHoverGridListRowBackgroundColor: #fff4f4,
        themeDashboardToolsBackgroundColor: linear-gradient(3deg, #fcf1fc, #fff0f7, #fff0f1, #fff3f0, #fef5ef),
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(95, 78, 93, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #fcf1fc, rgba(140, 35, 124, 0.07) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fcf1fc 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #fef5ef 25%),
        themeTransparentToSemiWhiteColorMiddle: #fff0f1,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #fff7f7 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #fef9f5 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fef9f5),
        themeWhiteColor: linear-gradient(3deg, #fdf7fc, #fff7fa, #fff7f7, #fff8f6, #fef9f5),
        themeWhiteDiagonalColor: linear-gradient(45deg, #fdf7fc, #fff7fa, #fff7f7, #fff8f6, #fef9f5),
        themeDirtyColor: #e3d9e2,
        themeLighterDirtyColor: #ebe4ea,
        themeBottomTabsBackgroundBase: linear-gradient(to right top, #782071, #a71c6a, #cd2959, #e74942, #f27121),
        themeBottomTabsBackgroundLight: linear-gradient(to right top, #fcf1fc, #fff0f7, #fff0f1, #fff3f0, #fef5ef),
        themePrimaryButtonBackgroundColor: linear-gradient(to right top, #782071, #a71c6a, #cd2959),
        themePrimaryButtonBackgroundColorHover: linear-gradient(to right top, #6d1d67, #951a5e, #c12655),
        themeGlobalNavBackgroundLighter: linear-gradient(3deg, #86247e, #b32272, #d5335f, #ea5348, #f2782f),
        themeDashboardToolIconBackground: linear-gradient(to bottom, #fdf7fc, rgba(140, 35, 124, 0.2)),
        themeTitleColor: #3f1a03,
        themeSplitViewSyncControlsBackgroundColor: linear-gradient(to right top, #fff0f7, #fff0f1, #fff3f0),
        themeWatermarkColor: #fff0f0,
        themeContactFormLabelColor: rgba(95, 78, 93, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #e3d9e2,
        themeCISLookupLabelColor: rgba(95, 78, 93, 0.4),
    ),
    nature: (
        themeDarkColor: linear-gradient(3deg, #093e33, #4d3e04),
        themeDarkModalBoxShadowColor: #093e33,
        themeWindowTabsBackgroundColor: linear-gradient(3deg, #093e33, #4d3e04),
        themeDarkColorOneHalf: linear-gradient(to top, #093e33, #2b3e1c),
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #1a3e27 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #3c3e10 50%),
        themeDarkColorSemiTransparent: rgba(9, 62, 51, 0.3),
        themeDarkColorSemiTransparent70: rgba(9, 62, 51, 0.7),
        themeBaseColor: #15977d,
        themeBaseColorDarkVersion: #0d5c4c,
        themeBaseColorLightVersion: #f7ebbb,
        themeWindowHeaderBackgroundColor: linear-gradient(3deg, #148d75, #d3ab0c),
        themeSubMenuBackgroundColor: #15977d,
        themeSubMenuBackgroundColorLight: #f1f9f7,
        themeMainNavItemSelectedBackgroundColorLight: #f1f9f7,
        themeSubMenuTextColor: rgba(78, 95, 92, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #15977d,
        themeSubMenuSettingsIconBackgroundColorLight: #f1f9f7,
        themeMainNavItemSelectedBackgroundColor: #15977d,
        themeMainNavItemSeparatorColor: #e3ebe2,
        themeMainNavItemSelectedTextColor: #093e33,
        themeMainNavItemTextColor: rgba(78, 95, 92, 0.9),
        themeBaseColorDesaturated: #5c7672,
        themeColorLocked: #737b79,
        themeBaseColorDesaturatedSemiTransparent5: rgba(78, 95, 92, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(78, 95, 92, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(78, 95, 92, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f3f4f4,
        themeBaseColorDesaturatedSemiTransparent: rgba(78, 95, 92, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(78, 95, 92, 0.7),
        themeMiddleColor: linear-gradient(3deg, #44955b, #93a12f),
        themeTextSelectionColor: #13806a,
        themeLayoutLeftHeaderBackgroundColor: linear-gradient(3deg, #148d75, #d3ab0c),
        themeBaseColorSemiTransparent5: rgba(21, 151, 125, 0.05),
        themeBaseColorSemiTransparent7: rgba(21, 151, 125, 0.07),
        themeBaseColorSemiTransparent10: rgba(21, 151, 125, 0.1),
        themeBaseColorSemiTransparent12: rgba(21, 151, 125, 0.12),
        themeBaseColorSemiTransparent15: rgba(21, 151, 125, 0.15),
        themeSemiTransparentBackground: rgba(21, 151, 125, 0.15),
        themeBaseColorSemiTransparent20: rgba(21, 151, 125, 0.2),
        themeBaseColorSemiTransparent30: rgba(21, 151, 125, 0.3),
        themeLightColor: #589850,
        themeBoxShadowColor: #589850,
        themeBoxShadowBorderColor: #589850,
        themeSemiWhiteColor: linear-gradient(3deg, #edfcf9, #fefae7),
        themeSemiWhiteDiagonalColor: linear-gradient(45deg, #edfcf9, #fefae7),
        themeSelectedGridListRowBackgroundColor: linear-gradient(to right, #edfcf9, #fefae7),
        themeHoverGridListRowBackgroundColor: #f2f9f8,
        themeDashboardToolsBackgroundColor: linear-gradient(3deg, #edfcf9, #fefae7),
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(78, 95, 92, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #edfcf9, rgba(21, 151, 125, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #edfcf9 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #fefae7 25%),
        themeTransparentToSemiWhiteColorMiddle: #f6fbf0,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fefd 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #fffef7 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fffef7),
        themeWhiteColor: linear-gradient(3deg, #f8fefd, #fffef7),
        themeWhiteDiagonalColor: linear-gradient(45deg, #f8fefd, #fffef7),
        themeDirtyColor: #d7e1d5,
        themeLighterDirtyColor: #e3ebe2,
        themeBottomTabsBackgroundBase: linear-gradient(to right top, #148d75, #d3ab0c),
        themeBottomTabsBackgroundLight: linear-gradient(to right top, #edfcf9, #fefae7),
        themePrimaryButtonBackgroundColor: linear-gradient(to right top, #148d75, #739c41),
        themePrimaryButtonBackgroundColorHover: linear-gradient(to right top, #12836c, #6a913c),
        themeGlobalNavBackgroundLighter: linear-gradient(3deg, #15977d, #e3b90d),
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f8fefd, rgba(21, 151, 125, 0.2)),
        themeTitleColor: #4d3e04,
        themeSplitViewSyncControlsBackgroundColor: linear-gradient(to right top, #edfcf9, #fefae7),
        themeWatermarkColor: #f1faf9,
        themeContactFormLabelColor: rgba(78, 95, 92, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d7e1d5,
        themeCISLookupLabelColor: rgba(78, 95, 92, 0.4),
    ),
    dusk: (
        themeDarkColor: linear-gradient(3deg, #0d2939, #574158),
        themeDarkModalBoxShadowColor: #0d2939,
        themeWindowTabsBackgroundColor: linear-gradient(3deg, #0d2939, #574158),
        themeDarkColorOneHalf: linear-gradient(to top, #0d2939, #323549),
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #202f41 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #453b50 50%),
        themeDarkColorSemiTransparent: rgba(13, 41, 57, 0.2),
        themeDarkColorSemiTransparent70: rgba(13, 41, 57, 0.7),
        themeBaseColor: #2580b3,
        themeBaseColorDarkVersion: #185172,
        themeBaseColorLightVersion: #f4e6f5,
        themeWindowHeaderBackgroundColor: linear-gradient(3deg, #237aa9, #c5b0c6),
        themeSubMenuBackgroundColor: #2580b3,
        themeSubMenuBackgroundColorLight: linear-gradient(to top, #f1f8fc, #f8f5f8),
        themeMainNavItemSelectedBackgroundColorLight: #f8f5f8,
        themeSubMenuTextColor: rgba(96, 110, 118, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #2580b3,
        themeSubMenuSettingsIconBackgroundColorLight: #f1f8fc,
        themeMainNavItemSelectedBackgroundColor: #2580b3,
        themeMainNavItemSeparatorColor: #dfe8ec,
        themeMainNavItemSelectedTextColor: #0d2939,
        themeMainNavItemTextColor: rgba(96, 110, 118, 0.9),
        themeBaseColorDesaturated: #687e8c,
        themeColorLocked: #71838c,
        themeBaseColorDesaturatedSemiTransparent5: rgba(96, 110, 118, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(96, 110, 118, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(96, 110, 118, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f4f5f6,
        themeBaseColorDesaturatedSemiTransparent: rgba(96, 110, 118, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(96, 110, 118, 0.7),
        themeMiddleColor: linear-gradient(3deg, #4c88b0, #8f9ebc),
        themeTextSelectionColor: #c5b0c6,
        themeLayoutLeftHeaderBackgroundColor: linear-gradient(3deg, #237aa9, #c5b0c6),
        themeBaseColorSemiTransparent5: rgba(37, 128, 179, 0.05),
        themeBaseColorSemiTransparent7: rgba(37, 128, 179, 0.07),
        themeBaseColorSemiTransparent10: rgba(37, 128, 179, 0.1),
        themeBaseColorSemiTransparent12: rgba(37, 128, 179, 0.12),
        themeBaseColorSemiTransparent15: rgba(37, 128, 179, 0.15),
        themeSemiTransparentBackground: rgba(37, 128, 179, 0.15),
        themeBaseColorSemiTransparent20: rgba(37, 128, 179, 0.2),
        themeBaseColorSemiTransparent30: rgba(37, 128, 179, 0.3),
        themeLightColor: #8da9c7,
        themeBoxShadowColor: #8da9c7,
        themeBoxShadowBorderColor: #8da9c7,
        themeSemiWhiteColor: linear-gradient(3deg, #f1f8fc, #f8f5f8),
        themeSemiWhiteDiagonalColor: linear-gradient(45deg, #f1f8fc, #f8f5f8),
        themeSelectedGridListRowBackgroundColor: linear-gradient(to right, #f1f8fc, #f8f5f8),
        themeHoverGridListRowBackgroundColor: #f3f7fa,
        themeDashboardToolsBackgroundColor: linear-gradient(3deg, #f1f8fc, #f8f5f8),
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(96, 110, 118, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f1f8fc, rgba(37, 128, 179, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f1f8fc 2%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #f8f5f8 25%),
        themeTransparentToSemiWhiteColorMiddle: #f5f7fa,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fdfe 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #fcfafc 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fcfafc),
        themeWhiteColor: linear-gradient(3deg, #f8fdfe, #fcfafc),
        themeWhiteDiagonalColor: linear-gradient(45deg, #f8fdfe, #fcfafc),
        themeDirtyColor: #d5e0e6,
        themeLighterDirtyColor: #dfe8ec,
        themeBottomTabsBackgroundBase: linear-gradient(to right top, #237aa9, #c5b0c6),
        themeBottomTabsBackgroundLight: linear-gradient(to right top, #f1f8fc, #f8f5f8),
        themePrimaryButtonBackgroundColor: linear-gradient(to right top, #237aa9, #8b9dbc),
        themePrimaryButtonBackgroundColorHover: linear-gradient(to right top, #20729d, #8094b5),
        themeGlobalNavBackgroundLighter: linear-gradient(3deg, #2580b3, #cbbacc),
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f8fdfe, rgba(37, 128, 179, 0.2)),
        themeTitleColor: #574158,
        themeSplitViewSyncControlsBackgroundColor: linear-gradient(to right top, #f1f8fc, #f8f5f8),
        themeWatermarkColor: #f3f7fa,
        themeContactFormLabelColor: rgba(96, 110, 118, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d5e0e6,
        themeCISLookupLabelColor: rgba(96, 110, 118, 0.4),
    ),
    northemLights: (
        themeDarkColor: linear-gradient(3deg, #0c2e50, #144e3a),
        themeDarkModalBoxShadowColor: #0c2e50,
        themeWindowTabsBackgroundColor: linear-gradient(3deg, #0c2e50, #144e3a),
        themeDarkColorOneHalf: linear-gradient(to top, #0c2e50, #103e45),
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #0e364b 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #124640 50%),
        themeDarkColorSemiTransparent: rgba(12, 46, 80, 0.3),
        themeDarkColorSemiTransparent70: rgba(12, 46, 80, 0.7),
        themeBaseColor: #1b64ad,
        themeBaseColorDarkVersion: #12406e,
        themeBaseColorLightVersion: #ccf0e4,
        themeWindowHeaderBackgroundColor: linear-gradient(3deg, #185a9d, #43cea2),
        themeSubMenuBackgroundColor: #1b64ad,
        themeSubMenuBackgroundColorLight: #f3f7fb,
        themeMainNavItemSelectedBackgroundColorLight: #f3f7fb,
        themeSubMenuTextColor: rgba(90, 99, 109, 0.9),
        themeSubMenuSettingsIconBackgroundColor: #1b64ad,
        themeSubMenuSettingsIconBackgroundColorLight: #f3f7fb,
        themeMainNavItemSelectedBackgroundColor: #1b64ad,
        themeMainNavItemSeparatorColor: #e0e9e8,
        themeMainNavItemSelectedTextColor: #0c2e50,
        themeMainNavItemTextColor: rgba(90, 99, 109, 0.9),
        themeBaseColorDesaturated: #67768b,
        themeColorLocked: #78838f,
        themeBaseColorDesaturatedSemiTransparent5: rgba(90, 99, 109, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(90, 99, 109, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(90, 99, 109, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f4f5f5,
        themeBaseColorDesaturatedSemiTransparent: rgba(90, 99, 109, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(90, 99, 109, 0.7),
        themeMiddleColor: linear-gradient(3deg, #23779e, #35a7a0),
        themeTextSelectionColor: #43cea2,
        themeLayoutLeftHeaderBackgroundColor: linear-gradient(3deg, #185a9d, #43cea2),
        themeBaseColorSemiTransparent5: rgba(27, 100, 173, 0.05),
        themeBaseColorSemiTransparent7: rgba(27, 100, 173, 0.07),
        themeBaseColorSemiTransparent10: rgba(27, 100, 173, 0.1),
        themeBaseColorSemiTransparent12: rgba(27, 100, 173, 0.12),
        themeBaseColorSemiTransparent15: rgba(27, 100, 173, 0.15),
        themeSemiTransparentBackground: rgba(27, 100, 173, 0.15),
        themeBaseColorSemiTransparent20: rgba(27, 100, 173, 0.2),
        themeBaseColorSemiTransparent30: rgba(27, 100, 173, 0.3),
        themeLightColor: #2980ab,
        themeBoxShadowColor: #2980ab,
        themeBoxShadowBorderColor: #2980ab,
        themeSemiWhiteColor: linear-gradient(3deg, #f0f7fd, #f1fcf9),
        themeSemiWhiteDiagonalColor: linear-gradient(45deg, #f0f7fd, #f1fcf9),
        themeSelectedGridListRowBackgroundColor: linear-gradient(to right, #f0f7fd, #f1fcf9),
        themeHoverGridListRowBackgroundColor: #f3f7fb,
        themeDashboardToolsBackgroundColor: linear-gradient(3deg, #f0f7fd, #f1fcf9),
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(90, 99, 109, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f0f7fd, rgba(27, 100, 173, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f0f7fd 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #f1fcf9 25%),
        themeTransparentToSemiWhiteColorMiddle: #f1fafb,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fbfe 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f8fefc 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f8fefc),
        themeWhiteColor: linear-gradient(3deg, #f8fbfe, #f8fefc),
        themeWhiteDiagonalColor: linear-gradient(45deg, #f8fbfe, #f8fefc),
        themeDirtyColor: #d7e3e2,
        themeLighterDirtyColor: #e0e9e8,
        themeBottomTabsBackgroundBase: linear-gradient(to right top, #185a9d, #43cea2),
        themeBottomTabsBackgroundLight: linear-gradient(to right top, #f0f7fd, #f1fcf9),
        themePrimaryButtonBackgroundColor: linear-gradient(to right top, #185a9d, #2e95a0),
        themePrimaryButtonBackgroundColorHover: linear-gradient(to right top, #185a9d, #2b8893),
        themeGlobalNavBackgroundLighter: linear-gradient(3deg, #16538f, #50d1a7),
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f8fbfe, rgba(27, 100, 173, 0.2)),
        themeTitleColor: #144e3a,
        themeSplitViewSyncControlsBackgroundColor: linear-gradient(to right top, #f0f7fd, #f1fcf9),
        themeWatermarkColor: #f3f8fc,
        themeContactFormLabelColor: rgba(90, 99, 109, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d7e3e2,
        themeCISLookupLabelColor: rgba(90, 99, 109, 0.4),
    ),
    eveningShades: (
        themeDarkColor: linear-gradient(3deg, #11225b, #422343),
        themeDarkModalBoxShadowColor: #11225b,
        themeWindowTabsBackgroundColor: linear-gradient(3deg, #11225b, #422343),
        themeDarkColorOneHalf: linear-gradient(to top, #11225b, #2a234f),
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #1d2255 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #362349 50%),
        themeDarkColorSemiTransparent: rgba(17, 34, 91, 0.2),
        themeDarkColorSemiTransparent70: rgba(17, 34, 91, 0.7),
        themeBaseColor: #4568dc,
        themeBaseColorDarkVersion: #2c4188,
        themeBaseColorLightVersion: #f7dbf8,
        themeWindowHeaderBackgroundColor: linear-gradient(3deg, #365bda, #aa62ae),
        themeSubMenuBackgroundColor: #4568dc,
        themeSubMenuBackgroundColorLight: linear-gradient(to top, #f0f2fd, #faf3fa),
        themeMainNavItemSelectedBackgroundColorLight: #faf3fa,
        themeSubMenuTextColor: #6f7893,
        themeSubMenuSettingsIconBackgroundColor: #4568dc,
        themeSubMenuSettingsIconBackgroundColorLight: #faf3fa,
        themeMainNavItemSelectedBackgroundColor: #4568dc,
        themeMainNavItemSeparatorColor: #e0e2e9,
        themeMainNavItemSelectedTextColor: #11225b,
        themeMainNavItemTextColor: #6f7893,
        themeBaseColorDesaturated: #6f7893,
        themeColorLocked: #777b88,
        themeBaseColorDesaturatedSemiTransparent5: rgba(134, 140, 156, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(134, 140, 156, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(134, 140, 156, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f7f7f8,
        themeBaseColorDesaturatedSemiTransparent: rgba(134, 140, 156, 0.5),
        themeBaseColorDesaturatedSemiTransparent70: rgba(134, 140, 156, 0.7),
        themeMiddleColor: linear-gradient(3deg, #535dcf, #8360bd),
        themeTextSelectionColor: #aa62ae,
        themeLayoutLeftHeaderBackgroundColor: linear-gradient(3deg, #365bda, #aa62ae),
        themeBaseColorSemiTransparent5: rgba(69, 104, 220, 0.05),
        themeBaseColorSemiTransparent7: rgba(69, 104, 220, 0.07),
        themeBaseColorSemiTransparent10: rgba(69, 104, 220, 0.1),
        themeBaseColorSemiTransparent12: rgba(69, 104, 220, 0.12),
        themeBaseColorSemiTransparent15: rgba(69, 104, 220, 0.15),
        themeSemiTransparentBackground: rgba(69, 104, 220, 0.15),
        themeBaseColorSemiTransparent20: rgba(69, 104, 220, 0.2),
        themeBaseColorSemiTransparent30: rgba(69, 104, 220, 0.3),
        themeLightColor: #6d80d6,
        themeBoxShadowColor: #6d80d6,
        themeBoxShadowBorderColor: #6d80d6,
        themeSemiWhiteColor: linear-gradient(3deg, #f0f2fd, #faf3fa),
        themeSemiWhiteDiagonalColor: linear-gradient(45deg, #f0f2fd, #faf3fa),
        themeSelectedGridListRowBackgroundColor: linear-gradient(to right, #f0f2fd, #faf3fa),
        themeHoverGridListRowBackgroundColor: #f6f5fc,
        themeDashboardToolsBackgroundColor: linear-gradient(3deg, #f0f2fd, #faf3fa),
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(134, 140, 156, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #f0f2fd, rgba(69, 104, 220, 0.1) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f0f2fd 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #faf3fa 25%),
        themeTransparentToSemiWhiteColorMiddle: #f5f3fc,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f8f9fe 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #fdfbfd 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #fdfbfd),
        themeWhiteColor: linear-gradient(3deg, #f8f9fe, #fdfbfd),
        themeWhiteDiagonalColor: linear-gradient(45deg, #f8f9fe, #fdfbfd),
        themeDirtyColor: #d7d9e1,
        themeLighterDirtyColor: #e0e2e9,
        themeBottomTabsBackgroundBase: linear-gradient(to right top, #365bda, #aa62ae),
        themeBottomTabsBackgroundLight: linear-gradient(to right top, #f0f2fd, #faf3fa),
        themePrimaryButtonBackgroundColor: linear-gradient(to right top, #365bda, #775fa8),
        themePrimaryButtonBackgroundColorHover: linear-gradient(to right top, #2951d8, #7058a0),
        themeGlobalNavBackgroundLighter: linear-gradient(3deg, #4568dc, #b06ab3),
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f8f9fe, rgba(69, 104, 220, 0.2)),
        themeTitleColor: #422343,
        themeSplitViewSyncControlsBackgroundColor: linear-gradient(to right top, #f0f2fd, #faf3fa),
        themeWatermarkColor: #f4f3fc,
        themeContactFormLabelColor: rgba(134, 140, 156, 0.5),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #d7d9e1,
        themeCISLookupLabelColor: rgba(134, 140, 156, 0.5),
    ),
    sightline: (
        themeDarkColor: #044e7c,
        themeDarkModalBoxShadowColor: #044e7c,
        themeWindowTabsBackgroundColor: #ffffff,
        themeDarkColorOneHalf: #002d3e,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffff 50%),
        themeDarkColorSemiTransparent: rgba(52, 1, 35, 0.2),
        themeDarkColorSemiTransparent70: rgba(4, 78, 124, 0.7),
        themeBaseColor: #00aeef,
        themeBaseColorDarkVersion: #0277a1,
        themeBaseColorLightVersion: #d0eaf5,
        themeWindowHeaderBackgroundColor: #00aeef,
        themeSubMenuBackgroundColor: #f1f5f8,
        themeSubMenuBackgroundColorLight: #f1f5f8,
        themeMainNavItemSelectedBackgroundColorLight: #f1f5f8,
        themeSubMenuTextColor: #778c9a,
        themeSubMenuSettingsIconBackgroundColor: #f1f5f8,
        themeSubMenuSettingsIconBackgroundColorLight: #f1f5f8,
        themeMainNavItemSelectedBackgroundColor: #f1f5f8,
        themeMainNavItemSeparatorColor: #e8edee,
        themeMainNavItemSelectedTextColor: #000000,
        themeMainNavItemTextColor: #778c9a,
        themeBaseColorDesaturated: #778c9a,
        themeColorLocked: #636e72,
        themeBaseColorDesaturatedSemiTransparent5: rgba(119, 140, 154, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(119, 140, 154, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(119, 140, 154, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f6f7f8,
        themeBaseColorDesaturatedSemiTransparent: rgba(119, 140, 154, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: rgba(119, 140, 154, 0.7),
        themeMiddleColor: #00aeef,
        themeTextSelectionColor: #044e7c,
        themeLayoutLeftHeaderBackgroundColor: #00a2df,
        themeBaseColorSemiTransparent5: rgba(0, 174, 239, 0.05),
        themeBaseColorSemiTransparent7: rgba(0, 174, 239, 0.07),
        themeBaseColorSemiTransparent10: rgba(0, 174, 239, 0.1),
        themeBaseColorSemiTransparent12: rgba(0, 174, 239, 0.12),
        themeBaseColorSemiTransparent15: rgba(0, 174, 239, 0.15),
        themeSemiTransparentBackground: rgba(0, 174, 239, 0.15),
        themeBaseColorSemiTransparent20: rgba(0, 174, 239, 0.2),
        themeBaseColorSemiTransparent30: rgba(0, 174, 239, 0.3),
        themeLightColor: #9de4ff,
        themeBoxShadowColor: rgba(0, 165, 228, 0.6),
        themeBoxShadowBorderColor: #00a5e4,
        themeSemiWhiteColor: #ecfaff,
        themeSemiWhiteDiagonalColor: #ecfaff,
        themeSelectedGridListRowBackgroundColor: #ecfaff,
        themeHoverGridListRowBackgroundColor: #f1f5f8,
        themeDashboardToolsBackgroundColor: #ffffff,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(119, 140, 154, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #ecfaff, rgba(119, 140, 154, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #ecfaff 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #ecfaff 25%),
        themeTransparentToSemiWhiteColorMiddle: #ecfaff,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f8fa 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f5f8fa 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f8fa),
        themeWhiteColor: #f5f8fa,
        themeWhiteDiagonalColor: #f5f8fa,
        themeDirtyColor: #dae2e4,
        themeLighterDirtyColor: #e8edee,
        themeBottomTabsBackgroundBase: #00aeef,
        themeBottomTabsBackgroundLight: rgba(0, 174, 239, 0.1),
        themePrimaryButtonBackgroundColor: #00aeef,
        themePrimaryButtonBackgroundColorHover: #00a5e4,
        themeGlobalNavBackgroundLighter: #ffffff,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f1f5f8, rgba(0, 174, 239, 0.2)),
        themeTitleColor: #044e7c,
        themeSplitViewSyncControlsBackgroundColor: #e5f7fd,
        themeWatermarkColor: #edf2f5,
        themeContactFormLabelColor: rgba(119, 140, 154, 0.4),
        themeColorDarkGrey: $dark-grey,
        themeColorBaseGrey: $base-grey,
        themeDropDownBorderColor: #dae2e4,
        themeCISLookupLabelColor: rgba(119, 140, 154, 0.4),
    ),
    sightlineHighContrast: (
        themeDarkColor: #044e7c,
        themeDarkModalBoxShadowColor: #044e7c,
        themeWindowTabsBackgroundColor: #ffffff,
        themeDarkColorOneHalf: #002d3e,
        themeWindowTabsArrowLeftBackgroundColor: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff 50%),
        themeWindowTabsArrowRightBackgroundColor: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffff 50%),
        themeDarkColorSemiTransparent: rgba(52, 1, 35, 0.2),
        themeDarkColorSemiTransparent70: rgba(4, 78, 124, 0.7),
        themeBaseColor: #066cc6,
        themeBaseColorDarkVersion: #0277a1,
        themeBaseColorLightVersion: #d0eaf5,
        themeWindowHeaderBackgroundColor: #066cc6,
        themeSubMenuBackgroundColor: #f1f5f8,
        themeSubMenuBackgroundColorLight: #f1f5f8,
        themeMainNavItemSelectedBackgroundColorLight: #f1f5f8,
        themeSubMenuTextColor: black,
        themeSubMenuSettingsIconBackgroundColor: #f1f5f8,
        themeSubMenuSettingsIconBackgroundColorLight: #f1f5f8,
        themeMainNavItemSelectedBackgroundColor: #f1f5f8,
        themeMainNavItemSeparatorColor: #e8edee,
        themeMainNavItemSelectedTextColor: #000000,
        themeMainNavItemTextColor: #4c5c67,
        themeBaseColorDesaturated: #778c9a,
        themeColorLocked: #636e72,
        themeBaseColorDesaturatedSemiTransparent5: rgba(119, 140, 154, 0.05),
        themeBaseColorDesaturatedSemiTransparent7: rgba(119, 140, 154, 0.07),
        themeBaseColorDesaturatedSemiTransparent10: rgba(119, 140, 154, 0.1),
        themeBaseColorDesaturatedSemiTransparent7filled: #f6f7f8,
        themeBaseColorDesaturatedSemiTransparent: rgba(119, 140, 154, 0.4),
        themeBaseColorDesaturatedSemiTransparent70: black,
        themeMiddleColor: #00aeef,
        themeTextSelectionColor: #044e7c,
        themeLayoutLeftHeaderBackgroundColor: #066cc6,
        themeBaseColorSemiTransparent5: rgba(0, 174, 239, 0.05),
        themeBaseColorSemiTransparent7: rgba(0, 174, 239, 0.07),
        themeBaseColorSemiTransparent10: rgba(0, 174, 239, 0.1),
        themeBaseColorSemiTransparent12: rgba(0, 174, 239, 0.12),
        themeBaseColorSemiTransparent15: rgba(0, 174, 239, 0.15),
        themeSemiTransparentBackground: rgba(0, 174, 239, 0.15),
        themeBaseColorSemiTransparent20: rgba(0, 174, 239, 0.2),
        themeBaseColorSemiTransparent30: rgba(0, 174, 239, 0.3),
        themeLightColor: #9de4ff,
        themeBoxShadowColor: rgba(0, 165, 228, 0.6),
        themeBoxShadowBorderColor: #00a5e4,
        themeSemiWhiteColor: #ecfaff,
        themeSemiWhiteDiagonalColor: #ecfaff,
        themeSelectedGridListRowBackgroundColor: #ecfaff,
        themeHoverGridListRowBackgroundColor: #f1f5f8,
        themeDashboardToolsBackgroundColor: #ffffff,
        themeBaseColorDesaturatedToSemiTransparentLeft: linear-gradient(to left, white, rgba(119, 140, 154, 0.02) 35%),
        themeBaseColorDesaturatedToSemiTransparentLeftSelected: linear-gradient(to left, #ecfaff, rgba(119, 140, 154, 0.08) 35%),
        themeTransparentToSemiWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #ecfaff 25%),
        themeTransparentToSemiWhiteColorRight: linear-gradient(to right, rgba(0, 0, 0, 0), #ecfaff 25%),
        themeTransparentToSemiWhiteColorMiddle: #ecfaff,
        themeTransparentWhiteColorLeft30: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f8fa 30%),
        themeTransparentWhiteColorRight30: linear-gradient(to right, rgba(0, 0, 0, 0), #f5f8fa 30%),
        themeTransparentWhiteColorLeft: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f8fa),
        themeWhiteColor: #f5f8fa,
        themeWhiteDiagonalColor: #f5f8fa,
        themeDirtyColor: #b5c5c9,
        themeLighterDirtyColor: #e8edee,
        themeBottomTabsBackgroundBase: #066cc6,
        themeBottomTabsBackgroundLight: #5bcbf5,
        themePrimaryButtonBackgroundColor: #066cc6,
        themePrimaryButtonBackgroundColorHover: #00a5e4,
        themeGlobalNavBackgroundLighter: #ffffff,
        themeDashboardToolIconBackground: linear-gradient(to bottom, #f1f5f8, rgba(0, 174, 239, 0.2)),
        themeTitleColor: #044e7c,
        themeSplitViewSyncControlsBackgroundColor: #e5f7fd,
        themeWatermarkColor: #edf2f5,
        themeContactFormLabelColor: black,
        themeColorDarkGrey: black,
        themeColorBaseGrey: black,
        themeDropDownBorderColor: #e8edee,
        themeCISLookupLabelColor: black,
    ),
);
