@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.sidebar-panel {
    background-color: $white-light-grey;
    @include box-shadow-common($shadow-outer-hover);
    @include border-radius(8px);

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background-color: $sightline-white-light-grey;
    }

    &:hover .sidebar-header {
        .sidebar-icon-group {
            .icon-wrap {
                opacity: 1;
            }
        }
    }

    .sidebar-content {
        min-height: 88px;
        background-color: white;

        &.no-footer {
            overflow: hidden;
            @include border-radius(0 0 8px 8px);
        }

        &:not(.no-padding) {
            padding: 15px 13px;
        }

        .empty-sidebar-content {
            padding: 0 15px 13px;
        }

        > img {
            display: block;
            width: 100%;
        }

        &.no-data {
            padding-top: 0;
            background: transparent;
        }
    }

    .grid-list-paging {
        border: none;
        border-radius: 0 0 8px 8px;
        @include tablet {
            padding: 0 5px;

            .paging-goto-page .field-group {
                margin: 0 4px;
            }
        }
    }
}
