@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.window-tabs {
    z-index: 1;
    @include themify($themes) {
        background: themed("themeWindowTabsBackgroundColor");
    }

    @include themify($themes) {
        background: themed("themeWindowTabsBackgroundColor");
        @include box-shadow(0, -2px, 4px, 0 themed("themeBaseColorDesaturatedSemiTransparent"));
    }

    body:not(.theme-mode-light) & {
        box-shadow: none;
    }

    .theme-mode-light & {
        background: white;

        @include tablet {
            z-index: 2;
        }
    }

    @include tablet-and-mobile {
        @include box-shadow(0, -1px, 8px, 0 rgba(0, 0, 0, 0.25));
    }

    > .main-grid-wrap.responsive {
        @include desktop {
            padding-right: 135px;
        }

        @include tablet-and-mobile {
            padding: 0;
        }

        @include tablet-only {
            padding-right: 105px;
        }

        @include tablet-large {
            position: relative;
            padding-right: 135px;
        }
    }

    .main-grid-wrap {
        @include desktop-large {
            .layout-full-screen & {
                padding-right: 145px;
            }
            .layout-with-borders & {
                padding-right: 145px;
            }
        }
    }

    @include tablet-large {
        &.layout-left > .main-grid-wrap {
            padding: 0 145px 0 20px;
            min-width: inherit;
            max-width: inherit;

            .scroll-control {
                &.left {
                    left: 74px;
                }

                &.right {
                    right: 110px;
                }
            }
        }

        &.split-view {
            .main-grid-wrap {
                padding: 0;
                min-width: inherit;
                max-width: inherit;
            }

            .window-tabs-container {
                margin-left: 84px;
                @include calc(width, "100% - 230px");

                .scroll-controls {
                    overflow: hidden;
                }
            }

            .scroll-controls .scroll-control {
                &.left {
                    left: 84px;
                }
            }
        }
    }

    .close-all-tabs-button-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 54px;
        width: 140px;
        padding-right: 15px;
        z-index: 3;

        @include mobile-only {
            position: relative;
        }

        @include tablet-and-mobile {
            height: 42px;
            width: 100px;
            align-self: flex-end;
            padding-left: 10px;
        }

        @include themify($themes) {
            background: themed("themeWindowTabsBackgroundColor");
        }

        .theme-mode-light & {
            background: white;
        }

        .close-text {
            margin-left: 7px;
            font-size: 9px;
            line-height: 1.1;
            text-transform: uppercase;

            .hc-theme & {
                font-weight: 700;
            }

            body:not(.theme-mode-light) & {
                color: white;
            }

            @include themify($themes) {
                color: themed("themeBaseColorDesaturated");
            }
        }
    }

    .window-tabs-container {
        white-space: nowrap;

        .scroll-controls {
            overflow: hidden;
        }

        @include tablet-large {
            margin-left: 10px;
            height: 54px;
            @include scrollbar-hide;
        }

        @include mobile-only {
            display: flex;
        }
    }

    .window-tabs-wrapper {
        margin-left: 43px;
        overflow-x: auto;

        @include ie {
            display: flex;
        }

        @include firefox {
            overflow-y: hidden;
        }
    }

    .scroll-controls {
        @include tablet-and-mobile {
            display: none;
        }

        .scroll-control {
            width: 60px;
        }

        .icon-wrap {
            top: 11px;
        }

        .left {
            left: 94px;
            @include themify($themes) {
                background: themed("themeWindowTabsArrowLeftBackgroundColor");
            }

            .theme-mode-light & {
                background: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff 50%);
            }

            @include desktop {
                left: 104px;

                .layout-full-screen & {
                    left: 94px;
                }
            }

            @include desktop-large {
                .layout-full-screen & {
                    left: 84px;
                }
            }

            .icon-wrap {
                text-align: left;
            }
        }

        .right {
            right: 120px;
            @include themify($themes) {
                background: themed("themeWindowTabsArrowRightBackgroundColor");
            }

            .theme-mode-light & {
                background: linear-gradient(to right, rgba(0, 0, 0, 0), #ffffff 50%);
            }

            @include desktop {
                right: 130px;

                .layout-full-screen & {
                    right: 110px;
                }
            }

            .icon-wrap {
                text-align: right;
            }
        }
    }
}
