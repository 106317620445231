@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.panel {
    &.budget-amounts,
    &.budget-invoices,
    &.budget-attributes {
        .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;
        }

        .grid-controls {
            margin: -36px 0 12px;
        }
    }
}

.budget-amounts-sidenav-panel,
.budget-invoices-sidenav-panel,
.budget-attributes-sidenav-panel,
.budget-program-assignments-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
