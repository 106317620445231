@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";

.panel {
    &.notes,
    &.qc-panel {
        .mt-14 {
            margin-top: -14px !important;
        }
        .ml-20 {
            margin-left: 20px;
        }
        .data-grid-controls {
            margin: -38px 0 12px;
            //pointer-events: none;

            @include tablet {
                margin-top: -46px;
            }

            .icon-with-label {
                pointer-events: auto;
            }
        }
    }

    &.qc-panel {
        @include mobile-only {
            .data-grid-controls {
                margin-top: 0;
                justify-content: space-between;

                .flex-one {
                    display: none;
                }
            }
        }
    }

    &.equipment-panel-large {
        .data-grid-controls {
            margin-top: -38px;
        }
    }

    &.qc-panel,
    &.events-panel-large,
    &.audit,
    &.documents,
    &.equipment-panel-large,
    &.notes,
    &.applications {
        @include flexbox;
        @include flex-direction(column);
        padding-bottom: 0 !important;
        height: 100%;
    }

    &.events-panel-large,
    &.equipment-panel-large {
        .data-grid-controls {
            align-items: flex-end;

            .grid-selected-items-label {
                margin-right: 20px;
            }

            .select-label {
                font-size: em(13);
                font-weight: 500;
                margin-right: 20px;

                .font-size-large & {
                    font-size: em(14);
                }

                @include mobile-only {
                    font-size: 13px;

                    .font-size-large & {
                        font-size: 15px;
                    }
                }
            }

            &__left-side-actions {
                .btn {
                    margin-right: 20px;
                }
            }

            @include mobile-only {
                &__left-side-actions,
                &__right-side-actions {
                    @include flex-direction(column);
                }
            }

            @include tablet-and-mobile {
                &__left-side-actions {
                    align-items: start;
                }

                .btn {
                    margin: 10px 0 0;
                }

                &__left-side-actions {
                    @include flex-direction(column);
                }
            }

            .split-view & {
                .btn {
                    margin: 10px 0 0;
                }

                &__left-side-actions {
                    @include flex-direction(column);
                }
            }
        }
    }

    &.qc-panel,
    &.notes {
        .panel {
            &.qc,
            &.notes-wrap {
                flex: 1;
                overflow: hidden;
                margin: 0 -25px;
                padding: 0 25px;
                @include calc(width, "100% + 50px");

                @include ie {
                    flex: 1 0 auto;
                    height: 0;
                }

                @include firefox {
                    flex: 1 0 auto;
                    height: 0;
                }

                > .grid-list {
                    @include data-grid-sticky-header;

                    @include ie {
                        overflow: hidden;
                    }
                }
            }
        }
    }

    &.audit,
    &.documents,
    &.equipment-panel-large,
    &.events-panel-large {
        > .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;
        }
    }
}

.app-equipment-sidenav-panel,
.app-events-sidenav-panel,
.add-event-sidenav-panel,
.add-equipment-sidenav-panel {
    @include tablet {
        width: auto;
        max-width: 90%;
    }

    .sidenav-body {
        @include ie {
            height: 0;
        }

        @include firefox {
            height: 0;
        }
    }
}

.add-equipment-panel,
.add-event-panel {
    .catalog-selector {
        margin-top: 0;

        @include tablet {
            width: 980px;
        }

        .catalog-controls {
            padding: 0 20px;
            background: rgba(255, 255, 255, 0.6);
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }
            font-size: em(10);
            line-height: 34px;

            .font-size-large & {
                font-size: em(12);
            }

            @include mobile-only {
                overflow-x: auto;
                white-space: nowrap;
            }
        }

        .catalog-columns {
            @include flexbox;
            background-color: white;
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }

            @include mobile-only {
                position: relative;
            }

            &.hidden {
                display: none;
            }

            .hidden-on-mobile {
                @include mobile-only {
                    display: none;
                }
            }

            .hidden {
                display: none;
            }

            > div {
                width: 100%;
                overflow-y: auto;
                @include themify($themes) {
                    border-right: 1px solid themed("themeLighterDirtyColor");
                }

                @include tablet {
                    @include calc(width, "100% / 3");
                }
            }

            .list-item-category {
                @include mobile-only {
                    padding: 0 20px;

                    > div {
                        width: 100%;
                    }
                }
            }

            &.recent,
            &.common,
            &.search {
                @include flex-wrap(wrap);
                overflow-y: auto;

                .no-equipment-found-block {
                    padding: 20px 0;
                    width: 100%;

                    .status-msg {
                        max-width: 190px;
                    }
                }

                .list-item-category {
                    width: 100%;

                    @include mobile-only {
                        padding-left: 5px !important;

                        > .flex-row {
                            padding: 5px 0;
                            @include flex-direction(column);
                            align-items: flex-start;
                        }
                    }

                    &:hover .category {
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                        }

                        .category-item .shevron-small-right {
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                            opacity: 0.5;
                        }
                    }
                }

                .timing {
                    opacity: 0.5;
                    font-size: em(10);

                    .font-size-large & {
                        font-size: em(12);
                    }

                    @include mobile-only {
                        order: 3;
                        margin-top: 15px;
                    }

                    @include tablet {
                        flex-shrink: 0;
                        width: 60px;
                        text-align: right;
                    }
                }

                .category {
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }

                    @include mobile-only {
                        margin-top: 3px;
                        order: 2;
                        font-size: 10px;

                        .font-size-large & {
                            font-size: 12px;
                        }
                    }

                    @include tablet {
                        flex-shrink: 0;
                        padding: 0 30px 0 20px;
                        width: 340px;
                    }

                    .category-item {
                        display: inline-block;
                    }

                    .shevron-small-right {
                        vertical-align: middle;
                        width: 18px;
                        height: 18px;
                        pointer-events: none;
                        line-height: 18px;

                        &:after {
                            @include mobile-only {
                                font-size: 14px;
                            }
                        }

                        @include tablet {
                            width: 23px;
                        }
                    }
                }

                .equipment-name-wrap {
                    @include mobile-only {
                        order: 1;

                        &.equipment .status-mark {
                            left: -25px;
                        }

                        &.event .status-mark {
                            left: -12px;
                        }
                    }
                }

                .status-mark {
                    @include mobile-only {
                        position: absolute;
                        @include calc(top, "50% - 9px");
                        @include rotate(-90deg);
                        @include border-radius(0 0 6px 6px);
                    }

                    @include tablet {
                        margin-right: 9px;
                    }
                }
            }

            &.all {
                height: 100%;

                .no-equipment-found-block {
                    padding: 20px;

                    .status-msg.warning-msg {
                        margin-bottom: 15px;
                        max-width: 210px;
                    }
                }
            }

            .list-item-category:not(.with-arrow) {
                position: relative;
                height: auto;
                padding: 0;
                overflow: hidden;
                cursor: pointer;

                > div {
                    @include flexbox;
                    align-items: center;
                    padding: 5px 50px 5px 20px;
                    min-height: 44px;

                    @include ie {
                        height: 0;
                    }

                    > .flex-row {
                        @include mobile-only {
                            padding: 5px 0;
                            @include flex-direction(column);
                            align-items: flex-start;
                        }
                    }
                }

                .icon-wrap.plus {
                    position: absolute;
                    right: 13px;
                    @include calc(top, "50% - 14px");

                    @include tablet-large {
                        visibility: hidden;
                    }
                }

                &:hover {
                    .icon-wrap {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.add-equipment-panel,
.add-event-panel,
.update-equipment-panel,
.clone-equipment-panel,
.edit-event-panel {
    position: relative;

    .sidenav-header {
        .scrollable-sidenav-header-part > .flex-column {
            margin-bottom: 15px;

            label {
                margin-bottom: 2px;
                font-weight: 400;

                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent70");
                }
            }

            .event-info-value {
                font-size: em(12);
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }
    }

    .equipment-form:not(.approved-equipment-list),
    .event-form {
        padding: 20px;

        @include tablet {
            .json-form {
                width: 500px;
            }

            .split-view & {
                .json-form {
                    width: 100%;
                }
            }
        }
    }

    .json-form .rjsf .form-group:not(.field-object) {
        margin: 20px 0;

        &:first-child {
            margin-top: 0;
        }
    }
}

.equipment-details,
.event-details {
    > .flex-row {
        padding: 14px 20px;

        @include tablet {
            padding: 0 20px 20px;
        }
    }

    .flex-column.ref-id label + div {
        word-break: break-all;
    }

    @include mobile-only {
        @include flexbox;
        @include flex-direction(column);
        overflow-y: hidden;

        .equipment-attributes .json-form .object-properties > .schema-container {
            width: 100%;
        }
    }

    .form-info {
        margin-top: 20px;

        .status-mark {
            top: -1px;
        }

        p {
            margin-left: 7px;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    .equipment-attributes,
    .event-attributes {
        .schema-container.hidden-attribute-container:before {
            @include tablet {
                height: 100%;
            }
        }
    }

    .equipment-info,
    .event-info {
        padding-top: 15px;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }

        @include mobile-only {
            @include flexbox;
            @include flex-direction(row);
            overflow-x: auto;
            padding: 15px 20px;
            @include box-shadow(inset 0, 2px, 3px, 1px rgba(0, 0, 0, 0.1));

            .quantity-input {
                position: fixed;
                z-index: 1;
                padding: 10px 20px;
                left: 0;
                top: 106px;
                width: 100%;
                background: white;

                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }

                .field-group {
                    @include flexbox;
                    align-items: center;

                    label {
                        margin: 0 7px 0 0;
                    }
                }
            }
        }

        @include tablet {
            @include flex-wrap(wrap);

            .quantity-input {
                text-align: right;
            }

            .flex-column:not(.quantity-input) {
                padding-right: 20px;
            }
        }

        .flex-column {
            @include mobile-only {
                &:not(.quantity-input) {
                    padding-right: 20px;
                }
            }

            @include tablet {
                @include calc(width, "100% / 4");
            }
        }

        label {
            text-transform: uppercase;
        }
    }

    .equipment-actions,
    .event-actions {
        margin: 20px 20px 0;
        padding: 20px 0;
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }

        @include tablet {
            margin-top: 10px;
        }

        .btn {
            + .btn {
                margin-left: 10px;
            }

            @include mobile-only {
                width: 50%;
            }
        }
    }

    .equipment-info-value,
    .event-info-value {
        @include mobile-only {
            font-size: em(12);
            white-space: nowrap;

            .font-size-large & {
                font-size: em(14);
            }
        }
    }
}
