// Font colors
.text-dark {
    color: #000 !important;
}

.text-primary {
    @include themify($themes) {
        color: themed("themeBaseColor") !important;
    }
}

.text-secondary {
    @include themify($themes) {
        color: themed("themeBaseColorDesaturated") !important;
    }
}

.text-danger {
    @include themify($themes) {
        color: var(--color-danger) !important;
    }
}

.text-theme-dark {
    @include themify($themes) {
        color: themed("themeDarkColor") !important;
    }
}

// Font size
.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-20 {
    font-size: 20px;
}

// Font weight
.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

// Line height
.lh-1 {
    line-height: 1;
}

.lh-1\.5 {
    line-height: 1.5;
}
