.grid-selected-items-label {
    white-space: nowrap;

    + .flex-row {
        margin-top: 5px;
    }

    .btn + &,
    .btn-group + & {
        margin: 0 20px;
    }

    .counter-box {
        display: inline-block;
        margin-right: 7px;
    }

    span {
        margin-right: 5px;
        font-weight: 500;
        font-size: 13px;

        .font-size-large & {
            font-size: 15px;
        }
    }

    .icon-wrap {
        top: 1px;
    }
}
