@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.rebate-list-item {
    position: relative;
    cursor: default;

    @include tablet {
        padding-right: 36px;
    }

    @include tablet-large {
        &:hover {
            .column-actions {
                opacity: 1;
            }
        }
    }

    &.flex-row {
        @include mobile-only {
            display: block;

            .flex-column {
                display: block;
            }
        }
    }

    .column-details {
        padding: 10px 15px 0;

        @include tablet {
            padding-bottom: 15px;
        }
    }

    .row-payee,
    .column-amount {
        .item-label {
            font-size: em(12);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }
        }

        .item-value {
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }
    }

    .row-payee {
        @include mobile-only {
            padding: 0 40px 15px 0;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .item-value {
            font-size: em(20);

            @include mobile-only {
                line-height: 1.2;
            }
        }
    }

    .row-payment-type {
        margin-top: 10px;

        @include mobile-only {
            @include flex-direction(column);
            align-items: flex-start;
        }

        @include tablet {
            margin-top: 15px;
        }

        .item-label {
            font-size: em(11);
            margin-bottom: 3px;
            white-space: nowrap;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }

            .font-size-large & {
                font-size: em(13);
            }

            @include tablet {
                margin: 0 5px 0 0;
            }
        }

        .item-value {
            font-size: em(12);
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    .column-amount {
        position: relative;

        @include mobile-only {
            @include themify($themes) {
                border-top: 2px dashed themed("themeLighterDirtyColor");
            }
        }

        @include tablet {
            min-width: 170px;
            width: initial;
            padding: 7px 10px;

            @include themify($themes) {
                border-left: 2px dashed themed("themeLighterDirtyColor");
                border-right: 2px dashed themed("themeLighterDirtyColor");
            }
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
            @include border-radius(50%);
        }

        &:before {
            top: -11px;
            left: -11px;
            @include box-shadow(inset -3px, 0, 3px, 0 rgba(0, 0, 0, 0.15));

            @include tablet {
                @include box-shadow(inset 0, -3px, 3px, 0 rgba(0, 0, 0, 0.15));
            }
        }

        &:after {
            @include mobile-only {
                top: -11px;
                right: -11px;
                @include box-shadow(inset 3px, 0, 3px, 0 rgba(0, 0, 0, 0.15));
            }

            @include tablet {
                bottom: -11px;
                left: -11px;
                @include box-shadow(inset 0, 3px, 3px, 0 rgba(0, 0, 0, 0.15));
            }
        }

        > div {
            text-align: center;
            padding: 6px 10px 10px;

            @include tablet {
                @include flexbox;
                @include flex-direction(column);
                align-items: center;
                justify-content: center;
                padding: 10px;
                height: 100%;
                text-align: right;
            }
        }

        .item-value {
            font-size: em(24);

            @include mobile-only {
                line-height: 1;
            }

            .currency {
                @include currency-sign-styles;
                font-size: 15px;
            }
        }
    }

    .row-info {
        @include flex-wrap(wrap);

        @include mobile-only {
            padding: 0 20px 10px 0;
            width: 50%;
            @include themify($themes) {
                border-right: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .flex-column {
            margin-top: 10px;

            @include tablet {
                margin: 15px 0 0;
                flex-grow: initial;

                &:not(:nth-of-type(2n)) {
                    padding-right: 40px;
                }
            }

            &:not(:last-child) {
                @include tablet-large {
                    padding-right: 40px;
                }
            }
        }

        > div {
            flex: 100%;

            @include tablet-only {
                flex: 50%;
            }

            @include tablet-large {
                flex: 20%;
            }
        }

        .item-label {
            font-size: em(11);
            margin-bottom: 3px;
            white-space: nowrap;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }

            .font-size-large & {
                font-size: em(13);
            }
        }

        .item-value {
            font-size: em(12);
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    .column-dates {
        padding: 10px 20px;
        font-size: em(10);

        .font-size-large & {
            font-size: em(12);
        }

        @include mobile-only {
            position: absolute;
            right: 0;
            bottom: 60px;
            width: 50%;
        }

        @include tablet {
            position: relative;
            width: 180px;
            @include themify($themes) {
                border-left: 1px solid themed("themeLighterDirtyColor");
            }
        }

        &:before,
        &:after {
            content: "";
            z-index: 1;
            position: absolute;
            width: 10px;
            height: 32px;
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }

            @include mobile-only {
                bottom: -18px;
            }

            @include tablet {
                right: -17px;
                width: 32px;
                height: 10px;
            }
        }

        &:before {
            @include mobile-only {
                @include calc(left, "-100% - 13px");
            }

            @include tablet {
                top: -11px;
            }
        }

        &:after {
            @include mobile-only {
                right: -12px;
            }

            @include tablet {
                bottom: -11px;
            }
        }

        .item-value {
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        .column-dates-item {
            position: relative;
            padding-left: 15px;

            &.status {
                margin-top: 5px;
            }

            &:not(:last-child) {
                padding-bottom: 5px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    height: 35px;
                    width: 2px;
                    border-left: 1px dotted black;
                }
            }

            .icon-wrap {
                position: absolute;
                top: 0;
                left: -9px;
                width: 18px;
                height: 18px;
                background: white;
                line-height: 18px;

                &.radio-button-checked {
                    @include scale(0.5);
                }
            }

            .item-value {
                &.Approved {
                    color: $green;
                }

                &.Cancelled {
                    color: $red;
                }
            }
        }
    }

    .column-actions {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px 0;
        width: 36px;
        height: 100%;

        @include tablet-large {
            opacity: 0;
        }
    }
}
