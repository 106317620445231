@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.label-icon {
    margin-left: 8px;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }
}

.default-text {
    .text-label {
        text-transform: none !important;
        font-weight: 400 !important;
    }
}
