@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

//override default styles

// This class is added to document body when maximizing the editor. Fixes menu positioning in toolbar.
.cke-full-screen {
    overflow: visible;
}

.cke_reset_all,
.cke_reset_all *,
.cke_reset_all a,
.cke_reset_all textarea {
    font: 400 13px $base-font-family !important;
}

.cke {
    @include themify($themes) {
        color: themed("themeColorBaseGrey");
    }

    &_resizer_ltr {
        margin-top: 47px !important;
        margin-right: -10px !important;
    }

    &_ltr {
        .cke_dialog_ui_hbox_first,
        .cke_dialog_ui_hbox_child {
            padding-right: 20px !important;
        }
    }

    &_dialog {
        &_background_cover {
            @include themify($themes) {
                background: themed("themeDarkColorSemiTransparent70") !important;
            }
            opacity: 1 !important;
        }

        div[name="fasttaglist"] {
            max-height: 535px;
        }

        &_ui_labeled_label {
            font-size: 12px !important;
        }

        &_ui_labeled_content {
            margin-top: 8px !important;
        }

        &_contents {
            display: block;
            padding: 20px !important;
            background: white !important;
            overflow-y: auto !important;

            @include themify($themes) {
                border-left: 1px solid themed("themeLighterDirtyColor");
                border-right: 1px solid themed("themeLighterDirtyColor");
                border-top: 2px solid themed("themeBaseColorSemiTransparent30") !important;
                border-bottom: 2px solid themed("themeBaseColorSemiTransparent30");
            }

            &_body {
                margin-top: 0 !important;
                padding: 0 !important;
                overflow: initial !important;

                .cke_dialog_ui_text,
                .cke_dialog_ui_select {
                    margin: 0 0 15px !important;
                }
            }
        }

        &_tabs {
            left: 20px !important;
        }

        &_tab {
            padding: 7px 20px !important;
            height: 19px !important;
            min-width: auto !important;
            background: transparent !important;
            border: none !important;
            border-bottom: 2px solid transparent;
            font-size: 12px !important;
            font-weight: 700;
            text-transform: uppercase;

            &:hover {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            &_selected {
                @include themify($themes) {
                    border-bottom: 2px solid themed("themeBaseColor") !important;
                    color: themed("themeBaseColor");
                }
            }
        }

        &_close_button {
            background: none !important;
            opacity: 1 !important;

            &:before {
                content: $icon-clear-close;
                @include icon-iconoteka;
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        &_body {
            width: 400px;
            padding: 0 20px 20px !important;
            @include themify($themes) {
                background: themed("themeSemiWhiteDiagonalColor");
            }
            @include themify($themes) {
                @include box-shadow(0, 0, 12px, 0 themed("themeDarkModalBoxShadowColor"));
            }
            border-radius: 8px;
        }

        &_title {
            padding: 30px 50px 15px !important;
            @include border-radius(8px 8px 0 0);
            text-align: center !important;
            background: transparent !important;
            border: none !important;
            font-size: 16px !important;
            font-weight: 500 !important;
            @include themify($themes) {
                color: themed("themeColorDarkGrey") !important;
            }
        }

        &_footer {
            border: none !important;
            border-radius: 0 0 8px 8px !important;
            background: transparent !important;
            text-align: center !important;

            &_buttons {
                margin: 15px 0 20px !important;
                font-weight: 700;

                .cke_dialog_ui_hbox_first {
                    padding-right: 17px !important;
                }
            }
        }

        &_ui_button {
            &_ok,
            &_cancel {
                padding: 0 !important;
                border: none !important;
                @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
                border-radius: 6px !important;
                height: 32px !important;
                line-height: 32px !important;
                min-width: 80px;
                font-size: 14px !important;

                span {
                    line-height: 32px !important;
                    font-weight: 700;
                }
            }

            &:hover {
                @include box-shadow(0, 0, 0, 0);
            }

            &_ok {
                @include themify($themes) {
                    background: themed("themePrimaryButtonBackgroundColor");
                }

                &:hover {
                    @include themify($themes) {
                        background: themed("themePrimaryButtonBackgroundColorHover");
                    }
                }
            }

            &_cancel {
                @include themify($themes) {
                    background: themed("themeWhiteDiagonalColor");
                    color: themed("themeBaseColor");
                }

                &:hover {
                    @include themify($themes) {
                        background: themed("themeWhiteDiagonalColor");
                    }
                }
            }
        }
    }

    &_combo_text {
        line-height: 26px !important;
    }
}
