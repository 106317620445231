@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";

.workflow-tasks-filter {
    &.content-panel {
        margin-top: 0 !important;
    }

    &.flex-column {
        position: fixed;
        width: 130px;
        flex-shrink: 0;
    }

    .workflow-tasks-show-history {
        margin-bottom: 10px;
        white-space: nowrap;

        label {
            white-space: initial;
            width: 50px;
            word-break: break-word;
            font-weight: 500;
            font-size: em(12);

            @include ie {
                word-wrap: break-word;
            }
        }

        label,
        .switcher {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .workflow-tasks-filter-block {
        padding: 5px;
        background-color: white;
        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
        }

        .workflow-tasks-filter-block-title {
            padding: 5px;
        }

        .select-box {
            justify-content: flex-start;
            margin: 5px;
            user-select: none;
        }

        + .workflow-tasks-filter-block {
            border-top: none;
        }
    }
}

.workflow-title {
    margin-bottom: 10px;
}
