@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.template-info-properties {
    padding-top: 12px;

    .property-list-item {
        border-bottom: none !important;
    }

    .property-list-item-inner {
        padding: 5px 0 10px;
    }
}

.template-info-date {
    font-size: em(11);
    padding: 15px 20px;
    justify-content: space-between;

    .template-info-date-label {
        opacity: 0.6;
    }

    .template-info-date-user {
        padding-left: 5px;
        font-weight: 500;
    }

    .template-info-date-title {
        opacity: 0.6;
    }

    .template-info-date-edited {
        text-align: right;
    }

    .template-info-date-sub-info {
        padding-top: 5px;
    }

    @include themify($themes) {
        background: themed("themeSemiWhiteColor");
    }
}
