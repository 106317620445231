@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item--border {
    .property-list > .property-list-item--border-width {
        .select-box {
            padding: 3px;

            &.selected {
                position: relative;
                border: none;
                background: white;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    @include themify($themes) {
                        border: 1px solid themed("themeBoxShadowBorderColor");
                        @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                        border-radius: 5px;
                    }
                }

                .border-box {
                    @include themify($themes) {
                        border-color: themed("themeBaseColor");
                        background: themed("themeBaseColorSemiTransparent7");
                    }
                }
            }

            &:not(.selected) {
                background: none;
                border-color: transparent;
            }

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }

    .property-list > .property-list-item--border-width-all-sides,
    .property-list > .property-list-item--border-width-side-by-side {
        margin: 0 20px;
    }

    .property-list > .property-list-item--border-width-all-sides {
        padding: 8px 0;
    }

    .property-list > .property-list-item--border-color {
        margin: 0 20px;

        .property-list-item {
            width: 100%;
        }

        &:not(.property-list-item--border-color-all-sides) .text-label {
            text-transform: uppercase;
            font-size: 10px;
            width: 50px;
            margin-right: 10px;

            .title-postfix {
                display: none;
            }
        }
    }

    .border-side {
        padding: 4px 0;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }

        .text-label {
            text-transform: uppercase;
            font-size: 10px;
            width: 50px;
            margin-right: 10px;
        }
    }

    .border-box {
        width: 100%;
        height: 100%;
        @include themify($themes) {
            border-color: themed("themeColorBaseGrey");
        }
        border-style: solid;

        @include themify($themes) {
            background: themed("themeBaseColorDesaturatedSemiTransparent7");
        }

        @for $i from 1 through 5 {
            &--width-#{$i} {
                border-width: #{$i}px;
            }
        }

        &--top {
            border-right: none;
            border-bottom: none;
            border-left: none;
        }

        &--right {
            border-top: none;
            border-bottom: none;
            border-left: none;
        }

        &--bottom {
            border-top: none;
            border-right: none;
            border-left: none;
        }

        &--left {
            border-top: none;
            border-right: none;
            border-bottom: none;
        }
    }
}
