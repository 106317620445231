@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.field-wrap {
    &.right {
        .text {
            padding-right: 28px;
        }

        .text + .icon-wrap,
        .text + label + .icon-wrap {
            position: absolute;
            right: 1px;
            top: 2px;
        }
    }
}
