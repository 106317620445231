@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.lead-image-property {
    .title-configure {
        margin-top: 5px;
    }

    .portal-builder-properties-section-info {
        padding-left: 0;
        padding-right: 0;
    }
}

.lead-image-property-button {
    width: 100%;
    text-align: left;
    text-transform: uppercase;
}
