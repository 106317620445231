@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.sidebar-content.no-footer.notes-sidebar-panel {
    overflow: inherit;

    .add-note {
        padding: 15px;
    }

    .notes-list-container {
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }

        .wait-icon {
            padding: 13px;
        }

        .expand-label {
            align-self: stretch;
            border: 1px solid $light-grey;
            padding-left: 5px;

            .label-text {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .note {
            padding: 7px 13px;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }

            &:not(:hover) {
                .note-actions {
                    @include tablet-large {
                        .icon-wrap {
                            opacity: 0;

                            &:focus {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .note-header {
                .icon-wrap {
                    right: -6px;

                    &:before {
                        font-size: 16px;
                    }

                    &:hover {
                        background: transparent !important;
                    }
                }
            }

            .note-body {
                position: relative;
                margin-left: 7px;
                padding: 7px 10px;
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
                word-wrap: break-word;

                &:before {
                    content: "";
                    position: absolute;
                    left: -6px;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                }
            }

            .note-footer {
                font-size: 11px;

                .font-size-large & {
                    font-size: 13px;
                }

                .icon-wrap {
                    margin-right: 5px;
                    text-align: right;

                    &:before {
                        font-size: 12px;

                        .font-size-large & {
                            font-size: 14px;
                        }
                    }

                    &.user-identity-person-filled:before {
                        top: 1px;
                        font-size: 10px;

                        .font-size-large & {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .notes-list {
            .footer {
                justify-content: space-between;
                height: 48px;
                padding-right: 10px;
                background-color: $white-light-grey;
                @include border-radius(0 0 6px 6px);

                .theme-sightline &,
                .theme-sightlineHighContrast & {
                    background: $sightline-white-light-grey;
                }

                .counter {
                    font-size: em(12);

                    .font-size-large & {
                        font-size: em(14);
                    }
                }
            }
        }
    }
}
