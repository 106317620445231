@import "../../../../../../../assets/sass/_variables";
@import "../../../../../../../assets/sass/_mixins";
@import "../../../../../../../assets/sass/_functions";

.program-wf {
    .tab-list {
        z-index: 1;
    }

    &__controls {
        right: 15px;

        @include mobile-only {
            @include flexbox;
            @include data-grid-controls-mobile;
            min-height: 40px;
        }

        @include tablet {
            z-index: 2;
            position: absolute;
            top: 22px;
            right: 20px;
        }

        @include tablet-large {
            richness: 25px;
        }
    }

    &__grid-wrap,
    &__audit {
        margin: 0 -15px;
        padding: 0 15px;
        @include calc(width, "100% + 30px");

        @include tablet-only {
            margin: 0 -20px;
            padding: 0 20px;
            @include calc(width, "100% + 40px");
        }

        @include tablet-large {
            margin: 0 -25px;
            padding: 0 25px;
            @include calc(width, "100% + 50px");
        }
    }

    &__audit {
        @include tablet {
            position: relative;
            margin-top: -50px;
            padding-top: 60px;
        }

        .grid-list {
            @include data-grid-sticky-header;

            @include ie {
                height: 100%;
            }
        }
    }
}
