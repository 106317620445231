@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";

.panel.snapshot {
    .wait-icon {
        margin: 50px 0;
    }

    .panel-report {
        margin-top: -35px;
        @include calc(height, "100vh - 218px");
        pointer-events: none;

        @include tablet {
            margin-top: -36px;

            .data-grid-controls {
                margin-bottom: 15px;
            }
        }

        .split-view & {
            @include calc(height, "100vh - 244px");
        }
    }

    .report {
        @include flexbox;
        @include flex-direction(column);

        .data-grid-controls .icon-with-label {
            pointer-events: auto;
        }

        .report-body {
            flex: 1;
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }
            pointer-events: auto;
        }
    }
}
