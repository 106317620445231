@import "../../../../../../assets/sass/_mixins";

.program-config-panel {
    @include tablet {
        .panel-header-large {
            text-align: center;
        }
    }
}

.program-config-criteria-sidenav-panel,
.program-config-attributes-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
