@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.window-header-content {
    @include mobile-only {
        @include flexbox;
    }

    .split-view & {
        @include flexbox;
    }

    > div {
        display: inline-block;
        vertical-align: top;

        @include mobile-only {
            @include flexbox;
            align-items: center;
        }

        .split-view & {
            @include flexbox;
            align-items: center;
        }
    }

    .project-mark {
        margin-right: 10px;

        span {
            font-size: em(10);
            font-weight: 700;
            text-transform: uppercase;
            @include themify($themes) {
                color: themed("themeLighterDirtyColor");
            }

            .font-size-large & {
                font-size: em(12);
            }

            @include tablet {
                font-size: em(12);
                line-height: 40px;

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }
    }

    &:not(.app-is-locked) .project-mark {
        @include desktop-extra-large {
            &:not(.application-form-pages-view) {
                position: absolute;
                left: 20px;
                top: 0;
            }

            .split-view & {
                position: initial;
            }
        }

        @include desktop-large {
            .layout-full-screen & {
                position: initial;
            }
        }
    }

    &.app-is-locked .project-mark {
        @include desktop-large {
            .layout-full-screen & {
                margin-left: 20px;
            }

            .layout-full-screen .layout-left & {
                margin-left: 0;
            }
        }

        @media only screen and (min-width: 1792px) {
            .layout-with-borders & {
                position: absolute;
                left: 52px;
                top: 0;
            }
        }

        .split-view & {
            position: relative;
            left: -8px;
        }
    }

    .project-number {
        position: relative;
        margin-right: 4px;
        font-size: em(18);
        font-weight: 500;
        line-height: 1;
        color: white;

        @include mobile-only {
            justify-content: center;
            padding: 7px 0;
            min-height: 40px;
        }

        @include tablet {
            height: 40px;
            font-size: em(22);
            line-height: 42px;
        }

        > .icon-wrap {
            display: none;
        }

        @include tablet-large {
            > .icon-wrap {
                @include centered;
                @include scale(0.7);
                line-height: 26px;
            }

            &:hover {
                color: rgba(255, 255, 255, 0.7);

                > .icon-wrap {
                    display: block;
                }
            }
        }

        .split-view & {
            padding: 7px 0;
            height: auto;
            min-height: 40px;
            line-height: 1.1;
            font-size: em(20);
        }

        + .icon-wrap {
            height: 40px;
            line-height: 42px;
            color: white;

            @include mobile-only {
                &:before {
                    font-size: em(14);
                }
            }
        }
    }
}
