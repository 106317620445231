.property-list-item--heading-type {
    border-bottom: none !important;
    width: 50%;

    .property-list-item--select-box {
        height: 100%;

        .property-list-item-inner {
            height: 100%;
            padding: 0 0 0 17px;

            .flex-row {
                height: 46%;
                margin-top: 10px;
            }

            .heading-widget {
                height: 100%;
                margin: -20px 0 0;
            }
        }
        .select-box {
            span {
                font-size: 13px;
            }
        }
    }
}
