@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-widgets-section .widget-header {
    .portal-builder-section-header-controls {
        margin: 10px;
        font-weight: 700;
    }

    .widget-title {
        text-transform: none;
        font-weight: 700;
    }

    .global-widget-warning {
        background: $warning-light;
        color: $warning;
        padding: 15px 5px;
        margin: 0 15px;
        height: 60px;

        .text-label {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .preview-area {
        padding: 0px 15px 30px 15px;

        .text-label {
            font-weight: 700;
            text-transform: uppercase;
            color: $light-grey;
            align-self: center;
            position: relative;
            top: 10px;
            background-color: $white-grey-light;
            padding: 0 10px;
            font-size: 14px;
        }

        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .preview-area-content {
            height: 100px;
            background-color: $white-grey-light;
            @include box-shadow(0, 0px, 15px, 0 rgba(0, 0, 0, 0.1) inset);
        }
    }
}
