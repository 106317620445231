@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.rebates-panel-large {
    .rebates-list {
        .item {
            background-color: white;
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .expandable-list {
            margin-top: 10px;
            border: none;

            @include tablet {
                margin-top: 20px;
            }

            &.expanded {
                position: relative;

                @include mobile-only {
                    @include expanded-panel-mobile;
                    padding-top: 120px;
                    overflow: auto;

                    .inline-panel-header {
                        z-index: 2;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }
                }

                @include tablet {
                    @include expanded-panel;
                    margin: 10px -10px -10px;
                }
            }

            .item {
                padding: 0;

                &:hover,
                &:focus,
                &.expanded {
                    background-color: white;
                }
            }
        }
    }
}
