@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.page-lead-block {
    position: relative;
    padding: 30px 30px 35px;
    text-align: center;
    font-weight: 500;

    > div {
        position: relative;
    }

    span {
        font-size: em(15);
        font-weight: 700;
        @include themify($themes) {
            color: themed("themeTitleColor");
        }
    }

    &:before {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: 0 auto;
        width: 40px;
        height: 40px;
        @include themify($themes) {
            background: themed("themeBottomTabsBackgroundBase");
        }
        @include border-radius(50%);
        @include box-shadow-common($shadow-outer-hover);
        line-height: 40px;
        color: white;
    }

    .background-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            body:not(.theme-sightline) & {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                background: url("../../../assets/img/login-screen-background-img.png") center bottom;
                opacity: 0.1;
            }

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: url("../../../assets/img/sightline-login-screen-background-img.jpg") center;
                opacity: 0.06;
            }
        }
    }
}
