@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item--type-selector {
    .text-label {
        padding-bottom: 10px;
    }

    .icon-wrap {
        height: 28px;
        width: 28px;
    }

    .type-list {
        .type-icon {
            width: 60px;
            height: 50px;
            padding-right: 25px;
        }

        .selected {
            border-radius: 8px;
            border: solid white;
            @include themify($themes) {
                box-shadow: themed("themeBaseColor") 0px 0px 8px;
            }
        }

        .info {
            background: linear-gradient(to right, #e1eff8, #affafb);
        }

        .attention {
            background: linear-gradient(to right, #f9d59e, #f9f9aa);
        }

        .regular {
            background: linear-gradient(to right, #e8e8e8, #f6f6f6);
        }
    }
}
