@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.settings-dropdown {
    .font-size-settings {
        height: 54px;
        padding: 0 15px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    .toggle-themes-open {
        text-transform: capitalize;
    }

    .theme-settings {
        height: 54px;
        padding: 0 15px;

        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    .setting-with-switcher {
        height: 54px;
        padding: 0 10px 0 15px;
        .settings-dropdown__title {
            margin-right: 25px;
        }
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .icon-wrap {
            flex-shrink: 0;
        }
    }

    .theme-mode {
        height: 54px;
        padding: 0 15px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    @include tablet {
        top: 45px !important;
    }

    .icon-dropdown {
        .settings-empty,
        .settings-filled {
            body:not(.theme-mode-light) & {
                @include tablet-and-mobile {
                    background: transparent;
                    box-shadow: none;

                    &:before {
                        color: white;
                        font-size: em(22);
                    }
                }
            }
        }
    }

    .dropdown-list-wrap {
        @include mobile-only {
            top: 50px !important;
        }

        @include tablet {
            @include dropdown-semi-transparent-background;
            width: 320px;
        }

        &.with-title .dropdown-list-title {
            border: none;
            padding: 0;
            line-height: initial;
            white-space: initial;
        }

        .dropdown-list__items {
            @include flexbox;
            @include flex-wrap(wrap);
            justify-content: center;
            padding: 5px 10px 10px;

            @include tablet {
                flex: 1;
                overflow: auto;

                @include ie {
                    flex: 1 0 auto;
                    min-height: 400px;
                    height: 0;
                }
            }
        }

        :first-of-type.dropdown-list__items {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }
            @include ie {
                flex: 1 0 auto;
                min-height: 400px;
                height: 0;
            }
        }

        :last-child.dropdown-list__items {
            padding: 0;
            margin: 0;
            overflow: unset;
            @include ie {
                flex: 1 0 auto;
                min-height: 54px;
                height: 0;
            }
        }

        .dropdown-list-item {
            margin-top: 7px;
            padding: 0;
            @include calc(width, "50% - 7px");
            height: initial;
            border: none;
            overflow: initial;
            white-space: initial;
            line-height: initial;

            @include ie {
                flex-shrink: 0;
                width: 137px;
            }

            &:nth-of-type(2n + 1) {
                margin-right: 7px;
            }

            &:hover {
                background: transparent;
            }
        }

        :last-child.dropdown-list-item {
            @include ie {
                padding-bottom: 10px;
            }
        }
    }

    .dropdown-list {
        text-align: center;

        @include mobile-only {
            padding-top: 0;
            @include calc(height, "100vh - 50px");
            overflow-y: auto;
        }

        @include tablet {
            @include calc(max-height, "100vh - 125px");
        }
    }

    @include mobile-only {
        .dropdown-list-title {
            display: block !important;
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: em(12);
        line-height: initial;
        text-align: left;
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeSubMenuTextColor");
        }
        .font-size-large & {
            font-size: em(14);
        }
    }

    .layout {
        display: none;
        @include desktop-large {
            display: flex;
        }
        padding: 0 15px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
        height: 54px;
    }

    .font-size-switchers .select-box {
        width: auto;

        &__text {
            text-transform: none;
            display: none;
        }

        &:first-child {
            .icon-wrap:before {
                font-size: 19px;
            }
        }
    }

    .select-box:first-child {
        margin-right: 7px;
    }

    .theme-mode {
        &__title {
            text-transform: uppercase;
            font-size: em(12);
            line-height: initial;
            @include themify($themes) {
                color: themed("themeSubMenuTextColor");
            }
            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    .theme-mode-switcher-wrap {
        padding: 4px;

        @include themify($themes) {
            background: themed("themeBaseColor");
        }

        @include border-radius(18px);

        &.light {
            @include themify($themes) {
                background: themed("themeSubMenuBackgroundColorLight");
            }

            .icon-wrap {
                &:not(.selected):before {
                    @include themify($themes) {
                        color: themed("themeSubMenuTextColor");
                    }
                }
            }
        }

        &.dark {
            @include themify($themes) {
                background: themed("themeDarkColor");
            }
        }

        .icon-wrap {
            &:not(.selected) {
                &:hover {
                    background: transparent !important;
                }

                &:before {
                    color: white;
                }
            }

            &.selected {
                background: white;
                @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));

                &:hover {
                    background: white !important;
                }
            }
        }
    }

    &.field-group-dropdown-icon-popup__container {
        z-index: 26 !important;
    }

    .dropdown-list-wrap {
        :first-of-type.dropdown-list__items {
            overflow: auto;
        }
    }

    &.hide-themes {
        .dropdown-list-wrap {
            :first-of-type.dropdown-list__items {
                display: none;
            }
        }
    }
    .system-version {
        width: 100%;
        .sv-date {
            text-transform: uppercase;
            font-size: em(12);
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
            .font-size-large & {
                font-size: em(14);
            }
        }
    }
}
