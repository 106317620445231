@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.onboarding-modal-workcenter {
    width: 548px !important;
    border-radius: 8px;

    img {
        border-radius: 8px 0 0 8px;
        width: 280px;
        height: 274px;
    }

    .getting-started {
        @include themify($themes) {
            color: themed("themeBaseColor") !important;
        }
    }

    div {
        @include themify($themes) {
            color: themed("themeBaseColorDesaturated") !important;
        }
    }
}

.workcenter-v2-view {
    .workcenter-menu {
        .filter-item {
            .wc-btn:not(.active) .filter-name-icon {
                color: var(--color-grey-200);
            }

            .filter-actions {
                display: none;

                button {
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }
                }
            }

            .wc-btn.focused,
            &:hover {
                .filter-actions {
                    display: initial;
                    button {
                        &:hover,
                        &:focus {
                            @include themify($themes) {
                                background: var(--color-grey-400);
                            }
                        }
                    }
                }

                .wc-btn:not(.active) .filter-name-icon {
                    color: var(--color-grey-400);
                }
            }

            &.filter-favorite {
                .filter-actions {
                    display: initial;

                    .wc-btn {
                        display: none;
                    }

                    .\!text-amber-400 {
                        display: initial;
                    }
                }

                .wc-btn.focused,
                &:hover {
                    .filter-actions {
                        .wc-btn {
                            display: initial;
                        }
                    }
                }
            }

            &.filter-expandable {
                .wc-btn {
                    font-weight: 600;
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }
                }

                .filter-actions button:hover {
                    @include themify($themes) {
                        background: var(--color-grey-200);
                    }
                }

                &.focused,
                &:hover {
                    .filter-actions {
                        display: initial;
                    }
                }
            }
        }

        &:hover {
            .filter-expandable {
                .filter-actions {
                    display: initial;
                }
            }
        }
    }

    .header-title {
        font-size: 24px;
        font-weight: 500;

        &--small {
            font-size: 16px;
        }
    }

    .expand-icon {
        transition: transform 0.2s ease;

        &.rotated {
            transform: rotate(90deg);
        }
    }

    .wc-btn {
        font-size: 14px;
        font-weight: 400;
        font-family: $base-font-family;
        background: transparent;
        border: none;
        cursor: pointer;

        &:hover,
        &:focus,
        &.active,
        &.focused {
            @include themify($themes) {
                background-color: var(--color-grey-200);
            }
        }

        &:disabled {
            cursor: initial;
            @include themify($themes) {
                background-color: transparent;
            }
        }

        &.active {
            font-weight: 700;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        &.primary {
            @include themify($themes) {
                color: white;
                background-color: themed("themePrimaryButtonBackgroundColor");
            }

            &:hover {
                @include themify($themes) {
                    background-color: themed("themePrimaryButtonBackgroundColorHover");
                }
            }

            &.focus {
                @include themify($themes) {
                    background-color: themed("themeBaseColorDesaturated");
                }
            }
        }
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    .assign-modal-buttons {
        border-width: 1px 0 0 0;
    }

    .no-users-message {
        height: 200px;
    }
}

.history-modal-workcenter {
    width: 420px;
    background: white;
    .search-type,
    .search-time,
    .history-icon {
        @include themify($themes) {
            color: themed("themeBaseColorDesaturated") !important;
        }
    }
    .clear-close {
        @include themify($themes) {
            color: themed("themeBaseColor") !important;
        }
        &.with-icon:before {
            font-size: 14px;
        }
    }
}

.ReactModal__Overlay.modal-styled .modal.modal-sm.bulk-actions-modal-workcenter {
    max-height: 640px;
    width: 420px;

    .modal-content {
        background: white;
    }
}
