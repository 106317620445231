.associations-list {
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    &__header {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;

        .font-size-large & {
            font-size: 15px;
        }

        &__action {
            margin-left: 5px;
        }
    }

    &__list {
        .btn-tag {
            margin-top: 7px;
        }
    }

    &__placeholder {
        font-style: italic;
    }
}
