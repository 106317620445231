@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

$listHeaderRowHeight: 70px;
$listBodyRowHeight: 40px;
$entityColumnMinWidth: 250px;
$entityColumnMaxWidth: 350px;
$roleColumnMinWidth: 100px;
$roleActionsMenuWidth: 140px;
$listHeaderBackgroundColor: $white-light-grey;
$listBodyBackgroundColor: white;
$listBorderColor: "themeLighterDirtyColor";
$listHighlightedRowBackgroundColor: "themeHoverGridListRowBackgroundColor";
$listUtilityRowBackgroundColor: "themeSelectedGridListRowBackgroundColor";

@include tablet {
    .role-modal .modal-body {
        min-height: 150px;
        overflow: hidden;
        > div {
            height: 100%;
        }
    }
}

.roles-management {
    padding: 20px 0;
}

.roles-management-body {
    overflow: hidden;
    background-color: $listBodyBackgroundColor;

    @include themify($themes) {
        border-bottom: 1px solid themed($listBorderColor);
        border-right: 1px solid themed($listBorderColor);
    }

    .roles-management-entities-column {
        min-width: $entityColumnMinWidth;
        max-width: $entityColumnMaxWidth;

        .roles-management-column-header {
            justify-content: flex-end;

            .field-group {
                padding: 0px 2px 2px;
            }
        }
    }

    .roles-management-roles-column {
        overflow: hidden;
        flex-grow: 1;

        .roles-management-roles-header,
        .roles-management-roles-column-body {
            overflow-x: auto;
            overflow-y: hidden;
        }

        .roles-management-roles-column-body {
            overflow: auto;
            @include scrollbar-hide;
        }

        .roles-management-roles-header {
            overflow-x: auto;
            overflow-y: hidden;
            overflow: overlay;
            z-index: 1;
        }
    }

    .roles-management-column-header {
        height: $listHeaderRowHeight;
        background: $listHeaderBackgroundColor;

        @include themify($themes) {
            border: 1px solid themed($listBorderColor);
        }

        &--role {
            width: 0; // Need for flexbox to make all role columns the same width
            min-width: $roleColumnMinWidth;
            justify-content: center;
            position: relative;

            .field-group {
                position: absolute;
                top: 0;
                right: 0;

                .icon-wrap::before {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }

    .roles-management-header-column-title {
        font-size: 10px;
        padding: 10px;
        overflow-wrap: break-word;
    }

    .roles-management-entities-row {
        height: $listBodyRowHeight;
        font-size: 12px;
        align-items: center;
        padding: 2px 10px;

        @include themify($themes) {
            border: 1px solid themed($listBorderColor);
        }

        .roles-management-entities-row-title {
            overflow-wrap: break-word;
            padding-left: 10px;
        }

        &--utility {
            justify-content: space-between;
            cursor: pointer;

            @include themify($themes) {
                background-color: themed($listUtilityRowBackgroundColor);
            }

            .roles-management-entities-row-title {
                padding: 0;
            }
        }

        &--highlighted {
            @include themify($themes) {
                background-color: themed($listHighlightedRowBackgroundColor);
            }
        }
    }

    .roles-management-roles-row {
        height: $listBodyRowHeight;

        .roles-management-role-cell {
            position: relative;
            width: 0; // Need for flexbox to make all role columns the same width
            min-width: $roleColumnMinWidth;
            display: flex;
            align-items: center;
            justify-content: center;

            @include themify($themes) {
                border: 1px solid themed($listBorderColor);
            }

            .checkbox-checked-filled,
            .indeterminate-checkbox-filled {
                cursor: initial;
            }

            .field-group {
                position: absolute;
                top: 0;
                right: 0;
                display: none;

                .icon-wrap::before {
                    font-weight: bold;
                    font-size: 14px;
                }
            }

            &:hover,
            &--menu-active {
                .field-group {
                    display: block;
                }
            }
        }

        &--utility {
            .roles-management-role-cell {
                @include themify($themes) {
                    background-color: themed($listUtilityRowBackgroundColor);
                }
            }
        }

        &--highlighted {
            .roles-management-role-cell {
                @include themify($themes) {
                    background-color: themed($listHighlightedRowBackgroundColor);
                }
            }
        }
    }
}

.roles-management-footer {
    padding: 15px 20px;
    background-color: white;
    @include box-shadow-common($shadow-outer);

    .btn + .btn {
        margin-left: 20px;
    }
}

@include tablet {
    .role-actions-dropdown {
        .dropdown-list-wrap {
            min-width: $roleActionsMenuWidth;
            width: $roleActionsMenuWidth;
        }
    }
}
