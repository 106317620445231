@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.inline-panel-footer {
    padding: 18px 20px;
    @include box-shadow-common($shadow-outer-center);

    .btn-group {
        @include flexbox;
        @include flex-direction(row);
    }
}
