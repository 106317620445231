@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.dropdown-field {
    position: relative;
    padding: 0 32px 0 8px;
    width: 100%;
    height: 32px;
    background-color: white;
    line-height: 30px;
    cursor: pointer;

    &.with-extra-input {
        padding-left: 8px;
    }

    div.field-group {
        &.with-extra-input {
            div.field-wrap {
                input.text {
                    border: 0;
                    background-color: rgba(0, 0, 0, 0);
                    box-shadow: none;
                }

                input.focused {
                    box-shadow: none;
                }
            }
        }
    }

    .dropdown-field-text {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        font-style: italic;
        @include text-overflow;
        color: $blue-dark-grey;
    }

    &:not(.active):not(.dropdown-ghost) {
        @include border-radius(4px);
    }

    &:not(.active):not(.dropdown-ghost):not(.disabled):not(.dropdown-read-only):not(.focused) {
        @include themify($themes) {
            border: 1px solid themed("themeDropDownBorderColor");
        }
        @include box-shadow(0, 1px, 2px, 0 rgba(45, 52, 54, 0.25));
    }

    &:after {
        position: absolute;
        right: 5px;
        top: 1px;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
    }

    &.active,
    &.focused:not(.dropdown-read-only) {
        @include themify($themes) {
            border: 1px solid themed("themeBoxShadowBorderColor");
            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
        }

        &:after {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }

    &.active {
        @include border-radius(4px 4px 0 0);

        @include themify($themes) {
            border-bottom: none;
        }
    }

    &.filled {
        .dropdown-field-text {
            font-style: normal;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        &:not(.disabled):not(.dropdown-read-only):after {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }

    &.error:not(.disabled):not(.dropdown-read-only) {
        border-color: $red !important;
        @include box-shadow(0, 1px, 2px, 0 rgba(208, 2, 27, 0.25) !important);
        @include transition(box-shadow 0.5s !important);
        color: $red;

        &.active {
            border-bottom: none;
            @include box-shadow(0, 0, 8px, 0 rgba(208, 2, 27, 0.5) !important);
        }

        + .dropdown-list-wrap .dropdown-list {
            border-color: $red !important;
            @include box-shadow(0, 0, 8px, 0 rgba(208, 2, 27, 0.5) !important);
        }

        &:after {
            color: $red;
        }

        &.light-up-error {
            @include box-shadow(inset 0, 0, 0, 0 $red-field-error-light !important);
            @include box-shadow(0, 0, 15px, 0 $red-field-error !important);
        }
    }

    &.disabled {
        pointer-events: none;

        &:not(.dropdown-ghost) {
            background-color: $white-grey-medium;
        }

        .dropdown-field-text {
            color: $light-grey;
        }

        &:not(.dropdown-ghost) {
            border: 1px solid $grey;
        }

        &:after {
            color: $grey;
        }
    }

    &.dropdown-read-only {
        color: $blue-grey;
        pointer-events: none;

        @include themify($themes) {
            background: themed("themeWhiteColor");
            border: 1px solid themed("themeLighterDirtyColor");
        }
    }

    &.dropdown-ghost {
        &:not(.active) {
            display: inline-block;
            padding: 0 0 0 8px;
            width: inherit;
            height: 16px;
            line-height: 16px;

            &:after {
                position: initial;
                vertical-align: top;
                margin-left: 5px;
            }
        }

        &.filled:not(.disabled) {
            .dropdown-field-text {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            &.active {
                .dropdown-field-text {
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }
        }
    }
}

.dropdown-select-boxes {
    padding-bottom: 10px;

    .select-box {
        width: 100% !important;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 800;
    }
}
