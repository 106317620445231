@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";

.panel {
    &.panel-program-criteria,
    &.files-panel,
    &.panel-attributes,
    &.panel-hostnames {
        .data-grid-controls {
            pointer-events: none;

            .icon-with-label {
                pointer-events: auto;
            }
        }
    }

    &.files-panel,
    &.panel-attributes,
    &.panel-hostnames {
        .data-grid-controls {
            margin: -36px 0 12px;
        }
    }

    &.panel-program-criteria {
        .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;
        }
    }
}
