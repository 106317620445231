@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.main-grid-wrap.ui-library {
    .ui-library-group {
        margin: 20px 0;
        padding: 27px;
        background-color: white;
        @include box-shadow-common($shadow-outer-hover);
        @include border-radius(8px);

        @include mobile-only {
            > .flexbox-row,
            > div > .flexbox-row {
                display: block;

                > .flexbox-col:not(:last-child) {
                    margin-bottom: 30px;
                    padding-bottom: 35px;
                    @include themify($themes) {
                        border-bottom: 1px solid themed("themeLighterDirtyColor");
                    }
                }
            }
        }

        @include tablet-only {
            > .flexbox-row,
            > div > .flexbox-row {
                display: block;

                > .flexbox-col {
                    display: inline-block;
                    vertical-align: top;
                    @include calc(width, "50% - 20px");

                    &:not(:last-child) {
                        margin-bottom: 30px;
                        padding-bottom: 35px;
                    }
                }
            }
        }
    }

    .ui-library-title {
        margin-bottom: 19px;
        font-size: em(24);
        line-height: 31px;
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }
    }

    .ui-library-sub-title {
        margin-bottom: 22px;
        font-size: em(12);
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        @include themify($themes) {
            color: themed("themeLightColor");
        }

        .font-size-large & {
            font-size: em(14);
        }
    }

    .flexbox-row {
        + .flexbox-row {
            margin-top: 50px;
        }

        .row {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            > .switcher,
            > .status-tag,
            > .icon-wrap {
                display: inline-block;
                vertical-align: top;

                &:not(:last-child) {
                    margin-right: 17px;
                }
            }
        }
    }

    .row.with-background {
        padding: 15px;
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
    }

    .panel-content {
        margin-left: 30px;
        padding: 9px 13px;
        background-color: #f0f0f0;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }

        &.visible {
            display: block;
        }
    }
}
