@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";

.panel.notes {
    .grid-list-column {
        .add-group-filled {
            display: inline-block;
            position: relative;
            top: 2px;
        }
    }

    .grid-list-row-container {
        .grid-list-row {
            height: auto;

            .grid-list-column {
                white-space: normal;
                height: auto;
                word-break: break-word;

                @include ie {
                    word-wrap: break-word;
                }

                &.actions {
                    align-self: stretch;
                }
            }
        }
    }
}
.notes-sidenav-body {
    overflow-x: hidden !important;
}
