@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.accessibility-message {
    padding: 4px;
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
        border-top: none;

        .icon-wrap {
            margin-right: 5px;
            background-color: initial;
        }
    }

    &.expandable-panel.with-icon::before {
        font-size: 12px;
        flex-shrink: 0;
        margin-right: 0;
    }

    &-title {
        font-size: 12px;
        @include text-overflow;
    }

    & + .accessibility-message {
        margin-top: 0;
    }

    &.active {
        margin-bottom: 0;
        border-bottom: none;
    }
}

.accessibility-message-details {
    padding-bottom: 5px;

    .info-list-row {
        margin-bottom: 0;
        background: none;
    }

    .info-list-field-value {
        overflow-wrap: break-word;
        overflow: hidden;
        width: 100%;
    }

    .accessibility-message-nodes-list {
        .nodes-list-item {
            overflow-wrap: anywhere;

            & + .nodes-list-item {
                margin-top: 3px;
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }
            }
        }
    }
}
