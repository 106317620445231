@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.activity-tab.location-block.no-info {
    width: 100%;
    min-height: 200px;
    padding: 15px;
    background: $white-light-grey;
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background: $sightline-white-light-grey;
    }

    .title {
        font-size: em(18);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }
    }

    .nothing-found-block {
    }
}

.activity-tab.location-view {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;
    padding: 10px;
    min-height: 343px;
    background-color: white;
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }

    @include tablet {
        @include flex-direction(row);
    }

    .map {
        @include mobile-only {
            margin-bottom: 10px;
        }

        @include tablet {
            padding-right: 10px;
        }
    }

    .view {
        width: 343px;
    }

    @include mobile-only {
        overflow: hidden;

        > div > div,
        > img {
            width: 100% !important;
        }

        > img {
            height: 100%;
            object-fit: cover;
        }
    }
}
