@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_functions";
@import "../../../../../../assets/sass/_mixins";

.equipment-panel-large {
    .not-found-msg {
        padding: 20px 0;
    }

    .panel-header-large h2 {
        @include mobile-only {
            padding-right: 100px;
        }
    }

    .grid-list {
        .grid-list-row {
            .flip-to-front {
                visibility: hidden;
            }

            &:hover {
                .flip-to-front {
                    visibility: visible;
                }
            }
        }
    }

    .data-grid-controls {
        position: relative;
        padding-top: 30px;
    }

    .icon-with-label {
        &.with-hand-made-icon {
            position: absolute;
            right: 0;
            top: -5px;
        }
    }

    &.edit-equipment,
    &.clone-equipment,
    &.compare-equipment {
        @include mobile-only {
            @include expanded-panel-mobile;
            padding-bottom: 0 !important;

            .panel-header-large {
                margin-bottom: 0;
                padding: 15px 20px;
                background-color: $white-light-grey;

                @include themify($themes) {
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }

                h2 {
                    padding: 0;
                    font-size: em(18);
                }
            }

            .title-breadcrumbs {
                display: none;
            }
        }

        > .icon-wrap.clear-close {
            position: absolute;
            top: 3px;
            right: 3px;

            @include tablet {
                display: none;
            }
        }
    }

    &.edit-equipment,
    &.clone-equipment {
        .edit-equipment-form,
        .clone-equipment-form {
            background-color: white;
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }

            @include mobile-only {
                overflow-y: auto;
                @include calc(height, "100% - 52px");
                border: none !important;
            }
        }

        .equipment-details .equipment-actions .btn {
            @include mobile-only {
                width: auto;

                &:first-child {
                    flex: 1;
                }
            }
        }

        .equipment-info {
            @include mobile-only {
                position: relative;
                padding-bottom: 15px;

                .quantity-input {
                    top: 15px;
                }

                .flex-column:not(:first-child):not(:nth-of-type(2)) {
                    padding-right: 0;
                }
            }

            @include tablet {
                padding-bottom: 5px;
            }
        }
    }
}

.add-equipment-panel,
.add-approved-equipment-panel,
.update-equipment-panel,
.clone-equipment-panel {
    .sidenav-header {
        .scrollable-sidenav-header-part > .flex-column {
            .equipment-info-value {
                font-size: em(12);
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .font-size-large & {
                    font-size: em(14);
                }
            }

            &.quantity-input label {
                margin-bottom: 5px;
            }
        }
    }

    .equipment-form {
        @include mobile-only {
            flex: 1;
            overflow-y: auto;
            &:not(.approved-equipment-list-details) {
                margin-top: 50px;
            }
        }

        .form-info {
            margin: 0;
        }

        .equipment-inline-actions {
            margin: 0 0 20px;
        }

        &.approved-equipment-list {
            padding: 0;
            width: auto;
        }
    }

    .add-approved-equipment-form {
        margin: 0;
        @include border-radius(0);
        box-shadow: none;
        border: none;
        overflow: hidden;
    }

    .sidenav-footer {
        > .btn-light-blue {
            margin-left: 0;
            margin-right: 17px;
        }
    }
}
