@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

span {
    &.textfield-read-only {
        pointer-events: none;
        border-radius: 4px;
        padding: 8px;
        min-height: 64px;
        word-wrap: break-word;
        display: block;

        @include themify($themes) {
            background: themed("themeWhiteColor");
            border: 1px solid themed("themeLighterDirtyColor");
        }
    }
}
