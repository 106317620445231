@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.global-search {
    @include tablet-and-mobile {
        body:not(.theme-mode-light) & {
            > .icon-wrap.icon-wrap-white {
                background: transparent;
                box-shadow: none;

                &:before {
                    font-size: em(22);
                    color: white;
                }
            }
        }
    }

    &.active {
        > .icon-wrap {
            display: none;
        }

        .semi-transparent-background {
            visibility: visible;

            &:before {
                opacity: 1;
                @include transition(opacity 0.5s linear);
            }
        }
    }

    &__container {
        z-index: 30;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background: white;
        overflow: hidden;

        @include tablet {
            max-height: 0;
            @include box-shadow-common($shadow-outer);
            @include transition(all 0.5s ease);
        }

        &.active {
            height: 100%;

            @include tablet {
                max-height: 90%;
            }
        }

        > .icon-wrap {
            position: absolute;
            right: 5px;
            top: 5px;
        }

        > .field-group {
            margin: 0 auto;
            max-width: 830px;

            @include mobile-only {
                padding: 0 20px;
            }
        }
    }

    &__input,
    &__options {
        display: none;
    }

    &__search-area {
        @include flexbox;
        @include flex-direction(column);
        height: 100%;

        > .wait-icon {
            margin: 10px 0;
            align-self: center;
        }
    }

    &__search-area-expander {
        padding: 20px 0;
    }

    &__search-area-expander,
    &__search-parameters {
        @include themify($themes) {
            background: themed("themeWhiteColor");
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }
    }

    &__search-parameters {
        padding-top: 50px;

        > div {
            max-width: 830px;

            @include tablet {
                margin: 0 auto;
            }
        }

        @include tablet-only {
            padding: 50px 40px;
        }

        @include tablet-large {
            padding: 30px 0 35px;
        }
    }

    &__options {
        @include flex-direction(column);
        flex: 1;
        overflow: hidden;
    }

    &__results {
        margin: 0 auto;
        width: 830px;
        flex: 1;
        overflow-y: auto;

        @include mobile-only {
            padding: 25px 20px;
        }

        @include tablet {
            overflow: hidden;

            .search-results {
                @include flexbox;
                @include flex-direction(column);
                height: 100%;

                &__list {
                    padding: 0 5px 0 25px;
                    flex: 1;
                    overflow: auto;

                    flex-grow: 0;
                    flex-basis: auto;
                }
            }
        }
    }

    .semi-transparent-background {
        @include semi-transparent-background;
        visibility: hidden;

        &:before {
            z-index: 29;
            opacity: 0;
            @include transition(visibility 0s 0.5s, opacity 0.5s linear);
            @include themify($themes) {
                background: themed("themeBaseColorSemiTransparent30");
            }
            pointer-events: inherit;
        }
    }

    &__section-title {
        margin-bottom: 8px;
        font-size: em(12);
        font-weight: 500;

        .font-size-large & {
            font-size: em(14);
        }

        &--tables {
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        &--center {
            @extend .global-search__section-title;
            text-align: center;
        }

        strong {
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }
    }

    .nothing-found-block {
        margin-bottom: 15px;
        border-top: none !important;

        .raw-html span {
            display: block;
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;

            .font-size-large & {
                font-size: 14px;
            }
        }
    }
}

.expandable-search-result {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 5px 10px 5px 0;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }

    @include themify($themes) {
        background-color: themed("themeBaseColorSemiTransparent7");
    }

    .expandable-panel-info {
        color: #00000059;
        font-size: 0.8em;

        @include themify($themes) {
            color: themed("themeDarkColorSemiTransparent70");
        }
    }
}
