@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.calculation-header-form {
    .json-form {
        .form-group.field {
            margin-bottom: 15px;

            > span {
                display: block;
                margin-top: -3px;
                font-size: em(12);
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }

        .schema-container.calc-name-container span {
            word-break: break-word;

            @include ie {
                word-wrap: break-word;
            }
        }

        .control-label {
            margin-bottom: 5px;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
            font-weight: 400;
        }

        .dropdown-field {
            @include dropdown-dynamic-height;
        }
    }

    .icon-with-label.hub-empty {
        margin-top: 10px;
    }
}
