@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.new-application-form {
    padding: 30px 0 0;

    .grid-list-row-container {
        max-height: 485px;
        overflow: auto;
    }

    &.from-scan {
        padding: 0;
    }

    > .main-grid-wrap > h3 {
        margin-bottom: 25px;
        text-align: center;
    }

    &__content {
        margin: 0 auto;
        padding: 20px 15px;
        max-width: 1010px;
        background: $white-light-grey;
        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
        }

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        @include tablet {
            padding: 30px;
        }

        > div:not(:last-child) {
            margin-bottom: 40px;

            @include tablet {
                margin-bottom: 50px;
            }
        }
    }

    > .btn-group {
        padding: 20px 30px 50px;
        text-align: center;

        @include mobile-only {
            @include flexbox;

            .btn:first-child {
                flex: 1;
            }
        }
    }

    .new-app-equipment .new-app-section {
        .status-msg {
            margin: 10px 0 0;
        }

        .data-grid-controls {
            padding-top: 0;
            margin-top: 0;

            @include mobile-only {
                &__right-side-actions,
                &__right-side-actions {
                    flex: 1;
                }

                .icon-with-label {
                    white-space: normal;
                    line-height: 16px;

                    &:before {
                        @include calc(top, "50% - 14px");
                    }
                }
            }
        }
    }

    .new-app-section {
        position: relative;
        margin: 0 auto;

        &__form {
            padding: 15px 20px 20px;
            width: 100%;
            background: white;
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }

            @include tablet {
                padding: 30px 35px 35px;
            }

            .status-msg .icon-wrap {
                vertical-align: middle;
            }
        }

        .status-msg {
            margin: 0 0 20px;
        }

        .section-name {
            position: relative;
            margin-bottom: 15px;
            width: 100%;

            h3 {
                display: inline-block;
                padding-left: 40px;

                @include mobile-only {
                    font-size: em(16);
                }
            }

            p {
                margin-top: 3px;
                padding-left: 40px;
                opacity: 0.7;
            }

            .icon-wrap {
                position: absolute;
                @include calc(top, "50% - 18px");
                left: -5px;

                @include themify($themes) {
                    background: themed("themeLighterDirtyColor");
                    color: themed("themeDarkColor");
                }
            }
        }
    }

    .new-app-form {
        .form-info {
            padding-bottom: 30px;

            @include mobile-only {
                @include flex-direction(column);
            }

            .status-mark {
                top: -1px;
                width: fit-content;
            }

            p {
                margin-left: 7px;
                font-size: em(12);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(14);
                }

                @include mobile-only {
                    margin: 5px 0 0 0;
                }
            }
        }

        fieldset > legend {
            span {
                &:before,
                &:after {
                    left: -20px;

                    @include tablet {
                        left: -35px;
                    }
                }
            }
        }

        .action-buttons {
            display: none;
        }
    }

    .new-app-files {
        .file-upload {
            @include expanded-panel;
            margin: -3px 0 10px 0;

            @include mobile-only {
                @include expanded-panel-mobile;
            }

            .json-form {
                @include expanded-panel-json-form;

                @include mobile-only {
                    @include calc(height, "100% - 52px");
                    overflow-y: auto;
                }
            }

            @include tablet {
                .upload-file-area {
                    min-height: 103px;
                }
            }
        }

        .file-controls {
            margin-bottom: 10px;
        }
    }

    .application-form-creation-waiting {
        padding: 40px;
        text-align: center;
        line-height: 20px;

        strong {
            display: block;
            margin: 10px 0;
        }
    }
}

.modal.app-creation-confirmation-modal .modal-content {
    .modal-footer {
        @include flex-direction(column);
        align-items: center;

        .flex-row {
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}
