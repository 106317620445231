@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.grid-list-scrollable-columns {
    @include flexbox;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    @include webkit-scrollbar-thin-colored;
    @include scrollbar-hide;

    /*    .grid-list-row.filter &,
    .grid-list-row-container & {
        @include scrollbar-hide;
    }

    .grid-list-row-header & {
        @include rotate(-180deg);
    }

    .grid-list-row-header & .grid-list-column {
        @include rotate(180deg);
    }*/
}

.grid-list-scrollable-columns-container,
.split-view .grid-list-scrollable-columns-container {
    @include flexbox;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.grid-list-row-header .scroll-controls {
    z-index: 2;

    .scroll-control {
        top: 2px;
        height: auto;

        .icon-wrap {
            &:before {
                font-size: 24px;
            }
        }

        &.left {
            left: -20px;

            @include tablet {
                left: -22px;
            }
        }

        &.right {
            right: -23px;

            @include tablet-large {
                right: -25px;
            }

            .icon-wrap.icon-wrap-right {
                right: 0;
            }
        }
    }
}
