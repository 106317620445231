@import "../../../../../../../assets/sass/variables";
@import "../../../../../../../assets/sass/mixins";
@import "../../../../../../../assets/sass/functions";

.workflow-step-types-widget {
    &__wrapper {
        @include flexbox;
        justify-content: space-between;
        align-items: center;

        & .edit-empty {
            margin-left: 10px;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        > .field-group {
            width: 0;
            flex: 1;

            @include ie {
                flex: 1 0 auto;
            }

            @include firefox {
                flex: 1 0 auto;
            }

            + .icon-with-label {
                margin-left: 20px;
            }
        }
    }

    &__form {
        &--no-modal {
            margin-top: 15px;

            & .form-group {
                margin-bottom: 0 !important;
            }

            & .action-buttons {
                margin: 0 !important;
            }
        }

        &-field {
            &-textarea {
                textarea {
                    min-height: 200px;
                }
            }

            &-no-modal {
                margin-top: 20px;
            }
        }
    }

    &__attribute-field {
        margin-top: 20px;
    }
}
