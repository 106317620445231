@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.view-placeholder {
    font-size: em(36);
    color: rgba(0, 0, 0, 0.11);
    font-weight: 700;

    @include mobile-only {
        font-size: em(28);
    }

    &--inner-block {
        text-align: center;
    }

    &__clickable-text {
        margin-top: 7px;
        @include flexbox;
        justify-content: center;

        .icon-with-label {
            font-size: 13px;
            font-weight: 500;
        }
    }

    &.small {
        font-size: em(26);
    }

    .wait-icon {
        align-self: initial;
    }

    &:not(.split-screen-placeholder) {
        justify-content: center;
        height: 100%;
        min-height: 100%;
    }

    &.split-screen-placeholder {
        padding: 24px 30px;
        background: white;
        font-size: em(32);
        font-weight: 500;
        text-align: center;
        color: $blue-grey;

        .split-view-inner-block {
            padding: 116px 97px;
            width: 100%;
            height: 100%;
            @include themify($themes) {
                border: 4px dashed themed("themeLighterDirtyColor");
            }
        }
    }
}
