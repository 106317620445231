.error-message,
.warning-message,
.success-message,
.info-message {
    &.status-msg.msg-field-status {
        margin: 0;
    }

    &--margin.status-msg.msg-field-status {
        margin: 20px;
    }

    &--inline.status-msg.msg-field-status {
        display: inline-block;
    }

    &--margin-top.status-msg.msg-field-status {
        margin-top: 10px;
    }

    &--margin-bottom.status-msg.msg-field-status {
        margin-bottom: 10px;
    }
}
