@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.status-msg {
    position: relative;
    padding-left: 24px;
    font-size: em(12);
    line-height: 18px;
    .font-size-large & {
        font-size: em(14);
    }

    &:after,
    &:before {
        position: absolute;
        left: -5px;
        top: -6px;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
    }

    &.msg-field-status {
        margin-top: 10px;

        &.error-msg {
            color: $red;
        }
    }

    &.msg-icon-right {
        padding: 0 24px 0 0;
        text-align: right;

        &:after {
            left: auto;
            right: -5px;
        }
    }

    &.error-msg {
        display: none;

        &:after {
            color: $red;
        }

        &.msg-visible {
            display: block;
        }
    }

    &.warning-msg {
        color: $warning;

        &:after {
            color: $warning;
        }
    }

    &.success-msg:after {
        color: $green;
    }

    &.msg-centered {
        padding: 23px 0 0;
        line-height: initial;
        text-align: center;

        &:after {
            left: 0;
            right: 0;
            top: -5px;
            margin: 0 auto;
        }
    }

    &.msg-square {
        margin: -20px -20px 20px !important;
        padding: 20px 20px 6px;
        @include calc(width, "100% + 40px");
        text-align: center;

        &:after {
            position: relative;
            top: 15px;
            left: initial;
            display: block;
            margin: 0 auto;
            width: 100px;
            height: auto;
            background: white;
            @include border-radius(8px);
            font-size: 14px;
            font-family: $base-font-family;
            font-weight: 500;
        }

        &.error-msg {
            background: $red-gradient-to-bottom;

            &:after {
                content: "error";
            }
        }

        &.success-msg {
            background: $green-gradient;
            color: $green;

            &:after {
                content: "success";
            }
        }

        &.warning-msg {
            background: $warning-gradient;
            color: $warning;

            &:after {
                content: "warning";
            }
        }
    }

    &.msg-has-content:after {
        @include calc(top, "50% - 15px");
    }
}
