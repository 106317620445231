@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.window-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .window-content {
        overflow: hidden;
        height: 0;
    }

    .window-body-container {
        overflow: auto;

        .window-body {
            height: 100%;

            .project-view,
            .utility-invoice-view {
                padding-top: 26px;

                @include ie {
                    margin-top: 26px;
                    padding-top: 0;
                }
            }
        }
    }

    .window {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;

        &.split-view {
            flex-direction: row;
        }

        &[hidden] {
            display: none;
        }

        &.entity-locked,
        &.split-view .window-view.entity-locked {
            .window-header {
                @include themify($themes) {
                    background: themed("themeColorLocked");
                }
            }
        }

        > .sidenav-container,
        .window-view > .sidenav-container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    &.has-split-view {
        .window-view {
            overflow: auto;

            &.left {
                @include themify($themes) {
                    border-right: 1px solid themed("themeColorLocked");
                }
            }

            &.right {
                @include themify($themes) {
                    border-left: 1px solid themed("themeColorLocked");
                }
            }
        }
    }
}
