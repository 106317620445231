@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.contract-budget-lookups {
    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    .grid-controls {
        margin: -36px 0 12px;

        @include mobile-only {
            margin-top: 0;
            justify-content: space-between;

            .flex-one {
                display: none;
            }
        }
    }
}

.contract-budget-lookups-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
