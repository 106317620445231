@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.window-header-content {
    &.app-header {
        @include mobile-only {
            @include flex-wrap(wrap);
            height: 100%;
            align-items: center;
            padding: 7px 0;

            .project-mark {
                width: 100%;
            }

            .project-number {
                padding: 0;
                min-height: initial;

                + .icon-wrap {
                    height: initial;
                    line-height: initial;
                }
            }
        }
    }

    &.app-is-locked {
        @include mobile-only {
            .project-mark,
            .project-number {
                padding-left: 30px;
            }
        }
    }

    .app-is-locked-mark {
        position: absolute;
        left: 0;
        color: white;

        @include tablet {
            position: relative;
            top: 6px;
            left: -20px;
        }

        @include tablet-large {
            position: absolute;
            left: 0;
        }

        .icon-wrap {
            padding-right: 3px;
            height: 27px;
            @include themify($themes) {
                background: themed("themeBaseColor");
            }
            @include border-radius(0);
            text-align: right;

            @include tablet-large-and-desktop {
                padding-right: 0;
                width: 24px;
            }

            @include desktop {
                padding-right: 5px;
                width: 32px;
            }

            &:before {
                font-size: 17px;
                line-height: 27px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 28px;
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                @include themify($themes) {
                    border-left: 10px solid themed("themeBaseColor");
                }

                @include tablet-large-and-desktop {
                    left: 24px;
                }

                @include desktop {
                    left: 32px;
                }
            }
        }

        .split-view & {
            position: relative;
            top: 0;
            left: -30px;
        }
    }

    .project-info {
        color: white;

        @include tablet-and-mobile {
            display: none;
        }

        @include tablet {
            padding-left: 30px;
            position: relative;
        }

        .status {
            @include flexbox;
            align-items: center;
            height: 40px;
            font-size: em(12);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }

            .wait-icon {
                align-self: center;
            }

            > span {
                margin-right: 5px;
                @include themify($themes) {
                    color: themed("themeLighterDirtyColor");
                }
                font-weight: 400;
            }

            .field-group {
                color: initial;

                > .icon-dropdown {
                    @include flexbox;
                }

                .icon-with-label {
                    padding: 0 25px 1px 10px;
                    @include themify($themes) {
                        background: themed("themeDarkColorSemiTransparent");
                    }
                    @include border-radius(8px);
                    line-height: 24px;
                    color: white;
                    // To not shrink to 1px if there is no value selected
                    min-height: 25px;
                    // For long text to not break layout
                    max-width: 250px;
                    @include text-overflow;

                    &:after {
                        position: absolute;
                        top: 0;
                        right: 4px;
                    }

                    &.active {
                        min-width: 150px;
                        @include border-radius(8px 8px 0 0);

                        &:after {
                            top: 1px;
                        }
                    }
                }
            }
        }

        @include tablet-large {
            &:before {
                content: "";
                position: absolute;
                top: 12px;
                left: 13px;
                display: block;
                width: 1px;
                height: 17px;
                background-color: white;
                opacity: 0.5;
            }

            .status .dropdown-list-wrap {
                top: 25px;
                width: 100%;
                min-width: initial;
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
                @include border-radius(0 0 8px 8px);
                border-top: 1px solid rgba(255, 255, 255, 0.05);

                @include tablet {
                    max-height: 400px;
                    overflow-y: auto;
                    @include webkit-scrollbar-light;
                }

                .dropdown-list {
                    padding: 0 10px;
                    @include themify($themes) {
                        background: themed("themeDarkColorSemiTransparent");
                    }
                    @include border-radius(0 0 8px 8px);
                    box-shadow: none;
                    border: none;
                }

                .dropdown-list-item {
                    padding: 6px 20px 7px 0;
                    height: initial;
                    border-color: rgba(255, 255, 255, 0.2);
                    font-size: em(11);
                    font-weight: 500;
                    line-height: initial;
                    white-space: initial;
                    color: rgba(255, 255, 255, 0.8);

                    .font-size-large & {
                        font-size: em(13);
                    }

                    &.selected:after {
                        right: 0;
                        background: white;
                        width: 6px;
                        height: 6px;
                    }

                    &.selected,
                    &:hover {
                        background: transparent;
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }

    > .similar-premises {
        margin: 9px 0 0 15px;

        .icon-with-label {
            color: white;

            &:hover:before {
                background: transparent;
            }
        }
    }

    .sub-info {
        position: absolute;
        left: 0;
        bottom: -26px;
        width: 100%;
        white-space: nowrap;
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
        }
        font-size: em(11);
        line-height: 26px;

        .font-size-large & {
            font-size: em(13);
        }

        @include mobile-only {
            overflow-x: auto;
        }

        .sub-info-items {
            .theme-sightlineHighContrast & {
                font-weight: 500;
                span {
                    opacity: 0.75;
                }
            }

            @include tablet {
                overflow: auto;
            }

            @include hover-supported-devices {
                overflow: hidden;
            }
        }

        @include tablet-large {
            .sub-info-wrapper {
                position: relative;
                padding-right: 405px;

                .split-view & {
                    padding-right: 0;
                }
            }
        }

        .sub-info-item {
            display: inline-block;

            &.similar-premises {
                margin-right: 0 !important;

                .icon-with-label {
                    margin-right: 30px;
                    font-size: 12px;

                    .font-size-large & {
                        font-size: 14px;
                    }
                }
            }

            &:not(:last-child) {
                margin-right: 30px;
            }

            &:last-child {
                @include mobile-only {
                    margin-right: 10px;
                }

                @include tablet-only {
                    margin-right: 20px;
                }
            }

            &.status-not-desktop {
                .wait-icon {
                    display: inline-block;
                }

                @include tablet-large {
                    display: none;
                }

                .field-group {
                    .icon-with-label {
                        padding: 0 23px 1px 8px;
                        @include themify($themes) {
                            background: themed("themeBaseColorSemiTransparent15");
                        }
                        @include border-radius(8px);
                        font-size: 12px;
                        line-height: 17px;

                        .font-size-large & {
                            font-size: 14px;
                        }

                        &:after {
                            position: absolute;
                            top: 0;
                            right: 4px;
                        }

                        &.active {
                            min-width: 150px;
                            @include border-radius(8px 8px 0 0);

                            &:after {
                                top: 1px;
                            }
                        }
                    }
                }

                .dropdown-list__items {
                    @include mobile-only {
                        overflow: auto;
                    }
                }
            }

            span {
                display: inline-block;
                margin-right: 5px;
                opacity: 0.6;
            }
        }

        .scroll-controls {
            @include mobile-only {
                display: none;
            }

            .scroll-control {
                width: 40px;

                &.left {
                    left: 20px;
                    @include themify($themes) {
                        background-image: linear-gradient(to left, rgba(0, 0, 0, 0), themed("themeTransparentToSemiWhiteColorMiddle") 50%);
                    }

                    @include tablet-large {
                        left: 0;
                    }

                    .icon-wrap {
                        text-align: left;
                    }
                }

                &.right {
                    right: 20px;
                    @include themify($themes) {
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), themed("themeTransparentToSemiWhiteColorMiddle") 50%);
                    }

                    @include tablet-large {
                        right: 402px;
                    }

                    .split-view & {
                        right: 0;
                    }

                    .icon-wrap {
                        text-align: right;
                    }
                }

                .icon-wrap {
                    top: 0;
                    height: 26px;
                    line-height: 26px;

                    &:before {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
