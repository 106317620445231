@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.logs-system,
.logs-security {
    padding: 20px 0;

    .tab-list {
        z-index: 1;

        .split-view & {
            z-index: initial;
        }
    }

    .tab-list-tab-content {
        position: relative;
        margin: -44px -15px 0;
        padding: 44px 15px 0;
        @include calc(width, "100% + 30px");

        @include tablet-only {
            margin: -44px -20px 0;
            padding: 44px 20px 0;
            @include calc(width, "100% + 40px");
        }

        @include tablet-large {
            margin: -44px -25px 0;
            padding: 44px 25px 0;
            @include calc(width, "100% + 50px");
        }

        .split-view & {
            margin-top: 0;
            padding-top: 0;
        }
    }

    .data-grid-controls {
        margin: 0;

        @include tablet-and-mobile {
            @include flexbox;
            @include data-grid-controls-mobile;
            min-height: 40px;
        }

        @include tablet-large {
            z-index: 2;
            position: absolute;
            right: 20px;
            top: 14px;

            .split-view & {
                z-index: initial;
                position: initial;
                @include flexbox;
                @include data-grid-controls-mobile;
                min-height: 40px;
            }
        }
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    .grid-list-row.details {
        @include mobile-only {
            z-index: 3;
        }

        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }

        .fill-width {
            width: 100%;

            @include mobile-only {
                @include expanded-panel-mobile;
            }
        }

        .info-list {
            margin: 20px;
        }
    }
}
