@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.sidenav-header-info-list {
    .info-list-row {
        margin-bottom: 15px;
        @include flex-direction(column);
        background: transparent;

        .info-list-field-value {
            margin-top: -3px;
            font-size: em(12);
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            .font-size-large & {
                font-size: em(14);
            }
        }

        .info-list-field-label,
        .info-list-field-value {
            padding: 0;
        }

        .info-list-field-label {
            margin-bottom: 5px;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
            font-weight: 400;
        }
    }
}
