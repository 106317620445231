@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.icon-wrap {
    display: inline-block;
    @include border-radius(50%);
    text-align: center;

    &.icon-wrap-disabled {
        color: $grey;
        // pointer-events: none; // This breaks title attribute usage
        cursor: default;
    }

    &.icon-wrap-clickable {
        cursor: pointer;

        &:not(.icon-wrap-disabled):not(.icon-wrap-transparent):not(.icon-wrap-error):not(.icon-wrap-theme) {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            &:not(.icon-wrap-dropdown):not(.icon-wrap-white):not(.icon-wrap-error):not(.icon-wrap-theme):hover {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
            }
        }
    }

    &.icon-wrap-white {
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
        @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));

        &:not(.icon-wrap-disabled):hover {
            background-color: white;
            box-shadow: none;
        }
    }

    &.icon-wrap-white-inverse {
        @include themify($themes) {
            background: themed("themeBaseColor");
        }
        @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
        color: white !important;

        &:hover {
            @include themify($themes) {
                background: themed("themeBaseColor") !important;
            }
            box-shadow: none;
        }
    }

    &.icon-wrap-dropdown {
        &.icon-wrap-active {
            z-index: 22;
        }
    }

    &.icon-wrap-medium {
        width: 28px;
        height: 28px;
        line-height: 28px;
        flex-shrink: 0; // To not shrink in flex containers
    }

    &.icon-wrap-small {
        width: 14px;
        height: 14px;
        line-height: 16px;

        &:before,
        &:after {
            font-size: 14px;
        }
    }

    &.icon-wrap-big {
        height: 36px;
        line-height: 36px;

        &:not(.icon-wrap-dropdown) {
            width: 36px;
        }

        &:before,
        &:after {
            font-size: 24px;
        }
    }

    &.icon-wrap-transparent {
        &:hover {
            background-color: transparent;
        }

        &:before,
        &:after {
            color: white;
        }

        &.icon-wrap-disabled:before {
            color: $grey;
        }
    }

    &.icon-wrap-success {
        background-color: $green-light;
        color: $green;
    }

    &.icon-wrap-error,
    &.icon-wrap-error:hover {
        background-color: $red-light;
        color: $red;
    }

    &.icon-wrap-neutral {
        background-color: #e8e8e8;
    }

    &.icon-wrap-theme,
    &.icon-wrap-theme:hover {
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent10");
            color: themed("themeBaseColor");
        }

        &.icon-wrap-disabled {
            color: $grey;
            // pointer-events: none; // This breaks title attribute usage
            cursor: default;
        }
    }

    &.icon-wrap-warning {
        background: $warning-light;
        color: $warning;
    }

    &.icon-wrap-hidden {
        display: none;
    }

    &.icon-wrap-rounded-square {
        @include border-radius(8px);

        &:not(.icon-wrap-disabled):hover {
            @include themify($themes) {
                @include box-shadow(0, 1px, 2px, 0 themed("themeBaseColor"));
            }
        }
    }

    &.icon-wrap-no-mouseover {
        &:not(.icon-wrap-dropdown):not(.icon-wrap-white):not(.icon-wrap-error):not(.icon-wrap-theme):hover {
            background: inherit;
        }
    }
}
