@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.separator {
    height: 20px;
    flex-shrink: 0;

    &--line {
        width: 100%;
        height: 0px;
        margin: 10px 0;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    &--vertical {
        height: 100%;
        width: 20px;
    }

    &--margin-top-0 {
        margin-top: 0px;
    }

    &--margin-top-15 {
        margin-top: 15px;
    }

    &--margin-bottom-0 {
        margin-bottom: 0px;
    }

    &--margin-bottom-20 {
        margin-bottom: 20px;
    }
}
