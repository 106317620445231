@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.nothing-found-block {
    padding: 40px;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;

    &:not(.small) {
        min-height: 250px;

        @include ie {
            height: 0;
        }
    }

    &:not(.no-border) {
        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
        }

        &.error {
            border-color: $red-medium-light;
        }
    }

    &.no-wrapper {
        background: transparent;
    }

    &.small {
        padding: 30px;

        .nothing-found-block__title {
            margin-top: 7px;
            font-size: 18px;
        }
    }

    &.with-margin-top {
        margin-top: 10px;
    }

    &.error {
        background: $red-white;
        color: $red;
    }

    &__content {
        max-width: 250px;

        > span {
            display: block;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }

            b {
                font-weight: 500;
            }
        }

        .icon-wrap {
            width: 60px;
            height: 60px;

            &:not(.icon-wrap-error) {
                @include themify($themes) {
                    background: themed("themeBottomTabsBackgroundBase");
                }
                opacity: 0.13;
            }

            &.icon-wrap-error:before {
                color: $red-medium;
            }

            &:before {
                color: white;
                font-size: 37px;
                line-height: 60px;
            }
        }

        img {
            opacity: 0.1;
        }
    }

    &__title {
        margin: 5px 0;
        font-size: em(18);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeTitleColor");
        }

        .error & {
            color: $red;
        }
    }
}
