@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.icon-fast-tags {
    margin-left: 5px;

    img {
        height: 26px;
        padding: 2px;
    }
}
