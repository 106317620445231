@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.switcher {
    position: relative;
    width: 46px;
    height: 20px;
    @include border-radius(10px);

    &:before {
        position: absolute;
        top: -4px;
        left: 0;
        width: 28px;
        height: 28px;
        @include border-radius(50%);
        @include themify($themes) {
            background: themed("themeBaseColorDesaturated");
        }
        @include box-shadow(0, 0, 5px, 0 rgba(0, 0, 0, 0.25));
        line-height: 30px;
        text-align: center;
        color: white;
    }

    &:not(.disabled) {
        cursor: pointer;

        &.active {
            @include themify($themes) {
                background: themed("themeBaseColor");
            }

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }
    }

    &:not(.active) {
        @include themify($themes) {
            border: 2px solid themed("themeBaseColorDesaturated");
        }

        &:before {
            top: -6px;
            left: -2px;
        }

        &.disabled {
            border-color: $grey;
        }
    }

    &.active:before {
        right: 0;
        left: auto;
        background-color: white;
        @include box-shadow(0, 0, 5px, 0 rgba(0, 0, 0, 0.5));
    }

    &.disabled {
        pointer-events: none;

        &:before {
            background-color: $grey;
        }

        &.active {
            background-color: $grey;

            &:before {
                background-color: white;
                color: $grey;
            }
        }
    }
}
