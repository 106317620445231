@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.tool-list {
    position: relative;

    .tools {
        position: relative;
        padding: 8px 15px;
        white-space: nowrap;
        overflow: auto;

        @include hover-supported-devices {
            overflow: hidden;
        }

        @include tablet {
            padding: 8px 0;
        }

        @include tablet-large {
            @include scrollbar-hide;
        }
    }

    .tool {
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding: 0 28px;
        min-width: 135px;
        text-align: center;
        font-weight: 500;
        line-height: 28px;
        cursor: pointer;

        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
            color: themed("themeBaseColor");
        }

        .theme-sightlineHighContrast & {
            background: #5bcbf5;
            color: black;
            .icon-wrap.pinner.with-icon.icon-wrap-clickable {
                color: black;
            }
        }

        &.without-pin {
            padding: 0 10px;
        }

        &.sticky {
            position: sticky;
            right: 0;
            padding: 0 10px 0 4px !important;
            min-width: initial;
            @include themify($themes) {
                background: themed("themeBaseColor");
            }
            @include border-radius(6px);
            color: white;

            &:before {
                content: "";
                position: absolute;
                left: -15px;
                width: 15px;
                height: 100%;
                background: linear-gradient(to left, white 23%, rgba(0, 0, 0, 0));
            }

            &:hover,
            &.active {
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
                color: white;
            }

            &.active:after {
                display: none;
            }

            .icon-wrap {
                color: white !important;
            }

            .tool-info > .icon-wrap {
                display: inline-block;
            }
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        .tool-info {
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }

            > .icon-wrap {
                display: none;
            }
        }

        &.active {
            @include themify($themes) {
                background: themed("themeBaseColorSemiTransparent15");
            }

            .tool-info {
                font-weight: 700;
                font-size: em(13);

                .font-size-large & {
                    font-size: em(14);
                }
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 1px;
                width: 100%;
                height: 2px;
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }

                .theme-sightlineHighContrast & {
                    background: black;
                }
            }
        }

        .pinner {
            position: absolute;
            right: 0;
            top: 0;
            width: 28px;
            @include border-radius(0);
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
            line-height: 27px;

            @include tablet-large {
                opacity: 0;

                &:focus {
                    opacity: 1;
                }
            }

            &:hover {
                @include themify($themes) {
                    background: themed("themeBaseColorSemiTransparent7") !important;
                }
            }

            &:before {
                font-size: 14px;
            }

            &.pinned {
                opacity: 1;
            }
        }

        @include tablet-large {
            &:hover {
                @include themify($themes) {
                    background: themed("themeBaseColorSemiTransparent15");
                }
            }

            &.tool-pinned,
            &:hover {
                .pinner {
                    opacity: 1;
                }
            }
        }

        &.draggable {
            padding-left: 35px;
            text-align: left;

            .drag-handle-icon {
                position: absolute;
                left: 0;
            }
        }

        &.disabled {
            padding-right: 30px;

            .icon-wrap.block-not-interested-empty {
                position: absolute;
                top: 3px;
                right: 3px;
                opacity: 0.6;

                &:before {
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }
        }
    }

    .wait-icon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
    }

    .scroll-controls {
        @include mobile-only {
            display: none;
        }

        .icon-wrap:before {
            font-size: 37px;
            line-height: 28px;
        }

        @include tablet-only {
            .scroll-control {
                &.left {
                    left: -26px;
                }

                &.right {
                    right: -26px;
                }
            }
        }
    }
}
