@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.add-new-item-panel {
    padding: 10px;
    @include themify($themes) {
        border: 2px dashed themed("themeLighterDirtyColor");
    }
    cursor: pointer;

    @include tablet-large {
        &:hover {
            @include themify($themes) {
                border: 2px dashed themed("themeDirtyColor");
            }
        }
    }

    &.disabled {
        pointer-events: none;
    }
}
