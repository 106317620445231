@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";

.application-document-queue {
    &__dq-grid-controls {
        justify-content: space-between;
        padding: 10px;
        background: $white-light-grey;
        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
        }

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        &-selected-count {
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }

            @include tablet-large {
                text-align: center;
            }

            .split-view & {
                text-align: left;
            }
        }

        &-actions-group {
            @include tablet-and-mobile {
                z-index: 2;
                position: fixed;
                left: 0;
                bottom: 43px;
                padding: 15px 10px;
                width: 100%;
                background: white;
                @include box-shadow-common($shadow-outer-center);

                &.hidden-on-mobile {
                    display: none;
                }
            }

            .split-view & {
                z-index: 2;
                position: fixed;
                left: 1px;
                bottom: 55px;
                padding: 15px 10px;
                @include calc(width, "50% - 2px");
                background: white;
                @include box-shadow-common($shadow-outer-center);

                &.hidden-on-mobile {
                    display: none;
                }
            }

            .split-view .window-view.right & {
                left: initial;
                right: 1px;
            }
        }
    }
}
