@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.panel.activity {
    .content {
        .content-item {
            @include flexbox;
            @include flex-direction(row);

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            h3 {
                display: inline-block;
                margin-bottom: 10px;
                padding-right: 135px;
            }

            .data-grid-controls {
                margin-top: -30px;
            }

            @include mobile-only {
                @include flex-wrap(wrap);
                @include flex-direction(column);
            }

            &.applications {
                .icon-wrap.user-identity-person-empty {
                    top: -3px;
                    margin-right: 7px;
                }

                .nothing-found-block {
                    margin-top: 0;
                }
            }
        }
    }

    .grid-list {
        max-height: 970px;
    }

    .grid-list-row.current-app {
        &:before {
            content: "current";
            z-index: 1;
            position: absolute;
            left: -18px;
            padding: 1px 3px;

            @include themify($themes) {
                background: themed("themeBaseColor");
            }

            @include rotate(-90deg);
            @include border-radius(4px);
            font-weight: 700;
            font-size: em(6);
            text-transform: uppercase;
            align-self: center;
            color: white;

            @include ie {
                top: 17px;
            }
        }
    }

    .participation {
        @include mobile-only {
            width: 100%;
        }

        @include tablet {
            margin-right: 20px;
        }
    }

    .participation {
        @include mobile-only {
            margin-bottom: 30px;
        }
    }

    .participation,
    .equipment {
        @include tablet {
            @include calc(max-width, "50% - 10px");
        }

        .custom-list {
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }

            .grid-list-paging {
                width: 100%;

                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }
        }

        .nothing-found-block {
            margin-top: 0;
        }
    }
}
