.help__modal {
    .bg-transparent {
        background-color: transparent;
    }

    .border-0 {
        border: none;
    }

    margin: 2px;
}
