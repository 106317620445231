@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

@mixin track() {
    @include themify($themes) {
        background: linear-gradient(to right, themed("themeBaseColor"), themed("themeBaseColor")), $light-grey-medium;
        background-size: var(--background-size, 0%) 100%;
        background-repeat: no-repeat;
        border-radius: 5px;
    }
}

@mixin thumb() {
    cursor: pointer;
    @include themify($themes) {
        background: themed("themeBaseColorLightVersion");
    }
    border: solid white 1px;
    border-radius: 50%;
    margin-top: -6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
}

.property-list-item {
    &--input-range {
        .percentage-value {
            padding-left: 5px;
        }

        .percentage-input-field {
            width: 50px;
        }

        .input-field-slider {
            margin-right: 10px;
            width: 0px;

            &::-webkit-slider-runnable-track {
                @include track;
                height: 5px;
            }
            &::-moz-range-track {
                @include track;
                height: 4px;
            }

            &::-moz-range-thumb {
                @include thumb;
                height: 13px;
                width: 13px;
            }

            &::-webkit-slider-thumb {
                @include thumb;
                height: 15px;
                width: 15px;
            }
        }

        .input-field-slider,
        .input-field-slider::-webkit-slider-runnable-track,
        .input-field-slider::-webkit-slider-thumb {
            -webkit-appearance: none;
        }
    }
}
