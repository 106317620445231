@import "./assets/sass/_variables";
@import "./assets/sass/_mixins";
@import "./assets/sass/_functions";
@import "./assets/sass/_fonts";
@import "./assets/sass/_flexbox";
@import "./assets/sass/_border";
@import "./assets/sass/_text";
@import "./assets/sass/_background";
@import "./assets/sass/_icons";
@import "./assets/sass/_grid";
@import "./assets/sass/_map-controls-custom-style";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./schadcn/globals.css";

@layer utilities {
    .fill-height {
        height: 100%;
    }
    .fill-width {
        width: 100%;
    }
}

// Global CSS variables for colors
:root {
    --color-danger: #{$red-field-error};
    --color-grey-200: #e8e8f0;
    --color-grey-400: #ceceda;
}

* {
    margin: 0;
    padding: 0;
    @include box-sizing(border-box);
    @include webkit-scrollbar;
    -webkit-overflow-scrolling: touch;

    &:focus {
        outline: none;
    }

    &[hidden] {
        display: none !important;
    }

    &::selection {
        @include themify($themes) {
            background: themed("themeTextSelectionColor");
        }
        color: white;
    }
}

html {
    line-height: unset;
}

html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    font-size: $base-font-size;
    font-family: $base-font-family;
    font-weight: 400;
    @include themify($themes) {
        color: themed("themeColorBaseGrey");
    }
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include themify($themes) {
        background: themed("themeWhiteColor");
    }
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: right !important;
}

.text-overflow {
    @include text-overflow;
}

.no-overflow,
.no-scroll {
    overflow: hidden;
}

.with-scroll {
    overflow: auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }
}

h1 {
    font-size: em(32);
}

h2 {
    font-size: em(24);

    @include mobile-only {
        font-size: em(22);
    }
}

h3 {
    font-size: em(18);
}

.content-header {
    font-weight: 700;
}

p {
    font-size: em(12);
    line-height: 1.33;

    .font-size-large & {
        font-size: em(14);
    }

    .content-header + & {
        margin-top: 10px;
    }
}

ul {
    list-style: none;
}

a,
.link {
    @include themify($themes) {
        color: themed("themeBaseColor");
    }
    text-decoration: none;
    cursor: pointer;
}

input,
textarea {
    vertical-align: bottom;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="tel"],
input:not([type]) {
    @include text-overflow;
    padding: 0 8px;
    height: 32px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="tel"],
input:not([type]) {
    width: 100%;

    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
        @include box-shadow(inset 0, 1px, 2px, 0 themed("themeLighterDirtyColor"));
    }
}

textarea,
input[type="text"],
input[type="password"],
input[type="number"],
input[type="tel"],
input:not([type]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include border-radius(4px);
    font-size: $base-font-size;
    font-family: $base-font-family;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }

    @include placeholder {
        font-style: italic;
        color: $blue-grey;
    }

    &:disabled {
        background-color: $white-grey-medium;
        border-color: $grey;
        @include box-shadow(0, 0, 0, 0);
        color: $light-grey;
    }

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
    }
}

textarea {
    padding: 8px;
}

.required:after {
    content: "*";
    margin-left: 3px;
    line-height: 15px;
    color: $red;
}

.data-grid-controls {
    margin-bottom: 10px;

    .icon-with-label + .icon-with-label {
        margin-left: 20px;
    }
}

