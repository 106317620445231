@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.checkbox-wrap {
    input[type="checkbox"] {
        display: none;

        &:checked + label {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        &:disabled {
            + label {
                color: $light-grey !important;
                cursor: default;

                &:before {
                    color: $grey;
                }
            }
        }

        &.checked-partly:checked {
            + label {
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }

                &:before {
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }
                }
            }
        }
    }

    &:hover label:before {
        @include themify($themes) {
            color: themed("themeBaseColor");
        }
    }

    label {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        font-size: em(12);
        line-height: 15px;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
        cursor: pointer;

        .font-size-large & {
            font-size: em(14);
        }

        &:before {
            position: absolute;
            left: 0;
            top: -1px;
        }

        &.label-icon-big {
            padding-left: 30px;

            &:before {
                font-size: 25px;
            }
        }

        &.icon-label-empty {
            padding-left: 20px;
        }
    }
}
