@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.header-logo {
    display: inline-block;
    vertical-align: middle;

    body:not(.theme-sightline):not(.theme-sightlineHighContrast) & {
        width: 136px;
        height: 58px;
        background: url("../../../assets/img/Vision-Horizontal-DSM-White.svg") center;
    }

    body.theme-mode-light.theme-default & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Default.svg") center;
    }

    body.theme-mode-light.theme-darkSky & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-DarkSky.svg") center;
    }

    body.theme-mode-light.theme-blueSky & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-BlueSky.svg") center;
    }
    //

    body.theme-mode-light.theme-lightSeaGreen & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-LightSeaGreen.svg") center;
    }

    body.theme-mode-light.theme-valentine & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Valentine.svg") center;
    }

    body.theme-mode-light.theme-violets & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Violets.svg") center;
    }

    body.theme-mode-light.theme-olive & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Olive.svg") center;
    }

    body.theme-mode-light.theme-earth & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Earth.svg") center;
    }

    body.theme-mode-light.theme-sunset & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Sunset.svg") center;
    }

    body.theme-mode-light.theme-nature & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Nature.svg") center;
    }

    body.theme-mode-light.theme-dusk & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-Dusk.svg") center;
    }

    body.theme-mode-light.theme-northemLights & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-NorthernLights.svg") center;
    }

    body.theme-mode-light.theme-eveningShades & {
        background: url("../../../assets/img/theme-light-mode/Vision-Horizontal-DSM-EveningShades.svg") center;
    }

    body:not(.theme-sightline):not(.theme-sightlineHighContrast) &,
    .theme-mode-light.theme-default &,
    .theme-mode-light.theme-darkSky &,
    .theme-mode-light.theme-lightSeaGreen &,
    .theme-mode-light.theme-valentine &,
    .theme-mode-light.theme-violets &,
    .theme-mode-light.theme-olive &,
    .theme-mode-light.theme-earth &,
    .theme-mode-light.theme-sunset &,
    .theme-mode-light.theme-nature &,
    .theme-mode-light.theme-dusk &,
    .theme-mode-light.theme-northemLights &,
    .theme-mode-light.theme-eveningShades & {
        background-size: auto;
        background-repeat: no-repeat;

        @include ie {
            background-size: 358px 462px;
            background-repeat: no-repeat;
        }
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        top: 14px;
        width: 200px;
        height: 58px;
        background: url("../../../assets/img/Sightline-logo-color.png") left center no-repeat;
        background-size: 200px auto;

        @include tablet-large {
            height: 33px;
        }
    }

    @include tablet-large {
        position: absolute;
        left: 0;
    }

    .mobile-menu-opened & {
        @include mobile-only {
            margin-left: 10px;
        }

        @include tablet-only {
            margin-left: 20px;
            background-size: 130px auto;
        }
    }
}

.nav-bar-placeholder {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    height: 58px;
    @include themify($themes) {
        background: themed("themeGlobalNavBackgroundLighter");
    }

    .theme-mode-light & {
        background: white;
    }

    .header-logo {
        display: none;
    }

    &.mobile-menu-opened {
        position: relative;

        .header-logo {
            @include tablet-only {
                display: block;
                background-size: 170px auto;
            }
        }
    }

    @include tablet-only {
        display: block;
    }
}

.nav-bar {
    @include themify($themes) {
        background: themed("themeGlobalNavBackgroundLighter");
        @include box-shadow(0, 2px, 4px, 0 themed("themeBaseColorDesaturatedSemiTransparent"));
    }

    @include ie {
        height: 58px;
    }

    body:not(.theme-mode-light) & {
        box-shadow: none;
    }

    .theme-mode-light & {
        z-index: 3;
        background: white;
    }

    > .main-grid-wrap {
        position: relative;

        @include tablet-and-mobile {
            height: 100%;
        }
    }

    &.mobile-menu-opened {
        @include mobile-only {
            left: 0;
            width: 100%;
        }

        @include tablet-and-mobile {
            z-index: 25;
            position: absolute;
            top: 0;
            height: 100%;
        }

        @include tablet-only {
            width: 100%;
            @include themify($themes) {
                background: themed("themeDarkColorSemiTransparent70");
            }

            > .main-grid-wrap.responsive {
                position: fixed;
                top: 0;
                right: 0;
                width: 300px;
                @include themify($themes) {
                    background: themed("themeGlobalNavBackgroundLighter");
                }
                @include box-shadow(-4px, 1px, 8px, 0 rgba(0, 0, 0, 0.25));

                .theme-mode-light & {
                    background: white;
                }
            }
        }

        @include landscape-view {
            overflow-y: auto;
        }

        > .main-grid-wrap {
            @include tablet-and-mobile {
                padding: 0;
            }
        }

        .header-menu-wrap {
            @include flexbox;
            @include flex-direction(column);

            @include tablet-large {
                display: inline-block;
            }

            @include landscape-view {
                padding-top: 58px;
            }
        }

        .current-page-name.visible {
            display: none;
        }

        .mobile-menu-icon {
            right: 0;

            @include tablet-only {
                right: 12px;
            }
        }
    }

    .mobile-header-wrap {
        position: relative;
    }

    .current-page-name {
        display: none;

        &.visible {
            max-width: 110px;
            font-size: em(10);

            .font-size-large & {
                font-size: em(12);
            }

            @include tablet-and-mobile {
                @include flexbox;
                align-items: center;
                position: absolute;
                top: 0;
                right: 30px;
                height: 100%;
                font-weight: 500;
                text-transform: uppercase;
                text-align: right;
                @include themify($themes) {
                    color: themed("themeMainNavItemTextColor");
                }

                body:not(.theme-mode-light) & {
                    color: white;
                }
            }

            @include tablet-only {
                max-width: inherit;
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }
    }

    .mobile-menu-icon {
        position: absolute;
        right: -8px;
        top: 12px;

        &:before {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                color: black;
            }

            body:not(.theme-mode-light) & {
                color: white;
            }
        }

        @include tablet-large {
            display: none;
        }
    }

    .header-menu-wrap {
        display: none;

        @include tablet-large {
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        .scroll-control {
            display: none;
        }
    }

    .main-nav {
        @include tablet-and-mobile {
            order: 2;
            position: relative;
        }

        @include tablet-large {
            margin-right: 29px;
            margin-left: 150px;
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow: auto;
            @include webkit-scrollbar-thin;

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                margin-left: 230px;
            }
        }

        .main-nav-item {
            position: relative;
            font-weight: 500;
            line-height: 20px;
            @include themify($themes) {
                color: themed("themeMainNavItemTextColor");
            }
            cursor: pointer;

            body:not(.theme-mode-light) & {
                color: white;
            }

            &.uppercase {
                text-transform: uppercase;
            }

            @include tablet-and-mobile {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeMainNavItemSeparatorColor");
                }

                body:not(.theme-mode-light) & {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                }

                &:first-child {
                    @include themify($themes) {
                        border-top: 1px solid themed("themeMainNavItemSeparatorColor");
                    }

                    body:not(.theme-mode-light) & {
                        border-top: 1px solid rgba(255, 255, 255, 0.15);
                    }
                }
            }

            @include tablet-large {
                display: inline-block;
                vertical-align: top;
                font-size: em(12);
                line-height: 15px;

                .font-size-large & {
                    font-size: em(14);
                }

                &.active,
                &:hover {
                    + li:before {
                        display: none;
                    }
                }

                &:hover {
                    @include themify($themes) {
                        background: themed("themeMainNavItemSelectedBackgroundColor");
                    }

                    .theme-mode-light & {
                        background: white;
                        color: black;
                    }

                    span {
                        @include themify($themes) {
                            background: themed("themeMainNavItemSelectedBackgroundColorLight");
                        }

                        @include tablet-and-mobile {
                            @include themify($themes) {
                                background: themed("themeBottomTabsBackgroundLight");
                            }
                        }

                        body:not(.theme-mode-light) & {
                            background: transparent;
                        }
                    }
                }

                &:not(.active):not(:hover) {
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        top: 14px;
                        left: 0;
                        width: 1px;
                        height: 30px;
                        @include themify($themes) {
                            background: themed("themeMainNavItemSeparatorColor");
                        }

                        .theme-mode-light & {
                            top: 0;
                            height: 100%;
                        }

                        body:not(.theme-mode-light) & {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }

                    &:after {
                        display: none;
                        right: 0;
                        left: inherit !important;
                    }

                    &:last-child:after {
                        display: block;
                    }
                }
            }

            &.active {
                @include themify($themes) {
                    background: themed("themeMainNavItemSelectedBackgroundColor");
                    color: themed("themeMainNavItemSelectedTextColor");
                }

                body:not(.theme-mode-light) & {
                    color: white;

                    @include tablet-and-mobile {
                        margin-top: -1px;
                        border: none;
                        @include box-shadow(inset 0, 0, 3px, 1px rgba(0, 0, 0, 0.25));
                    }
                }

                .theme-mode-light & {
                    background: white;
                    font-weight: 700;
                }

                span {
                    @include themify($themes) {
                        background: themed("themeMainNavItemSelectedBackgroundColorLight");
                    }

                    @include tablet-and-mobile {
                        @include themify($themes) {
                            background: themed("themeBottomTabsBackgroundLight");
                        }
                    }

                    body:not(.theme-mode-light) & {
                        background: transparent;
                    }
                }
            }

            span {
                @include flexbox;
                align-items: center;
                padding: 10px;
                min-height: 50px;

                @include tablet-only {
                    padding: 10px 20px;
                }

                @include tablet-large-and-desktop {
                    width: 132px;
                }

                @include tablet-large {
                    justify-content: center;
                    padding: 8px 15px;
                    height: 58px;
                    text-align: center;
                }

                @include desktop {
                    width: 150px;
                }

                .split-view & {
                    width: auto;
                    max-width: 150px;
                }
            }
        }
    }

    .dropdown-list-wrap {
        top: 35px;
        right: 0;
        background-color: transparent;
        min-width: inherit;
        max-width: inherit;
    }

    .header-search-block,
    .header-settings-block,
    .header-profile-block {
        @include tablet-large {
            margin: 16px 0 0 18px;
        }
    }

    .header-settings-block {
        position: relative;

        .new-version-alert {
            position: absolute;
            bottom: 15px;
            left: 20px;
            z-index: 23;
            color: white !important;
            background: red !important;
            line-height: 12px;

            &:before,
            &:after {
                font-size: 11px;
            }
        }

        @include mobile-only {
            right: 88px;
        }

        @include tablet-and-mobile {
            position: absolute;
            top: 15px;
        }

        @include tablet-only {
            right: 95px;
        }
    }

    .header-search-block {
        order: 1;

        @include mobile-only {
            right: 45px;
        }

        @include tablet-and-mobile {
            position: absolute;
            top: 15px;
        }

        @include tablet-only {
            right: 60px;
        }

        @include tablet-large {
            order: unset;
        }
    }

    .header-profile-block {
        @include tablet-and-mobile {
            order: 3;
            position: absolute;
            bottom: 0;
            left: 0px;
            width: 100%;

            .field-group,
            .icon-dropdown {
                width: 100%;
            }

            .field-group {
                display: block;
            }
        }

        .icon-dropdown {
            @include mobile-only {
                .dropdown-list-item {
                    padding: 0;

                    > div {
                        padding: 0 3px;
                    }
                }
            }

            @include tablet-only {
                .dropdown-list-item {
                    padding: 0;

                    > div {
                        padding: 0 13px;
                    }
                }
            }

            @include tablet-and-mobile {
                > .icon-wrap {
                    display: none;
                }

                > .dropdown-list-wrap {
                    display: block;
                    position: initial;
                    width: 100%;
                }

                .dropdown-list {
                    background-color: transparent;
                    box-shadow: none;
                    border: none;
                    @include border-radius(0);
                }

                .dropdown-list-item {
                    height: 50px;
                    border-bottom: none;
                    font-weight: 500;
                    line-height: 50px;

                    @include themify($themes) {
                        border-top: 1px solid themed("themeMainNavItemSeparatorColor");
                        color: themed("themeMainNavItemTextColor");
                    }

                    body:not(.theme-mode-light) & {
                        border-top: 1px solid rgba(255, 255, 255, 0.15);
                        color: white;
                    }

                    &:hover,
                    &.selected {
                        @include themify($themes) {
                            background: themed("themeMainNavItemSelectedBackgroundColor");
                            color: themed("themeMainNavItemSelectedTextColor");
                        }

                        body:not(.theme-mode-light) & {
                            margin-bottom: -1px;
                            border: none;
                            @include box-shadow(inset 0, 0, 3px, 1px rgba(0, 0, 0, 0.25));
                            color: white;
                        }

                        .theme-mode-light & {
                            background: white;
                            font-weight: 700;
                        }

                        > div {
                            @include themify($themes) {
                                background: themed("themeMainNavItemSelectedBackgroundColorLight");
                            }

                            @include tablet-and-mobile {
                                @include themify($themes) {
                                    background: themed("themeBottomTabsBackgroundLight");
                                }
                            }

                            body:not(.theme-mode-light) & {
                                background: transparent;
                            }
                        }
                    }

                    &.selected {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .dropdown-list {
                padding-top: 0;
            }

            @include tablet-large {
                order: unset;

                .dropdown-list-wrap {
                    width: 130px;
                }
            }
        }
    }
}
