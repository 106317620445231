@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.dropdown-field {
    &.dropdown-multiple-choice {
        padding-left: 0;

        &.active.filled {
            background-color: $white-light-grey;

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background-color: $sightline-white-light-grey;
            }
        }

        &.filled {
            padding: 2px 32px 5px 6px;
            height: auto;
            min-height: 32px;

            &:after {
                @include calc(top, "50% - 15px");
            }

            + .dropdown-list-wrap {
                &:before {
                    top: -6px;
                    height: 5px;
                    background-color: $white-light-grey;
                }
            }
        }

        .dropdown-field-text {
            padding: 0 32px 0 8px;
        }

        .btn-tag:not(.btn-tag-hidden) {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}
