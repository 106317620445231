@import "../../../../../../../assets/sass/_variables";
@import "../../../../../../../assets/sass/_mixins";
@import "../../../../../../../assets/sass/_functions";

.quantity-widget {
    margin-bottom: -15px;

    &.edit-mode {
        margin: 0 -20px;
        padding: 20px;
        @include calc(width, "100% + 40px");
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
        }
        @include box-shadow(inset 0, 0, 3px, 1px rgba(0, 0, 0, 0.15));

        .btn-group {
            margin-top: 10px;
            @include flexbox;

            .btn {
                flex: 1;

                @include ie {
                    flex: 1 0 auto;
                }
            }
        }
    }
}
