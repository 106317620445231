@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.program-portal-settings {
    .portal-template-select-list,
    .property-list-item-selected-value {
        padding: 20px;
    }

    &.border-top,
    .property-list-item-selected-value {
        top: 2px;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }

    .portal-template-select-item {
        padding: 10px;
        border-radius: 10px 0;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        cursor: pointer;
        overflow: hidden;

        + .portal-template-select-item {
            margin-top: 20px;
        }

        &--active {
            @include themify($themes) {
                border: 1px solid themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            }
        }

        .text-label {
            width: 100%;
            font-weight: 700;
            font-size: em(16);
            margin: 15px 0 5px;
            text-align: center;
            @include text-overflow;
        }
    }

    .portal-template-thumbnail,
    .nothing-found-block {
        width: 100%;
        border-radius: 10px 0;
    }

    .nothing-found-block {
        @include themify($themes) {
            background-color: themed("themeWhiteColor");
        }
    }
}
