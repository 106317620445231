.portal-builder-cookies-panel {
    .tab-list.tab-list-ghost {
        padding-left: 15px;
    }

    .cookies-panel-texts-tab {
        .property-list-item {
            &--select-box,
            &--textarea {
                padding-top: 5px;
            }
            &--select-box,
            &--text,
            &--switch,
            &--textarea {
                border-bottom: none;
            }

            &--switch {
                .text-label {
                    text-transform: none !important;
                    font-weight: unset !important;
                }
                .icon-wrap {
                    margin-left: 5px;
                }

                .property-list-item-inner {
                    padding-top: 0;
                }
            }

            .enable-link-switch {
                .text-label {
                    font-weight: 700 !important;
                    text-transform: uppercase !important;
                }
            }

            &--select-box {
                .select-box__text {
                    padding: 0 8px;
                }
            }
        }
    }
}
