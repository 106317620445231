.note-preview {
    font-size: 13px;

    ol,
    ul,
    dl {
        padding-inline-start: 40px;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    ul,
    dl {
        list-style: initial;
    }

    p {
        font-size: 13px;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
}
