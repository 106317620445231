@import "../../../../assets/sass/_mixins";

.window-header-icon-group {
    @include flexbox;
    align-items: center;
    position: relative;
    right: -6px;
    min-height: 40px;

    @include desktop-extra-large {
        position: absolute;
        right: 14px;

        .split-view & {
            position: initial;
        }
    }

    @include desktop-large {
        .layout-full-screen & {
            position: initial;
        }
    }

    .view-stream-view-agenda-filled,
    .view-stream-view-agenda-empty {
        @include tablet-and-mobile {
            display: none;
        }

        @media screen and (min-width: 1025px) and (max-width: 1439px) {
            &:not(.icon-wrap-disabled) {
                display: none;
            }
        }

        &.icon-wrap-disabled {
            display: none;

            @media screen and (min-width: 1025px) and (max-width: 1439px) {
                display: block;
            }
        }
    }

    .movie-movies-empty,
    .movie-movies-filled {
        display: none;

        @include desktop-large {
            display: block;
        }
    }
}
