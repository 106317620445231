@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/variables";

.rebate-approvals {
    &__item-levels {
        background: white;
        @include border-radius(0 0 8px 0);
        font-size: 13px;

        .font-size-large & {
            font-size: 15px;
        }

        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
            border-top: none;
        }

        &-error {
            padding: 15px;
        }

        &-approved-entry,
        &-action {
            position: relative;
            padding: 10px 10px 12px;
            text-align: center;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 3px;
            }

            > .icon-wrap {
                z-index: 1;
                position: absolute;
                @include calc(left, "50% - 14px");
                bottom: -14px;
                background: white !important;
                @include scale(0.7);

                @include ie {
                    bottom: -19px;
                }
            }
        }

        &-approved {
            &-entry {
                &:before {
                    background: $green-medium;
                }

                &-title {
                    font-weight: 500;
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }

                &-description {
                    font-size: 11px;
                    @include themify($themes) {
                        color: themed("themeBaseColorDesaturatedSemiTransparent70");
                    }

                    .font-size-large & {
                        font-size: 13px;
                    }

                    span {
                        font-weight: 500;

                        &:not(.approved) {
                            margin: 0 3px;
                            @include themify($themes) {
                                color: themed("themeColorBaseGrey");
                            }
                        }

                        &.approved {
                            color: $green;
                        }
                    }
                }
            }
        }

        &-action {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 50%;
                height: 3px;
                background: $green-medium;
            }

            &:before {
                background: #dedede;
            }

            &:not(.disabled) {
                + .disabled:after {
                    display: none;
                }
            }

            &.disabled {
                + .disabled:after {
                    display: none;
                }
            }

            .btn {
                margin-left: 10px;
            }
        }
    }

    &__reject-modal {
        p {
            text-align: center;
        }

        .btn-group {
            margin-top: 20px;
            text-align: center;
        }
    }
}
