@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.panel.fast-tags-panel {
    .panel-header-large {
        + .data-grid-controls,
        + .grid-controls {
            margin: -36px 0 12px;
            pointer-events: none;

            .icon-with-label {
                pointer-events: auto;
            }

            @include mobile-only {
                position: relative;
                padding-top: 30px;
                justify-content: space-between;

                .icon-with-label {
                    &:last-child {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }

        @include mobile-only {
            + .grid-controls > .flex-one {
                display: none;
            }
        }
    }

    .grid-list-row.details {
        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }

        .full-width {
            width: 100%;

            @include mobile-only {
                @include expanded-panel-mobile;
            }
        }
    }

    .add-fast-tag-form {
        position: relative;
        @include expanded-panel;
        margin-top: -5px;

        @include mobile-only {
            @include expanded-panel-mobile;
        }
    }

    .fast-tag-data-form {
        .json-form {
            @include expanded-panel-json-form;

            @include mobile-only {
                overflow-y: auto;
                @include calc(height, "100vh - 52px");
            }
        }
    }

    .fast-tag-audit {
        padding: 15px 20px 20px;

        @include mobile-only {
            @include flexbox;
            @include flex-direction(column);
            @include calc(height, "100vh - 52px");
        }

        .grid-list {
            @include ie {
                height: auto;
            }

            @include mobile-only {
                @include data-grid-sticky-header;
                overflow: hidden;
            }
        }
    }

    .fast-tag-data-form,
    .fast-tag-audit {
        .json-form {
            .object-properties {
                > .schema-container {
                    display: inline-block;
                    vertical-align: top;

                    &:not(.fill-width) {
                        margin-right: 20px;
                        width: 100%;

                        @include tablet {
                            width: 25%;
                        }
                    }

                    &.fill-width {
                        @include tablet {
                            @include calc(width, "(100% / 4) * 3 - 20px");
                        }
                    }
                }
            }
        }
    }
}

.fast-tag-upload-modal {
    p + .json-form {
        margin-top: 20px;
    }

    .action-buttons {
        padding-top: 20px;
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }
    }
}
