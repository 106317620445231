@import "../../../../../../assets/sass/mixins";

.app-contact-form {
    .use-existing {
        margin-bottom: 25px;

        .btn-group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 8px;
            padding: 3px;

            .btn {
                margin: 3px !important;
            }
        }
    }

    .json-form {
        .account-number-widget {
            display: flex;
            flex-direction: row;

            .btn {
                margin-left: 10px;
            }
        }

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
