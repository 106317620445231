@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list {
    &--level-1 {
        > .property-list-item > .property-list-item-inner:not(.full-width-property) .text-label {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &--collapsed {
        height: 0;
        overflow: hidden;
    }

    &--level-2,
    &--level-3,
    &--level-4 {
        margin: 0 20px 17px;

        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }
}

.property-list-order-buttons {
    margin-left: -10px;
    .icon-wrap.icon-wrap-big {
        line-height: 14px;
    }
    > .shevron-small-up-expand-less {
        height: 14px;
        transform: scale(1.2);
    }

    > .shevron-small-down-expand-more {
        height: 14px;
        transform: scale(1.2);
    }

    > .arrow-wrap {
        background: transparent !important;
    }
}

.clone-row-btn {
    margin-left: 5px;
}
