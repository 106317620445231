@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-properties-section {
    position: relative;

    .content-pages-tabs {
        padding-left: 20px;
    }

    &-body {
        padding-bottom: 30px;
    }

    .disabled-configuration-overlay {
        position: absolute;
        width: 360px;
        z-index: 2;
        background: rgba(255, 255, 255, 0.9);
        padding: 0 50px 0 50px;
        text-align: center;
        margin-left: 5px;

        .large-text {
            font-size: 22px;
            font-weight: 700;
            padding-bottom: 20px;
            @include themify($themes) {
                color: themed(themeBaseColorDesaturatedSemiTransparent70);
            }
        }

        .small-text {
            font-size: 14px;
            font-weight: 700;
            padding-bottom: 20px;
            @include themify($themes) {
                color: themed("themeColorBaseGrey");
            }
        }

        .icon-with-label {
            align-self: center;
        }
    }
}
