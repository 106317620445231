@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.close-circle {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.icon-wrap {
        width: 10px;
        height: 10px;
    }

    &.icon-wrap.icon-wrap-clickable {
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
        color: $red !important;
    }

    &:after {
        left: -2px;
        position: relative;
        line-height: 12px;
    }
}
