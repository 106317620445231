@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.report {
    height: 100%;
    overflow: hidden;
}

.report-body {
    height: 95%;
    overflow: hidden;

    @include themify($themes) {
        border-top: 1px solid themed("themeDirtyColor");
        border-bottom: 1px solid themed("themeDirtyColor");
    }

    iframe {
        border: none;
    }
}

.report-action {
    height: 5%;
}
