@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

@mixin three-columns-contacts-layout {
    .contacts-panel-items {
        @include flex-direction(row);
        margin-top: 20px;
    }

    .contact-panel-item {
        &:not(.edit-mode) {
            @include calc(width, "(100% / 3) - 14px");
        }

        &:not(:last-child) {
            margin: 0 20px 0 0;
        }
    }
}

.panel.contacts {
    .wait-icon {
        align-self: center;
    }

    .contacts-panel-items {
        margin-top: 10px;

        .contact-panel-item:not(:last-child) {
            margin-bottom: 24px;

            @include tablet {
                margin-bottom: 10px;
            }
        }
    }

    .similar-premises {
        position: relative;
        z-index: 1;
    }

    .assigned-contacts {
        margin-bottom: 40px;

        @include tablet-only {
            @include three-columns-contacts-layout;
        }

        @include desktop {
            @include three-columns-contacts-layout;
        }

        .info-msg {
            @include tablet-and-mobile {
                margin-bottom: 10px;
            }

            @include desktop {
                margin-bottom: 10px;
            }

            + p {
                @include ie {
                    width: 100%;
                }
            }
        }
    }
}

.contractor-preview-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
.mt-4 {
    margin-top: 4px;
}
.ml-2 {
    margin-left: 2px;
}
