@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";
@import "../../../../../../assets/sass/functions";

.workflow-task-details {
    padding: 15px 20px 20px;

    > .task > .error-message.status-msg {
        margin: 5px 0 0;
    }

    .step-actions {
        margin-top: 34px;

        .btn-group.transparent {
            margin-top: 20px;
        }
    }

    .application-form {
        overflow: inherit;

        .json-form {
            .schema-container table {
                @include themify($themes) {
                    border: 1px solid themed("themeDirtyColor");
                }
                width: 100%;
                border-collapse: collapse;

                td,
                th {
                    padding: 5px 10px;
                    @include themify($themes) {
                        border: 1px solid themed("themeDirtyColor");
                    }
                    font-size: em(12);

                    .font-size-large & {
                        font-size: em(14);
                    }
                }

                td {
                    @include themify($themes) {
                        color: themed("themeColorLocked");
                    }
                }

                th {
                    background: $white-light-grey;
                    text-align: left;
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }
        }
    }

    .file-upload {
        .grid-columns-3 > fieldset > .object-properties {
            display: block;

            > .schema-container {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                @include tablet-only {
                    &:not(:nth-of-type(3n)) {
                        margin-right: 20px;
                    }
                }

                @include tablet {
                    display: inline-block;
                    vertical-align: top;
                    @include calc(width, "(100% / 2) - 10px");

                    &.fill-width-container {
                        width: 100%;
                    }
                }

                @include tablet-large {
                    @include calc(width, "(100% / 3) - 14px");

                    &:not(:nth-of-type(4n)) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
