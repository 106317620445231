@import "../../../assets/sass/_variables.scss";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

@include tablet-large {
    .window.layout-left {
        &.info-sidebar-hidden {
            .window-header .sub-info,
            .sub-header,
            .window-body-container .window-body .project-view .sub-header {
                display: none;
            }

            .window-body .dashboard {
                .header-view-wrapper {
                    margin-top: 0;
                    padding: 0 !important;
                    width: 55px;

                    .window-body-header > .main-grid-wrap {
                        padding: 0;
                    }
                }

                .header-icon-group {
                    position: initial;
                }

                .dashboard-tools {
                    @include themify($themes) {
                        background: themed("themeSemiWhiteColor");
                    }

                    .tool-list .tools {
                        padding: 0;

                        .tool {
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            height: 50px;
                            min-width: initial;

                            &:not(.active) {
                                background: transparent;
                            }

                            &:hover:not(.active) {
                                @include themify($themes) {
                                    background: themed("themeBaseColorSemiTransparent7");
                                }
                            }

                            .tool-info {
                                width: 100%;
                                height: 100%;
                                overflow: hidden;

                                .icon-wrap {
                                    @include flexbox;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    height: 100%;
                                    @include scale(1.3);
                                }
                            }

                            .tool-info-text,
                            .pinner {
                                display: none;
                            }
                        }
                    }
                }
            }

            .program-view,
            .utility-view {
                .header-wrap {
                    @include themify($themes) {
                        border-bottom: 1px solid themed("themeDirtyColor");
                    }
                }
            }

            .window-body .dashboard .header-icon-group,
            .hide-info-sidebar-wrap {
                box-shadow: none;
            }

            .window-header-content:before {
                z-index: 1;
                @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.25));
            }

            .window-header > .main-grid-wrap {
                padding-left: 84px;
            }

            &.entity-locked {
                .window-header > .main-grid-wrap {
                    padding-left: 52px;
                }

                .app-is-locked-mark {
                    position: relative;
                    margin-right: 20px;
                }
            }

            .hide-info-sidebar-wrap {
                z-index: 2;
            }
        }

        &:not(.info-sidebar-hidden) {
            .window-header {
                &.header-more-details-opened {
                    .window-header-content {
                        padding-left: 510px;

                        &:before {
                            width: 500px;
                        }
                    }

                    .project-mark {
                        width: 500px;
                    }
                }
            }

            .window-header-content {
                padding-left: 365px;

                &:before {
                    width: 355px;
                    @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.25));
                }

                .project-mark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding-right: 15px;
                    width: 355px;
                    text-align: right;
                }
            }

            &.entity-locked {
                .app-is-locked-mark {
                    left: 323px;

                    .icon-wrap {
                        padding-left: 3px;
                        width: 32px;
                        text-align: center;

                        &:after {
                            left: -11px;

                            @include themify($themes) {
                                border-left: 0;
                                border-right: 10px solid themed("themeBaseColor");
                            }
                        }
                    }
                }

                .project-mark {
                    left: -35px;
                }
            }
        }

        .window-header-content {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 55px;
                height: 100%;
                @include themify($themes) {
                    background: themed("themeLayoutLeftHeaderBackgroundColor");
                }
            }
        }

        &.entity-locked .window-header-content:before {
            @include themify($themes) {
                background: themed("themeColorLocked");
            }
        }

        .window-body-container .window-body {
            .header-view-wrapper {
                padding-left: 55px;

                .header-item {
                    margin: 5px 0;
                    @include flexbox;
                    justify-content: space-between;
                    width: 100%;

                    .header-item-title span,
                    .header-item-title .icon-with-label,
                    .header-value {
                        background: white;
                    }

                    .header-item-title {
                        position: relative;
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 11px;
                            width: 100%;
                            height: 1px;
                            @include themify($themes) {
                                border-bottom: 1px dashed themed("themeDirtyColor");
                            }
                        }

                        span {
                            position: relative;
                            margin-right: 20px;
                            padding-right: 5px;
                        }

                        &.with-link {
                            @include flexbox;
                            @include flex-direction(column);
                            align-items: flex-start;
                        }

                        .icon-with-label {
                            margin-left: 0;
                            top: -2px;
                        }
                    }

                    .header-value {
                        position: relative;
                        margin: 0;
                        padding-left: 5px;
                        text-align: right;
                    }

                    &.Application {
                        .header-item-title {
                            align-items: flex-start;
                        }
                    }
                }
            }

            .header-icon-group {
                position: fixed;
                @include flexbox;
                @include flex-direction(column);
                left: 0;
                right: inherit;
                top: 40px;
                padding: 8px;
                width: 55px;
                @include calc(height, "100% - 95px");
                @include box-shadow(1px, 0, 6px, 0 rgba(0, 0, 0, 0.15));

                > div {
                    margin: 0;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }
            }

            .project-view {
                padding-top: 0;

                @include ie {
                    margin-top: 0;
                }

                .header-view-wrapper {
                    position: relative;
                    padding-top: 133px;
                }

                .window-body-header {
                    @include themify($themes) {
                        border-bottom: 1px solid themed("themeDirtyColor");
                    }
                }
            }

            .utility-view {
                .panel.snapshot .panel-report {
                    @include calc(height, "100vh - 156px");
                }
            }

            .program-view {
                .panel.snapshot .panel-report {
                    @include calc(height, "100vh - 156px");
                }
            }

            .utility-view,
            .program-view {
                .sub-header {
                    padding: 8px 0 12px;
                }
            }
        }

        .main-grid-wrap.responsive {
            padding: 0 30px 0 20px;
            min-width: inherit;
            max-width: inherit;
            width: auto;
        }

        .window-header {
            div.project-mark {
                position: relative;
                left: 0;
            }

            .sub-info {
                z-index: 1;
                bottom: auto;
                top: 40px;
                left: 55px;
                width: 300px;
                white-space: normal;
                line-height: inherit;

                .main-grid-wrap {
                    padding: 6px 15px 12px;

                    @include themify($themes) {
                        background: themed("themeSemiWhiteColor");
                        border-bottom: 1px solid themed("themeDirtyColor");
                    }
                }

                &:after {
                    content: "";
                    z-index: -1;
                    position: absolute;
                    top: 3px;
                    right: 0;
                    width: 8px;
                    @include calc(height, "100% - 3px");
                    @include themify($themes) {
                        background: themed("themeSemiWhiteColor");
                    }
                }

                .sub-info-wrapper {
                    padding: 0;
                }

                .scroll-controls {
                    display: none;
                }
            }

            .sub-info-items {
                .sub-info-item {
                    margin: 0;
                    width: 50%;

                    &:nth-of-type(2n + 1) {
                        text-align: right;
                    }

                    &:nth-of-type(1n + 3) {
                        margin-top: 5px;
                    }

                    span {
                        display: block;
                        margin: 0;
                    }
                }
            }

            .window-header-icon-group {
                position: initial;
            }
        }

        .header-wrap {
            padding: 0;

            .header-icon-group {
                padding: 0;
                min-width: inherit;
                max-width: inherit;
                text-align: left;

                .field-group:first-child .icon-dropdown > .icon-wrap {
                    margin-left: 0;
                }

                .dashboard-tools-settings-dropdown {
                    .dropdown-list-wrap {
                        right: auto;
                        left: -5px;

                        .dashboard-tools-settings-dropdown__title {
                            padding: 0 45px;
                            text-align: center;

                            + .icon-with-label.backup-restore-empty {
                                right: 10px;
                            }
                        }
                    }
                }

                .icon-with-dropdown .dropdown-list-wrap {
                    right: auto;
                    top: -5px;
                    left: -4px;

                    .dropdown-list-title {
                        padding: 0 10px 0 56px;
                    }
                }
            }

            .sub-header .sub-header-visible-part {
                @include flex-direction(column);

                .header-items-list {
                    overflow: inherit;
                    padding: 0;
                }

                .scroll-controls {
                    display: none;
                }

                .header-items-list-wrap {
                    @include flex-wrap(wrap);
                    overflow: inherit;
                    width: 100%;
                }

                .section-title {
                    display: none;
                }

                .header-item {
                    padding: 0;
                    white-space: normal;

                    &.Portal,
                    &.Application,
                    &.Hostname,
                    &.Number {
                        .header-value {
                            word-break: break-all;
                        }
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .dashboard {
            flex-direction: row;

            .sidenav-container {
                @include ie {
                    height: auto;
                }
            }

            .header-view-wrapper {
                z-index: inherit;
                @include flexbox;
                @include flex-direction(column);
                width: 355px;
                background: white;
                @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.25));

                &.header-more-details-opened {
                    padding-right: 145px;
                    width: 500px;
                }

                .main-grid-wrap {
                    padding: 0 15px;
                }

                .expander-wrap {
                    margin: 10px 0 0;
                    padding: 10px 0;
                    width: 100%;
                    justify-content: left;
                    pointer-events: auto;
                    cursor: pointer;

                    @include themify($themes) {
                        border: none;
                        border-top: 1px solid themed("themeDirtyColor");
                    }

                    > div {
                        @include flexbox;
                        @include flex-direction(row-reverse);
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }

                    .icon-with-label:before {
                        @include rotate(-90deg);
                    }

                    span {
                        text-align: left;
                    }

                    &:hover,
                    &.expanded {
                        margin: 10px -15px 0;
                        padding: 10px 15px;
                        @include calc(width, "100% + 30px");
                        @include themify($themes) {
                            background: themed("themeTransparentWhiteColorLeft");
                        }
                    }

                    &.expanded {
                        z-index: 1;
                        position: relative;
                        @include box-shadow(inset 1px, 1px, 4px, 0 rgba(0, 0, 0, 0.15));

                        &:after {
                            content: "";
                            position: absolute;
                            top: 2px;
                            right: -6px;
                            width: 8px;
                            @include calc(height, "100% - 4px");
                            background: white;
                        }
                    }
                }

                .sub-header-hidden-part {
                    position: fixed;
                    left: 355px;
                    top: 40px;
                    @include flex-direction(column);
                    margin: 0;
                    padding: 0;
                    width: 145px;
                    @include calc(height, "100% - 95px");
                    @include box-shadow(inset 2px, 0, 4px, 0 rgba(0, 0, 0, 0.15));
                    border: none;
                    @include themify($themes) {
                        border-left: 1px solid themed("themeDirtyColor");
                    }

                    &.visible {
                        @include flexbox;
                    }

                    .section-title {
                        display: block;
                        margin: 0 10px 0 11px;
                        padding: 0 10px;
                        @include calc(width, "100% - 21px");
                        @include themify($themes) {
                            background: themed("themeLighterDirtyColor");
                        }
                        @include border-radius(4px);
                        font-size: em(13);
                        line-height: 24px;

                        .font-size-large & {
                            font-size: em(14);
                        }
                    }

                    .sub-header-flags-items,
                    .sub-header-tags-items {
                        @include flexbox;
                        @include flex-direction(column);
                        padding: 10px 0 0;
                        overflow: hidden;

                        > .btn-group {
                            padding: 2px 10px 10px 11px;
                            overflow-y: auto;

                            .add-tag-group {
                                display: none;
                            }
                        }

                        .btn-tag {
                            margin: 5px 0 0;
                            white-space: normal;
                            padding: 5px 26px 5px 10px;
                            width: 100%;
                            height: inherit;
                            text-align: left;
                            line-height: 1.1;
                            font-size: 12px;

                            .font-size-large & {
                                font-size: 14px;
                            }

                            .icon-wrap {
                                width: initial;
                            }
                        }
                    }

                    .sub-header-flags-items {
                        margin-bottom: 0;
                        max-height: 50%;
                        @include themify($themes) {
                            border-bottom: 1px solid themed("themeDirtyColor");
                        }

                        .btn-tag {
                            padding-left: 26px;
                            padding-right: 10px;

                            &:before {
                                position: absolute;
                                left: 9px;
                                top: 5px;
                            }
                        }

                        @include ie {
                            .btn-group {
                                @include calc(max-height, "100% - 24px");
                            }
                        }
                    }

                    .sub-header-tags-items,
                    .btn-group {
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }
                    }

                    .sub-header-tags-items {
                        position: relative;

                        @include ie {
                            height: 0;

                            .btn-group {
                                @include calc(max-height, "100% - 24px");
                            }
                        }

                        > .add-tag-group {
                            display: block;

                            @include ie {
                                &.opened + .btn-group {
                                    @include calc(max-height, "100% - 114px");
                                }
                            }
                        }
                    }

                    .add-tag-group {
                        position: initial;
                        padding: 0 10px 0 11px;

                        .btn-tag-add {
                            position: absolute;
                            top: 12px;
                            right: 10px;
                            width: auto;
                        }

                        .btn-tag {
                            margin: 0;
                        }
                    }

                    .content-wrap-popup {
                        position: initial;
                        padding: 10px 0;
                        width: 100%;
                        border: none;
                        @include themify($themes) {
                            border-bottom: 1px solid themed("themeDirtyColor");
                        }
                        box-shadow: none;

                        &--content-wrap {
                            padding: 0;
                            border: none;
                            box-shadow: none;
                            @include border-radius(0);
                        }

                        &--footer {
                            padding: 10px 0 0;
                        }

                        .hint {
                            font-size: em(12);
                        }

                        .btn {
                            flex: 1;
                            min-width: initial;
                            height: 24px;
                            font-size: 12px;

                            button,
                            a {
                                padding: 0;
                                line-height: 24px;
                            }

                            &:before {
                                display: none;
                            }

                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .dashboard-tools {
                    flex: 1;
                    overflow-y: auto;
                    overflow-y: overlay;
                    direction: rtl;
                    background: white;
                    box-shadow: none;

                    .main-grid-wrap {
                        padding: 0;
                        direction: ltr;
                    }

                    .tool-list .tools {
                        white-space: normal;
                        padding: 15px 0 15px 15px;

                        .tool {
                            margin: 0 0 7px;
                            padding: 0 30px 0 5px;
                            @include calc(width, "100% - 15px");
                            line-height: 32px;
                            text-align: left;

                            .tool-info {
                                @include flexbox;
                                @include flex-direction(row);
                                align-items: center;

                                .icon-wrap {
                                    display: block;
                                }
                            }

                            &.active {
                                width: 100%;

                                &:after {
                                    right: 0;
                                    left: inherit;
                                    width: 3px;
                                    height: 100%;
                                }

                                .pinner {
                                    right: 15px;
                                }
                            }

                            .pinner {
                                line-height: 31px;
                            }
                        }
                    }

                    .scroll-controls {
                        display: none;
                    }
                }
            }

            .dashboard-panels-container {
                flex: 1;

                > .main-grid-wrap {
                    padding: 0 30px;
                }
            }
        }
    }

    .hide-info-sidebar-wrap {
        position: fixed;
        top: 1px;
        left: 0;
        width: 55px;
        height: 38px;
        @include box-shadow(1px, 0, 6px, 0 rgba(0, 0, 0, 0.15));
        cursor: pointer;
    }
}
