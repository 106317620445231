@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";
@import "../../../../../../assets/sass/functions";

.wf {
    @include firefox {
        &.flex-one {
            flex: 1 1;
        }
        .wf__status-lead-text,
        .wf__status-title {
            &.flex-one {
                flex: 1 1;
            }
        }
    }
    &__step {
        position: relative;
        margin-top: 5px;
        min-height: 40px;
        background: white;
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
        @include border-radius(4px);

        .drag-handle-icon {
            @include calc(top, "50% - 14px");
            right: 8px;
        }

        > .icon-wrap {
            z-index: 1;
            position: absolute;
            left: 61px;
            @include calc(top, "50% - 13px");
            background: white;
            @include scale(0.7);

            @include ie {
                left: 44px;
                @include calc(top, "50% - 8px");
            }
        }

        &--disabled {
            background: $white-grey-medium;

            > .icon-wrap {
                background: $white-grey-medium;
            }
        }

        &--error {
            border-color: $red !important;
        }

        &--selected {
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
                border: 1px solid themed("themeBoxShadowBorderColor");
            }

            > .icon-wrap {
                @include themify($themes) {
                    background: themed("themeSemiWhiteColor");
                }
            }
        }

        &--has-rules {
            > .icon-wrap {
                top: 10px;
            }

            .wf__step-main-cell:first-child,
            .wf__step-head-cell:first-child {
                top: 25px;
            }
        }

        &-head {
            position: absolute;
            top: 7px;
            left: 0;
            width: 100%;
            font-size: em(10);
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }

            .font-size-large & {
                font-size: em(12);
            }
        }

        &-main,
        &-head {
            padding: 0 100px 0 140px;
            cursor: pointer;

            &-cell {
                &:not(:first-child) {
                    flex: 1;
                    padding-right: 20px;
                    word-break: break-word;

                    @include ie {
                        word-wrap: break-word;
                    }
                }

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    width: 70px;
                    flex: initial;
                }

                &:first-child {
                    position: absolute;
                    left: 88px;
                    @include calc(top, "50% - 2px");
                    font-size: 11px;

                    .font-size-large & {
                        font-size: 13px;
                    }
                }
            }
        }

        &-main {
            position: relative;
            margin-top: -8px;
            padding-top: 30px;
            padding-bottom: 8px;
            font-size: em(11);

            @include firefox {
                .flex-one {
                    width: 0;
                    flex: 1 1;
                }
            }

            .font-size-large & {
                font-size: em(13);
            }

            &-cell .general-procedure .status-mark {
                margin-top: 3px;
                align-self: flex-start;
            }

            &--not-confirmed {
                margin-top: 0;
                padding: 0 0 0 65px;
                height: 100%;
                font-size: 15px;

                @include ie {
                    height: 38px;
                }
            }

            &--step-placeholder {
                font-weight: 700;
                font-size: 17px;
                @include themify($themes) {
                    color: themed("themeBaseColorSemiTransparent30");
                }
            }

            .wf__step-number {
                position: absolute;
                left: 0;
                top: 8px;
                padding: 0 10px;
                width: 53px;
                @include calc(height, "100% - 8px");
                @include border-radius(4px 0 0 4px);
                font-size: 11px;
                font-weight: 500;

                .font-size-large & {
                    font-size: 13px;
                }

                @include themify($themes) {
                    background: themed("themeWhiteColor");
                    color: themed("themeBaseColorDesaturated");
                }

                &.not-confirmed {
                    top: 0;
                    height: 100%;
                }

                &.error {
                    background: $red-light;
                    color: $red;
                }

                &.selected {
                    @include themify($themes) {
                        background: themed("themeBaseColorSemiTransparent7");
                    }
                }

                &.disabled {
                    background: #ecececec;
                    @include themify($themes) {
                        color: themed("themeColorBaseGrey");
                    }
                }

                span {
                    font-size: 19px;
                    line-height: 19px;
                }
            }
        }

        &-actions {
            position: absolute;
            right: 5px;
            @include calc(top, "50% - 9px");

            &.not-confirmed {
                top: initial;

                @include ie {
                    top: 6px;
                }
            }

            .icon-wrap.delete-trash-empty {
                margin-left: 5px;
            }
        }

        &-rules {
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }

            &.two-col {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 10px;
                    width: 1px;
                    @include calc(height, "100% - 20px");
                    @include themify($themes) {
                        background: themed("themeDirtyColor");
                    }
                }
            }

            > .flex-column {
                padding: 10px;
                background: rgba(255, 255, 255, 0.6);
                font-size: 13px;
                word-break: break-word;

                @include ie {
                    word-wrap: break-word;
                }

                .font-size-large & {
                    font-size: 15px;
                }
            }

            span:first-child {
                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent70");
                }
            }
        }
    }
}
