@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.utility-portal-settings {
    .property-list-item--switch-on {
        &.show-cookies-panel {
            border-bottom: none;
        }
    }

    .property-list-item--text {
        .text-label {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .property-list-item--site-title {
        .portal-builder-properties-section-info {
            padding: 15px 0;
        }

        .preview-area-site-title {
            padding: 15px;
            margin-top: 18px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1rem inset;
            height: 100px;

            @include themify($themes) {
                border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent5");
            }

            .preview-area-text-label {
                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent70");
                }
                position: relative;
                bottom: 47%;
                background-color: white;
                font-weight: 700;
                font-size: 12px;
                text-transform: uppercase;
                padding: 0 10px;
            }

            .preview-text {
                font-size: 11px;
                color: black;
                height: 50%;
            }
        }
    }

    .property-list-item--config-panel .property-list-item-inner {
        margin: 0 20px;

        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }

    .property-list-item--layout-selection {
        .property-list-item-description {
            margin-bottom: 0px;
        }

        .info-text {
            padding-bottom: 6px;
        }

        .portal-builder-properties-section-info {
            padding: 15px 0 0;
        }

        .property-list-item-inner {
            padding-bottom: 0px;
        }
    }
    .property-list-item--prop-group {
        .property-list-item-description {
            .portal-builder-properties-section-info {
                padding: 15px 0px 0px;
            }
        }
    }
}
