@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_variables";

.eligibility-verification-panel {
    .margin-auto {
        margin: auto;
    }

    .json-form .rjsf .action-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;
        .btn:nth-child(2) {
            margin-right: 0;
        }
    }

    .error-hint {
        color: $medium-grey;
        margin-top: 15px;
        font-size: 13px;
        text-align: center;
    }

    .back-button-container {
        padding: 0 25px;
        text-align: center;
        .back-button {
            margin-bottom: 0;
        }
    }

    .form-property-result-container {
        margin-top: 0;
        margin-bottom: 20px;

        .form-property-label {
            color: #85929d;
            font-size: 12px;
            margin-bottom: 2px;
        }
        .form-property-result {
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
            font-size: 13px;
            font-weight: 500;
        }
        .address-number-container {
            margin-top: 10px;
        }
        .utility-container {
            margin-top: 10px;
        }
    }

    .result-text {
        font-weight: 600;
        text-transform: uppercase;
        &.not-active {
            color: $red;
        }
        &.active {
            color: $green;
        }
    }

    .json-form {
        height: 350px;
        max-width: 450px;
        padding: 20px 60px;
        border-radius: 16px;
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
        }
        display: flex;
        align-items: center;
        @include mobile-only {
            padding: 30px;
            height: auto;
        }

        .object-properties {
            .schema-container:first-child {
                margin-bottom: 20px;
            }
        }
    }

    .info-text-block {
        margin-left: 16px;
        .please-wait {
            font-weight: 700;
            margin-bottom: 5px;
            font-size: 17px;
        }
        .info-description {
            font-size: 14px;
        }
    }

    .info-block {
        border-radius: 16px;
        height: 350px;
        width: 100%;
        max-width: 450px;
        padding: 30px 0;

        &.error {
            background-color: #fcf2f2;
        }

        &.success {
            background-color: $green-pale;
        }

        &.loading {
            padding: 40px;
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }
        }
    }

    .form-info-message {
        margin-bottom: 20px;
    }
}
