@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.grid-details-panel {
    background-color: white;

    .json-form {
        padding: 20px;
    }

    .grid-details-panel-footer {
        padding: 0 20px 20px;
    }
}
