@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.grid-settings-dropdown {
    .icon-wrap-dropdown.icon-wrap-active {
        z-index: initial;
    }

    .dropdown-list-wrap {
        .dropdown-list-item {
            display: flex;
            padding: 0 7px 0 5px;
        }

        @include mobile-only {
            z-index: 101;
        }
    }

    .dropdown-list__items {
        overflow-y: auto;
        max-height: 100%;

        @include tablet {
            max-height: 280px;
        }
    }

    .dropdown-list-title.dropdown-list-title {
        padding: 0 10px;
        width: 100%;
        background: white;
    }

    .grid-settings-actions {
        line-height: initial;
        margin-bottom: 10px;
    }

    .dropdown-list-item {
        position: relative;
        padding-right: 40px !important;

        @include mobile-only {
            padding: 0 44px 0 6px;
        }

        span {
            flex: 1;
            white-space: normal;
            line-height: 1.1;
            word-break: break-word;
            font-size: em(13);
            text-transform: uppercase;

            .font-size-large & {
                font-size: em(14);
            }

            @include ie {
                word-wrap: break-word;
            }
        }

        .icon-wrap {
            flex-shrink: 0;
        }
    }

    .drag-handle-icon {
        position: absolute;
        right: 4px;
        top: 0;
    }

    &.dropdown-list-up {
        .dropdown-list__header {
            position: relative;
            order: 1;

            .dropdown-list-title {
                @include tablet {
                    @include themify($themes) {
                        border-bottom: none;
                        border-top: 1px solid themed("themeDirtyColor");
                    }
                }
            }
        }
    }
}
