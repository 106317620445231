@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_functions";
@import "../../../assets/sass/_mixins";

label {
    .field-group > & {
        display: inline-block;
        margin-bottom: 8px;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }

        &.label-full-width {
            display: block;
        }
    }
}

label.label-for-field {
    font-size: em(12);

    .font-size-large & {
        font-size: em(14);
    }
}

.action-label {
    font-size: 12px;
    @include themify($themes) {
        color: themed("themeBaseColor");
    }
    cursor: pointer;

    .font-size-large & {
        font-size: 14px;
    }

    .grid-list-column & {
        font-size: 13px;

        .font-size-large & {
            font-size: 15px;
        }
    }
}

.content-label {
    @include themify($themes) {
        color: themed("themeColorBaseGrey");
    }
    font-weight: 700;
    font-size: 12px;

    .font-size-large & {
        font-size: 14px;
    }
}
