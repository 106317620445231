@import "../../../../../../assets/sass/_mixins";

.audit-main-panel-grid {
    .row-icon-value {
        @include text-overflow();

        .icon-wrap {
            position: relative;
            top: -1px;
            margin-right: -5px;
            vertical-align: middle;
            text-align: left;
        }
    }

    .grid-list-row {
        height: auto;
    }
}
