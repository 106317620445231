@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.users-grid {
    @include data-grid-sticky-header;

    @include ie {
        height: 100%;
    }
}
