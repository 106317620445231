@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-file-panel {
    height: 100%;
    padding-bottom: 20px;

    .rjsf {
        .action-buttons {
            margin-top: 0;

            .btn {
                margin-top: 20px;
            }
        }
    }

    .file-tiles-container {
        padding: 0 15px 15px 0;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
        min-height: 0;
        overflow: auto;

        &.dark-background {
            .file-tile {
                @include themify($themes) {
                    background-color: themed("themeColorDarkGrey");
                }
            }
        }

        .file-tile {
            width: calc(50% - 20px);
            height: 150px;
            margin-left: 20px;
            margin-top: 15px;
            position: relative;
            border-radius: 5px;
            border: 1px solid $white-grey-dark;
            background-color: $white-light-grey;
            padding-bottom: 20px;

            .file-name {
                position: absolute;
                bottom: 5px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                font-weight: 600;
                color: $light-grey;
                font-size: 12px;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                padding: 0 15px;
            }

            .file-preview-container {
                height: 100px;
                margin: 10px 0;
            }

            &:hover {
                .view-button,
                .copy-button {
                    display: block;
                }
            }

            .view-button,
            .copy-button {
                position: absolute;
                bottom: 10px;
                right: 10px;
                z-index: 1;
                cursor: pointer;
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
                display: none;
            }

            .copy-button {
                right: 45px;
            }
        }
    }

    .file-upload-form {
        margin: 0 20px;

        .json-form {
            height: auto;
        }

        .form-group.field-array {
            margin-bottom: 0;
        }
    }

    .background-switcher {
        padding: 5px 0 5px 0;
        margin: 0 20px;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;

        .content-label {
            font-size: 12px;
            width: 150px;
            text-transform: uppercase;
        }
    }

    .margin-auto {
        margin: auto;
    }

    .with-hand-made-icon.document-icon {
        position: relative;
        border: 6px solid $light-grey;

        span {
            background: $light-grey !important;
        }
    }

    .close-circle {
        position: absolute;
        top: -5px;
        right: -5px;
    }

    .files-grid-file-preview-sidenav-panel {
        .sidenav-header__title {
            word-break: break-all;
        }
    }
}
