@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.panel.panel-attributes {
    .grid-list-row.details {
        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }

        .attributes-form {
            @include mobile-only {
                @include expanded-panel-mobile;
            }
        }
    }

    .add-attribute-form {
        position: relative;
        @include expanded-panel;
        margin-top: -5px;

        @include mobile-only {
            @include expanded-panel-mobile;
        }
    }

    .attributes-form {
        &:not(.add-attribute-form) {
            width: 100%;
        }

        .json-form {
            @include expanded-panel-json-form;

            @include mobile-only {
                overflow-y: auto;
                @include calc(height, "100vh - 52px");
            }

            .object-properties {
                > .schema-container {
                    display: inline-block;
                    vertical-align: top;

                    &:not(.fill-width) {
                        width: 100%;

                        @include mobile-only {
                            margin-bottom: 10px;
                        }

                        @include tablet {
                            margin-right: 20px;
                            width: 25%;
                        }
                    }

                    &.fill-width {
                        @include tablet {
                            @include calc(width, "(100% / 4) * 3 - 20px");
                        }
                    }
                }
            }
        }
    }
}
