@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/mixins";

@mixin workflow-task-tablet-view {
    &.flex-row {
        @include flex-direction(column);
    }

    > .status-mark {
        top: 36px;
    }

    .step-title {
        padding: 14px 185px 14px 37px;

        @include mobile-only {
            padding-right: 35px;
        }
    }

    .col-content-new-line {
        display: inline-block;
        margin-left: 2px;
    }

    .step-assignments {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        justify-content: flex-end;

        .step-assignment,
        .step-date-completed {
            text-align: right;
        }
    }

    .step-assignment {
        &.step-automated {
            margin: -4px -4px 0 0;
            padding: 0;

            &.step-completed {
                margin-right: -10px;
                padding: 0 15px;
                justify-content: flex-end;
            }

            + .step-date-completed {
                margin-top: 0;
            }
        }
    }

    .task-dates {
        margin: 0 10px;
        padding: 0;
        @include calc(width, "100% - 20px");
        border-width: 1px 0 0 0 !important;

        &-top-block {
            order: 2;
        }

        .step-assignment {
            order: 1;
            border-width: 0 0 1px;
        }
    }

    .step-created {
        @include calc(width, "100% - 120px");
    }

    .step-term {
        right: -45px;
        width: 120px;
        @include flex-direction(row);

        .col-label {
            margin: 0 5px 0 0;
        }
    }
}

@mixin workflow-task-horizontal-view {
    &.flex-row {
        @include flex-direction(row);
    }

    > .status-mark {
        @include calc(top, "50% - 7px");
    }

    .step-title {
        padding: 14px 15px 14px 37px;
        @include flexbox;
        @include flex-direction(column);
        justify-content: center;
    }

    .col-content-new-line {
        display: block;
        margin-left: 0;
    }

    .step-assignments {
        position: initial;
        padding-right: 10px;
        justify-content: space-between;

        .step-assignment,
        .step-date-completed {
            text-align: initial;
        }
    }

    .step-assignment {
        &.step-automated {
            margin-right: 0;
            padding: 0 15px;

            &.step-completed {
                margin-right: 0;
                padding: 0 15px;
                justify-content: initial;
            }

            + .step-date-completed {
                margin-top: 0;
            }
        }
    }

    .task-dates {
        margin: 0;
        padding: 0 15px;
        width: 285px;
        border-width: 0 1px !important;

        &-top-block {
            padding: 5px 0;
        }

        .step-assignment {
            padding: 5px 0 3px;

            @include themify($themes) {
                border: 1px solid themed("themeBaseColorSemiTransparent20");
                border-width: 1px 0 0;
            }

            .workflow-task-completed & {
                border-color: $green-medium;
            }

            .workflow-task-skipped & {
                @include themify($themes) {
                    border-color: themed("themeLighterDirtyColor");
                }
            }

            .workflow-task-dependent & {
                border-color: #dedede;
            }

            .col-label {
                margin-right: 5px;
                white-space: nowrap;
            }
        }
    }

    .step-created {
        @include calc(width, "100% - 60px");
    }

    .step-term {
        @include flex-direction(column);
        right: initial;
        width: 60px;

        .col-label {
            margin: 0 0 3px;
        }
    }
}

.workflow-task {
    position: relative;

    @include tablet-and-mobile {
        @include workflow-task-tablet-view;
    }

    @include tablet-large {
        @include workflow-task-horizontal-view;
    }

    .split-view & {
        @include workflow-task-tablet-view;
    }

    .layout-left .dashboard-panels.sidebar-expanded & {
        @include workflow-task-tablet-view;
    }

    .layout-left.info-sidebar-hidden .dashboard-panels.sidebar-expanded & {
        @include workflow-task-horizontal-view;
    }

    > .status-mark {
        position: absolute;
        left: -18px;
        width: 70px;
        @include rotate(-90deg);
        text-align: center;
        line-height: 17px !important;

        + .status-mark {
            left: -36px;
        }
    }

    .task-indicators-wrap {
        &.top-right-corner {
            position: absolute;
            top: 4px;
            right: 4px;
        }

        .workflow-tasks-indicator {
            position: relative;
            margin-left: 4px;

            &:before {
                content: "";
                position: absolute;
                top: -4px;
                left: -4px;
                width: 16px;
                height: 16px;
            }
        }
    }

    .step-title {
        .task-indicators-wrap {
            margin: 9px 0 9px -4px;
        }
    }

    .step-type {
        font-size: em(13);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        .font-size-large & {
            font-size: em(14);
        }
    }

    .step-name {
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }
    }

    .step-assignments {
        width: 185px;

        .font-size-large & {
            width: 210px;
        }

        .icon-wrap.check-circle-filled {
            color: $green;
        }

        .col-content,
        .col-label {
            font-size: em(11);

            .font-size-large & {
                font-size: em(13);
            }
        }

        .col-label {
            opacity: 0.5;
            margin-bottom: 3px;
        }

        .step-assignment,
        .step-date-completed {
            padding: 0 15px;
        }

        .step-date-completed {
            white-space: nowrap;

            .col-content-new-line {
                display: inline-block;
                margin-left: 2px;
            }
        }

        .step-date-completed {
            margin-top: 8px;
        }
    }

    .step-assignment {
        &.step-automated {
            div:not(.icon-wrap) {
                font-size: em(10);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(12);
                }
            }
        }
    }

    .task-dates {
        @include themify($themes) {
            border: 1px solid themed("themeBaseColorSemiTransparent20");
            border-width: 0 1px;
        }

        .workflow-task-completed & {
            border-color: $green-medium;
        }

        .workflow-task-skipped & {
            @include themify($themes) {
                border-color: themed("themeLighterDirtyColor");
            }
        }

        .workflow-task-dependent & {
            border-color: #dedede;
        }

        .col-label,
        .col-content {
            font-size: em(11);
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            .font-size-large & {
                font-size: em(13);
            }
        }

        .col-label {
            opacity: 0.6;
            margin-bottom: 3px;
        }
    }

    .step-date {
        text-align: right;
        @include calc(width, "(100% - 60px) / 2");

        &.due-date-soon {
            .col-content {
                color: $warning;
            }

            &:before {
                background: $warning;
            }
        }

        &.due-date-overdue {
            .col-content {
                color: $red;
            }

            &:before {
                background: $red;
            }
        }

        &.completed:before {
            background: $green;
        }

        &__value {
            white-space: nowrap;
        }

        .status-mark {
            position: relative;
            top: 1px;
            margin-left: 5px;
        }

        .status-msg {
            font-size: 12px;
            line-height: initial;

            .font-size-large & {
                font-size: 14px;
            }

            &:after {
                top: -6px;
            }
        }
    }

    .step-term {
        position: relative;
        padding: 5px;
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent5");
        }
        @include border-radius(10px);

        .workflow-task-completed & {
            background: $green-light;
        }

        .workflow-task-skipped & {
            background: $white-light-grey;

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: $sightline-white-light-grey;
            }
        }

        .workflow-task-dependent & {
            background: rgba(0, 0, 0, 0.04);
        }
    }

    .task-dates {
        &--mobile {
            padding-top: 3px;

            .col-label {
                margin-right: 5px;
            }

            .col-content {
                margin-bottom: 3px;
            }

            .status-mark {
                margin-left: 5px;
            }
        }
    }
}
