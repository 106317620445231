@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.user-edit-form {
    .user-custom-actions {
        position: relative;
        top: -5px;
        margin-bottom: 10px;
        padding-bottom: 15px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }

        @include tablet {
            .dropdown-list-wrap {
                top: 30px;
            }
        }

        &.three-action-items {
            .icon-with-label {
                line-height: 14px;

                &:before {
                    @include calc(top, "50% - 14px");
                }

                &:nth-of-type(2) {
                    margin: 0 10px;
                }
            }
        }
    }

    .json-form .rjsf {
        padding-bottom: 10px;

        .form-group:not(.field-object) {
            margin-bottom: 20px;
        }

        .action-buttons {
            display: none;
        }
    }

    .sidenav-body {
        @include ie {
            height: 0;
        }

        @include firefox {
            height: 0;
        }
    }

    .sidenav-footer {
        align-items: flex-end;

        .flex-one-in-row .btn:last-child:not(:first-child) {
            margin: 10px 0 0;
        }
    }
}

.field-group-dropdown-icon-popup__container.user-custom-actions__item,
.field-group-dropdown-icon-popup__container.user-custom-actions__item .dropdown-list-wrap {
    z-index: 82 !important;

    @include tablet {
        top: 36px;
    }
}
