@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/mixins";

.application-document-queue {
    &__grid-entry-row {
        cursor: pointer;

        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
            border-top: none;
        }

        &-container {
            padding: 10px;
            height: 40px;
            background: white;

            &:hover:not(.expanded) {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
            }

            &.expanded {
                font-weight: 500;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }

            > .checkbox-wrap {
                margin-right: 10px;
            }

            > .icon-wrap {
                margin: 0 -8px 0 0;

                @include tablet {
                    margin-left: 10px;
                }

                &.shevron-small-up-expand-less {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }
        }

        &-name {
            font-size: 13px;
            text-align: left;
            @include text-overflow;

            .font-size-large & {
                font-size: 15px;
            }
        }

        &-loader {
            padding-bottom: 20px;
        }
    }
}
