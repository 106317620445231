@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.app-form-pages {
    @include themify($themes) {
        background: themed("themeWhiteColor");
    }

    .app-form-pages-header {
        background-color: white;

        @include tablet {
            z-index: 1;
        }
    }

    .form-page-editor {
        &.form-error {
            padding: 20px 0;
        }

        &:not(.form-error) {
            padding: 10px 0;

            > .main-grid-wrap.responsive {
                padding-left: 5px;
                padding-right: 5px;

                @include tablet-only {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @include tablet-large {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                @include desktop {
                    padding-left: 40px;
                    padding-right: 40px;
                }

                @include desktop-large {
                    .layout-full-screen & {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }

                .split-view & {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        .nothing-found-block {
            height: 100%;

            > div {
                @include tablet {
                    max-width: 420px;
                }
            }
        }
    }
}
