@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.components {
    margin: 10px;

    .components-list {
        max-width: 600px;
        margin: 10px;
        @include box-shadow-common($shadow-outer-hover);

        .user-rights-section {
            min-width: 500px;

            .btn-group,
            .override-rights-controls {
                margin-top: 10px;
            }
        }

        .tile {
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }

            &:hover {
                @include themify($themes) {
                    background: themed("themeLighterDirtyColor");
                }
            }

            &:not(:first-child) {
                border-top: none;
                cursor: pointer;
            }

            .btn:not(:last-child) {
                margin-right: 10px;
            }

            small {
                color: black;
            }
        }
    }
}
