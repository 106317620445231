@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/mixins";

.application-document-queue {
    &__batch-grid-controls {
        justify-content: space-between;
        padding: 15px 10px;
        background: white;

        @include tablet-and-mobile {
            z-index: 2;
            position: fixed;
            left: 0;
            bottom: 43px;
            width: 100%;
            @include box-shadow-common($shadow-outer-center);

            &.hidden-on-mobile {
                display: none;
            }
        }

        .split-view & {
            z-index: 2;
            position: fixed;
            left: 1px;
            bottom: 55px;
            @include calc(width, "50% - 2px");
            @include box-shadow-common($shadow-outer-center);

            &.hidden-on-mobile {
                display: none;
            }
        }

        .split-view .window-view.right & {
            left: initial;
            right: 1px;
        }

        @include tablet-large {
            padding: 10px;
            background: $white-light-grey;
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: $sightline-white-light-grey;
            }
        }

        .split-view & {
            padding: 15px 10px;
            background: white;
            border: none;
        }

        &-selected-count {
            padding: 0 10px;
            font-size: 13px;
            text-align: center;

            .font-size-large & {
                font-size: 15px;
            }
        }
    }
}
