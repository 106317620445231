@import "../../../../../../../assets/sass/variables";
@import "../../../../../../../assets/sass/mixins";

.fast-tag-form-sidenav {
    .json-form {
        .rjsf,
        .rjsf > .schema-container,
        .rjsf > .schema-container > .form-group,
        .rjsf > .schema-container .form-group > fieldset,
        .rjsf > .schema-container .form-group > fieldset > .object-properties {
            height: 100%;
        }

        .object-properties {
            @include flexbox;
            @include flex-direction(column);

            .schema-container {
                margin-bottom: 20px;

                .form-group {
                    @include flexbox;
                    @include flex-direction(column);
                    height: 100%;
                }

                .form-group textarea,
                &:last-child {
                    flex: 1;

                    @include ie {
                        flex: 1 0 auto;
                    }
                }
            }
        }
    }
}
