@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_functions";
@import "../../../../../../assets/sass/_mixins";

.sidebar-contacts-list-container {
    padding: 13px;
    background: white;

    &:not(:last-child) {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    .contact > .flex-column {
        &.left-block {
            width: 0;
        }

        &.right-block {
            margin-left: 10px;
            padding-left: 10px;
            width: 90px;
            flex-shrink: 0;
            @include themify($themes) {
                border-left: 1px solid themed("themeLighterDirtyColor");
            }

            .contact-column-item {
                text-align: right;

                @include ie {
                    width: 100%;
                }

                &:not(:first-child) {
                    margin-top: 7px;
                }

                &.account,
                &.premise,
                &.workflowgroup {
                    .value {
                        word-break: break-word;

                        @include ie {
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }
    }

    .contact-column-item {
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }

        &.type-and-name {
            position: relative;
            margin-bottom: 10px;
            padding-left: 25px;

            > .flex-column:last-child {
                margin-left: 10px;
            }

            .type {
                text-transform: uppercase;
                font-weight: 700;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }

            .contact-name {
                font-size: 15px;
            }

            .contact-name-icon {
                position: absolute;
                left: -4px;
                top: 0;
                width: 24px;
            }

            .contact-action {
                align-self: flex-end;

                &:hover {
                    background: transparent !important;
                }
            }
        }

        .label,
        .identifier .label {
            display: block;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent");
            }
            font-size: 11px;

            .font-size-large & {
                font-size: 13px;
            }
        }

        &.company,
        &.email,
        &.phone,
        &.address {
            margin-top: 5px;

            .icon-wrap {
                margin-right: 11px;
                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent");
                }
            }

            a {
                @include text-overflow;
            }
        }
    }

    &:not(:hover) {
        .contact-action {
            @include tablet-large {
                opacity: 0;

                &:focus {
                    opacity: 1;
                }
            }
        }
    }
}
