@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.panel.files-panel {
    .grid-list-row.details {
        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }

        .full-width {
            width: 100%;

            @include mobile-only {
                @include expanded-panel-mobile;
            }
        }
    }

    .file-upload-form {
        position: relative;
        @include expanded-panel;
        margin-top: -5px;

        @include mobile-only {
            @include expanded-panel-mobile;
        }

        .json-form .object-properties .schema-container {
            width: 100%;

            @include tablet {
                @include calc(width, "(100% / 3) - 20px");
            }
        }

        .json-form .object-properties .schema-container.field-container {
            @include tablet {
                flex-grow: 1;
                border-right: 1px solid #dae2e4;
                .field {
                    width: 97%;
                }
            }
            .upload-file-area {
                height: 7rem;
                align-items: center;
                display: flex;
                justify-content: center;
            }
            .dropzone-file-list {
                margin-top: 0;
                padding: 10px;
            }
        }

        .json-form .object-properties .file-column-container {
            @include tablet {
                width: 90%;
            }
        }
    }

    .info-msg {
        margin-bottom: 10px;
    }

    .file-form {
        @include mobile-only {
            overflow-y: auto;
            @include calc(height, "100vh - 52px");
        }

        .json-form {
            @include expanded-panel-json-form;

            @include mobile-only {
                height: initial;

                .form-group {
                    margin-bottom: 0;
                }
            }
        }
    }
    .info-text {
        font-style: italic;
    }
    .status-msg {
        .info-text {
            color: $base-grey;
            b {
                color: $black;
                font-style: normal;
            }
        }
    }
    .status-message {
        margin-left: 0;
    }
}
