.portal-builder-properties-section-info {
    padding: 15px 20px;

    .program-portal-settings &,
    .portal-builder-properties-colors &,
    .content-page-list & {
        padding-bottom: 0;
    }

    .status-msg + .status-msg {
        margin-top: 10px;
    }
}
