@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";

.bulk-approve-rebate-total {
    .bulk-approve-total-container {
        padding-left: 50px;
        padding-right: 25px;
        padding-bottom: 20px;

        @include firefox {
            padding-right: 17px;
        }

        .layout-left & {
            padding-left: 55px;
        }
    }

    &__measures {
        width: 38%;

        > .flex-column {
            padding-right: 10px;
        }
    }

    &__money {
        width: 18.7%;

        .layout-left & {
            width: 18.5%;
        }
    }

    &__measures-label {
        font-size: 11px;
        line-height: 1;
        text-align: right;
        @include themify($themes) {
            color: themed("themeBaseColorDesaturatedSemiTransparent70");
        }

        .font-size-large & {
            font-size: 13px;
        }
    }

    &__measures-value {
        font-size: 15px;
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }
        text-align: right;
    }

    &__label {
        margin-right: 5px;
        @include themify($themes) {
            color: themed("themeBaseColorDesaturatedSemiTransparent70");
        }
        font-size: em(12);
        font-weight: 500;

        .font-size-large & {
            font-size: em(14);
        }
    }

    &__value {
        font-size: 23px;
        font-weight: 700;
        text-align: right;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        .currency {
            @include currency-sign-styles;
            font-size: 15px;
        }
    }
}
