@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.sidebar-content.sidebar-events {
    background: transparent;
}

.events-view-sidebar.no-info {
    padding: 0 13px 15px;
}

.events-list {
    &:not(.no-data) {
        background: white;
    }

    .wait-icon {
        align-self: center;
    }

    .list-item-row {
        padding: 0 5px;
        height: auto;
        min-height: 44px;
        cursor: default;

        .item-value {
            padding: 5px 8px;
            white-space: normal;
            font-weight: 400;
        }
    }

    .list-body {
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }
    }

    .footer {
        justify-content: space-between;
        height: 48px;
        padding-right: 10px;
        background-color: $white-light-grey;
        @include border-radius(0 0 6px 6px);

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        .counter {
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    &:not(.event-details) {
        .list-item-row {
            padding: 0 13px;

            .item-value {
                padding: 5px 16px 5px 0;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    &.events-details {
        .list-header-row .header,
        .list-item-row .item-value {
            width: initial;
            max-width: 50%;

            &:last-child {
                text-align: right;
            }
        }
    }
}

.sidebar-events-wrap {
    .breadcrumb {
        padding: 0 15px 7px 10px;
        background-color: $white-light-grey;
        @include flex-wrap(wrap);

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }
    }
}
