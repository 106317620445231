@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";

.sidenav.view-log-system-error-sidenav-panel {
    &.state-details-opened .sidenav-body {
        width: 340px;

        .json-state-errors {
            position: fixed;
            right: 0;
            top: 49px;
            padding: 20px;
            width: 340px;
            @include calc(height, "100% - 49px - 62px");
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
            @include box-shadow(inset 3px, 0, 3px, 0 rgba(0, 0, 0, 0.15));
            overflow: auto;
        }

        .disclosure {
            margin-right: 3px;
        }
    }

    .info-list-row {
        margin: 0 0 10px;
        justify-content: space-between;
        background: transparent;
        @include themify($themes) {
            border-top: 1px dashed themed("themeDirtyColor");
        }

        &:first-child {
            margin-top: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .info-list-field-label,
    .info-list-field-value {
        position: relative;
        top: -13px;
        padding: 0;
        background: white;
    }

    .info-list-field-label {
        margin-right: 20px;
        padding-right: 5px;
        padding-right: 5px;
        min-width: auto !important;
        white-space: nowrap;
        @include themify($themes) {
            color: themed("themeBaseColorDesaturatedSemiTransparent");
        }

        .theme-sightlineHighContrast & {
            color: black;
        }
    }

    .info-list-field-value {
        padding-left: 5px;
        text-align: right;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
        word-break: break-word;

        @include ie {
            word-wrap: break-word;
        }
    }

    .json-string {
        .info-list-field-value {
            white-space: pre-wrap;
            text-align: left;
            word-break: initial;
        }
    }
}
