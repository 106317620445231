@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.sidebar-doc-tab {
    &.correspondence .item {
        padding-left: 50px;
    }

    .security-type {
        display: inline-block;
        width: 100%;
        margin-top: 15px;
        font-size: em(12);

        &:not(:last-child) {
            margin-right: 30px;
        }

        span {
            display: inline-block;
            margin-right: 5px;
            opacity: 0.6;
        }

        .icon-wrap {
            margin-left: 10px;
            color: $base-grey;
        }

    }

    .file-folder {
        &:not(:last-of-type):not(.empty) {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .folder-name {
            .icon-wrap {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        &--open {
            .folder-name {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }
            }
        }
    }

    .item {
        position: relative;
        padding: 10px 13px 10px 44px;

        >.icon-wrap {
            position: absolute;
            left: 7px;
            top: 0px;

            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            &.security-icon {
                color: inherit;
                right: 15px;
                left: auto;
            }

            &.user-identity-person-filled {
                @include scale(0.8);
                right: 24px;
            }
        }

        .item-header {
            margin-bottom: 7px;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }

        .item-name {
            display: inline-block;
            font-size: 15px;

            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        .btn-tag {
            display: inline-block;
        }

        .document-icon {
            top: 7px;
            left: 16px;

            @include themify($themes) {
                border: 2px solid themed("themeBaseColorDesaturated");
            }

            span {
                @include themify($themes) {
                    background: themed("themeBaseColorDesaturated");
                }
            }
        }

        &-tags {
            max-width: 20ch;
        }
    }


    .item-actions {
        &-wrap {
            @include themify($themes) {
                border-top: 2px solid themed("themeLighterDirtyColor");
            }

        }

        &:not(:last-of-type):not(.empty) {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

    }
}
