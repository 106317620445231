@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.dictionary-widget {
    &__add-item {
        margin-top: 5px;
    }

    .array-item {
        padding: 10px;
        padding-top: 20px;
        margin-top: 5px;

        @include themify($themes) {
            background-color: themed("themeBaseColorSemiTransparent12");
        }
    }

    .array-item-remove {
        margin-top: 5px;
        margin-right: 10px;
        float: right;
        z-index: 3;
    }
}
