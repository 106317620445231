@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.portal-row-layout {
    position: relative;
    padding: 15px;
    border-radius: 4px;
    flex-shrink: 0;

    &.highlighted {
        &:before {
            content: "";
            position: absolute;
            top: -7px;
            right: -7px;
            bottom: -7px;
            left: -7px;

            @include themify($themes) {
                border: 1px solid themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                border-radius: 4px;
            }
        }
    }

    &--selectable {
        cursor: pointer;
    }

    @include themify($themes) {
        border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent5");
        background-color: themed("themeBaseColorDesaturatedSemiTransparent5");
    }

    + .portal-row-layout {
        margin-top: 15px;
    }

    .close-circle {
        position: absolute;
        top: -5px;
        right: -5px;
    }

    .flex-one {
        @include firefox {
            flex: 1 1 !important;
        }
    }
}

.portal-row-layout-section {
    padding: 15px 0;
    text-align: center;
    text-transform: lowercase;
    font-size: em(11);

    @include themify($themes) {
        color: $light-grey;
        border: 1px solid themed("themeDirtyColor");
        background-color: themed("themeBaseColorDesaturatedSemiTransparent5");
    }

    &--selectable {
        cursor: pointer;
    }

    &--selectable:hover,
    &--selected {
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
            background-color: themed("themeBaseColorDesaturatedSemiTransparent10");
            border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent10");
        }
    }
}
