@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.color-theme-item {
    padding: 9px;
    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
    }
    @include border-radius(4px);

    &:not(.current):hover {
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
    }

    .theme-name {
        display: block;
        margin-bottom: 3px;
        font-size: em(12);
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        .font-size-large & {
            font-size: em(14);
        }
    }

    &.current {
        position: relative;

        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
            border: 1px solid themed("themeBaseColorSemiTransparent30");
        }

        .check-circle-filled {
            position: absolute;
            top: -2px;
            right: -2px;

            &:after {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
                font-size: 14px;
            }
        }
    }

    .theme-color-list {
        .icon-wrap {
            margin: -10px 0;
        }

        &.theme-default {
            .theme-dark-color:before {
                color: #1a143a;
            }
            .theme-bottom-tabs-background-base:before {
                color: #1e13a6;
            }
            .theme-light-color:before {
                color: #00b3ff;
            }
            .theme-semi-white-color:before {
                color: #ebf9ff;
            }
            .theme-white-color:before {
                color: #f6f6f7;
            }
            .theme-dirty-color:before {
                color: #bebec6;
            }
            .theme-lighter-dirty-color:before {
                color: #dfdfe6;
            }
        }

        &.theme-blueSky {
            .theme-dark-color:before {
                color: #2d3436;
            }
            .theme-bottom-tabs-background-base:before {
                color: #0984e3;
            }
            .theme-light-color:before {
                color: #74b9ff;
            }
            .theme-semi-white-color:before {
                color: #f1f8ff;
            }
            .theme-white-color:before {
                color: #f5f7fa;
            }
            .theme-dirty-color:before {
                color: #dadfe2;
            }
            .theme-lighter-dirty-color:before {
                color: #dfe6e9;
            }
        }

        &.theme-darkSky {
            .theme-dark-color:before {
                color: #1a1f4f;
            }
            .theme-bottom-tabs-background-base:before {
                color: #3e4abb;
            }
            .theme-light-color:before {
                color: #838bd6;
            }
            .theme-semi-white-color:before {
                color: #f2f3fb;
            }
            .theme-white-color:before {
                color: #f9f9fd;
            }
            .theme-dirty-color:before {
                color: #d6d8e2;
            }
            .theme-lighter-dirty-color:before {
                color: #dfe0e8;
            }
        }

        &.theme-lightSeaGreen {
            .theme-dark-color:before {
                color: #092f2b;
            }
            .theme-bottom-tabs-background-base:before {
                color: #1d9c8f;
            }
            .theme-light-color:before {
                color: #28d5c4;
            }
            .theme-semi-white-color:before {
                color: #e2faf8;
            }
            .theme-white-color:before {
                color: #f8fefd;
            }
            .theme-dirty-color:before {
                color: #d6e0df;
            }
            .theme-lighter-dirty-color:before {
                color: #e0e9e8;
            }
        }

        &.theme-valentine {
            .theme-dark-color:before {
                color: #340123;
            }
            .theme-bottom-tabs-background-base:before {
                color: #d60592;
            }
            .theme-light-color:before {
                color: #fb3cbd;
            }
            .theme-semi-white-color:before {
                color: #ffeefa;
            }
            .theme-white-color:before {
                color: #fff9fd;
            }
            .theme-dirty-color:before {
                color: #e7d6e2;
            }
            .theme-lighter-dirty-color:before {
                color: #ecdfe8;
            }
        }

        &.theme-violets {
            .theme-dark-color:before {
                color: #221b41;
            }
            .theme-bottom-tabs-background-base:before {
                color: #6d57bb;
            }
            .theme-light-color:before {
                color: #b9aedf;
            }
            .theme-semi-white-color:before {
                color: #f4f3fa;
            }
            .theme-white-color:before {
                color: #fffff7;
            }
            .theme-dirty-color:before {
                color: #dad7e3;
            }
            .theme-lighter-dirty-color:before {
                color: #e3e1ea;
            }
        }

        &.theme-olive {
            .theme-dark-color:before {
                color: #353500;
            }
            .theme-bottom-tabs-background-base:before {
                color: #868600;
            }
            .theme-light-color:before {
                color: #bfbf00;
            }
            .theme-semi-white-color:before {
                color: #ffffdf;
            }
            .theme-white-color:before {
                color: #fffff2;
            }
            .theme-dirty-color:before {
                color: #e6e4d7;
            }
            .theme-lighter-dirty-color:before {
                color: #ecebe1;
            }
        }

        &.theme-earth {
            .theme-dark-color:before {
                background: -webkit-linear-gradient(#221b41, #002445, #002a3f, #002d35, #092f2b);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #002a3f;
                }
            }
            .theme-bottom-tabs-background-base:before {
                background: -webkit-linear-gradient(#6d57bb, #0074cb, #0087c2, #0094aa, #1d9c8f);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #0087c2;
                }
            }
            .theme-light-color:before {
                color: #0080dd;
            }
            .theme-semi-white-color:before {
                background: -webkit-linear-gradient(#f4f3fa, #f0f4fd, #eaf6ff, #e4f8fd, #e2faf8);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #eaf6ff;
                }
            }
            .theme-white-color:before {
                background: -webkit-linear-gradient(#faf9fd, #f8fafe, #f7fcff, #f7fdfe, #f8fefd);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f7fcff;
                }
            }
            .theme-dirty-color:before {
                color: #d7dee1;
            }
            .theme-lighter-dirty-color:before {
                color: #e0e7e9;
            }
        }

        &.theme-sunset {
            .theme-dark-color:before {
                background: -webkit-linear-gradient(#360e33, #3f0a27, #430c1b, #431310, #3f1a03);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #430c1b;
                }
            }
            .theme-bottom-tabs-background-base:before {
                background: -webkit-linear-gradient(#782071, #a71c6a, #cd2959, #e74942, #f27121);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #cd2959;
                }
            }
            .theme-light-color:before {
                color: #a85199;
            }
            .theme-semi-white-color:before {
                background: -webkit-linear-gradient(#fcf1fc, #fff0f7, #fff0f1, #fff3f0, #fef5ef);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #fff0f1;
                }
            }
            .theme-white-color:before {
                background: -webkit-linear-gradient(#fdf7fc, #fff7fa, #fff7f7, #fff8f6, #fef9f5);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #fff7f7;
                }
            }
            .theme-dirty-color:before {
                color: #e3d9e2;
            }
            .theme-lighter-dirty-color:before {
                color: #ebe4ea;
            }
        }

        &.theme-nature {
            .theme-dark-color:before {
                background: -webkit-linear-gradient(#093e33, #4d3e04);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #093e33;
                }
            }
            .theme-bottom-tabs-background-base:before {
                background: -webkit-linear-gradient(#148d75, #d3ab0c);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #148d75;
                }
            }
            .theme-light-color:before {
                color: #589850;
            }
            .theme-semi-white-color:before {
                background: -webkit-linear-gradient(#edfcf9, #fefae7);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #edfcf9;
                }
            }
            .theme-white-color:before {
                background: -webkit-linear-gradient(#f8fefd, #fffef7);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f8fefd;
                }
            }
            .theme-dirty-color:before {
                color: #e3ebe2;
            }
            .theme-lighter-dirty-color:before {
                color: #e4e0e9;
            }
        }

        &.theme-dusk {
            .theme-dark-color:before {
                background: -webkit-linear-gradient(#0d2939, #574158);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #0d2939;
                }
            }
            .theme-bottom-tabs-background-base:before {
                background: -webkit-linear-gradient(#237aa9, #c5b0c6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #237aa9;
                }
            }
            .theme-light-color:before {
                color: #8da9c7;
            }
            .theme-semi-white-color:before {
                background: -webkit-linear-gradient(#f1f8fc, #f8f5f8);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f1f8fc;
                }
            }
            .theme-white-color:before {
                background: -webkit-linear-gradient(#f8fdfe, #fcfafc);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f8fdfe;
                }
            }
            .theme-dirty-color:before {
                color: #d5e0e6;
            }
            .theme-lighter-dirty-color:before {
                color: #dfe8ec;
            }
        }

        &.theme-northemLights {
            .theme-dark-color:before {
                background: -webkit-linear-gradient(#0c2e50, #144e3a);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #0c2e50;
                }
            }
            .theme-bottom-tabs-background-base:before {
                background: -webkit-linear-gradient(#185a9d, #43cea2);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #185a9d;
                }
            }
            .theme-light-color:before {
                color: #2980ab;
            }
            .theme-semi-white-color:before {
                background: -webkit-linear-gradient(#f0f7fd, #f1fcf9);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f0f7fd;
                }
            }
            .theme-white-color:before {
                background: -webkit-linear-gradient(#f8fbfe, #f8fefc);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f8fbfe;
                }
            }
            .theme-dirty-color:before {
                color: #d7e3e2;
            }
            .theme-lighter-dirty-color:before {
                color: #e0e9e8;
            }
        }

        &.theme-eveningShades {
            .theme-dark-color:before {
                background: -webkit-linear-gradient(#11225b, #422343);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #11225b;
                }
            }
            .theme-bottom-tabs-background-base:before {
                background: -webkit-linear-gradient(#365bda, #aa62ae);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #365bda;
                }
            }
            .theme-light-color:before {
                color: #6d80d6;
            }
            .theme-semi-white-color:before {
                background: -webkit-linear-gradient(#f0f2fd, #faf3fa);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f0f2fd;
                }
            }
            .theme-white-color:before {
                background: -webkit-linear-gradient(#f8f9fe, #fdfbfd);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include ie {
                    color: #f8f9fe;
                }
            }
            .theme-dirty-color:before {
                color: #d7d9e1;
            }
            .theme-lighter-dirty-color:before {
                color: #e0e2e9;
            }
        }

        &.theme-sightline {
            .theme-dark-color:before {
                color: #044e7c;
            }
            .theme-bottom-tabs-background-base:before {
                color: #00aeef;
            }
            .theme-light-color:before {
                color: #9de4ff;
            }
            .theme-semi-white-color:before {
                color: #ecfaff;
            }
            .theme-white-color:before {
                color: #f5f8fa;
            }
            .theme-dirty-color:before {
                color: #dae2e4;
            }
            .theme-lighter-dirty-color:before {
                color: #e8edee;
            }
        }
    }
}
