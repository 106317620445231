@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.select-box {
    padding: 2px 7px;
    width: 80px;
    @include border-radius(4px);
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 7px;
    }

    @include themify($themes) {
        background: themed("themeBaseColorDesaturatedSemiTransparent7");
        border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent7");
        color: themed("themeBaseColorDesaturated");
    }

    &.without-icon {
        width: auto;
        height: 32px;
    }

    &__text {
        margin-left: 4px;
        text-transform: uppercase;
        font-size: em(7);
        text-align: left;
        font-weight: 700;

        .font-size-large & {
            font-size: em(9);
        }

        &.without-icon {
            margin-left: 0;
            font-size: 13px;

            .font-size-large & {
                font-size: 14px;
            }
        }
    }

    &.selected {
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
            border: 1px solid themed("themeBaseColorSemiTransparent30");
            color: themed("themeBaseColor");
        }
        cursor: default;
    }

    &.disabled {
        background-color: $white-light-grey;
        cursor: default;
        pointer-events: none;

        &:hover {
            background-color: $white-light-grey !important;
            @include themify($themes) {
                color: themed("themeColorBaseGrey") !important;
            }

            .icon-wrap {
                opacity: 0.5;
                cursor: no-drop;
            }
        }

        span {
            opacity: 0.5;
        }
    }

    &.blocked {
        background-color: $white-light-grey;
        cursor: not-allowed;

        &:hover {
            background-color: $white-light-grey !important;
            @include themify($themes) {
                color: themed("themeColorBaseGrey") !important;
            }
        }

        span {
            opacity: 0.5;
        }
    }

    .icon-wrap:before {
        font-size: 24px;
    }
}
