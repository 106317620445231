@import "../../../../../../assets/sass/_mixins";

.audit-small.container-body {
    .audit-types {
        padding: 0 13px 10px 13px;
        @include flex-wrap(wrap);

        > span {
            display: block;
            width: 100%;
            line-height: 34px;
        }
    }
}
