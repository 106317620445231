@import "~assets/sass/_variables.scss";
@import "~assets/sass/_mixins.scss";
@import "~assets/sass/_functions.scss";

.title-breadcrumbs {
    margin-bottom: 5px;
    font-size: em(13);

    .font-size-large & {
        font-size: em(14);
    }

    @include tablet-and-mobile {
        margin: -20px -10px 20px;
        padding: 1px 10px 0;
        white-space: nowrap;
        overflow-x: auto;
        background: rgba(0, 0, 0, 0.04);
        line-height: 40px;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }
    }

    @include tablet-only {
        margin: -31px -20px 20px;
        padding: 1px 20px 0;
    }

    .link {
        font-size: inherit;
    }

    &__item {
        display: inline-block;
        font-weight: 500;
    }

    .icon-wrap {
        top: 2px;
    }
}

.panel-controls + .panel-areas .panel-header-large .title-breadcrumbs {
    @include mobile-only {
        margin-top: -20px;
    }

    @include tablet-only {
        margin-top: -30px;
    }
}
