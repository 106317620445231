@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.login-screen {
    position: relative;
    height: 100%;
}

.login-screen-left-block,
.login-screen-right-block {
    position: absolute;
    top: 0;
    padding: 20px;
    height: 100%;

    @include tablet {
        padding: 30px;
    }

    @include landscape-view {
        padding: 30px;
    }
}

.login-screen-left-block {
    position: relative;
    width: 100%;
    left: 0;
    overflow-x: hidden;

    > img {
        position: absolute;
        transform: translate(-50%, 0%);

        .vision-login & {
            top: 0;
            left: 50%;
            height: 100%;
            filter: brightness(0.8);
            -webkit-filter: brightness(0.8);
        }

        .sightline-login & {
            top: 0;
            left: 50%;
            height: 100%;

            @include tablet-large {
                top: -20%;
                height: 120%;
            }
        }
    }

    @include tablet {
        @include flexbox;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 34%;

        .sightline-login & {
            width: 50%;
        }
    }

    .sightline-login & {
        overflow: hidden;
    }

    .vision-login & .logo-icon {
        position: absolute;
        @include calc(top, "50% - 30px");
        left: 0;
        margin: 0 auto 20px;
        width: 152px;
        height: 152px;
        background: url("../../../assets/img/Vision-Icon-White.svg") center center;
        background-size: 60px;
        background-repeat: no-repeat;

        @include tablet-only {
            width: 70px;
            height: 70px;
            background-size: 70px;
        }

        @include tablet {
            position: initial;
        }

        @include tablet-large {
            width: 100px;
            height: 100px;
            background-size: 100px;
        }

        @include mobile-only {
            width: 60px;
            height: 60px;
        }
    }

    .text-block-wrap {
        font-weight: 500;
        color: white;

        .vision-login & {
            position: relative;
            padding-left: 70px;
            width: 85%;
            font-size: em(17);

            @include tablet-and-mobile {
                position: absolute;
                bottom: 35px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            @include tablet {
                padding: 0;
                text-align: center;
            }

            @include tablet-only {
                font-size: em(22);
                width: 50%;
            }

            @include tablet-large {
                max-width: 450px;
                font-size: em(28);
            }
        }

        .sightline-login & {
            @include flexbox;
            @include flex-direction(column);
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 30px 90px;
            width: 100%;
            height: 100%;
            @include themify($themes) {
                background: linear-gradient(to bottom, themed("themeDarkColor"), rgba(0, 0, 0, 0)) 75%;
            }

            @include tablet-large {
                justify-content: center;
                padding: 0 40px;
                height: 60%;
                @include themify($themes) {
                    background: linear-gradient(to bottom, themed("themeDarkColor") 25%, rgba(0, 0, 0, 0));
                }
            }

            @include landscape-view {
                justify-content: center;
                padding-bottom: 0;
                height: 60%;
            }

            .img-wrap {
                margin: -15% 0 20px;
                max-width: 500px;

                @include tablet-and-mobile {
                    display: none;
                }

                img {
                    width: 100%;

                    &.logo-simplified {
                        max-width: 500px;
                    }
                }
            }

            span {
                max-width: 500px;
                font-size: em(25);
                text-align: center;

                @include tablet-and-mobile {
                    font-size: em(20);
                }
            }
        }
    }
}

.login-screen-right-block {
    padding: 0;
    width: 85%;
    @include themify($themes) {
        background: themed("themeWhiteColor");
    }
    @include box-shadow-common($shadow-outer);

    .login-form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        overflow: auto;
    }

    .aeg {
        padding: 20px;
        color: #949398;
        text-align: center;
        @include mobile-only {
            padding: 10px 0px;
        }
    }

    .sightline-login & {
        background: white;
        @include box-shadow(0, 0, 10px, 0 rgba(0, 0, 0, 0.45));

        @include mobile-only {
            top: 5%;
        }

        @include tablet-and-mobile {
            @include border-radius(8px);
        }

        @include landscape-view {
            top: 0;
            @include border-radius(0 0 8px 8px);
        }

        @include tablet-large {
            width: 50%;
            @include box-shadow(-10px, 0, 10px, 0 rgba(0, 0, 0, 0.25));
        }
    }

    .welcome {
        margin-bottom: 10px;
        @include themify($themes) {
            color: themed("themeBaseColorDesaturated");
        }
        font-weight: 700;
        font-size: em(23);

        @include mobile-only {
            font-size: em(18);

            .sightline-login & {
                padding: 20px 20px 0;
            }
        }
    }

    .vision-login & {
        text-align: center;
    }

    @include mobile-only {
        left: 0;
        right: 0;
        margin: 0 auto;
        @include border-radius(0 0 8px 8px);
        height: auto;
    }

    @include tablet-only {
        width: 80%;
        height: 70%;

        .sightline-login & {
            top: 10%;
            width: 500px;
            height: initial;
            min-height: 50%;
        }
    }

    @include tablet {
        @include flexbox;
        flex-direction: column;
    }

    @include tablet {
        right: 0;
        width: 66%;
        height: 100%;
    }

    .logo {
        .vision-login & {
            margin: 30px auto 20px;
            width: 280px;
            height: 60px;
            background: url("../../../assets/img/Vision-Horizontal-DSM-Navy.svg") center center;
            background-size: contain;
            background-repeat: no-repeat;

            @include tablet-and-mobile {
                width: 230px;
                height: 50px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        .sightline-login & {
            margin: 0 20px 30px;

            @include tablet {
                margin: 0 0 30px;
            }

            @include mobile-only {
                width: 250px;
                height: auto;
            }
        }
    }

    .login-square {
        background-color: white;
        @include border-radius(0 0 8px 8px);
        text-align: left;

        @include mobile-only {
            max-height: 400px;
            overflow-y: auto;
        }

        .vision-login & {
            padding: 35px 55px;
            margin-top: 40px;
            h3 {
                line-height: 30px;
                font-size: 2em;
                text-align: center;
                margin-bottom: 25px;
                @include themify($themes) {
                    color: themed("themeDarkColor");
                }
                @include tablet-and-mobile {
                    font-size: 1.7em;
                }
            }

            .user-name,
            .form-btn-wrap,
            .field-group,
            .forgotPassword-block p,
            .captcha {
                padding: 0px 60px;

                &.password {
                    padding-right: 25px;
                }

                @include mobile-only {
                    padding: 0px;

                    &.password {
                        padding-right: 0;
                    }
                }

                label {
                    color: #1a143a;
                    font-weight: 500;
                }
            }

            .forgotPassword-block {
                width: 300px;
                margin: 0 auto;

                .field-group,
                .form-btn-wrap,
                p,
                .captcha {
                    padding: 0px !important;
                }
            }

            @include mobile-only {
                padding: 40px 35px;
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }

            @include tablet {
                @include border-radius(6px);
                @include box-shadow-common($shadow-outer);
            }
        }

        @include landscape-view {
            padding: 0;
            box-shadow: none;
        }

        p.text-center {
            margin: 10px 0 20px;
            text-align: center;
        }
    }
}
