@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.application-form-side-panel {
    .header-values {
        justify-content: space-between;
        padding: 15px 20px;

        @include themify($themes) {
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }

        @include mobile-only {
            @include flex-wrap(wrap);
        }

        .attribute {
            @include mobile-only {
                width: 50%;

                &:nth-of-type(2n) {
                    text-align: right;
                }

                &:nth-of-type(1n + 3) {
                    margin-top: 10px;
                }

                .btn {
                    margin-top: 4px;
                }
            }

            .title {
                font-size: em(12);
                font-weight: 500;
                text-transform: uppercase;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .font-size-large & {
                    font-size: em(14);
                }

                @include tablet {
                    margin-bottom: 4px;
                }
            }

            .value {
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
                font-size: 12px;

                .font-size-large & {
                    font-size: 14px;
                }
            }
        }
    }

    .schema-container.page-container > .form-group.field-object > fieldset > legend {
        span {
            display: none;
        }
    }
}
