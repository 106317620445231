.forecast {
    height: 100%;
    overflow: hidden;
}

.forecast iframe {
    border: none;
    width: 100%;
    height: 100%;
}
