.property-list-item--link-url {
    .field-group {
        &.no-padding {
            padding: 0px;
        }

        padding: 8px 0;
        width: 100%;
    }

    .property-list {
        margin-right: 20px;

        .property-list-item--select-box {
            .select-box {
                width: 100%;
            }
        }
    }

    .property-list-item-value {
        .status-msg.msg-field-status {
            margin-top: 8px;
            margin-bottom: 2px;
        }
    }

    > .property-list-item {
        width: 100%;
    }
    .property-list-item--switch {
        border-bottom: none;
    }
}
