@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.expandable-panel {
    display: inline-block;
    font-size: em(18);
    font-weight: 500;
    color: black;
    cursor: pointer;

    &.active {
        margin-bottom: 10px;
        @include themify($themes) {
            color: themed("themeBaseColor");
        }

        &.with-icon:before {
            @include rotate(90deg);
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }

    &.with-icon:before {
        margin: -3px 6px 0 0;
        vertical-align: middle;
        transition-duration: 0.2s;
        transition-property: transform;
        font-size: 25px;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }
    }
}
