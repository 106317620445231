@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

input[type="text"],
input[type="password"],
input[type="number"],
input[type="tel"],
input:not([type]) {
    &.error {
        border-color: $red !important;
        @include box-shadow(inset 0, 1px, 2px, 0 $red-field-error-light !important);
        @include box-shadow(0, 0, 0, 0 $red-field-error-light !important);
        color: $red;
        @include transition(box-shadow 0.5s !important);
    }

    &.light-up-error {
        @include box-shadow(inset 0, 0, 0, 0 $red-field-error-light !important);
        @include box-shadow(0, 0, 15px, 0 $red-field-error !important);
    }

    &.with-one-icon {
        padding-left: 33px;

        &.right {
            padding: 0 33px 0 10px;
        }
    }

    &:not(.error):not(.input-read-only) {
        &:focus,
        &.focused,
        &.focus {
            @include themify($themes) {
                border-color: themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            }
        }
    }

    &.frozen {
        padding-left: 42px;

        &.right {
            padding: 0 42px 0 10px;
        }
    }

    &.with-two-icons {
        padding-right: 61px;
    }

    &.highlighted {
        @include themify($themes) {
            border-color: themed("themeBaseColor");
        }
    }

    &.input-read-only {
        box-shadow: none !important;
        color: $blue-grey;
        pointer-events: none;

        @include themify($themes) {
            background: themed("themeWhiteColor");
            border-color: themed("themeLighterDirtyColor");
        }
    }

    &.input-table-filter {
        width: 100%;
        @include border-radius(0);
        font-weight: 500;
        font-size: 13px;

        .font-size-large & {
            font-size: 15px;
        }

        @include themify($themes) {
            border: none;
            box-shadow: none;
            border-top: 2px solid themed("themeDirtyColor");
            border-bottom: 2px solid themed("themeDirtyColor");
            border-right: 1px solid themed("themeDirtyColor");
        }

        &:focus,
        &.focused {
            @include themify($themes) {
                border: 2px solid themed("themeLightColor");
                @include box-shadow(inset 0, 1px, 4px, 0 themed("themeLightColor"));
            }
        }
    }

    &.with-label-inside {
        height: 42px;

        + label {
            position: absolute;
            top: 11px;
            left: 0;
            padding: 0 10px;
            font-style: italic;
            color: $blue-grey;
            pointer-events: none;

            + .icon-wrap {
                top: 7px !important;
            }
        }

        @include placeholder {
            color: transparent;
        }

        &:not(:placeholder-shown),
        &:focus {
            padding-top: 14px;

            + label {
                top: 5px;
                font-size: em(11);
                font-style: normal;
            }
        }

        &:focus + label {
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeLightColor");
            }
        }
    }
}

input[type="number"].number {
    &:not(.input-table-filter) {
        width: 90px;
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
        @include box-shadow-common($shadow-outer-hover);
        -moz-appearance: textfield;

        &:not(.numeric-input-filled) {
            font-style: italic;
        }

        &.input-read-only {
            box-shadow: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &:focus,
        &.focused {
            font-style: normal;
        }
    }
}
