@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.rebate-approvals {
    &__filters {
        position: relative;
        padding-bottom: 15px;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }

        @include ie {
            z-index: 2;
        }

        &.hidden {
            display: none;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;
            background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.15));
        }

        &-item {
            margin-top: 15px;

            &:not(:last-child) {
                margin-right: 30px;
            }

            &--programs {
                .field-group {
                    white-space: normal !important;
                }

                .dropdown-wrap {
                    min-width: 250px;
                }
            }

            &--date,
            &--received-date,
            &--status {
                .dropdown-wrap {
                    width: 140px;
                }
            }
        }

        .field-group {
            @include flexbox;
            align-items: center;
            white-space: nowrap;

            .field-wrap {
                width: 100%;
            }

            > label {
                margin: 0 10px 0 0;
            }

            .dropdown-list-wrap {
                white-space: normal;
            }
        }
    }
}
