@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";

.rules-target-status-field {
    padding: 0 20px;
    margin-top: -20px;
    margin-bottom: 30px;
}

.wf {
    &__settings-step-form {
        padding: 0 20px;

        &.json-form {
            height: initial;
        }

        &-container {
            padding-top: 20px;
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }
        }

        &-title {
            padding: 0 20px;
            text-transform: uppercase;
            font-weight: 500;
        }

        &-field {
            &-container {
                max-width: 330px;
                margin-top: 20px;
            }

            & .dropdown-list-item {
                @include text-overflow;

                > div {
                    white-space: normal;
                }
            }

            & .dropdown-list-wrap {
                margin-bottom: 20px;
            }
        }
    }
}
