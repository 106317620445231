@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";

.add-approved-equipment-form {
    position: relative;

    .approved-equipment-search-content {
        > .btn-group {
            @include mobile-only {
                padding: 15px 20px;
                @include box-shadow-common($shadow-outer-center);

                .btn.search {
                    flex: 1;
                }

                .flex-one {
                    display: none;
                }
            }

            @include tablet {
                margin: 20px 20px 0;
                padding: 15px 0;

                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }
        }
    }

    .approved-equipment-search-form {
        padding: 20px;

        @include tablet {
            padding: 20px 20px 0;
        }

        &.hidden,
        + .btn-group.hidden {
            display: none;
        }

        .form-fields {
            margin: -10px;
            @include flex-wrap(wrap);

            @include mobile-only {
                @include flex-wrap(wrap);
            }

            > .field-group {
                margin: 10px;

                @include tablet {
                    @include calc(width, "25% - 20px");
                    min-width: 250px;
                }
            }
        }

        .form-actions {
            padding-top: 20px;
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }

            .btn + .btn {
                margin-left: 10px;
            }
        }
    }

    .approved-equipment-search-results {
        padding: 20px;

        @include tablet {
            margin-top: 25px;
            padding: 5px 20px 20px;
        }

        .icon-with-label.with-hand-made-icon {
            margin-bottom: 10px;
        }

        .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;
        }

        .grid-list-row.details {
            margin: 15px;
            padding: 20px;
            @include calc(width, "100% - 30px");

            .approved-equipment-details {
                .json-form {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.add-approved-equipment-sidenav-panel {
    .sidenav-content {
        .sidenav-body {
            @include ie {
                padding: 0;
            }

            .json-form {
                word-wrap: break-word;

                @include ie {
                    padding: 20px 0 20px 20px;
                    overflow-x: hidden;
                }
            }
        }

        .sidenav-footer {
            .shevron-small-left {
                margin: 0;
            }
        }
    }
}
