@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.window-header {
    position: relative;
    min-height: 40px;
    @include themify($themes) {
        background: themed("themeWindowHeaderBackgroundColor");
    }

    .header-info > .header-title {
        @include flexbox;
        align-items: center;
        padding: 7px 0;
        min-height: 40px;
        font-size: em(16);
        font-weight: 500;
        line-height: 40px;
        line-height: inherit;
        color: white;
        word-break: break-word;

        @include ie {
            word-wrap: break-word;
        }
    }
}
