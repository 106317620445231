@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.scanned-apps-panel {
    .scan-queue__side-panel {
        margin-bottom: 30px;

        > div {
            position: relative;
            padding: 10px 20px 60px;
            justify-content: center;
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }

            @include tablet {
                padding: 5px 20px;
                align-items: flex-start;
            }
        }
    }

    .scan-queue__side-panel > div,
    .scan-totals-item {
        @include flexbox;
        @include flex-direction(row);
    }

    .scan-totals-item {
        padding: 0;
        align-items: center;

        &.flaw {
            position: relative;
            margin: 0 0 0 25px;
            padding: 0 0 0 20px;
            border: none;

            &:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                width: 1px;
                height: 28px;
                @include themify($themes) {
                    background: themed("themeDirtyColor");
                }

                @include tablet {
                    top: 8px;
                    height: 33px;
                }
            }

            @include mobile-only {
                span {
                    &:not(.value) {
                        order: 1;
                    }

                    &.value {
                        margin: 0 0 0 5px;
                        order: 2;
                    }
                }
            }
        }

        @include tablet {
            &:not(.flaw) {
                @include flex-wrap(wrap);
            }
        }

        span {
            &:not(.value) {
                margin: 0;
                width: 92px;
                order: 2;
            }

            &.value {
                margin: 0 5px 0 0;
                order: 1;
                font-size: 37px;
            }
        }

        .btn-group {
            order: 3;
            margin: 0 0 0 10px;

            @include mobile-only {
                margin: 0;
                bottom: 13px;
                left: 13px;
                @include calc(width, "100% - 26px");

                .btn {
                    width: 100%;
                }
            }
        }
    }
}
