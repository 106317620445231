@import "./_functions";

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin box-shadow($left, $top, $radius, $color) {
    box-shadow: $left $top $radius $color;
    -webkit-box-shadow: $left $top $radius $color;
    -moz-box-shadow: $left $top $radius $color;
}

@mixin box-shadow-common($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin icon-iconoteka {
    display: inline-block;
    min-width: 1px;
    min-height: 1px;
    font-size: 18px;
    font-family: $font-family-iconoteka;
    font-style: normal;
    font-weight: normal;
    box-sizing: content-box;
}

@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin placeholder {
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin landscape-view {
    @media screen and (min-width: 320px) and (max-width: 991px) and (orientation: landscape) {
        @content;
    }
}

@mixin landscape-view-large {
    @media screen and (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
        @content;
    }
}

@mixin mobile-only {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin tablet-and-mobile {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        @content;
    }
}

@mixin tablet-large {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}

@mixin desktop-small {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin tablet-and-desktop-small {
    @media only screen and (min-width: 768px) and (max-width: 1280px) {
        @content;
    }
}

@mixin tablet-large-and-desktop {
    @media screen and (min-width: 1025px) and (max-width: 1279px) {
        @content;
    }
}

@mixin tablet-large-and-desktop-small {
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

@mixin desktop-large {
    @media only screen and (min-width: 1599px) {
        @content;
    }
}

@mixin desktop-extra-large {
    @media only screen and (min-width: 1710px) {
        @content;
    }
}

@mixin desktop-small-height {
    @media screen and (min-width: 1024px) and (max-height: 850px) {
        @content;
    }
}

@mixin hover-supported-devices {
    @media not all and (pointer: coarse) {
        @content;
    }
}

// IE10+ specific styles
@mixin ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// Edge 12+ specific styles
@mixin ie-edge {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

// Firefox specific styles
@mixin firefox {
    @-moz-document url-prefix() {
        @content;
    }
}

@mixin webkit-scrollbar {
    @include tablet-large {
        &::-webkit-scrollbar {
            height: 10px;
            width: 10px;
            @include themify($themes) {
                background-color: themed("themeBaseColorDesaturatedSemiTransparent7");
            }
            @include border-radius(10px);
        }

        &::-webkit-scrollbar-thumb {
            @include border-radius(10px);

            @include themify($themes) {
                background-color: themed("themeBaseColorDesaturatedSemiTransparent");
                border: 2px solid themed("themeBaseColorDesaturatedSemiTransparent7filled");
            }
        }
    }
}

@mixin webkit-scrollbar-thin {
    @include tablet-large {
        &::-webkit-scrollbar {
            height: 3px;
            width: 3px;
            @include border-radius(3px);
        }

        &::-webkit-scrollbar-thumb {
            @include border-radius(3px);
            border: none;
        }
    }
}

@mixin webkit-scrollbar-thin-colored {
    @include tablet-large {
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
            background: rgba(0, 0, 0, 0.1);
            @include border-radius(4px);
        }

        &::-webkit-scrollbar-thumb {
            @include themify($themes) {
                background-color: themed("themeBaseColorSemiTransparent20");
            }
            @include border-radius(4px);
            border: none;
        }
    }
}

@mixin webkit-scrollbar-light {
    @include tablet-large {
        &::-webkit-scrollbar {
            @include themify($themes) {
                background-color: themed("themeMiddleColor");
            }
        }

        &::-webkit-scrollbar-thumb {
            @include themify($themes) {
                background-color: themed("themeLightColor");
            }
            border: none;
        }
    }
}

@mixin scrollbar-hide {
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE 10+ */

    &::-webkit-scrollbar {
        /* WebKit */
        display: none !important;
    }
}

@mixin rotate($degrees) {
    -ms-transform: rotate($degrees);
    -webkit-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin flip-horizontally {
    -ms-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

@mixin scale($scale-level) {
    -webkit-transform: scale($scale-level) translateZ(0);
    -moz-transform: scale($scale-level);
    -o-transform: scale($scale-level);
    -ms-transform: scale($scale-level);
    transform: scale($scale-level);
}

@mixin item-separator($space, $border-color) {
    content: "";
    position: absolute;
    top: 0;
    left: $space;
    width: calc(100% - (#{$space}) * 2);
    border-bottom: 1px solid $border-color;
}

@mixin centered {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@mixin dropdown-dynamic-height {
    @include flexbox;
    height: auto;
    min-height: 32px;

    &-text {
        padding: 5px 0;
        align-self: center;
        white-space: normal;
        line-height: 1.2;
        word-break: break-word;

        @include ie {
            word-wrap: break-word;
        }
    }
}

@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin expanded-panel {
    z-index: 2;
    margin: 20px 0 10px;
    background-color: white;

    @include themify($themes) {
        border: 1px solid themed("themeBoxShadowBorderColor");
        @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
    }

    @include tablet {
        margin-left: -10px;
        margin-right: -10px;
    }
}

@mixin expanded-panel-mobile {
    z-index: 21;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: none !important;
    border: none !important;
    margin: 0 !important;
}

@mixin semi-transparent-background {
    &:before {
        content: "";
        z-index: 2;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        @include themify($themes) {
            background: themed("themeSemiTransparentBackground");
        }
        pointer-events: none;
    }

    .window-view.left & {
        &:before {
            width: 50%;
            @include calc(height, "100% - 54px");
        }
    }

    .window-view.right & {
        &:before {
            left: 50%;
            width: 50%;
            @include calc(height, "100% - 54px");
        }
    }
}

@mixin dropdown-semi-transparent-background {
    right: -5px;
    @include semi-transparent-background;

    .dropdown-list {
        z-index: 3;
        position: relative;
    }
}

@mixin print-background {
    position: relative;

    &:before {
        content: "";
        z-index: -1;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@mixin currency-sign-styles {
    margin: 0 2px;
    vertical-align: super;
    font-style: italic;
    @include themify($themes) {
        color: themed("themeBaseColor");
    }

    @include ie {
        position: relative;
        top: 5px;
    }

    @include ie-edge {
        position: relative;
        top: 5px;
    }
}

@mixin data-grid-controls-mobile {
    padding: 0 8px;
    width: 100%;
    height: 40px;
    background: $white-light-grey;
    justify-content: space-between;
    align-items: center;

    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
        border-bottom: none;
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background: $sightline-white-light-grey;
    }
}

@mixin data-grid-sticky-header {
    max-height: 100%;

    .grid-list-row-container {
        overflow-y: auto;

        .grid-list-row:last-child {
            border-bottom: none;
        }
    }
}

@mixin dragging-item-style {
    @include themify($themes) {
        background: themed("themeWhiteColor");
        border: 1px solid themed("themeDirtyColor");
    }
}

@mixin expanded-panel-json-form {
    padding: 15px 20px 20px;

    .action-buttons {
        padding-top: 20px;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }

        .btn {
            @include mobile-only {
                width: 50%;
            }
        }
    }
}

@mixin status-mark-small {
    padding: 0 5px;
    height: 15px;
    font-size: em(8);
    @include border-radius(4px);
    line-height: 16px;
}

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{"" + $theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}
