@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-properties-color-list {
    padding: 10px 10px 20px;

    .portal-builder-new-item {
        cursor: pointer;
        height: 60px;
        @include calc(width, "25% - 20px");
        border-radius: 4px;
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
        display: flex;
        margin-left: 10px;
        margin-top: 10px;

        @include themify($themes) {
            color: themed("themeBaseColor");
        }
    }
}

.portal-builder-properties-color-list-item {
    @include calc(width, "25% - 14px");
    margin: 7px;
    position: relative;
    padding: 2px;

    &.highlighted {
        top: -1px;
        @include themify($themes) {
            color: themed("themeBaseColor");
        }
        @include themify($themes) {
            border: 1px solid themed("themeBoxShadowBorderColor");
            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            border-radius: 4px;
        }
    }

    .text-label {
        margin-top: 5px;
        text-align: center;
    }

    .close-circle {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .portal-builder-color-picker {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}

.portal-builder-properties-color-list-item-preview {
    cursor: pointer;
    position: relative;
    height: 60px;
    width: 100%;
    border-radius: 4px;
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }

    .color-text {
        font-weight: 700;
        font-size: 12px;
        text-align: center;

        &--click-to-set {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }
}
