@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-page-editor {
    padding: 5px 0;

    .program-page-info {
        position: relative;
        overflow: hidden;
        margin: 15px 20px 7px 20px;
        padding: 15px;
        z-index: 0;
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
        }

        .template-link,
        .settings-link {
            cursor: pointer;
            font-weight: 700;

            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        .info-empty {
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0.1;
            @include scale(6);

            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        p {
            position: relative;
            z-index: 1;

            + p {
                margin-top: 5px;
                margin-right: 40px;
            }
        }

        .btn {
            display: inline-block;
            margin-top: 10px;
        }
    }
}

.content-page-list {
    .status-msg {
        .info-text-block {
            margin-bottom: 10px;

            .icon-wrap {
                color: $medium-grey;
            }
        }
    }

    .pages-section {
        padding: 0 20px 20px;

        .status-msg {
            margin-bottom: 10px;
        }

        .list-item-row {
            padding: 0;
            font-size: 16px;
            cursor: unset;

            &:hover {
                background: unset;
            }

            &.selected {
                background: initial;
            }

            &:not(.selected) {
                .action-label {
                    color: inherit;
                }
            }
        }

        .icon-with-label {
            margin-top: 15px;
        }

        .page-name {
            .action-label {
                margin-right: 10px;
            }

            .icon-wrap {
                color: $medium-grey;
            }
        }
    }
}

.portal-builder-properties-overlay-view {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
    box-shadow: $shadow-inner;
    z-index: 1;
}
