.field-group {
    &:not(.inline) {
        width: 100%;
    }

    &.inline {
        display: inline-block;

        > .checkbox-wrap {
            margin-top: 0;
        }
    }
}
