@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.window-body-header {
    position: relative;
    background-color: white;

    .header-icon-group {
        .field-group.inline {
            vertical-align: top;
        }
    }

    &.with-shadow {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }

        body:not(.theme-mode-light) & {
            z-index: 2;
            @include box-shadow-common($shadow-outer);
            border-bottom: none;
        }
    }

    .project-view &,
    .utility-invoice-view & {
        .sub-header-expand.active {
            @include tablet {
                padding-bottom: 30px;
                height: initial;
            }
        }

        .header-icon-group {
            @include tablet-only {
                text-align: center;
            }

            @include tablet {
                right: 0;
                left: 0;
            }

            @include tablet-large {
                position: fixed;
                top: 33px;
                margin: 0 auto;
                padding-left: 40px;
                padding-right: 40px;
                width: 100%;
                text-align: right;
                pointer-events: none;

                * {
                    pointer-events: auto;
                }

                .split-view & {
                    position: absolute;
                    right: 0;
                    top: -21px;
                    padding: 0;
                    text-align: center;
                }
            }

            @include desktop {
                padding-left: 50px;
                padding-right: 50px;
                min-width: $site-min-width-new;
                max-width: $site-max-width-new;

                .split-view & {
                    padding: 0;
                    min-width: auto;
                    max-width: inherit;
                }
            }

            @include desktop-large {
                .layout-full-screen & {
                    padding: 0;
                    left: initial;
                    right: 30px;
                    width: initial;
                    min-width: auto;
                    max-width: inherit;
                }
            }
        }
    }

    .program-view &,
    .utility-view &,
    .utility-contract-view &,
    .utility-budget-view & {
        .header-icon-group > div:last-child {
            margin-right: 0;
        }
    }

    .customer-view & {
        @include tablet {
            z-index: 22;
        }
    }

    .program-view &,
    .utility-contract-view &,
    .customer-view &,
    .utility-budget-view &,
    .utility-view & {
        .header-wrap {
            .header-icon-group {
                @include tablet {
                    position: absolute;
                    margin-bottom: 0;
                }
            }
        }

        .header-icon-group {
            @include calc(top, "50% - 20px");
            right: 0;
        }
    }

    .utility-contract-view &,
    .utility-view & {
        .header-wrap {
            @include tablet {
                padding-right: 125px;

                .split-view & {
                    padding-right: 0;
                }
            }
        }
    }

    .program-view &,
    .customer-view &,
    .project-view &,
    .utility-invoice-view &,
    .utility-budget-view & {
        .sub-header-expand.active {
            + .header-wrap {
                @include tablet-only {
                    @include themify($themes) {
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }
                }

                .split-view & {
                    @include themify($themes) {
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }
                }
            }
        }

        .header-wrap {
            .header-icon-group {
                @include tablet-only {
                    position: absolute;
                    top: -21px;
                }

                .split-view & {
                    top: -20px;
                }
            }
        }
    }

    .project-view &,
    .utility-invoice-view & {
        .sub-header-expand.active {
            + .header-wrap {
                .layout-top &,
                .split-view & {
                    @include tablet {
                        padding-top: 30px;
                    }
                }
            }
        }
    }

    .users-management &,
    .utility-view &,
    .utility-contract-view &,
    .app-form-pages & {
        @include tablet {
            .sub-header-expand {
                display: none;
            }
        }

        .header-wrap {
            .split-view & {
                display: block;
            }

            @include tablet {
                display: block;
                padding: 11px 0 12px;
            }
        }
    }

    .create-new-application-page &,
    .create-application-from-scan &,
    .application-document-queue &,
    .users-management & {
        .split-view & {
            .sub-header-expand {
                display: none;
            }
        }
    }

    .create-new-application-page &,
    .create-application-from-scan &,
    .application-document-queue & {
        @include tablet-and-mobile {
            .sub-header-expand {
                display: none;
            }
        }

        .header-wrap {
            .split-view & {
                display: block;
            }

            @include tablet-and-mobile {
                display: block;
            }
        }
    }

    .create-new-application-page &,
    .create-application-from-scan & {
        > .main-grid-wrap {
            @include mobile-only {
                padding: 0 2px;
            }
        }

        .header-wrap {
            @include mobile-only {
                padding: 10px 0;
            }

            @include tablet {
                .header-item-title {
                    margin-right: 5px;
                }

                .header-item {
                    display: inline-block;
                }
            }

            @include tablet-large {
                text-align: center;

                .header-item-title {
                    display: inline-block;

                    .split-view & {
                        display: block;
                    }
                }

                .header-value {
                    margin-top: 0;

                    .split-view & {
                        margin-top: 2px;
                    }
                }
            }

            .split-view & {
                text-align: left;

                .sub-header-visible-part {
                    @include flexbox;
                    @include flex-wrap(inherit);
                    justify-content: space-between;
                    padding: 0;
                }

                .header-item {
                    padding: 0 40px 0 0;

                    &:last-child {
                        padding: 0;
                    }
                }
            }

            .sub-header .header-item:not(:first-child):after {
                @include tablet-large {
                    left: 0;
                }

                .split-view & {
                    left: -21px;
                }
            }

            .sub-header-visible-part {
                @include mobile-only {
                    padding: 0 15px;
                }

                @include tablet {
                    display: inline-block;
                }
            }

            .section-title {
                display: none;
            }

            .header-item {
                margin: 0;
                width: auto;

                @include mobile-only {
                    margin: 7px 0;
                    background: transparent;
                }

                @include tablet-only {
                    margin: 0 20px 0 0;
                }

                @include tablet-large {
                    padding: 0 20px;
                }
            }
        }
    }

    .sub-header-expand {
        margin-left: -10px;
        height: 40px;
        @include calc(width, "100% + 20px");
        background-color: white;
        text-align: center;
        cursor: pointer;

        .theme-mode-light & {
            background: transparent;
        }

        &.active {
            + .header-wrap {
                display: block;

                @include mobile-only {
                    @include flexbox;
                }
            }
        }

        .icon-with-label {
            margin-top: 11px;

            &:hover:before {
                background: transparent;
            }

            &:before {
                font-size: em(14);
                font-weight: 700;
            }
        }
    }

    &:not(.no-popup-details-on-mobile) .header-wrap {
        @include mobile-only {
            z-index: 3;
            position: fixed;
            top: 0;
            left: 0;
            padding: 0;
            @include flex-direction(column);
            width: 100%;
            height: 100%;
            background: white;

            .popup-header-details {
                position: relative;
                margin-bottom: 20px;
                padding: 0 50px 0 15px;
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: left;
                font-weight: 500;

                @include themify($themes) {
                    background: themed("themeSemiWhiteColor");
                    border-bottom: 1px solid themed("themeDirtyColor");
                }

                .icon-wrap {
                    position: absolute;
                    right: 4px;
                    @include calc(top, "50% - 13px");
                }
            }
        }
    }

    .header-wrap {
        display: none;
        position: relative;
        padding: 18px 0;

        .program-view &,
        .utility-view &,
        .customer-view &,
        .utility-contract-view &,
        .utility-budget-view &,
        .app-form-pages & {
            @include tablet {
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }

        .program-view & {
            @include tablet-large {
                padding-right: 150px;
            }
        }

        @include tablet-large {
            display: block;

            .split-view & {
                display: none;
            }
        }

        &.no-padding {
            padding: 0;
        }

        .header-icon-group {
            z-index: 3;

            @include mobile-only {
                padding: 0 15px 10px;
                text-align: center;
            }

            > div {
                margin: 0 4px;

                @include mobile-only {
                    margin-bottom: 8px;
                }

                @include tablet {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            > .icon-wrap,
            .icon-dropdown > .icon-wrap {
                width: 40px;
                height: 40px;
                background-color: white;

                &:after {
                    display: none;
                }

                &:before {
                    width: 100%;
                    height: 40px;
                    @include themify($themes) {
                        background: themed("themeDashboardToolIconBackground");
                    }
                    @include border-radius(50%);
                    font-size: 22px;
                    line-height: 40px;
                }

                &:not(.icon-wrap-disabled):before {
                    @include transition(all 0.2s);
                }

                &:not(.icon-wrap-disabled):hover:before {
                    font-size: 24px;
                }

                &.more:before {
                    font-weight: 700;
                }

                &.icon-wrap-disabled:before {
                    background: linear-gradient(to bottom, $white-light-grey, rgba(0, 0, 0, 0.1));
                }
            }

            .field-group {
                &.icon-with-dropdown {
                    margin-left: 4px;
                }
            }

            > .icon-wrap {
                vertical-align: top;
            }

            .icon-dropdown {
                > .icon-wrap {
                    &:after {
                        content: "";
                    }

                    &.icon-wrap-active:before {
                        background: transparent;
                    }
                }
            }
        }

        .icon-with-dropdown {
            .dropdown-list .dropdown-list-item {
                padding-right: 10px;
            }

            .dropdown-list-wrap {
                @include tablet-large {
                    right: 0;
                }

                @include tablet {
                    width: 233px;
                    @include dropdown-semi-transparent-background;
                }
            }

            .dropdown-sub-block {
                padding: 5px;

                @include tablet {
                    @include flexbox;
                    @include flex-wrap(wrap);
                }

                .dropdown-sub-block-item {
                    position: relative;
                    display: inline-block;
                    margin: 4px;
                    padding: 5px 10px 7px;
                    @include calc(width, "50% - 10px");
                    @include border-radius(4px);
                    cursor: pointer;

                    @include themify($themes) {
                        background: themed("themeBaseColorDesaturatedSemiTransparent7");
                        border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent7");
                    }

                    .theme-sightline &,
                    .theme-sightlineHighContrast & {
                        background: $sightline-white-light-grey;
                    }

                    &.active:not(.disabled) {
                        @include themify($themes) {
                            background: themed("themeBaseColorSemiTransparent7");
                            border: 1px solid themed("themeBaseColorSemiTransparent30");
                        }

                        .icon-with-label {
                            &:before {
                                @include themify($themes) {
                                    color: themed("themeBaseColor");
                                }
                            }

                            &.test {
                                &:before {
                                    @include themify($themes) {
                                        border-color: themed("themeBaseColor");
                                    }
                                }
                            }
                        }

                        .icon-wrap {
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }
                    }

                    &:nth-of-type(2n-1) {
                        margin-right: 5px;
                    }

                    &:hover {
                        .icon-with-label:before {
                            background: transparent;
                        }

                        .icon-wrap:not(.icon-wrap-disabled) {
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }
                    }

                    &.disabled {
                        background: $white-grey-medium;
                        border-color: $grey;
                        pointer-events: none;

                        .icon-with-label {
                            pointer-events: none;
                            color: $grey;

                            &.test:before,
                            &:before {
                                color: $grey;
                            }

                            &.test:before {
                                border-color: $grey;
                            }
                        }
                    }
                }

                .icon-with-label {
                    padding: 0;
                    font-size: em(10);
                    line-height: 1.3;
                    @include themify($themes) {
                        color: themed("themeBaseColorDesaturated");
                    }

                    .font-size-large & {
                        font-size: em(12);
                    }

                    &.test {
                        padding-top: 28px;

                        &:before {
                            position: absolute;
                            left: 0;
                            top: 8px;
                            @include themify($themes) {
                                border-color: themed("themeBaseColorDesaturated");
                            }
                            text-align: center;
                        }
                    }

                    &:before {
                        position: initial;
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        @include themify($themes) {
                            color: themed("themeBaseColorDesaturated");
                        }
                    }

                    &:not(.disabled) {
                        b {
                            @include themify($themes) {
                                color: themed("themeColorDarkGrey");
                            }
                        }
                    }
                }

                .icon-wrap {
                    position: absolute;
                    top: 0;
                    right: 0;

                    &.fiber-smart-record-filled {
                        @include rotate(180deg);
                    }
                }
            }
        }

        .sub-header {
            @include mobile-only {
                order: 2;
                flex: 1;
                @include flexbox;
                @include flex-direction(column);
                overflow: hidden;

                @include ie {
                    flex: 1 0 auto;
                }
            }
        }
    }
}
