@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.sidebar-panel {
    .sidebar-content.rebates-sidebar-panel {
        overflow: initial;
    }

    .nothing-found-block {
        @include border-radius(0 0 8px 8px);
    }
}

.rebates-sidebar-body {
    .rebate-totals {
        @include flexbox;
        padding: 0 13px 10px;
        background: $white-light-grey;

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        .rebate-total-item {
            @include flexbox;
            @include flex-wrap(wrap);
            align-items: center;
            width: 50%;

            &:last-child {
                @include flex-direction(row-reverse);
                @include themify($themes) {
                    border-left: 1px solid themed("themeLighterDirtyColor");
                }
                text-align: right;

                .item-value {
                    padding: 0 0 0 5px;
                }
            }

            .item-label,
            .item-value {
                font-weight: 500;
            }

            .item-label {
                margin: 0;
                font-size: em(10);
                line-height: 1.2;

                .font-size-large & {
                    font-size: em(12);
                }
            }

            .item-value {
                padding-right: 5px;
                white-space: nowrap;
                font-size: em(24);
                line-height: 1;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .currency {
                    font-size: 15px;
                    @include currency-sign-styles;
                }
            }
        }
    }

    .rebates-list {
        @include tablet-large {
            overflow: auto;
        }

        .expandable-list {
            border: none;

            &:not(:last-child) {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }
            }

            &:first-child {
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }

            .item {
                padding: 0 13px;

                &:focus {
                    background-color: white;
                }

                &:hover {
                    @include themify($themes) {
                        background: themed("themeWhiteColor");
                    }
                }
            }

            &.expanded {
                position: relative;
                margin: 10px -5px;
                background-color: white;

                @include themify($themes) {
                    border: 1px solid themed("themeBoxShadowBorderColor");
                    @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                }

                @include tablet-large {
                    margin: 10px;
                }

                + div {
                    @include themify($themes) {
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }
                }

                .item,
                .item:hover {
                    background-color: white;
                }

                .item {
                    border: none;

                    > div {
                        @include themify($themes) {
                            border-bottom: 1px solid themed("themeLighterDirtyColor");
                        }
                    }
                }

                @include tablet-and-mobile {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .expanded-details .rebate-sidebar-list-item {
                padding: 8px 13px 8px 55px;
                @include flex-wrap(wrap);

                > div {
                    margin: 5px 0;
                    padding-right: 15px;
                    @include calc(width, "100% / 3");

                    .item-field {
                        font-size: em(10);

                        .font-size-large & {
                            font-size: em(12);
                        }
                    }

                    &.Cancelled .item-value {
                        color: $red;
                    }

                    &.Approved .item-value {
                        color: $green;
                    }
                }
            }

            .rebate-sidebar-list-item {
                padding: 13px 0;

                .status-icon {
                    margin-right: 10px;
                    width: 32px;

                    .icon-wrap {
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                    }
                }

                .date-time {
                    padding: 0 10px;
                }

                .amount {
                    text-align: right;
                }
            }

            .item-field {
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }

                .item-label {
                    opacity: 0.9;
                    margin-bottom: 3px;
                }

                .item-value {
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }
        }
    }
}
