@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.expandable-list {
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }

    &.expanded {
        margin: 10px auto;

        .item {
            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
            }
        }
    }

    .item {
        padding: 10px;
        width: 100%;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        &:hover,
        &:focus,
        &.expanded {
            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
            }
        }

        .item-row {
            .item-summary {
                margin-right: 20px;

                .item-description {
                    font-size: 12px;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                    font-weight: 700;

                    .font-size-large & {
                        font-size: 14px;
                    }
                }
            }

            .item-actions {
                align-items: center;
            }
        }
    }
}
