@import "../../../../../../../assets/sass/variables";
@import "../../../../../../../assets/sass/mixins";
@import "../../../../../../../assets/sass/functions";

.panel-user-management {
    .dropdown-wrap {
        min-width: 200px;
    }

    .role-management-bulk-actions {
        padding: 20px;
        background: white;

        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
    }

    .role-managemet-controls {
        .field-group.inline {
            display: flex;
            align-items: center;

            > label {
                margin: 0 10px;
            }
        }
    }

    .view-placeholder {
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }
    .status-msg {
        line-height: 32px !important;
        &:after,
        &:before {
            position: absolute;
            left: -9px;
            top: 5px;
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
        }
    }
}

.role-management-modal-message {
    &__title {
        text-align: center;
    }

    .associations-list__list {
        max-height: 25vh;
        overflow: auto;
        padding-bottom: 8px;

        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .btn-tag {
            border: none;
            text-align: left;
            display: block;
            padding-left: 0;
        }
    }
}

.role-management-sidenav {
    .scrollable-sidenav-header-part {
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .role-management-sidenav-header-top {
            padding: 15px 20px;
        }

        .associations-list {
            overflow: hidden;
            display: flex;
            flex-direction: column;

            &__header {
                padding: 5px 20px;
            }

            &__list {
                padding: 0 20px 8px 20px;
                overflow: auto;

                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }

                .btn-tag {
                    border: none;
                    text-align: left;
                    display: block;
                    padding-left: 0;
                }
            }
        }
    }
}
