@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

$tab-width-desktop: 160px;
$tab-width-mobile: 160px;

.tab-home {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 43px;
    height: 43px;
    cursor: pointer;

    body:not(.theme-sightline):not(.theme-sightlineHighContrast) & {
        background: url("../../../../assets/img/Vision-Icon-White.svg");
    }

    body.theme-mode-light.theme-default & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Default.svg");
    }

    body.theme-mode-light.theme-darkSky & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-DarkSky.svg");
    }

    body.theme-mode-light.theme-blueSky & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-BlueSky.svg");
    }

    body.theme-mode-light.theme-lightSeaGreen & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-LightSeaGreen.svg");
    }

    body.theme-mode-light.theme-valentine & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Valentine.svg");
    }

    body.theme-mode-light.theme-violets & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Violets.svg");
    }

    body.theme-mode-light.theme-olive & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Olive.svg");
    }

    body.theme-mode-light.theme-earth & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Earth.svg");
    }

    body.theme-mode-light.theme-sunset & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Sunset.svg");
    }

    body.theme-mode-light.theme-nature & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Nature.svg");
    }

    body.theme-mode-light.theme-dusk & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-Dusk.svg");
    }

    body.theme-mode-light.theme-northemLights & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-NorthernLights.svg");
    }

    body.theme-mode-light.theme-eveningShades & {
        background: url("../../../../assets/img/theme-light-mode/Vision-Icon-EveningShades.svg");
    }

    body:not(.theme-sightline):not(.theme-sightlineHighContrast) &,
    .theme-mode-light.theme-default &,
    .theme-mode-light.theme-darkSky &,
    .theme-mode-light.theme-lightSeaGreen &,
    .theme-mode-light.theme-valentine &,
    .theme-mode-light.theme-violets &,
    .theme-mode-light.theme-olive &,
    .theme-mode-light.theme-earth &,
    .theme-mode-light.theme-sunset &,
    .theme-mode-light.theme-nature &,
    .theme-mode-light.theme-dusk &,
    .theme-mode-light.theme-northemLights &,
    .theme-mode-light.theme-eveningShades & {
        background-size: auto 32px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background: url("../../../../assets/img/icf-logo.png") no-repeat center / 28px;
        background-size: auto 28px;
    }

    @include tablet-large {
        z-index: 4;
        left: 40px;
        width: 54px;
        height: 54px;

        .layout-left & {
            left: 20px;
        }

        .split-view & {
            left: 30px;
        }

        body:not(.theme-sightline):not(.theme-sightlineHighContrast) &,
        .theme-mode-light.theme-default &,
        .theme-mode-light.theme-darkSky &,
        .theme-mode-light.theme-lightSeaGreen &,
        .theme-mode-light.theme-valentine &,
        .theme-mode-light.theme-violets &,
        .theme-mode-light.theme-olive &,
        .theme-mode-light.theme-earth &,
        .theme-mode-light.theme-sunset &,
        .theme-mode-light.theme-nature &,
        .theme-mode-light.theme-dusk &,
        .theme-mode-light.theme-northemLights &,
        .theme-mode-light.theme-eveningShades & {
            background-size: auto 40px;
            background-position: center left;
            background-repeat: no-repeat;
        }

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: url("../../../../assets/img/icf-logo.png") no-repeat left -5px center;
            background-size: auto 36px;
        }
    }

    @include desktop {
        left: 50px;
    }

    @include desktop-large {
        .layout-full-screen & {
            left: 30px;
        }

        .layout-full-screen .layout-left & {
            left: 20px;
        }
    }
}

.window-tab {
    display: inline-block;
    vertical-align: bottom;
    margin: 10px 10px 0 0;
    width: $tab-width-desktop;
    height: 44px;
    background-color: white;
    white-space: normal;
    cursor: pointer;

    .theme-mode-light & {
        @include border-radius(6px 6px 0 0);
    }

    @include tablet-and-mobile {
        position: relative;
        margin: 4px 1px 0 0;
        width: $tab-width-mobile;
        height: 39px;

        .theme-mode-light & {
            margin-right: 5px;
        }
    }

    &:first-child {
        @include tablet-and-mobile {
            margin-left: 1px;
        }
    }

    .tab-details {
        z-index: 1;
        @include flexbox;
        align-items: flex-end;
        width: $tab-width-desktop;
        height: 100%;

        .theme-mode-light & {
            @include border-radius(6px 6px 0 0);
        }

        @include tablet-and-mobile {
            width: $tab-width-mobile;
        }

        .tab-content {
            padding: 10px 38px 8px 10px;
            width: 100%;
            line-height: 1.33;

            .font-size-large & {
                padding: 5px 38px 4px 10px;
            }

            @include tablet-and-mobile {
                padding: 7px 10px 5px;
                @include text-overflow;
            }

            .tab-title {
                font-size: em(12);
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }

                .theme-sightlineHighContrast & {
                    font-weight: 500;
                    color: black;
                }

                .font-size-large & {
                    font-size: em(14);
                }

                @include tablet-and-mobile {
                    @include text-overflow;
                }
            }

            .tab-content-item {
                .title {
                    font-size: em(10);
                    text-transform: uppercase;
                    line-height: normal;

                    .font-size-large & {
                        font-size: em(12);
                    }

                    .theme-sightlineHighContrast & {
                        font-weight: 500;
                    }
                }

                .value {
                    font-size: em(12);
                    font-weight: 500;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }

                    .theme-sightlineHighContrast & {
                        color: black;
                    }

                    .font-size-large & {
                        font-size: em(14);
                    }

                    @include tablet-and-mobile {
                        @include text-overflow;
                    }
                }

                &:not(:last-child) {
                    display: none;
                    margin-bottom: 10px;
                }
            }
        }

        &.entity-locked {
            .lock-encryption-empty {
                top: -18px;
                left: 6px;
                width: 15px;

                @include tablet-and-mobile {
                    top: -15px;
                }

                &:before {
                    font-size: 12px;

                    .font-size-large & {
                        font-size: 14px;
                    }
                }
            }

            &.tab-right .lock-encryption-empty {
                left: 16px;
            }
        }
    }

    @include tablet-large {
        &.hover:not(.locked) {
            .tab-content:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 29px;
                height: 100%;
                background: rgba(0, 0, 0, 0.07);
                @include box-shadow(inset 1px, 0, 4px, 0 rgba(0, 0, 0, 0.2));

                .theme-mode-light & {
                    @include border-radius(0 6px 0 0);
                }
            }

            .tab-left .tab-content:after {
                display: none;
            }
        }
    }

    .clear-close {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &:not(.active):not(.locked):not(.hover) {
        @include themify($themes) {
            background: themed("themeBottomTabsBackgroundLight");
            border: 1px solid themed("themeBaseColorSemiTransparent12");
            border-bottom: none;
        }

        body:not(.theme-mode-light) & {
            background: white;
            border: none;
        }
    }

    &.locked {
        @include themify($themes) {
            background: themed("themeBaseColorDesaturated");
        }

        .theme-sightlineHighContrast & {
            background: black;
        }

        .tab-content {
            padding-right: 10px;

            .title {
                @include tablet-and-mobile {
                    @include text-overflow;
                }
            }
        }

        @include tablet-large {
            &:not(.hover) .tab-content .title {
                @include text-overflow;
            }
        }
    }

    &.active {
        @include themify($themes) {
            background: themed("themeBottomTabsBackgroundBase");
        }
    }

    &.active,
    &.locked {
        .tab-details {
            .tab-content {
                .tab-title {
                    color: white;
                }

                .tab-content-item {
                    .title {
                        @include themify($themes) {
                            color: themed("themeLighterDirtyColor");
                        }
                    }

                    .value {
                        color: white;
                    }
                }
            }
        }
    }

    &.active,
    &.locked.hover {
        @include tablet-large {
            min-height: 54px;

            .theme-mode-light & {
                min-height: 44px;
            }
        }
    }

    &.active,
    &.hover {
        margin: 0 10px 0 0;

        .theme-mode-light & {
            margin-top: 10px;
        }

        @include tablet-and-mobile {
            margin: 4px 1px 0 0;

            .theme-mode-light & {
                margin: 10px 5px 0 0;
            }
        }

        &:first-child {
            @include tablet-and-mobile {
                margin-left: 1px;
            }
        }

        .tab-details.entity-locked {
            @include themify($themes) {
                background: themed("themeColorLocked");
            }

            .lock-encryption-empty {
                color: white;
            }

            .tab-content .tab-content-item {
                .title {
                    @include themify($themes) {
                        color: themed("themeLighterDirtyColor");
                    }
                }

                .value {
                    color: white;
                }
            }
        }
    }

    @include tablet-large {
        &:not(.hover) {
            .tab-content {
                @include text-overflow;

                .tab-title {
                    @include text-overflow;
                }

                .tab-content-item {
                    .value {
                        @include text-overflow;
                    }
                }
            }
        }

        &.hover {
            &:not(.split-view):not(.locked) .tab-details {
                min-height: 58px;
            }

            .tab-details {
                position: absolute;
                bottom: 0;
                height: auto;
                min-height: 54px;
                @include themify($themes) {
                    background: themed("themeBottomTabsBackgroundBase");
                }

                &.entity-locked {
                    @include themify($themes) {
                        background: themed("themeColorLocked");
                    }

                    .lock-encryption-empty {
                        top: -47px;
                    }
                }

                .tab-content {
                    overflow-wrap: break-word;

                    .tab-title {
                        color: white;
                    }

                    .tab-content-item {
                        display: block;

                        .title {
                            @include themify($themes) {
                                color: themed("themeLighterDirtyColor");
                            }
                        }

                        .value {
                            color: white;
                        }
                    }
                }
            }

            .clear-close {
                display: block;
            }
        }
    }

    &.split-view {
        width: 310px;

        .split-view-details {
            z-index: 1;
            @include flexbox;
            align-items: flex-end;
            height: 100%;
            width: 310px;

            .tab-details {
                bottom: 0;
                width: 50%;
                position: relative;

                .theme-mode-light &.tab-left {
                    @include border-radius(6px 0 0 0);
                }

                .theme-mode-light &.tab-right {
                    @include border-radius(0 6px 0 0);
                }

                &:before {
                    content: "";
                    z-index: 2;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    margin: 0 auto;
                    width: 2px;
                    height: 100%;
                    @include themify($themes) {
                        background: themed("themeDarkColor");
                    }
                }
            }

            .tab-left {
                left: 0;

                &:before {
                    right: -1px;
                }
            }

            .split-view-sync-controls {
                width: 0;
                height: 100%;
                overflow: visible;
                flex-shrink: 0;
                position: relative;

                .icon-wrap-transparent {
                    z-index: 2;
                    display: none;
                    position: absolute;
                    left: -14px;
                    bottom: 8px;
                    margin: 0 auto;
                    @include themify($themes) {
                        background: themed("themeSplitViewSyncControlsBackgroundColor");
                    }
                    @include border-radius(50%);

                    body:not(.theme-mode-light) & {
                        background: white;
                    }
                }
            }

            .tab-right {
                right: 0;

                &:before {
                    left: -1px;
                }

                .tab-content {
                    padding-left: 20px;
                }
            }
        }

        &.active:not(.hover) {
            .split-view-details {
                .split-view-sync-controls {
                    .icon-wrap-transparent {
                        @include themify($themes) {
                            background: themed("themeMiddleColor");
                        }

                        &.locked {
                            &:before {
                                color: white;
                            }

                            &.copy-link {
                                z-index: 3;
                                background: transparent;
                            }

                            &.cut-scissors-filled:before {
                                font-size: 0;
                            }
                        }
                    }
                }
            }

            .split-view-sync-controls.entity-locked:after {
                content: "";
                z-index: 2;
                position: absolute;
                top: 16px;
                right: -14px;
                width: 14px;
                height: 28px;
                @include border-radius(0 50% 50% 0);
                @include themify($themes) {
                    background: themed("themeColorLocked");
                }

                .theme-mode-light & {
                    top: 8px;
                }
            }

            .entity-locked + .split-view-sync-controls.entity-locked:after {
                left: -14px;
                right: inherit;
                width: 14px;
                @include border-radius(50% 0 0 50%);
            }

            .split-view-sync-controls.both-entity-locked .icon-wrap {
                @include themify($themes) {
                    background: themed("themeColorLocked");
                }
            }
        }

        &:not(.hover) {
            .split-view-details {
                .split-view-sync-controls {
                    .icon-wrap-transparent {
                        display: block;

                        &:before {
                            color: transparent;
                        }

                        &.locked {
                            &:before {
                                @include themify($themes) {
                                    color: themed("themeColorBaseGrey");
                                }
                            }

                            &.copy-link {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.active,
        &.hover {
            .split-view-details {
                .split-view-sync-controls {
                    .icon-wrap-transparent {
                        bottom: 10px;
                        @include rotate(-35deg);

                        .theme-mode-light & {
                            bottom: 8px;
                        }

                        &:before {
                            @include rotate(35deg);
                        }
                    }
                }
            }
        }

        &.hover {
            .split-view-details {
                position: absolute;
                bottom: 0;
                height: auto;

                @include tablet-and-mobile {
                    left: auto !important;
                }

                .tab-details {
                    min-height: 58px;
                }

                .split-view-sync-controls {
                    .icon-wrap-transparent {
                        display: block;
                        @include box-shadow(0, 0, 4px, 0 rgba(0, 0, 0, 0.5));

                        @include themify($themes) {
                            border: 1px solid themed("themeLightColor");
                            background: themed("themeMiddleColor");
                        }

                        &.locked {
                            @include themify($themes) {
                                border: 1px solid themed("themeTitleColor");
                            }

                            &.copy-link {
                                display: none;
                            }

                            &.cut-scissors-filled {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
