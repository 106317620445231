@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";
.catalog-sidenav-panel .sidenav-body > .panel .attributes-form .grid-controls {
    padding-right: 0;
}

.attributes-form {
    .add-attribute-button-row {
        position: sticky;
        background: white;
        bottom: 0;
        margin: 0 -20px;
        z-index: 2;
        .add-attribute-button-inner {
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }
            padding: 15px 20px;
        }
    }

    &-items {
        padding: 50px 0 0;

        &-columns {
            position: relative;
            z-index: 1;
            padding: 5px 80px 8px 52px;
            @include box-shadow(0, 2px, 4px, 0 rgba(0, 0, 0, 0.15));
            @include ie {
                z-index: 3;
            }

            &:before {
                content: "";
                position: absolute;
                top: -4px;
                left: 0;
                width: 100%;
                height: 4px;
                background: white;
            }

            > div {
                @include calc(width, "(100% / 5) - 14px");
                font-size: em(12);

                &:not(:last-child) {
                    margin-right: 20px;
                }

                &.checkboxes {
                    margin-left: -15px;
                    width: 165px;
                    min-width: 165px;

                    > div {
                        text-align: center;

                        &:nth-of-type(2) {
                            margin: 0 20px 0 10px;
                        }
                    }
                }
            }
        }

        &--scroll-container {
            padding: 0 20px;
        }

        .drag-handle-icon {
            font-size: 20px;
            text-align: center;
            font-weight: 700;
        }

        .flex-column {
            &.fields {
                > div {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .attribute-name {
            .text-label {
                font-weight: 700;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }

            > .field-group {
                @include flexbox;
                align-items: center;

                label {
                    margin: 0 10px 0 0;
                }
            }

            .dropdown-wrap {
                width: 100%;
            }
        }

        .attribute-fields-part {
            &__default-fields {
                padding: 0 60px 0 32px;

                > div {
                    @include calc(width, "(100% / 5) - 14px");

                    &:not(:last-child) {
                        margin-right: 20px;
                    }

                    &.checkboxes {
                        margin-left: -15px;
                        width: 165px;
                        min-width: 165px;
                        text-align: center;
                    }
                }

                .checkbox-wrap {
                    &:first-child {
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }
                    }

                    &:nth-of-type(2) {
                        margin: 0 20px 0 10px;
                    }

                    label {
                        padding-left: 24px;
                    }
                }
            }

            &__hidden-fields {
                &.visible {
                    margin: 15px 60px 0 32px;
                    padding-top: 20px;
                    @include themify($themes) {
                        border-top: 1px solid themed("themeDirtyColor");
                    }
                }

                > .icon-wrap {
                    position: absolute;
                    right: -2px;
                    top: 18px;
                }
            }

            .json-form {
                height: auto;

                .inline-form.form-columns-5 .schema-container {
                    @include calc(width, "(100% / 5) - 17px");

                    &.data-description-container {
                        @include calc(width, "(100% / 5) * 2.5 - 17px");
                    }

                    &.item-order-container {
                        @include calc(width, "(100% / 5) * 0.5 - 17px");
                    }

                    &.attribute-lookup-values-container {
                        @include calc(width, "(100% / 5) * 2 - 17px");
                    }
                }
            }
        }

        &__item {
            position: relative;
            padding: 15px 0;
            background: white;
            &:not(:nth-last-of-type(2)) {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }

            @include ie {
                min-height: 63px;
            }

            &.new-item {
                padding: 19px 20px;

                > .flex-column {
                    z-index: 1;
                }

                .watermark-new-item {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    overflow: hidden;

                    &:before {
                        content: "new new new new new new new new new new new new new new";
                        position: absolute;
                        top: -3px;
                        left: 4px;
                        white-space: nowrap;
                        font-size: 78px;
                        font-weight: 700;
                        line-height: 1;
                        text-transform: uppercase;
                        @include themify($themes) {
                            color: themed("themeWatermarkColor");
                        }
                    }
                }

                .attribute-fields-part__hidden-fields {
                    &.visible {
                        margin-top: 21px;
                    }

                    > .icon-wrap {
                        top: 36px;
                        @include ie {
                            z-index: 1;
                        }
                    }
                }

                .view-headline-list-text {
                    top: 18px;
                }
            }

            &:not(.new-item) {
                &.selected,
                &:hover {
                    padding: 15px 20px;
                }

                .view-headline-list-text {
                    top: 16px;
                }
            }

            &.selected,
            &:hover,
            &.new-item {
                margin: 0 -20px;
                @include calc(width, "100% + 40px");
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }

                &:not(:first-child) {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: -1px;
                        width: 100%;
                        height: 1px;
                        @include themify($themes) {
                            background: themed("themeDirtyColor");
                        }
                    }
                }

                .attribute-name .text-label {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }

                .view-headline-list-text {
                    left: 11px;
                }

                > .clear-close,
                .attribute-fields-part__hidden-fields > .icon-wrap {
                    right: 18px;
                }
            }

            .numeric-wrap {
                width: 100%;

                .numeric {
                    width: 100% !important;
                }
            }

            .view-headline-list-text,
            .clear-close {
                z-index: 2;
                position: absolute;
            }

            .view-headline-list-text {
                left: -9px;
            }

            .clear-close {
                top: 4px;
                right: -6px;
            }
        }
    }

    .attributes-actions {
        white-space: nowrap;
    }

    .nothing-found-block .icon-wrap:before {
        content: "0";
    }
}
