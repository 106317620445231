@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.program-catalog {
    .tab-list {
        z-index: 1;
    }

    &-tab {
        position: relative;
        margin: -26px -15px 0;
        padding: 36px 15px 0;
        @include calc(width, "100% + 30px");
        @include flexbox;
        @include flex-direction(column);

        @include tablet-only {
            margin: -26px -20px 0;
            padding: 36px 20px 0;
            @include calc(width, "100% + 40px");
        }

        @include tablet-large {
            margin: -26px -25px 0;
            padding: 36px 25px 0;
            @include calc(width, "100% + 50px");
        }

        > .grid-controls {
            z-index: 2;
            position: absolute;
            right: 15px;
            top: 1px;
            margin: 0;

            @include tablet-only {
                right: 20px;
            }

            @include tablet-large {
                right: 25px;
            }
        }

        .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;
        }
    }

    &__associations {
        @include ie {
            margin-bottom: 20px;
        }

        @include firefox {
            margin-bottom: 20px;
        }

        .associations-list {
            margin-bottom: 0;
            padding: 10px 0;

            &:first-child {
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }

            &:last-child {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }

            &__header {
                .icon-wrap {
                    margin-left: 20px;
                }
            }

            &__list {
                padding: 5px 0;

                .btn-tag {
                    word-break: break-word;

                    @include ie {
                        word-wrap: break-word;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
