@import "../../../assets/sass/_variables.scss";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.dashboard {
    width: 100%;

    .window:not(.layout-left) .utility-view &.all-widgets-off,
    .window:not(.layout-left) .program-view &.all-widgets-off {
        .header-view-wrapper {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                bottom: -7px;
                left: 0;
                width: 100%;
                height: 7px;
                background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
            }
        }
    }

    .header-view-wrapper {
        .project-view &,
        .program-view &,
        .utility-view & {
            @include tablet {
                z-index: 22;
            }
        }
    }

    .dashboard-panels-container {
        overflow-y: auto;
        overflow-x: hidden;

        .view-placeholder {
            @include calc(height, "100% - 20px");
            min-height: initial;
        }

        .react-grid-item.react-grid-placeholder {
            @include themify($themes) {
                background: themed("themeLightColor");
            }
        }

        .react-resizable-handle {
            padding: 0;
            width: 20px;
            height: 20px;
            background: none;

            @include tablet-and-mobile {
                left: 0;
                margin: 0 auto;
            }

            &:after {
                width: auto;
                height: auto;
                border: none;

                @include tablet-and-mobile {
                    right: auto;
                    bottom: 8px;
                    width: 24px;
                    height: 3px;
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                }

                @include tablet-large {
                    content: $icon-shevron-small-down;
                    right: -4px;
                    bottom: -5px;
                    @include icon-iconoteka;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                    font-size: em(30);
                }
            }
        }
    }

    @include ie {
        .sidenav-container {
            height: 0;
        }
    }
}
