@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

$grid-list-cell-max-height: 85px;

.grid-list-row {
    @include flexbox;
    position: relative;
    align-items: center;
    width: 100%;
    height: 0;
    min-height: 44px;
    background-color: white;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor");
    }

    .wait-icon-wrap {
        background: white;
        width: 38px;
        height: 28px;
        line-height: 28px;
        flex-shrink: 0;
        border-radius: 8px;
        margin-left: 5px;
        @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
    }

    &.no-data {
        height: auto;

        &:hover {
            background: white !important;
        }
    }

    &:not(.grid-list-row-header) {
        .grid-list-column {
            &:not(.actions):not(.row-select) {
                @include text-overflow;
            }
        }
    }

    &.group-header-row:hover {
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
    }

    &:not(.grid-list-row-header):not(.filter):not(.show-expanded-row):not(.selected):not(.details) {
        .grid-list-column {
            max-height: $grid-list-cell-max-height;

            &.actions {
                z-index: 2;
                display: flex;
                flex-direction: row;

                .icon-wrap {
                    background: white;

                    @include tablet-large {
                        opacity: 0;

                        &.focused {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &:hover {
            @include themify($themes) {
                background: themed("themeHoverGridListRowBackgroundColor");
            }

            .grid-list-column {
                &.actions .icon-wrap {
                    @include tablet-large {
                        opacity: 1;
                    }
                }
            }
        }

        &:nth-of-type(even):not(:hover):not(.is-children):not(.has-children-expanded) {
            background: rgba(255, 255, 255, 0.2);

            .theme-darkSky &,
            .theme-valentine & {
                background: rgba(255, 255, 255, 0.4);
            }

            .theme-violets & {
                background: rgba(255, 255, 255, 0.3);
            }

            .theme-default &,
            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: rgba(255, 255, 255, 0.5);
            }

            .theme-sunset & {
                background: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .grid-list-column {
        padding: 5px 15px;
        width: auto;
        font-size: em(12);
        flex-shrink: 0;
        position: relative;

        .font-size-large & {
            font-size: em(14);
        }

        .grid-list.audit-side-panel-grid {
            padding: 5px 8px;
        }

        &.row-select {
            padding: 0 9px;
        }

        h2,
        h1 {
            font-size: inherit;
            font-family: $base-font-family;
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorBaseGrey");
            }
        }

        &:not(.actions):not(.row-select) {
            line-height: 1.33;
        }

        &.actions {
            position: absolute;
            right: 0;
            top: 0;
            white-space: nowrap;
            text-align: right;

            .wait-icon {
                align-self: center;
            }

            .icon-wrap {
                margin-left: 5px;
            }
        }
    }

    &.expandable {
        cursor: pointer;

        &.expanded {
            z-index: 2;
            padding: 0 12px;
            min-height: 54px;
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }
            border-bottom: none;
            text-transform: uppercase;

            @include mobile-only {
                &:not(.group-header-row) {
                    margin-left: -5px;
                    margin-right: -5px;
                    @include calc(width, "100% + 10px");
                }
            }

            .grid-list-column.actions {
                background: transparent;
            }

            + .show-expanded-row {
                margin-bottom: 10px;

                @include mobile-only {
                    margin-left: -5px;
                    margin-right: -5px;
                    @include calc(width, "100% + 10px");
                }
            }
        }
    }

    &.selected {
        @include themify($themes) {
            background: themed("themeSelectedGridListRowBackgroundColor");
        }
        .grid-list-column {
            max-height: $grid-list-cell-max-height;
        }
    }

    &.details {
        @include expanded-panel;
        height: auto;
        margin-top: 14px;
        margin-bottom: 14px;

        @include tablet {
            @include calc(width, "100% + 20px");
        }

        &.show-expanded-row {
            margin-top: 0;

            &:before {
                content: "";
                z-index: 1;
                position: absolute;
                left: 0;
                top: -11px;
                display: block;
                width: 100%;
                height: 10px;

                @include themify($themes) {
                    background: themed("themeSemiWhiteColor");
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }
        }

        + div {
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }
        }
    }

    &.has-children-expanded,
    &.is-children {
        @include themify($themes) {
            background: themed("themeBaseColorDesaturatedToSemiTransparentLeft");
        }

        &.selected {
            @include themify($themes) {
                background: themed("themeBaseColorDesaturatedToSemiTransparentLeftSelected");
            }
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 5px;
            height: 100%;
            background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.15));
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
            @include rotate(180deg);
        }
    }

    &:not(.has-children) {
        .group-people-empty:before {
            position: relative;
            top: -2px;
            width: 14px;
            @include themify($themes) {
                border: 1px solid themed("themeColorBaseGrey");
            }
            @include border-radius(50%);
            font-size: 9px;
            line-height: 14px;
            font-weight: 700;

            .font-size-large & {
                font-size: 11px;
            }
        }
    }

    &.expanded {
        &:not(.show-expanded-row) {
            display: none;
        }

        &:not(.group-header-row) {
            @include calc(width, "100% + 20px");
            margin: 10px -10px 0 -10px;
        }

        &.show-expanded-row:not(.group-header-row) {
            @include themify($themes) {
                border: 1px solid themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            }
        }

        .grid-list-column {
            &.actions {
                .icon-wrap {
                    opacity: 1;
                }
            }
        }
    }

    &.draggable {
        .drag-handle-icon {
            z-index: 1;
            position: absolute;
            width: 30px;
            font-size: 18px;
            font-weight: 700;
            @include themify($themes) {
                color: themed("themeDirtyColor");
            }

            + .grid-list-scrollable-columns .grid-list-column:first-child {
                padding-left: 38px;
            }
        }
    }

    &:hover .grid-list-resize-handle {
        opacity: 1;
    }
}
