@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.status-mark {
    display: inline-block;
    padding: 0 7px;
    @include border-radius(6px);
    font-size: 11px;
    font-weight: 500;

    .font-size-large & {
        font-size: 13px;
    }

    &:not(.with-line-break) {
        height: 18px;
        white-space: nowrap;
        line-height: 19px;
    }

    &.with-line-break {
        padding: 2px 7px;
    }

    &.status-mark-small {
        @include status-mark-small;
        text-transform: uppercase;
    }

    &.status-success-empty {
        background-color: $green-medium-light;
        color: $green;
    }

    &.status-success-filled {
        background-color: $green;
        color: white;
    }

    &.status-error {
        background-color: $red-light;
        color: $red;
    }

    &.status-warning {
        background-color: $warning-light;
        color: $warning;
    }

    &.status-warning {
        background-color: $warning-light;
        color: $warning;
    }

    &.status-neutral {
        background: rgba(0, 0, 0, 0.05);
    }

    &.status-ghost {
        background: white;
        @include themify($themes) {
            border: 1px solid themed("themeBaseColorDesaturatedSemiTransparent");
        }
        line-height: 16px;

        &.status-mark-small {
            line-height: 14px;
        }
    }

    &.status-disabled {
        line-height: 16px;

        @include themify($themes) {
            background: themed("themeMainNavItemSeparatorColor");
            border: 1px solid themed("themeBaseColorDesaturated");
        }

        &.status-mark-small {
            line-height: 14px;
        }
    }

    &.status-child {
        @include themify($themes) {
            background: themed("themeBaseColorLightVersion");
            color: themed("themeBaseColorDarkVersion");
        }
    }

    &.status-parent {
        @include themify($themes) {
            background: themed("themeBaseColorDarkVersion");
        }
        color: white;
    }

    &.status-theme {
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent10");
            color: themed("themeBaseColor");
        }
    }

    &.status-theme-base {
        @include themify($themes) {
            background: themed("themeBaseColor");
        }
        color: white;
    }

    &.status-theme-dark {
        @include themify($themes) {
            background: themed("themeDarkColor");
        }
        color: white;
    }

    &.with-icon {
        &:before {
            vertical-align: middle;
            margin-right: 5px;
            font-size: 14px;
        }
    }
}
