@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.tiles-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 120px;
    grid-gap: 15px;
    padding: 15px 0;
}

.tiles-list-item {
    position: relative;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;

    @include themify($themes) {
        background-color: themed("themeBaseColorDesaturatedSemiTransparent7filled");
        border: 1px solid $widget-list-item-border-color;
        color: themed("themeBaseColorDesaturatedSemiTransparent70");
    }

    &.selected {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: -6px;
            right: -6px;
            bottom: -6px;
            left: -6px;

            @include themify($themes) {
                border: 1px solid themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                border-radius: 5px;
            }
        }
    }

    .text-label {
        padding: 15px 0;
        margin-top: auto;
        text-align: center;
    }

    .icon-wrap {
        position: absolute;
        top: 5px;
        right: 5px;
        display: none;
    }

    &:hover {
        .icon-wrap {
            display: initial;
        }
    }
}
