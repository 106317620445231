@import "~assets/sass/_variables.scss";
@import "~assets/sass/_mixins.scss";
@import "~assets/sass/_functions.scss";

.panel-header-large {
    margin: 0 0 15px;

    @include tablet {
        &.centered {
            text-align: center;
        }
    }

    @include tablet-only {
        margin-bottom: 20px;

        .utility-view &,
        .program-view & {
            margin-bottom: 14px;
        }
    }

    + .data-grid-controls {
        @include tablet {
            margin-top: -15px;
        }
    }

    @include tablet-large {
        margin-bottom: 24px;

        .utility-view &,
        .program-view & {
            margin-bottom: 14px;
        }
    }

    h2 {
        display: inline-block;
    }

    .panel-header-actions {
        float: right;
    }
}
