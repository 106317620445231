@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.workflow-sidebar-panel {
    .list-body {
        padding: 13px;
    }

    .workflow-step {
        .hc-theme & {
            font-weight: 500;
        }
        padding: 10px 12px 5px;
        @include border-radius(8px);

        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
            border: 1px solid themed("themeBaseColorSemiTransparent20");
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &.workflow-step-dependent {
            border-color: #dedede;
            background: #f2f2f2;

            .theme-darkSky &,
            .theme-violets &,
            .theme-eveningShades &,
            .theme-dusk & {
                background: #eeeeee;
            }

            .task-dates {
                border-color: #dedede;
            }

            .step-type {
                opacity: 0.4;
            }

            .step-name {
                opacity: 0.5;
            }

            .step-date,
            .step-date-and-term,
            .step-assignment {
                opacity: 0.6;
            }
        }
    }

    .status-marks {
        margin-bottom: 6px;

        .status-mark {
            width: 70px;
            text-align: center;

            + .status-mark {
                margin-left: 5px;
            }
        }
    }

    .step-type {
        font-size: em(13);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        .font-size-large & {
            font-size: em(14);
        }
    }

    .step-name {
        margin-top: 3px;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }
    }

    .step-assignment,
    .task-dates {
        .col-label,
        .col-content {
            font-size: em(11);
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            .font-size-large & {
                font-size: em(13);
            }
        }

        .col-label {
            margin-right: 5px;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
            white-space: nowrap;
        }

        .due-date-na .col-label {
            margin-right: 0;
        }
    }

    .task-dates {
        position: relative;
        margin: 9px 0 4px;
        padding: 5px 0;

        @include themify($themes) {
            border-top: 1px solid themed("themeBaseColorSemiTransparent20");
            border-bottom: 1px solid themed("themeBaseColorSemiTransparent20");
        }

        > .icon-wrap {
            position: absolute;
            left: -32px;
            bottom: -3px;
        }

        > .status-mark {
            position: relative;
            margin-right: 10px;
            width: 66px;
            text-align: center;
        }

        .step-created {
            @include flex-direction(column);
            align-items: flex-start;
        }

        .step-term {
            flex: 1;
            @include flex-direction(column);

            span {
                margin-right: 0;
            }
        }

        .col-content-new-line {
            display: inline-block;
            margin-left: 2px;
        }
    }

    .step-date {
        &.due-date-soon {
            .col-content {
                color: $warning;
            }

            &:before {
                background: $warning;
            }
        }

        &.due-date-overdue {
            .col-content {
                color: $red;
            }

            &:before {
                background: $red;
            }
        }

        &.completed:before {
            background: $green;
        }

        &__value {
            white-space: nowrap;
        }

        .status-msg {
            font-size: 12px;
            line-height: initial;

            .font-size-large & {
                font-size: 14px;
            }

            &:after {
                top: -6px;
            }
        }
    }

    .icon-wrap {
        right: -6px;
    }
}
