@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.datepicker-label {
    margin-bottom: 8px;
    font-size: em(12);
    font-weight: 500;

    .font-size-large & {
        font-size: em(14);
    }
}

.react-datepicker-wrapper {
    position: relative;

    &.disabled {
        &:after {
            color: $grey;
        }
    }

    &:not(.error) {
        &.read-only,
        &.focus {
            &:after {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }
    }

    &.focus:after {
        content: $icon-calendar-filled;
    }

    &.error:after {
        color: $red;
    }

    &:after {
        content: $icon-calendar-empty;
        @include icon-iconoteka;
        position: absolute;
        right: 7px;
        top: 0;
        line-height: 32px;
        pointer-events: none;
    }

    input[type="text"].datepicker {
        padding-right: 42px;

        &:not(:disabled):not(:read-only):not(:focus):not(.input-table-filter) {
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }
            @include box-shadow-common($shadow-outer-hover);
        }

        &.error:not(:focus) {
            border-color: $red !important;
            @include box-shadow(0, 1px, 2px, 0 rgba(208, 2, 27, 0.25) !important);
        }
    }
}

.react-datepicker-popper {
    z-index: 121;

    &:not(.react-datepicker-popper--time-only) {
        &[data-placement^="bottom"] {
            @include mobile-only {
                margin-top: 0;
                padding-top: 0;
            }
        }

        @include mobile-only {
            position: fixed !important;
            transform: none !important;
            width: 100%;
            height: 100%;
            background: white;
        }
    }

    &.with-timepicker {
        @include mobile-only {
            padding-right: 100px;
        }

        @media screen and (min-width: 768px) {
            padding-right: 100px;
        }
    }

    &[data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    }
}

.react-datepicker {
    border: none;
    @include border-radius(0);
    font-family: $base-font-family;
    font-weight: 400;
    @include themify($themes) {
        color: themed("themeColorBaseGrey");
    }

    @include mobile-only {
        width: 100%;
        height: 100%;
    }

    @include tablet {
        @include border-radius(4px);

        @include themify($themes) {
            border: 1px solid themed("themeBoxShadowBorderColor");
            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
        }
    }

    .react-datepicker__triangle {
        display: none;
    }
}

.react-datepicker__month-container {
    @include mobile-only {
        width: 100%;
    }
}

.react-datepicker__month {
    margin: 0;
    padding: 5px 0;
}

.react-datepicker__header {
    margin: 0 10px;
    padding-top: 0;
    background: transparent;
    @include border-radius(4px 4px 0 0);
    @include mobile-only {
        margin: 0;
    }
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor");
    }
}

.react-datepicker__current-month {
    margin: 10px 0;
    padding: 10px;
    @include border-radius(4px);
    @include themify($themes) {
        background: themed("themeSemiWhiteColor");
    }
    font-weight: 500;
    @include themify($themes) {
        color: themed("themeColorBaseGrey");
    }

    @include mobile-only {
        margin-bottom: 20px;
        margin: 0;
        margin-top: 50px;
    }
}

.react-datepicker__navigation {
    width: 40px;
    height: 40px;
    border: none;
    @include border-radius(0 4px 4px 0);
    line-height: 40px;
    text-indent: inherit;
    font-size: 0;
    top: 10px;
    display: inline-block;

    &:hover {
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
        }
    }

    &:before {
        content: $icon-shevron-right-keyboard-arrow-right;
        @include icon-iconoteka;
        @include themify($themes) {
            color: themed("themeBaseColor");
        }
    }

    &.react-datepicker__navigation--previous {
        @include border-radius(4px 0 0 4px);
        left: 10px;

        &:before {
            @include rotate(180deg);
        }
    }

    &.react-datepicker__navigation--next {
        right: 10px;
    }

    .react-datepicker__navigation-icon {
        display: none;
    }

    @include mobile-only {
        position: initial;
    }
}

.react-datepicker__day {
    &:not(.react-datepicker__day--today):not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled) {
        &:hover {
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }
    }

    &:hover {
        @include border-radius(4px);
    }

    &--outside-month {
        @include themify($themes) {
            color: themed("themeBaseColorDesaturatedSemiTransparent");
        }
    }

    &--keyboard-selected {
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent10");
        }
        font-weight: 700;
    }
}

.react-datepicker__day-name,
.react-datepicker__day {
    @include themify($themes) {
        color: themed("themeColorBaseGrey");
    }
    @include border-radius(4px);
    width: 40px;
    line-height: 32px;
    font-size: 15px;

    @include mobile-only {
        margin: 0;
        @include calc(width, "100% / 7");
        line-height: 40px;
    }
}

.react-datepicker__day-name {
    line-height: inherit;
    font-size: 13px;

    .font-size-large & {
        font-size: 15px;
    }
}

.react-datepicker__day--today:not(.react-datepicker__day--selected) {
    @include themify($themes) {
        background: themed("themeSemiWhiteColor");
        color: themed("themeBaseColor");
    }
}

.react-datepicker__day--selected {
    @include themify($themes) {
        background: themed("themeBaseColor");
    }
    font-weight: 700;
    color: white;
}

.react-datepicker__day--disabled {
    @include themify($themes) {
        color: themed("themeLighterDirtyColor");
    }
}

.react-datepicker__day-names {
    padding: 0 0 5px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;

    .font-size-large & {
        font-size: 15px;
    }
}

.react-datepicker__today-button {
    margin: 0 10px;
    padding: 15px 0;
    background: transparent;
    font-weight: 500;

    @include themify($themes) {
        border-top: 1px solid themed("themeLighterDirtyColor");
        color: themed("themeBaseColor");
    }
}

.react-datepicker__input-time-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
}
.react-datepicker__mobile-header {
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 50px 0 10px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-weight: 500;
    font-size: initial;
    @include themify($themes) {
        background: themed("themeSemiWhiteColor");
        border-bottom: 1px solid themed("themeDirtyColor");
    }

    .icon-wrap {
        position: absolute;
        right: 4px;
        @include calc(top, "50% - 13px");
    }
}
.react-datepicker__time-container {
    right: -100px;
    top: -1px;
    bottom: -1px;
    display: flex;
    flex-direction: column;
    width: 100px;
    @include border-radius(0 4px 4px 0);

    @include tablet {
        @include themify($themes) {
            border: 1px solid themed("themeBoxShadowBorderColor");
            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
        }

        &:before {
            left: -10px;
            width: 16px;
            background: white;
        }

        &:after {
            left: 0;
            width: 1px;
            @include tablet {
                @include themify($themes) {
                    background: themed("themeDirtyColor");
                }
            }
        }
    }
    @include mobile-only {
        position: absolute;
        top: 50px;
    }

    .react-datepicker__header--time {
        margin: 0;
        padding: 10px 10px 0;
        background: white;
        border: none;

        .react-datepicker-time__header {
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }
            @include border-radius(4px);
            padding: 10px 0;
            @include themify($themes) {
                color: themed("themeColorBaseGrey");
            }
        }
        @include mobile-only {
            padding: 0 !important;
        }
    }

    .react-datepicker__time {
        flex-grow: 1;
        overflow: auto;

        .react-datepicker__time-list {
            height: auto !important;
            overflow-y: initial !important;

            &-item {
                height: auto !important;
                line-height: 25px;

                &:hover {
                    @include themify($themes) {
                        background: themed("themeWhiteColor") !important;
                    }
                    font-weight: 500;
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }

            &-item--selected {
                @include themify($themes) {
                    background-color: themed("themeBaseColor") !important;
                }

                &:hover {
                    @include themify($themes) {
                        background-color: themed("themeBaseColor") !important;
                    }
                }
            }
        }
    }
}

.react-datepicker__time-container--with-today-button {
    @include mobile-only {
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
            border-top: none;
        }
    }
}

.react-datepicker.react-datepicker--time-only {
    border: 0;
    display: block;

    .react-datepicker__time-container {
        right: 0;
        bottom: unset;
        max-height: 260px;
        border-radius: 4px;
        top: 0;
        @include themify($themes) {
            border: 1px solid themed("themeBoxShadowBorderColor");
            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
        }
    }

    .react-datepicker__time-container {
        &:before,
        &::after {
            display: none;
        }
    }
}
