@import "../../../../../../assets/sass/mixins";

.application-document-queue {
    &__dq-grid-program {
        font-size: 13px;
        text-align: left;

        .font-size-large & {
            font-size: 15px;
        }

        span {
            margin-left: 15px;
            opacity: 0.4;

            .application-document-queue__grid-entry-row-container.expanded & {
                opacity: 1;
            }
        }
    }
}
