.property-list-item--lead-image-url {
    .property-list-item--switch {
        .text-label {
            padding-bottom: 0px;
            padding-right: 10px;
        }

        .property-list-item-inner {
            padding-top: 0px;
        }
    }

    .property-list-item-inner {
        .text-label {
            padding-bottom: 0px;
        }
    }
}
