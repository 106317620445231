@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.scroll-controls {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    pointer-events: none;
    z-index: 20;

    .scroll-control {
        position: absolute;
        top: 0;
        width: 36px;
        height: 100%;
        pointer-events: initial;

        &.left {
            left: -30px;
        }

        &.right {
            right: -30px;
        }

        .icon-wrap {
            z-index: 2;
            position: absolute;
            top: 8px;
            left: 0;

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            &.icon-wrap-right {
                right: 0;
                left: auto;
            }
        }
    }
}
