@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.global-search__main-criteria-list {
    margin-bottom: 20px;

    .select-box {
        margin-bottom: 7px;
    }
}
