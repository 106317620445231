@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.breadcrumb-item {
    font-size: em(14);

    &:not(:last-child) {
        cursor: pointer;

        @include themify($themes) {
            color: themed("themeBaseColor");
        }
    }

    .icon-wrap {
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }

        + .icon-wrap {
            margin-left: -6px;
        }
    }
}
