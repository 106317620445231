@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.filter-container {
    button {
        border: none;
        background: transparent;
        cursor: pointer;
    }

    .filter-navigation {
        width: 50%;
        &.isVariableSelected {
            &.hasDropdownOpen {
                .filter-column:not(.isDropdownOpen) {
                    pointer-events: none;
                }
            }

            .filter-column {
                &:hover,
                &.isDropdownOpen {
                    &:not(.variable-filter) {
                        width: 50%;
                    }
                }
            }
        }

        .filter-column {
            width: 33%;

            @include themify($themes) {
                border-left: 4px solid themed("themeLighterDirtyColor");
            }

            .filter-header {
                height: 26px;
                font-weight: 500;
                &:not(.isActiveColum, .not-empty) {
                    font-size: 14px;
                    @include themify($themes) {
                        color: themed("themeBaseColorDesaturatedSemiTransparent70");
                    }
                }
                &.isActiveColum {
                    .column-title {
                        font-size: 18px !important;
                    }
                }
            }

            .filter-add-button {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }

                &:not(:disabled) {
                    cursor: pointer;
                    &.active,
                    &:hover,
                    &:focus {
                        @include themify($themes) {
                            background-color: themed("themeWhiteColor") !important;
                        }
                    }
                }

                &:disabled {
                    @include themify($themes) {
                        color: themed("themeBaseColorDesaturatedSemiTransparent70");
                    }
                }
                border: none;
            }

            .filter-list {
                .filter-item {
                    cursor: pointer;
                    min-height: 45px;
                    padding: 0.25rem 0.25rem 0.2rem 0;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }

                    .item-icon {
                        padding: 0.2rem;
                        width: 14px;
                        height: 14px;
                    }

                    .dropdown-button {
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                            background-color: themed("themeLighterDirtyColor") !important;
                        }
                    }
                }
            }
        }
    }
}

.list-item-dropdown {
    .dropdown-menu {
        width: 162px;
        button {
            border: none;
            background: transparent;
            cursor: pointer;
            font-size: 16px;
        }
    }
}

.workcenter-filter-dropdown {
    .dropdown-menu {
        width: 278px !important;
        min-width: 0 !important;

        .dropdown-filter-list {
            max-height: 300px;
            min-height: 40px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0.5rem 0;
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .dropdown-list {
            box-shadow: none !important;
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .dropdown-search {
            input {
                border: none !important;
                box-shadow: none !important;
                height: 24px;
                padding: 0;
            }

            .clear-search-btn {
                border: none;
                cursor: pointer;
                background: transparent;
            }
        }

        .menu-title {
            font-size: 12px;
            font-weight: 500;
            padding: 0.6rem;
            @include themify($themes) {
                color: themed("themeSubMenuTextColor");
            }
        }

        .dropdown-item {
            cursor: pointer;
            border: none;
            height: 30px !important;
            line-height: 2 !important;
            &:hover,
            &:focus,
            &:active {
                @include themify($themes) {
                    background-color: themed("themeWhiteColor") !important;
                }
            }
        }
    }
}

.variable-filter {
    .filter-list {
        .item-icon {
            @include themify($themes) {
                background-color: themed("themeMiddleColor") !important;
            }
        }
    }
}
