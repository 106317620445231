@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.upload-file-area {
    padding: 15px;
    @include border-radius(8px);
    @include themify($themes) {
        border: 2px dashed themed("themeBaseColor");
    }

    @include ie {
        overflow: auto;
    }

    .dropzone-text {
        @include flexbox;

        .icon-wrap {
            height: 18px;
            line-height: 18px;
            align-self: center;

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        > span {
            font-size: em(12);
            line-height: 18px;

            .font-size-large & {
                font-size: em(14);
            }
        }

        + .dropzone-file-list {
            margin-top: 10px;
        }
    }

    .upload-link {
        display: inline-block;
        margin-left: 3px;
        cursor: pointer;
        @include themify($themes) {
            color: themed("themeBaseColor");
        }

        input[type="file"] {
            display: none;

            + label {
                display: block;
                cursor: pointer;
            }
        }
    }

    .multiple-upload-text {
        display: none;
    }

    .dropzone-file-list {
        margin-left: 5px;
        max-height: 100px;
        overflow: auto;

        li {
            @include flexbox;
        }

        .status-msg {
            line-height: 18px;
            word-break: break-word;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            @include ie {
                word-wrap: break-word;
                width: 100%;
            }
        }

        .icon-wrap {
            margin: -4px 0 0 3px;
            min-width: 28px;

            &:before {
                font-size: 14px;
            }
        }
    }

    &.file-uploaded:not(.upload-file-multiple) {
        .dropzone-text {
            display: none;
        }

        .dropzone-file-list {
            margin-top: 0;

            li:not(:first-child) {
                display: none;
            }
        }
    }

    &.upload-file-multiple {
        .single-upload-text {
            display: none;
        }

        .multiple-upload-text {
            display: inline-block;
        }
    }

    &.file-uploaded {
        padding-bottom: 8px;

        .dropzone-file-list {
            display: block;
        }
    }

    &.file-upload-disabled {
        border-color: $grey;
        pointer-events: none;

        .dropzone-text {
            > span {
                color: $light-grey;
            }

            .upload-link,
            .icon-wrap:before {
                color: $grey;
            }
        }

        .dropzone-file-list {
            .status-msg,
            .icon-wrap:before {
                color: $light-grey;
            }
        }
    }
}
