@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.bg-grey-200,
.hover\:bg-grey-200:hover,
.focus\:bg-grey-200:focus,
.active\:bg-grey-200:active,
.focus-within\:bg-grey-200:focus-within {
    @include themify($themes) {
        background-color: color-mix(in srgb, var(--color-grey-200) var(--bg-opacity, 100%), transparent) !important;
    }
}

.bg-grey-400,
.hover\:bg-grey-400:hover,
.focus\:bg-grey-400:focus,
.active\:bg-grey-400:active,
.data-\[state\=open\]\:bg-grey-400[data-state="open"] {
    @include themify($themes) {
        background-color: color-mix(in srgb, var(--color-grey-400) var(--bg-opacity, 100%), transparent) !important;
    }
}

.bg-grey-600 {
    @include themify($themes) {
        background-color: themed("themeBaseColorDesaturated") !important;
    }
}

.bg-theme-white {
    @include themify($themes) {
        background: themed("themeWhiteColor") !important;
    }
}

.bg-theme-light {
    @include themify($themes) {
        background-color: themed("themeLightColor") !important;
    }
}

.bg-theme-light-grey {
    @include themify($themes) {
        background-color: themed("themeLighterDirtyColor") !important;
    }
}

.bg-theme-middle {
    @include themify($themes) {
        background-color: themed("themeMiddleColor") !important;
    }
}

.bg-theme-semi-white-diagonal {
    @include themify($themes) {
        background-color: themed("themeSemiWhiteDiagonalColor") !important;
    }
}

.bg-primary {
    @include themify($themes) {
        background-color: themed("themeBaseColor") !important;
    }
}

.bg-primary-light {
    @include themify($themes) {
        background-color: themed("themeBaseColorLightVersion") !important;
    }
}

.bg-primary-hover,
.hover\:bg-primary-hover:hover,
.focus\:bg-primary-hover:focus,
.active\:bg-primary-hover:active {
    @include themify($themes) {
        background-color: themed("themePrimaryButtonBackgroundColorHover") !important;
    }
}

.bg-secondary {
    @include themify($themes) {
        background-color: themed("themeWhiteDiagonalColor") !important;
    }
}

.bg-primary-semi-transparent {
    @include themify($themes) {
        background-color: themed("themeBaseColorSemiTransparent12") !important;
    }
}

.bg-blue,
.hover\:bg-blue:hover,
.focus\:bg-blue:focus,
.active\:bg-blue:active,
.focus-within\:bg-blue:focus-within {
    @include themify($themes) {
        background: themed("themeSelectedGridListRowBackgroundColor") !important;
    }
}

.bg-opacity-50 {
    --bg-opacity: 50%;
}

.bg-opacity-100 {
    --bg-opacity: 100%;
}

.bg-dotted {
    --dot-size: 0.8px;
    --dot-space: 18px;

    background: radial-gradient(circle, var(--color-grey-400) var(--dot-size), transparent var(--dot-size)) center / var(--dot-space)
        var(--dot-space);
}
