@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.custom-list {
    @include tablet-large {
        position: relative;
    }

    .list-header-row {
        padding: 15px 13px 10px;
        font-weight: 500;
        font-size: em(12);
        text-transform: uppercase;
        justify-content: space-between;

        .font-size-large & {
            font-size: em(14);
        }

        .header {
            width: 100%;
        }
    }

    .list-filter {
        padding: 0 13px 10px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }
    }

    .list-item-row {
        display: flex;
        flex-shrink: 0;
        position: relative;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        padding: 0 13px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }
        font-size: em(12);
        cursor: pointer;

        .font-size-large & {
            font-size: em(14);
        }

        &:hover {
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }

            @include tablet-large {
                .show-on-hover {
                    opacity: initial;
                }
            }
        }

        &.selected {
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
        }

        .item-value {
            width: 100%;
            @include text-overflow;
            font-weight: 500;
        }

        @include tablet-large {
            .show-on-hover {
                opacity: 0;

                &:focus {
                    opacity: initial;
                }
            }
        }
    }

    .list-search-headers-row {
        display: flex;
        align-items: center;

        input:not(:focus) {
            &:first-child {
                @include themify($themes) {
                    border-left: 2px solid themed("themeDirtyColor");
                }
            }

            &:last-child {
                @include themify($themes) {
                    border-right: 2px solid themed("themeDirtyColor");
                }
            }
        }
    }

    .not-found-msg {
        padding: 15px;
    }
}
