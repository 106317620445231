@import "../../../assets/sass/_mixins";

.customer-view {
    @include mobile-only {
        .window-body-header {
            z-index: 3;
            position: relative;
        }
    }

    .dashboard-tools {
        position: relative;
        z-index: 1;
    }
}

.edit-customer-details-form {
    .sidenav-body {
        @include firefox {
            height: 0;
        }
    }

    .customer-password-section {
        margin-top: 40px;
    }

    .json-form {
        .object-properties > .schema-container:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
}
