@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.sidenav-container {
    overflow: hidden;
    position: relative;

    &--active {
        z-index: 0; // Fixes issue with hiding bottom tab shadow
    }
}

.sidenav-backdrop {
    z-index: 80;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include themify($themes) {
        background: themed("themeSemiTransparentBackground");
    }

    @include mobile-only {
        display: none;
    }

    &--transparent {
        @include themify($themes) {
            background: none;
        }
    }

    &--no-pointer-events {
        pointer-events: none;
    }
}

.sidenav {
    z-index: 81;
    overflow: auto;
    background: white;
    flex-shrink: 0;
    @include box-shadow-common($shadow-outer);

    @include mobile-only {
        width: 100%;
    }

    @include tablet {
        min-width: 340px;
    }

    &--over {
        position: absolute;
        top: 0;
        bottom: 0;
    }

    &--left {
        left: 0;
    }

    &--right {
        right: 0;
    }

    &--push {
        position: relative;
    }

    &--small {
        @include tablet {
            width: 340px;
        }
    }

    &--medium {
        @include tablet-large {
            max-width: 680px;
        }
    }

    &--static-medium {
        @include tablet-large {
            width: 680px;
        }
    }

    &--large {
        @include tablet-only {
            width: 90%;

            @include ie {
                width: 100%;
            }
        }

        @include tablet-large {
            max-width: 75%;

            @include ie {
                width: 100%;
            }
        }
    }

    &--static-large {
        @include tablet-only {
            width: 90%;
        }

        @include tablet-large {
            width: 75%;
        }
    }

    &--static-xxl {
        @include tablet-large {
            width: 90%;
        }
    }
}

.sidenav-header {
    position: relative;
    padding: 15px 20px;

    &__title {
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
    }

    &__lead {
        .clear-close {
            position: absolute;
            top: 5px;
            right: 5px;

            &:hover {
                background: transparent !important;
            }
        }
        .sub-title {
            padding-bottom: 13px;
        }
    }

    b {
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }
    }

    &--with-icon {
        @include themify($themes) {
            background: themed("themeSemiWhiteDiagonalColor");
        }

        .add-user-add-identity-add-person-empty:before {
            font-size: 24px;
        }

        &__title {
            margin-top: 10px;
        }
    }

    &--error {
        background: $red-gradient-to-bottom;
    }

    &--small {
        padding: 10px 50px 10px 15px;
        text-align: left;

        &__title {
            margin: 0 0 0 10px;
        }
    }

    &--left-aligned {
        position: relative;
        padding: 0;
        width: 250px;
        @include flexbox;
        @include flex-direction(column);
        flex-shrink: 0;
        @include themify($themes) {
            background: themed("themeSemiWhiteDiagonalColor");
        }
        @include box-shadow(inset -3px, 0, 3px, 1px rgba(0, 0, 0, 0.1));

        .scrollable-sidenav-header-part {
            padding: 15px 20px;
        }

        &__lead {
            padding: 15px 20px 0 20px;
            @include themify($themes) {
                border-bottom: 1px dashed themed("themeLighterDirtyColor");
            }

            .clear-close {
                position: absolute;
                top: 5px;
                left: 5px;

                &:hover {
                    background: transparent !important;
                }
            }
        }

        &__title {
            @include flexbox;
            @include flex-direction(column);
            margin-bottom: 20px;
            font-size: 17px;
            text-transform: none;
            text-align: center;
            @include themify($themes) {
                color: themed("themeTitleColor");
            }

            .icon-wrap {
                margin-bottom: 5px;
            }
        }
    }
}

.sidenav-body {
    padding: 20px;

    &--no-padding {
        padding: 0;
    }

    &.flex-one {
        @include ie {
            flex: 1 0 auto;
            height: 0;
        }
    }
}

.sidenav-footer {
    z-index: 22;
    position: relative;
    padding: 15px 20px;
    @include box-shadow-common($shadow-outer-center);

    &--no-padding {
        padding: 0;
    }

    .btn-light-blue {
        margin-left: 17px;
    }

    &.set-primary-button {
        > .btn-blue {
            flex: 1;

            @include ie {
                flex: 1 0 auto;
            }
        }
    }
    &.set-primary-button.justify-end {
        > .btn-blue {
            flex: 0;

            @include ie {
                flex: 1 0 auto;
            }
        }
    }
}
