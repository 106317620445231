@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.file-select-property {
    .background-switcher {
        padding: 5px 0 5px 0;
        margin: 0 20px;
        border-bottom: 1px solid $grey;

        .content-label {
            font-size: 12px;
            width: 150px;
            text-transform: uppercase;
        }
    }

    .file-tiles-container {
        padding: 0 15px 15px 0;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
        min-height: 0;
        overflow: auto;

        &.dark-background {
            .file-tile {
                @include themify($themes) {
                    background-color: themed("themeColorDarkGrey");
                }
            }
        }

        .file-tile {
            width: calc(50% - 20px);
            height: 150px;
            margin-left: 20px;
            margin-top: 15px;
            position: relative;
            border-radius: 5px;
            border: 1px solid $white-grey-dark;
            background-color: $white-light-grey;
            padding-bottom: 20px;
            cursor: pointer;

            &.disabled {
                cursor: not-allowed;

                .file-preview-container {
                    position: absolute;
                    top: 25px;
                    left: 50px;
                }

                .document-icon {
                    border: 6px solid $light-grey;

                    span {
                        background: $light-grey;
                    }
                }
            }

            .file-select-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .file-name {
                position: absolute;
                bottom: 5px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                font-weight: 600;
                color: $light-grey;
                font-size: 12px;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                padding: 0 15px;
            }

            .file-preview-container {
                height: 100px;
                margin: 10px 0;
            }
        }
    }

    .file-upload-form {
        .json-form {
            padding-top: 15px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .separator--line {
            width: auto;
            margin: 0 20px;
        }
    }

    .rjsf {
        .action-buttons {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}

.selected-file-label {
    font-size: 13px;
    margin-top: 10px;
}

.selected-file-tile {
    height: 70px;
    margin: 15px 0 15px 0;
    position: relative;
    border-radius: 5px;
    border: 1px solid $white-grey-dark;
    background-color: $white-light-grey;
    padding-bottom: 10px;

    .file-preview-container {
        .file-viewer {
            height: 50px;
            width: 100px;
            margin-top: 10px;
        }
        .document-icon {
            position: relative;
            top: 0px;
            margin-right: 10px;
        }
    }

    .file-name {
        font-size: 14px;
        font-weight: 700;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;

        @include themify($themes) {
            color: themed("themeBaseColorDesaturatedSemiTransparent");
        }
    }

    .close-circle.icon-wrap {
        position: absolute;
        top: 0;
        right: 0;
        height: 0;
        width: 5px;
    }
}

.file-select-property-button {
    min-height: 50px;
    text-transform: uppercase;
    cursor: default;
    font-size: 12px;

    label {
        cursor: pointer;
    }

    @include themify($themes) {
        color: themed("themeBaseColor");
    }
}
