@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";

.calculation-details-panel {
    .info-list-row {
        &.calc-name {
            word-break: break-word;

            @include ie {
                word-wrap: break-word;
            }
        }
    }
}
