@import "../../../../../../assets/sass/mixins";

.application-document-queue {
    &__batch-grid-item {
        width: 100%;
        overflow: hidden;

        &-field {
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }

            &--user {
                margin-left: 15px;
                font-size: em(10);

                .font-size-large & {
                    font-size: em(12);
                }

                @include tablet {
                    margin-left: 30px;
                }

                span {
                    display: block;
                    font-size: 10px;
                    opacity: 0.6;

                    .font-size-large & {
                        font-size: 12px;
                    }

                    @include tablet {
                        font-size: 11px;

                        .font-size-large & {
                            font-size: 13px;
                        }
                    }
                }
            }

            &--archive {
                margin-left: 30px;

                @include mobile-only {
                    margin-left: 10px;
                    width: 70px;
                    white-space: normal;
                    font-size: 11px;
                    line-height: 1.2;
                    text-align: right;

                    .font-size-large & {
                        font-size: 13px;
                    }

                    &:not(.icon-after) {
                        padding: 0 28px 0 0;

                        &:before {
                            top: -2px;
                            left: initial;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
