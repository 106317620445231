@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.app-processing-workcenter {
    padding-bottom: 10px;

    @include tablet {
        padding: 30px 0;
    }

    > .main-grid-wrap {
        @include tablet-and-mobile {
            position: relative;
        }

        > .flex-row {
            @include mobile-only {
                @include flex-direction(column);
            }
        }

        .split-view & {
            position: relative;
        }
    }

    .show-hide-filter-panel {
        @include mobile-only {
            @include flex-direction(row);
            left: -10px;
            margin-bottom: 15px;
            padding: 5px 4px 8px;
            @include calc(width, "100% + 20px");

            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
                color: themed("themeBaseColor");
            }

            body:not(.theme-mode-light) & {
                @include box-shadow(0, 1px, 8px, 0 rgba(0, 0, 0, 0.15));
                border-bottom: none;
            }

            .theme-mode-light & {
                padding: 8px 4px;
            }
        }

        @include desktop-small {
            position: relative;
            background: white;

            .icon-wrap {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        @include tablet-and-desktop-small {
            left: -20px;
            padding: 10px 0;
            width: 40px;
            @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.15));

            &.filter-hidden {
                @include border-radius(0 8px 8px 0);
            }

            &:not(.filter-hidden) {
                z-index: 3;
            }

            @include tablet-large {
                left: -40px;
            }
        }

        .split-view & {
            left: -30px;
        }

        span {
            @include desktop-small {
                white-space: nowrap;
                font-size: em(12);
                font-weight: 700;
                text-transform: uppercase;

                .font-size-large & {
                    font-size: em(14);
                }
            }

            @include tablet-and-desktop-small {
                @include rotate(-90deg);
            }
        }
    }

    .filter-block-wrap {
        @include mobile-only {
            position: fixed;

            &:not(.filter-block-hidden) {
                left: 0;

                .filter-block {
                    max-width: 348px;
                }
            }
        }

        @include desktop-small {
            z-index: -1;
            top: 0;
            left: 0;
            height: 100%;

            &:not(.filter-block-hidden) {
                z-index: 3;
                @include tablet-and-mobile {
                    @include semi-transparent-background;
                }
            }

            &.filter-block-hidden .filter-block {
                max-width: 0;
            }
        }

        @include tablet-only {
            position: absolute;

            &:not(.filter-block-hidden) {
                left: 40px;

                .filter-block {
                    max-width: 700px;
                }
            }

            &.filter-block-hidden .filter-block {
                max-width: 0;
            }
        }
    }

    .split-view & {
        .show-hide-filter-panel {
            position: relative;
            padding: 10px 0;
            width: 40px;
            background: white;
            @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.15));

            &:not(.filter-hidden) {
                z-index: 3;
            }

            &.filter-hidden {
                @include border-radius(0 8px 8px 0);
            }

            span {
                @include rotate(-90deg);
                white-space: nowrap;
                font-size: em(12);
                font-weight: 700;
                text-transform: uppercase;

                .font-size-large & {
                    font-size: em(14);
                }
            }

            .icon-wrap {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        .filter-block-wrap {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            &:not(.filter-block-hidden) {
                z-index: 2;
                left: 40px;
                @include semi-transparent-background;

                .filter-block {
                    max-width: 700px;
                }
            }

            &.filter-block-hidden .filter-block {
                max-width: 0;
            }
        }
    }

    .split-view .left & {
        .filter-block-wrap:not(.filter-block-hidden):before {
            width: 50%;
        }
    }

    .split-view .right & {
        .filter-block-wrap:not(.filter-block-hidden):before {
            left: initial;
            right: 0;
            width: 50%;
        }
    }

    .filter-block {
        position: relative;
        margin: 0 30px 0 -18px;

        @include mobile-only {
            padding: 15px 0;

            > .icon-wrap.clear-close {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }

        @include tablet-and-mobile {
            z-index: 2;
            margin: 0;
            background: white;
            @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.15));
            overflow: hidden;
            @include transition(all 0.3s ease);
        }

        @include tablet-only {
            padding: 15px 0 15px 5px;
            @include border-radius(0 8px 8px 0);
        }

        .split-view & {
            z-index: 2;
            margin: 0;
            padding: 15px 0 15px 5px;
            background: white;
            @include box-shadow(1px, 0, 8px, 0 rgba(0, 0, 0, 0.15));
            @include border-radius(0 8px 8px 0);
            overflow: hidden;
            @include transition(all 0.3s ease);
        }

        @include tablet-large-and-desktop-small {
            margin-right: 20px;
        }

        &__static {
            width: 450px;

            @include tablet-large {
                width: 462px;
            }
            @include mobile-only {
                width: 340px;
            }
        }

        .filter-expandable {
            margin-left: -30px;
            width: 30px;

            &:not(.expanded) {
                @include tablet-and-mobile {
                    @include flexbox;
                    align-items: flex-start;
                    justify-content: center;
                    width: 40px;
                }

                @include themify($themes) {
                    border-left: 1px solid themed("themeDirtyColor");
                    border-right: 1px solid themed("themeDirtyColor");
                }

                @include tablet-large-and-desktop-small {
                    right: 0;
                }
            }

            @include tablet-large-and-desktop-small {
                position: absolute;
                top: 0;
                height: 100%;
            }

            &.expanded {
                .program-filters {
                    @include flexbox;
                }

                .saved-filters-content {
                    > .wait-icon {
                        display: block;
                    }
                }
            }

            &.expanded {
                @include mobile-only {
                    @include expanded-panel-mobile;
                }

                @include tablet-only {
                    margin: 0 20px 0 -30px;
                }

                @include tablet {
                    width: 300px;
                }

                .split-view & {
                    margin: 0 20px 0 -30px;
                    width: 300px;
                }

                @include tablet-large-and-desktop-small {
                    z-index: 2;
                    right: -270px;
                    @include semi-transparent-background;
                }
            }

            > .icon-with-label {
                margin-top: 178px;
                @include rotate(-90deg);
                white-space: nowrap;

                &:before {
                    @include rotate(90deg);
                }
            }
        }
    }

    .saved-filters {
        @include calc(max-height, "100% - 232px");

        .add-new-item-panel {
            margin: 0 50px 10px 18px;
        }

        &__title-block {
            margin: 0 50px 10px 18px;

            .title span {
                font-weight: 700;
                font-size: em(18);
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }

            .counter-box {
                margin-right: 7px;
            }

            .filter-marks-wrap {
                margin-left: 20px;

                .status-msg {
                    padding-right: 20px;
                    font-size: 11px;
                    line-height: 14px;

                    .font-size-large & {
                        font-size: 13px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 4px;
                    }

                    &.stars-filled--after:after {
                        top: -6px;
                        right: -8px;
                        font-size: 17px;
                    }
                }
            }
        }

        .saved-filters-list {
            overflow-y: auto;
            overflow-y: overlay;
            padding-right: 30px;
            direction: rtl;

            @include tablet-large-and-desktop-small {
                z-index: 3;
            }

            > div {
                padding: 3px 0 3px 18px;
                direction: ltr;
            }
        }

        .saved-filters-list > div > .icon-with-label {
            @include flexbox;
            align-items: center;
            padding: 0 13px;
            @include calc(width, "100% - 20px");
            height: 36px;
            background: rgba(0, 0, 0, 0.04);
            @include box-shadow(0, 1px, 3px, 0 rgba(0, 0, 0, 0.15));
            @include transition(width 0.3s);
            line-height: 1.1;

            &.active-filter {
                width: 100%;
                background: white;
                @include themify($themes) {
                    border-right: 4px solid themed("themeBaseColor");
                }
                font-weight: 500;

                .icon-wrap.shevron-in-circle-right-empty {
                    @include tablet-large {
                        display: block;
                    }
                }

                @include tablet-and-mobile {
                    @include themify($themes) {
                        background: themed("themeSemiWhiteColor");
                    }
                }

                .split-view & {
                    @include themify($themes) {
                        background: themed("themeSemiWhiteColor");
                    }
                }
            }

            &:not(.stars-filled):before {
                display: none;
            }

            &:not(.active-filter) {
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
            }

            &:not(:last-child) {
                margin-bottom: 7px;
            }

            @include tablet-large {
                &:hover {
                    &:not(.active-filter) {
                        background-color: rgba(0, 0, 0, 0.06);
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                        }
                    }

                    &:before {
                        background: transparent;
                    }
                }
            }

            &:before {
                position: relative;
                top: 1px;
            }

            &.shared-filter {
                padding-right: 26px;
            }

            > .icon-wrap {
                position: absolute;

                &.share-filled-after {
                    right: 4px;
                    top: 4px;
                    width: initial;
                    height: initial;
                    line-height: initial;

                    &:before,
                    &:after {
                        width: 15px;
                    }
                }

                &.shevron-in-circle-right-empty {
                    display: none;
                    right: -34px;
                    top: 4px;
                }
            }
        }
    }

    .filter-second-block {
        max-height: 361px;
        min-height: 232px;

        + .btn-group {
            margin: 30px 50px 0 18px;

            .btn {
                width: 100%;
            }
        }
    }

    .tab-list {
        margin: 0 50px 0 18px;
        @include calc(width, "100% - 48px");
    }

    .tabs-block {
        padding: 20px 20px 0 0;

        &.disabled {
            pointer-events: none;

            .radio-wrap label {
                color: $light-grey;
            }

            input[type="radio"]:checked + label {
                color: $light-grey;

                &:before {
                    color: $light-grey;
                }
            }

            .tab {
                color: $grey;

                &.active {
                    border-color: $light-grey;
                    color: $light-grey;
                }
            }
        }
    }

    .filter-tab {
        margin-top: 10px;
        overflow-y: auto;
        overflow-y: overlay;
        padding-right: 30px;
        direction: rtl;

        @include tablet-large-and-desktop-small {
            z-index: 3;
        }

        > div {
            padding: 3px 0 3px 18px;
            direction: ltr;
        }

        > .field-group,
        .list-of-radio-buttons > .field-group {
            &:hover {
                @include tablet-large {
                    background-color: rgba(0, 0, 0, 0.06);
                }
            }

            &:not(:last-child) {
                margin-bottom: 7px;
            }
        }
    }

    .filter-first-block {
        .program-filters {
            overflow: hidden;
            background: white;
            overflow: hidden;
            display: none;

            @include tablet-large-and-desktop-small {
                position: relative;
                z-index: 2;
            }

            @include tablet {
                @include box-shadow(0, 0, 4px, 0 rgba(0, 0, 0, 0.25));
                @include border-radius(8px);

                &:not(.is-edit):hover {
                    @include box-shadow(0, 0, 8px, 0 rgba(0, 0, 0, 0.25));
                }

                &.is-edit {
                    @include border-radius(8px);

                    @include themify($themes) {
                        border: 1px solid themed("themeBoxShadowBorderColor");
                        @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                    }
                }
            }

            .checkbox-filter {
                padding: 10px;
                @include border-radius(4px 4px 0 0);

                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }

                + .checkboxes {
                    padding: 10px;
                    @include border-radius(0 0 4px 4px);
                    max-height: 150px;
                    overflow-y: auto;

                    @include themify($themes) {
                        background: themed("themeWhiteColor");
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }
                }
            }
        }

        .add-new-filter {
            background: white;

            @include tablet {
                width: 300px;
                @include border-radius(8px);

                @include themify($themes) {
                    border: 1px solid themed("themeBoxShadowBorderColor");
                    @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                }
            }

            .inline-panel-header {
                padding: 15px 40px;
                @include border-radius(8px 8px 0 0);
                text-align: center;

                @include themify($themes) {
                    background: themed("themeSemiWhiteDiagonalColor");
                }

                h3 {
                    font-size: 17px;
                }
            }
        }

        .program-filter {
            position: relative;
            padding: 30px 30px 38px;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }

            @include tablet {
                padding: 30px 25px;
            }

            > .icon-wrap {
                position: absolute;
                top: 5px;
                right: 11px;

                @include tablet {
                    right: 5px;
                }
            }

            .json-form .object-properties .schema-container {
                margin: 0;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        .add-new-item-panel {
            margin: 20px;
            padding: 20px;

            @include tablet {
                margin: 20px 15px;
            }
        }
    }

    .filter-footer {
        z-index: 1;
        padding: 15px;
        @include box-shadow-common($shadow-outer-center);
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }

        .btn {
            display: inline-block;
            width: 50%;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .saved-filters-content {
        > .wait-icon {
            display: none;
            padding: 20px 0 5px;
        }
    }

    .filters-grid {
        &__lead-block {
            @include flex-wrap(wrap);
            padding: 10px 40px;
            @include themify($themes) {
                background: themed("themeSelectedGridListRowBackgroundColor");
            }
            font-size: em(16);
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            &.edit,
            &.icons {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }

            &.icons {
                padding-top: 0;
                text-align: center;

                .icon-wrap {
                    margin: 0 3px;
                }
            }

            @include tablet {
                &:not(.icons) {
                    @include border-radius(8px 8px 0 0);
                }
            }

            span {
                margin-right: 3px;
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
            }

            .icon-wrap {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }

                &.clear-close,
                &.shevron-in-circle-left-filled {
                    position: absolute;
                    right: 3px;
                    top: 3px;

                    @include tablet-only {
                        right: 23px;
                        top: 18px;
                    }

                    .split-view & {
                        right: 23px;
                        top: 18px;
                    }
                }
            }
        }

        &__block {
            padding: 20px;

            @include tablet {
                padding: 20px 15px;
            }

            &:not(:last-child) {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }
        }

        &__column {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            span {
                display: block;
                margin-bottom: 2px;
                font-size: em(12);
                font-weight: 500;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }

        &__filters-list {
            overflow-y: auto;
        }
    }

    .program-filters-list {
        overflow-y: auto;
    }

    .workqueue-controls .field-group,
    .filter-tab > .field-group,
    .filter-tab .list-of-radio-buttons > .field-group {
        background-color: rgba(0, 0, 0, 0.04);
        @include box-shadow(0, 1px, 3px, 0 rgba(0, 0, 0, 0.15));

        .radio-wrap {
            @include flexbox;
            align-items: center;
            height: 36px;

            label {
                @include flexbox;
                align-items: center;
                padding: 0 13px 0 36px;
                width: 100%;
                height: 36px;

                &:before {
                    left: 13px;
                    @include calc(top, "50% - 7px");
                }
            }

            + .field-group {
                margin: 0 0 0 5px;
                padding-right: 2px;
                background-color: transparent;
                flex: 1;

                @include ie {
                    flex: 1 0 auto;
                }

                .dropdown-wrap {
                    @include tablet {
                        min-width: 266px;
                    }
                }
            }
        }

        &.applicaition-tab,
        &.task-tap {
            justify-content: space-between;
            .field-group {
                flex: 0 0 !important;
            }
            .dropdown-wrap {
                @include tablet {
                    width: 272px;
                }
                @include mobile-only {
                    width: 160px;
                }
            }
        }

        &.radio-plus-dropdown {
            @include flexbox;
            @include flex-direction(row);
            align-items: center;

            label {
                padding-right: 5px;
            }

            > .status-msg {
                margin-right: 10px;
            }
        }
    }

    .workqueue-controls {
        .filter-controls-title {
            width: 100%;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }

            span {
                display: block;
            }
        }

        > .flex-row {
            @include mobile-only {
                @include flex-direction(column);
                align-items: flex-start;
            }

            > .btn {
                margin: 5px;
                min-width: 100px;
            }
        }

        .field-group {
            margin: 5px 8px 5px 0;

            @include mobile-only {
                &.radio-plus-dropdown {
                    margin-right: 0;
                }

                .radio-wrap label {
                    padding: 0 8px 0 28px;

                    &:before {
                        left: 7px;
                    }
                }
            }

            &:not(.radio-disabled):hover {
                @include tablet-large {
                    background-color: rgba(0, 0, 0, 0.06);
                }
            }

            &.radio-disabled {
                .radio-wrap + .field-group {
                    cursor: default;
                }
            }
        }

        .dropdown-list__filter .field-group {
            margin: 0;
        }
    }
}

.workqueue-grid {
    width: 100%;
    flex-shrink: 1;

    @include mobile-only {
        flex: 1;
    }

    .grid-controls {
        margin-bottom: 5px;

        @include mobile-only {
            @include flex-wrap(wrap);
            margin: 5px 0;
        }

        @include landscape-view {
            @include flex-wrap(nowrap);
        }

        > .icon-with-label {
            margin-bottom: 5px;
            white-space: nowrap;

            @include mobile-only {
                margin-top: -24px;
            }

            @include landscape-view {
                margin-top: 0;
            }
        }
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    .view-placeholder {
        &--inner-block {
            @include mobile-only {
                padding: 10px;
            }

            @include tablet {
                max-width: 535px;
            }
        }

        span {
            display: block;
            margin-top: 7px;
            font-size: 13px;
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
            cursor: pointer;

            .font-size-large & {
                font-size: 15px;
            }
        }
    }
}
