@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.content-wrap-popup {
    z-index: 21;
    background: white;

    @include mobile-only {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        @include flexbox;
        @include flex-direction(column);
    }

    @include tablet {
        position: absolute;
        @include border-radius(4px);

        @include themify($themes) {
            border: 1px solid themed("themeBoxShadowBorderColor");
            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
        }
    }

    &--mobile-header {
        position: relative;
        padding: 0 50px 0 10px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: left;
        font-weight: 500;
        font-size: 15px;

        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .icon-wrap {
            position: absolute;
            right: 4px;
            @include calc(top, "50% - 13px");
        }

        @include tablet {
            display: none;
        }
    }

    &--content-wrap {
        padding: 20px 15px 15px;

        @include mobile-only {
            text-align: initial;
            flex: 1;

            @include ie {
                flex: 1 0 auto;
            }

            .program-filter {
                height: 100%;
            }
        }

        @include tablet {
            z-index: 2;
            position: relative;
            padding-top: 15px;
        }

        .layout-left & {
            text-align: initial;
        }

        .json-form {
            .control-label {
                font-size: 13px;
                font-weight: 400;
            }

            > .rjsf {
                @include mobile-only {
                    @include flexbox;
                    @include flex-direction(column);
                    height: 100%;

                    > .schema-container {
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }
                    }
                }
            }

            .action-buttons {
                @include mobile-only {
                    padding: 15px;
                    margin: 10px -15px -15px;
                    @include calc(width, "100% + 30px");
                    @include box-shadow-common($shadow-outer-center);
                }

                @include tablet {
                    margin-top: 0;
                }

                .btn {
                    font-size: 14px;

                    &.btn-blue {
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }
                    }

                    @include tablet {
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }
                    }
                }
            }
        }
    }

    &--footer {
        padding: 0 15px 15px;

        @include mobile-only {
            padding: 15px 20px;
            @include box-shadow-common($shadow-outer-center);

            .btn-blue {
                flex: 1;
            }
        }

        @include tablet {
            justify-content: flex-end;
        }
    }
}

.content-wrap-backdrop {
    @include semi-transparent-background;

    &::before {
        z-index: 3;
    }
}
