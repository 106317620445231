@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.add-qc-request-form {
    position: relative;

    @include mobile-only {
        height: 100%;
        @include flexbox;
        @include flex-direction(column);
        overflow: hidden;
    }

    .inline-panel-header {
        @include mobile-only {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
        }

        @include tablet {
            height: 88px;
        }
    }

    .content {
        @include mobile-only {
            flex: 1;
            overflow: auto;
        }

        .form-values {
            @include mobile-only {
                @include flex-wrap(wrap);
            }

            .types-wrapper {
                @include mobile-only {
                    padding: 20px;
                    width: 100%;

                    .types {
                        @include flexbox;
                        @include flex-wrap(wrap);
                    }

                    .list-item-category {
                        @include calc(width, "50% - 5px");
                        @include themify($themes) {
                            border: 1px solid themed("themeLighterDirtyColor");
                        }

                        &:not(.list-item-selected) {
                            padding-right: 20px;

                            &:after {
                                display: none;
                            }
                        }

                        &.list-item-selected:after {
                            content: "";
                            @include calc(top, "50% - 4px");
                            width: 8px;
                            height: 8px;
                            @include border-radius(50%);
                            @include themify($themes) {
                                background: themed("themeBaseColor");
                            }
                        }

                        &:nth-of-type(2n) {
                            margin-left: 10px;
                            @include themify($themes) {
                                border-left: 1px solid themed("themeLighterDirtyColor");
                            }
                        }

                        &:nth-of-type(1 + 2n) {
                            @include themify($themes) {
                                border-left: 1px solid themed("themeLighterDirtyColor");
                            }
                        }

                        &:not(:last-child):not(:nth-last-child(2)) {
                            margin-bottom: 10px;
                        }
                    }
                }

                @include tablet {
                    @include calc(width, "100% / 3");
                }

                .list-item-category {
                    font-weight: 400;
                }
            }

            .rich-editor {
                @include mobile-only {
                    padding: 0 20px 20px;
                }

                .quill {
                    display: flex;
                    flex-direction: column;

                    @include tablet {
                        height: 100%;
                    }
                }
            }
        }
    }

    .flex-two {
        @include mobile-only {
            width: 100%;
        }

        @include tablet {
            flex: 2 1;
        }
    }

    .rich-editor {
        position: relative;
    }

    .inline-panel-footer {
        z-index: 1;
        position: relative;

        @include mobile-only {
            z-index: 22;
            padding: 15px 20px;
        }

        .btn-group {
            @include tablet {
                display: inline-block;
            }

            @include mobile-only {
                .btn.btn-blue {
                    flex: 1;
                }

                .flex-one {
                    display: none;
                }
            }
        }

        > .btn {
            float: right;
        }
    }
}
