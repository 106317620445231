@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";

.panel {
    &.panel-audits,
    &.panel-catalog-categories,
    &.panel-event-categories,
    &.goals-panel,
    &.rebates-panel,
    &.panel-approved-measures,
    &.panel-dashboard-criteria,
    &.budgets-panel,
    &.program-events,
    &.program-content-panel {
        .data-grid-controls,
        .grid-controls {
            margin: -36px 0 12px;
            pointer-events: none;

            .icon-with-label {
                pointer-events: auto;
            }
        }
    }

    &.applications-panel,
    &.program-documents-panel {
        .grid-controls {
            @include mobile-only {
                margin-top: 0;
                justify-content: space-between;

                .flex-one {
                    display: none;
                }
            }

            @include tablet {
                margin: -36px 0 12px;
                pointer-events: none;

                .icon-with-label {
                    pointer-events: auto;
                }
            }
        }
    }

    &.goals-panel,
    &.panel-catalog-categories,
    &.panel-event-categories,
    &.program-documents-panel {
        .grid-list-row.details {
            @include tablet {
                margin-left: 15px;
                margin-right: 15px;
                @include calc(width, "100% - 30px");
            }

            .full-width {
                width: 100%;

                @include mobile-only {
                    @include expanded-panel-mobile;
                }
            }
        }

        .add-new-item {
            position: relative;
            @include expanded-panel;
            margin-top: -5px;

            @include mobile-only {
                @include expanded-panel-mobile;
            }
        }

        .with-form {
            .json-form {
                @include expanded-panel-json-form;

                @include mobile-only {
                    overflow-y: auto;
                    @include calc(height, "100vh - 52px");

                    .form-group {
                        margin-bottom: 0;
                    }
                }

                @include mobile-only {
                    .object-properties {
                        display: block;

                        > .schema-container {
                            @include calc(width, "100% - 20px");

                            &:not(:last-child) {
                                margin-bottom: 20px;
                            }
                        }
                    }

                    .action-buttons .btn {
                        margin-right: 0;

                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    &.panel-catalog-categories,
    &.panel-event-categories {
        .panel-header-large h2 {
            @include mobile-only {
                max-width: 130px;
            }
        }
    }
}

.catalog-sidenav-panel {
    width: 90%;
}

.catalog-sidenav-panel,
.events-sidenav-panel {
    .sidenav-body > .panel {
        position: relative;

        .attributes-actions {
            z-index: 2;
            position: absolute;
            right: 20px;
            top: 5px;

            > .icon-with-label,
            > .grid-controls {
                margin: 10px 0;
            }
        }

        .atrributes-main-block {
            position: relative;
        }

        .grid-controls {
            padding: 0 20px;
        }
    }

    .attributes-form__header {
        padding-top: 50px;
    }

    .atrributes-lead-text {
        position: absolute;
        top: 5px;
        left: 0;
        padding: 10px 20px;
        font-weight: 700;
        white-space: nowrap;

        .counter-box {
            margin-right: 10px;
        }
    }

    .scrollable-sidenav-header-part {
        .json-form {
            height: auto;

            .form-group.field {
                margin-bottom: 15px;

                > span {
                    display: block;
                    margin-top: -3px;
                    font-size: em(12);
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }

                    .font-size-large & {
                        font-size: em(14);
                    }
                }
            }

            .field-radio-group {
                margin-top: -3px;
            }

            .control-label {
                margin-bottom: 5px;
                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent70");
                }
            }

            .dropdown-field {
                @include dropdown-dynamic-height;
            }
        }
    }
}

.events-sidenav-panel {
    .attributes-form-items-columns > div {
        @include calc(width, "100% / 4");
    }

    .attributes-form-items .attribute-fields-part__default-fields > div {
        @include calc(width, "100% / 4");
    }

    .attributes-form-items .attribute-fields-part .json-form .inline-form.form-columns-5 .schema-container {
        @include calc(width, "(100% / 4) - 10px");

        &.data-description-container {
            @include calc(width, "(100% / 4) * 2.5 - 10px");
        }

        &.item-order-container {
            @include calc(width, "(100% / 4) * 0.5 - 10px");
        }

        &.attribute-lookup-values-container {
            @include calc(width, "(100% / 4) * 2 - 10px");
        }
    }
}

.catalog-category-sidenav-panel,
.event-category-sidenav-panel,
.goals-sidenav-panel,
.correspondence-workflow-item-edit-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}

.content-revision-edit-sidenav-panel,
.correspondence-workflow-item-edit-sidenav-panel,
.documents-revision-edit-sidenav-panel {
    .sidenav-footer {
        > .btn-light-blue {
            margin-left: 0;
            margin-right: 17px;
        }
    }
}

.content-add-sidenav-panel,
.content-edit-sidenav-panel,
.content-revision-edit-sidenav-panel,
.correspondence-add-sidenav-panel,
.correspondence-edit-sidenav-panel,
.documents-revision-edit-sidenav-panel {
    .json-form {
        height: auto;

        @include mobile-only {
            .object-properties .schema-container {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}
