@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.assignment-selector {
    &__list-container {
        min-height: 300px;

        @include mobile-only {
            @include calc(min-height, "100vh - 230px");
        }
    }

    &__list {
        overflow: auto;
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }

        &--selected {
            .assignment-selector__list-item {
                text-align: right;

                .icon-wrap {
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    &__list-controls {
        margin-bottom: 5px;
        height: 18px;
    }

    &__list-item {
        align-items: center;
        padding: 5px 8px;
        min-height: 44px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
        font-size: em(12);
        cursor: pointer;
        word-break: break-word;

        .font-size-large & {
            font-size: em(14);
        }

        @include ie {
            word-wrap: break-word;
        }

        .icon-wrap {
            margin-left: 10px;
            min-width: 28px;

            @include tablet-large {
                opacity: 0;
            }
        }

        &:hover {
            @include themify($themes) {
                background: themed("themeWhiteColor");
                color: themed("themeBaseColor");
            }

            .icon-wrap {
                opacity: 1;
            }
        }

        &--disabled {
            background-color: $white-light-grey;
            cursor: default;

            &:hover {
                background-color: $white-light-grey !important;
                @include themify($themes) {
                    color: themed("themeColorBaseGrey") !important;
                }

                .icon-wrap {
                    opacity: 0.5;
                    cursor: no-drop;
                }
            }

            span {
                opacity: 0.5;
            }
        }
    }

    .list-filter-container {
        margin-bottom: 5px;
    }

    .btn-group {
        @include flexbox;
        justify-content: center;
        margin-top: 20px;
    }
}
