.link-color-properties {
    margin: 0 20px;
    .property-list-item--title {
        .property-list-item-inner {
            .text-label {
                line-height: unset;
            }
        }
    }
}
