@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.workflow-tasks-container {
    padding: 20px 20px 26px;
    background-color: white;
    overflow: hidden;

    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
    }

    & + .workflow-tasks-container {
        margin-top: 20px;
    }

    .workflow-tasks {
        width: 100%;

        .expandable-list {
            border: none;
        }

        &:empty {
            margin-top: 0;
        }

        .item {
            position: relative;
            padding: 0;
            @include border-radius(8px);

            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
                border: 1px solid themed("themeBaseColorSemiTransparent20");
            }

            &:not(:last-child):not(.expanded) {
                margin-bottom: 10px;
            }

            &.workflow-task-completed {
                background: $green-light-semi-transparent-60;
                border-color: $green-medium;

                .status-msg {
                    color: $green;
                }
            }

            &.workflow-task-skipped {
                background-color: white;
                @include themify($themes) {
                    border: 1px solid themed("themeLighterDirtyColor");
                }
            }

            &.workflow-task-dependent {
                border-color: #dedede;
                background: #f2f2f2;

                .step-type {
                    opacity: 0.4;
                }

                .step-name {
                    opacity: 0.5;
                }

                .step-date,
                .step-date-and-term,
                .step-assignment {
                    opacity: 0.6;
                }

                .theme-darkSky &,
                .theme-violets &,
                .theme-eveningShades &,
                .theme-dusk & {
                    background: #eeeeee;
                }
            }

            &.workflow-task-read-only {
                cursor: default;
            }
        }

        .expandable-list {
            &.expanded {
                margin: 10px -10px;
                background-color: white;
                @include border-radius(8px);

                @include themify($themes) {
                    border: 1px solid themed("themeBoxShadowBorderColor");
                    @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                }

                &.expanded-workflow-task-completed {
                    border: 1px solid $green;
                    @include box-shadow(0, 0, 8px, 0 $green);
                }

                &.expanded-workflow-task-skipped {
                    border: 1px solid $light-grey;
                    @include box-shadow(0, 0, 8px, 0 $light-grey);
                }

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .item {
                    padding: 0;
                    border-width: 0 0 1px 0;
                    @include border-radius(8px 8px 0 0);

                    .icon-wrap {
                        width: 36px;
                        height: 36px;
                        line-height: 36px;

                        &:before,
                        &:after {
                            font-size: em(24);
                        }
                    }
                }
            }
        }
    }

    &.completed-status {
        position: relative;
        background: white;

        .top-right-corner-style {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-top: 128px solid $green-white;
            border-left: 400px solid transparent;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-left: 400px solid transparent;

            @include tablet {
                z-index: 1;
            }
        }

        &:before {
            border-top: 80px solid $green-light-semi-transparent-35;
        }

        &:after {
            border-top: 32px solid $green-light-semi-transparent-60;
        }
    }
}
