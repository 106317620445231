@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.widget-selection {
    .property-list-item-selected-value {
        .widgets-list-item {
            height: 120px;
            width: 140px;
            margin-left: 20px;

            position: relative;
            text-transform: uppercase;
            border-radius: 4px;

            .text-label {
                padding: 15px 0 !important;
                margin-top: 70px;
            }
        }
    }
}
