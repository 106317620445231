.wait-icon {
    text-align: center;
    align-self: flex-start;
    padding: 0 4px;
    display: flex;
    gap: 5px;
    justify-content: center;

    &.centered {
        align-self: auto;
    }

    > div {
        width: 6px;
        height: 6px;
        background-color: rgba(0, 0, 0, 0.54);
        border-radius: 100% !important;
        display: inline-block;
        animation: sk-bouncedelay 1.7s infinite ease-in-out both;
    }

    .wait {
        &__item1 {
            animation-delay: -0.6s;
        }

        &__item2 {
            animation-delay: -0.4s;
        }

        &__item3 {
            animation-delay: -0.2s;
        }
    }

    &.with-space-around {
        padding: 20px;
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
        opacity: 0;
    }

    40% {
        transform: scale(1);
        opacity: 1;
    }
}
