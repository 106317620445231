@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.lookup-table {
    padding: 20px;

    .grid-list {
        @include data-grid-sticky-header;
        overflow-y: auto;
    }

    .grid-controls {
        margin-top: 0;
    }

    .grid-list-row.details {
        @include mobile-only {
            @include expanded-panel-mobile;
            align-items: flex-start;
        }

        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }
    }
}

.lookup-table,
.add-lookup-item-form {
    .json-form {
        @include expanded-panel-json-form;

        .object-properties {
            .schema-container:not(.fill-width) {
                width: 100%;

                @include tablet {
                    @include calc(width, "(100% / 4) - 20px");
                }
            }
        }
    }
}

.add-lookup-item-form {
    position: relative;
    @include expanded-panel;

    @include mobile-only {
        @include expanded-panel-mobile;
    }

    @include tablet {
        margin: -5px -10px 10px;
        @include calc(width, "100% + 20px");
    }
}
