@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.signature-block-widget {
    gap: 15px;
    word-break: break-all;

    .signature-preview {
        font-family: "Italianno", sans-serif;
        font-size: 36px;
        border-bottom: 2px solid $black;
        line-height: 0.5;
        padding-top: 5px;

        .font-size-large & {
            font-size: 38px;
        }
    }

    .signature-date {
        align-self: flex-end;
        font-size: em(10);

        .font-size-large & {
            font-size: em(12);
        }
    }
}
