@import "../../../../../../assets/sass/_mixins";

.contract-workflow {
    .wf__settings {
        @include calc(max-height, "100vh - 178px");
    }

    .program-wf__management-statuses:not(:last-of-type) {
        margin-bottom: 0;
    }

    .nothing-found-block {
        margin-top: 0;
    }
}
