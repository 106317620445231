@import "../../../../../../assets/sass/_mixins";

.panel.contract-attributes {
    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    .grid-controls {
        margin: -36px 0 12px;
    }
}

.contract-attributes-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
