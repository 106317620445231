@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";

.application-document-queue {
    &__dq-grid-filters-item-batch {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 25px;

        &-size {
            position: relative;
            margin-left: 7px;
            padding-right: 20px;
            text-align: left;
            font-size: 13px;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
            cursor: pointer;

            .font-size-large & {
                font-size: 15px;
            }

            &::before,
            &::after {
                position: absolute;
                content: "";
                right: 2px;
                width: 5px;
                height: 5px;
                @include themify($themes) {
                    border-left: 2px solid themed("themeBaseColor");
                    border-bottom: 2px solid themed("themeBaseColor");
                }
            }

            &::after {
                top: 2px;
                transform: rotate(135deg);
            }

            &::before {
                bottom: 2px;
                transform: rotate(-45deg);
            }
        }

        .content-wrap-popup {
            @include tablet {
                top: 24px;
                left: 0;
            }

            .batch-tooltip-label {
                padding-left: 15px;
                width: 155px;
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }
            }

            &--footer {
                .btn {
                    @include tablet {
                        flex: 1;
                    }

                    &:first-child {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
