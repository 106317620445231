@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.panel {
    &.customer-applications {
        .panel-header-large {
            position: relative;
        }

        .grid-controls {
            margin-top: -7px;

            @include mobile-only {
                justify-content: space-between;

                .flex-one {
                    display: none;
                }
            }

            @include tablet {
                margin-top: -46px;
            }
        }
    }

    &.customer-contacts {
        .customer-contact-list {
            margin-top: -30px;
            padding-bottom: 30px;
        }

        .nothing-found-block {
            height: 100%;

            @include mobile-only {
                margin-top: 10px;
            }
        }

        .panel-header-large {
            position: relative;
        }

        .grid-controls {
            margin: -36px 0 20px;

            @include tablet-large {
                margin: -46px 0 30px;
                padding-left: 113px;

                .flex-one {
                    display: none;
                }
            }
        }

        .contact-panel-item {
            margin-top: 30px !important;
            padding: 30px 20px 15px;
            width: 100%;
            text-align: center;

            @include tablet-only {
                @include calc(width, "(100% / 2) - 10px");

                &:nth-of-type(2n - 1) {
                    margin-right: 20px;
                }
            }

            @include tablet-large {
                @include calc(width, "(100% / 4) - 15px");

                &:hover .actions {
                    opacity: 1;
                }

                &:nth-of-type(4n - 2) {
                    margin: 0 20px;
                }

                &:nth-of-type(4n - 1) {
                    margin-right: 20px;
                }

                .split-view & {
                    @include calc(width, "(100% / 2) - 10px");

                    &:nth-of-type(2n - 1) {
                        margin-right: 20px;
                    }

                    &:nth-of-type(4n - 2),
                    &:nth-of-type(4n - 1) {
                        margin: 0;
                    }
                }
            }

            .actions {
                position: absolute;
                top: 5px;
                right: 5px;

                @include tablet-large {
                    opacity: 0;
                }
            }

            &:before {
                top: -23px;
                left: 0;
                @include box-shadow(inset 0, -3px, 3px, 0 rgba(0, 0, 0, 0.15));
            }

            &:after {
                top: -24px;
                left: initial;
                width: 46px;
                height: 23px;
            }

            > .icon-wrap,
            &:before,
            &:after {
                right: 0;
                left: 0;
                margin: 0 auto;
            }

            > .icon-wrap {
                top: -17px;
                left: 0;
            }

            .email-container {
                position: relative;
                bottom: -15px;
                left: -10px;
                margin-top: -5px;
                padding: 10px 0;
                @include calc(width, "100% + 20px");
            }

            .content-lead .contact-row-item.full-name {
                margin-bottom: 5px;
            }

            .content-body {
                justify-content: space-between;
                width: 100%;
                text-align: left;

                .contact-row-item {
                    &:not(.premise-and-account):not(.email-container) {
                        @include flexbox;
                        @include flex-direction(row);
                        justify-content: space-between;
                        @include themify($themes) {
                            border-top: 1px dashed themed("themeDirtyColor");
                        }

                        &:first-child {
                            margin-top: 20px;
                        }

                        .label span,
                        .title span {
                            position: relative;
                            background: white;
                        }

                        .label,
                        .title {
                            position: relative;
                            top: -13px;
                        }

                        .label {
                            flex: 1;

                            @include ie {
                                flex: 1 0 auto;
                            }

                            &:before {
                                left: 0;
                            }

                            span {
                                margin-right: 20px;
                                padding-right: 5px;
                            }
                        }

                        .title {
                            &:before {
                                left: 0;
                            }

                            span {
                                padding-left: 5px;
                            }
                        }
                    }

                    &:not(.email-container) {
                        margin-top: 10px;

                        .label {
                            font-size: 13px;

                            .font-size-large & {
                                font-size: 15px;
                            }
                        }

                        .title {
                            text-align: right;
                        }
                    }
                }
            }

            .contact-row-item {
                &.premise-and-account {
                    @include flexbox;
                    @include flex-direction(column);

                    > .flex-row {
                        position: relative;
                        justify-content: space-between;
                        @include themify($themes) {
                            border-top: 1px dashed themed("themeDirtyColor");
                        }

                        &:first-child {
                            margin-bottom: 10px;
                        }

                        .identifier {
                            margin-right: 20px;
                            padding-right: 5px;
                            font-size: 13px;

                            .font-size-large & {
                                font-size: 15px;
                            }
                        }

                        > div {
                            position: relative;
                            top: -13px;
                            background: white;

                            &:not(.identifier) {
                                padding-left: 5px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.customer-add-new-contact-form,
.customer-contact-edit-form {
    .json-form {
        .object-properties > .schema-container:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
}
