@font-face {
    font-family: "iconoteka";
    src: url("../fonts/iconoteka/iconoteka.woff2") format("woff2"), url("../fonts/iconoteka/iconoteka.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block; // Render an invisible fallback font face while loading.
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Thin.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Italianno";
    src: url("../fonts/Italianno/Italianno-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
