@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.contractor-details {
    @include mobile-only {
        .json-form {
            height: auto;

            .object-properties .schema-container:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

.contractor-modal {
    .grid-list-row.details {
        @include mobile-only {
            z-index: 3;
        }
    }

    .grid-details-panel-footer {
        z-index: 1;
        padding: 15px 20px;
        @include box-shadow-common($shadow-outer-center);

        @include tablet {
            padding: 18px 20px;
        }

        @include mobile-only {
            .btn-group {
                @include flexbox;

                &:first-child {
                    flex: 1;
                    margin-right: 17px;
                }

                .btn {
                    width: 100%;
                }
            }

            .flex-one {
                display: none;
            }
        }
    }
}
