@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.dashboard-tools {
    @include mobile-only {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;
            @include themify($themes) {
                background: linear-gradient(to top, rgba(0, 0, 0, 0), themed("themeBaseColorDesaturatedSemiTransparent") 130%);
            }
        }
    }

    @include tablet {
        @include box-shadow-common($shadow-outer);
    }

    @include themify($themes) {
        background: themed("themeDashboardToolsBackgroundColor");
        border-top: 1px solid themed("themeLighterDirtyColor");
    }

    body:not(.theme-mode-light) & {
        border-top: none;
    }

    .theme-mode-light & {
        background: white;
    }

    > .main-grid-wrap.responsive {
        @include mobile-only {
            padding: 0;
        }
    }
}
