@import "../../../../assets/sass/_mixins";

.sidebar-icon-group {
    position: absolute;
    right: 5px;
    top: 4px;

    @include tablet-large {
        .icon-wrap {
            opacity: 0;

            &:focus {
                opacity: 1;
            }
        }
    }
}
