@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item--border-radius {
    .property-list > .property-list-item--border-corner {
        margin: 0 20px;
    }

    .property-list > .property-list-item--border-corner-all-sides {
        padding-top: 10px;
    }

    .border-corner {
        padding: 8px 0;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }

        .text-label {
            text-transform: uppercase;
            font-size: 10px;
            width: 50px;
            margin-right: 10px;
        }
    }

    .corner-boxes {
        .portal-builder-properties-overlay-view & {
            max-width: 150px;
        }
    }

    .corner-box {
        position: relative;
        padding: 0;
        height: auto;
        border: none;

        &:not(:last-child) {
            margin-right: 9px;
        }

        .corner-box-border {
            position: absolute;
            width: 100%;
            height: 100%;
            @include themify($themes) {
                border: 2px solid themed("themeColorBaseGrey");
            }
            clip-path: polygon(
                0 0,
                40% 0,
                40% 2px,
                60% 2px,
                60% 0,
                100% 0,
                100% 40%,
                calc(100% - 2px) 40%,
                calc(100% - 2px) 60%,
                100% 60%,
                100% 100%,
                60% 100%,
                60% calc(100% - 2px),
                40% calc(100% - 2px),
                40% 100%,
                0 100%,
                0 60%,
                2px 60%,
                2px 40%,
                0 40%,
                0 0
            );
        }

        &.selected .corner-box-border {
            @include themify($themes) {
                border-color: themed("themeBaseColor");
            }
        }

        &--top-left .corner-box-border {
            clip-path: polygon(0 0, 40% 0, 40% 40%, 0 40%, 0 0);
        }

        &--top-right .corner-box-border {
            clip-path: polygon(60% 0, 60% 40%, 100% 40%, 100% 0);
        }

        &--bottom-right .corner-box-border {
            clip-path: polygon(60% 60%, 100% 60%, 100% 100%, 60% 100%);
        }

        &--bottom-left .corner-box-border {
            clip-path: polygon(0 60%, 40% 60%, 40% 100%, 0 100%);
        }

        &--size-1 .corner-box-border {
            border-radius: 6.25%;
        }

        &--size-2 .corner-box-border {
            border-radius: 12.5%;
        }

        &--size-3 .corner-box-border {
            border-radius: 18.75%;
        }

        &--size-4 .corner-box-border {
            border-radius: 25%;
        }

        &--size-5 .corner-box-border {
            border-radius: 50%;
        }

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        &.selected {
            border: none;

            .corner-box-border {
                border-width: 2px;
                border-style: solid;
            }

            &:after {
                content: "";
                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;

                @include themify($themes) {
                    border: 1px solid themed("themeBoxShadowBorderColor");
                    @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                    border-radius: 5px;
                }
            }
        }
    }
}
