@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.radio-wrap {
    input[type="radio"] {
        display: none;

        &:checked + label {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }

        &:disabled {
            + label {
                @include themify($themes) {
                    color: $light-grey;
                }
                cursor: default;

                &:before {
                    @include themify($themes) {
                        color: $grey;
                    }
                }
            }
        }
    }

    &:hover label:before {
        @include themify($themes) {
            color: themed("themeBaseColor");
        }
    }

    label {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        font-size: em(12);
        line-height: 15px;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
        cursor: pointer;

        .font-size-large & {
            font-size: em(14);
        }

        &:before {
            @include icon-iconoteka;
            position: absolute;
            left: 0;
            top: -1px;
        }
    }
}
