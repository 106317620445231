@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.sticky-bottom-panel {
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 49px;
    background: white;
    width: 100%;
    height: 0;
    @include box-shadow-common($shadow-outer);
    @include transition(all 0.5s ease);

    @include tablet-large {
        bottom: 54px;
    }

    .layout-left & {
        left: 360px;
        @include calc(width, "100% - 360px");
    }

    .layout-left.info-sidebar-hidden & {
        left: 60px;
        @include calc(width, "100% - 60px");
    }

    &:not(.visible) {
        overflow: hidden;
    }

    &.visible {
        height: 62px;
    }

    &-container {
        padding: 15px;
    }

    .btn {
        margin: 0 7px;
    }

    &.app-view {
        position: sticky;
        bottom: 0;
        height: auto;
        margin: 0 -20px -4px;
        padding: 10px 20px;
        @include calc(width, "100% + 40px");
        @include border-radius(0);

        @include firefox {
            flex-shrink: 0;
        }

        @include mobile-only {
            .sticky-bottom-panel__buttons-container {
                justify-content: space-between;
            }
        }

        @include tablet {
            margin: 0 0 -4px;
            width: 100%;
            @include border-radius(8px 8px 0 0);
        }

        .btn.edit {
            margin: 0;
        }

        .form-name {
            padding-right: 15px;

            @include mobile-only {
                flex: 1;
                @include flex-direction(column);
                font-size: em(12);
            }

            span {
                margin-right: 5px;
            }

            b {
                font-weight: 500;
            }
        }

        .sticky-bottom-panel__buttons-container {
            min-height: 42px;
        }

        .layout-left.info-sidebar-hidden & {
            left: 0;
            width: 100%;
        }
    }
}
