@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.grid-list-row.filter {
    border: none;
    background-color: $white-light-grey;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor");
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background-color: $sightline-white-light-grey;
    }

    &.no-columns {
        padding-bottom: 0;
    }

    .grid-list-column {
        @include flexbox;
        margin-bottom: 9px;

        &:not(:last-child) {
            padding-right: 15px;
        }

        &:not(.row-select) {
            padding: 0;

            &:first-child {
                padding-left: 15px;

                input:not(:focus) {
                    @include themify($themes) {
                        border-left: 2px solid themed("themeDirtyColor");
                    }
                }
            }

            &:last-child {
                padding-right: 15px;

                input:not(:focus) {
                    @include themify($themes) {
                        border-right: 2px solid themed("themeDirtyColor");
                    }
                }
            }
        }

        &.actions {
            z-index: 1;
            right: -63px;
            top: -37px;
            margin: 0;
            padding: 40px 0 0 !important;
            align-items: flex-start;

            .no-columns & {
                top: 7px;
            }

            .grid-settings-dropdown {
                position: absolute;
                top: 0;
                right: 0;
            }

            .icon-wrap {
                margin-left: 0;
            }
        }

        // Dropdown in grid filter cell
        .grid-filter-dropdown {
            .dropdown-field:not(.active):not(.dropdown-ghost):not(.disabled):not(.dropdown-read-only):not(.focused) {
                border: none;
                box-shadow: none;
                border-radius: 0;

                @include themify($themes) {
                    border-top: 2px solid themed("themeDirtyColor");
                    border-bottom: 2px solid themed("themeDirtyColor");
                    border-right: 1px solid themed("themeDirtyColor");
                }
            }

            .dropdown-field.active,
            .dropdown-field.focused {
                border-radius: 0;
                @include themify($themes) {
                    border: 2px solid themed("themeLightColor");
                }
            }
        }
    }

    .grid-list-resize-handle {
        right: -3px;
    }

    &:hover .grid-list-resize-handle {
        opacity: 0;
    }

    [x-out-of-boundaries] {
        visibility: hidden;
        pointer-events: none;
    }
}

// Dropdown in grid filter cell popup styles
@include tablet {
    .field-group-dropdown-popup__container.grid-filter-dropdown {
        .dropdown-list-wrap::before {
            content: none;
        }

        .dropdown-list {
            @include themify($themes) {
                box-shadow: none;
                border-radius: 0;
                border: 2px solid themed("themeLightColor");
                border-top: none;
            }
        }

        &[data-popper-placement="top-start"] {
            .dropdown-list {
                @include themify($themes) {
                    border: 2px solid themed("themeLightColor");
                    border-bottom: none;
                }
            }
        }
    }
}
