@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.utility-financials,
.budget-program-assignments {
    &__title {
        z-index: 1;
        margin: 0 10px;
        @include calc(width, "100% - 20px");

        @include tablet-only {
            padding: 0 10px;
            width: 100%;
        }

        @include tablet {
            margin: -31px auto 0;
            position: relative;
            top: 41px;
        }

        .counter-box {
            margin-right: 5px;
        }
    }
}
