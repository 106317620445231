@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.window-header-content.program {
    .project-mark {
        &.child-program,
        &.parent-program {
            position: relative;

            @include mobile-only {
                @include flexbox;
                @include flex-direction(column);
                align-items: flex-end;
                padding: 9px 0;
            }

            @include desktop-extra-large {
                position: absolute;
            }

            .split-view &,
            .layout-left.info-sidebar-hidden &,
            .layout-full-screen .window:not(.layout-left) & {
                position: initial;
            }
        }

        .status-mark {
            margin-bottom: 3px;

            @include tablet {
                vertical-align: middle;
                margin: 0 8px 0 0;
            }

            @include desktop {
                position: absolute;
                @include calc(top, "50% - 9px");
                margin: 0;

                &.status-child {
                    left: -46px;
                }

                &.status-parent {
                    left: -52px;
                }
            }

            @include desktop-extra-large {
                position: initial;
                margin: 0 8px 0 0;
            }

            .split-view &,
            .layout-left &,
            .layout-full-screen & {
                position: initial;
                margin: 0 8px 0 0;
            }
        }
    }
}
