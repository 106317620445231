@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.sidebar-footer {
    padding: 15px;
    @include border-radius(0 0 8px 8px);
    @include themify($themes) {
        border-top: 1px solid themed("themeLighterDirtyColor");
    }
}
