@import "../../../../../../assets/sass/_mixins";

.panel.snapshot {
    .panel-report {
        @include tablet-only {
            @include calc(height, "100vh - 248px");
        }

        @include tablet-large {
            @include calc(height, "100vh - 266px");
        }
    }
}
