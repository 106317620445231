@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.tile-item {
    position: relative;
    margin-bottom: 15px;
    padding: 10px;
    @include calc(width, "(100% / 2) - 8px");
    @include border-radius(10px 0 10px 0);
    @include box-shadow-common($shadow-outer-hover-lighter);
    cursor: pointer;

    @include mobile-only {
        @include border-radius(6px);
        background: white;
        justify-content: flex-start !important;
        text-align: center;

        &:not(:nth-of-type(2n)) {
            margin-right: 15px;
        }
    }

    @include tablet-only {
        @include calc(width, "(100% / 3) - 20px");
    }

    @include tablet {
        margin: 0 10px 20px;
        padding: 3px 10px 10px;
        border: 10px solid white;
        white-space: nowrap;
    }

    @include tablet-large {
        background: rgba(255, 255, 255, 0);
        @include transition(background-color 0.3s ease-out);
        @include calc(width, "(100% / 5) - 20px");

        .layout-left & {
            @include calc(width, "(100% / 4) - 20px");
        }

        .split-view & {
            @include calc(width, "(100% / 3) - 20px");
        }
    }

    &:hover {
        > .icon-wrap {
            &:before {
                font-size: 38px;
            }
        }

        @include tablet {
            .title {
                .icon-wrap {
                    display: block;
                }

                .icon-wrap.shevron-small-right {
                    right: 0;
                }

                &:after {
                    right: 12px;
                    width: 15px;
                }
            }
        }

        @include tablet-large {
            background: rgba(255, 255, 255, 1);
        }
    }

    .title {
        font-weight: 500;

        &.with-action-icon:after {
            display: none;
        }

        span {
            font-size: em(13);

            .font-size-large & {
                font-size: em(14);
            }

            @include tablet {
                font-size: em(16);
            }
        }

        @include mobile-only {
            padding: 0 20px;
        }

        @include tablet {
            display: inline-block;
            margin: -5px 0 10px;
        }

        .icon-wrap {
            position: absolute;
            bottom: 0;
            right: 0;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            @include tablet-large {
                display: none;
            }

            &.shevron-small-right {
                bottom: -1px;
                right: 5px;
                @include transition(all 0.3s ease);

                @include tablet-large {
                    display: block;
                }
            }

            @include tablet {
                &:after {
                    font-size: 24px;
                }
            }
        }

        @include tablet {
            &:after {
                content: "";
                position: absolute;
                right: 17px;
                bottom: 12px;
                width: 10px;
                height: 2px;
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
                @include transition(all 0.3s ease);
            }
        }
    }

    > .icon-wrap {
        display: block;
        margin: 0 auto 10px;
        width: 100%;
        height: 60px;
        @include themify($themes) {
            color: themed("themeBaseColor");
        }
        line-height: 62px;

        @include mobile-only {
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }
            @include border-radius(6px 6px 0 0);
        }

        @include tablet {
            width: 64px;
            height: 64px;
            margin-bottom: 0;
            line-height: 66px;
        }

        &.offer-empty:before {
            position: relative;
            top: -1px;
            @include rotate(-45deg);
        }

        &:before {
            font-size: 30px;
            @include transition(all 0.2s);
        }
    }
}
