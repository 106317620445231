@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";

.rebate-approvals {
    .wait-icon {
        margin-top: 20px;
    }

    >.panel-header-large {
        @include flexbox;
        align-items: flex-end;

        .panel-header-actions {
            float: none;
            flex: 1;
            @include flexbox;
            justify-content: flex-end;

            @include ie {
                flex: 1 0 auto;
            }

            .icon-with-label {
                margin-left: 20px;
            }
        }
    }

    &__items {
        &.with-border-top {
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }

            .rebate-approvals__item:first-child {
                margin-top: 0;
            }
        }

        &.with-border-bottom {
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }
        }

        &-bulk {
            z-index: 1;
            position: fixed;
            left: 0;
            bottom: 54px;
            background: white;
            width: 100%;
            @include box-shadow-common($shadow-outer);

            .layout-left & {
                left: 360px;
                @include calc(width, "100% - 360px");
            }

            .layout-left.info-sidebar-hidden & {
                left: 60px;
                @include calc(width, "100% - 60px");
            }

            &:not(.visible) {
                overflow: hidden;
            }

            &.visible {
                height: 62px;
            }

            &-container {
                padding: 15px;
            }

            .btn-group {
                margin-left: 30px;
            }
        }

        .nothing-found-block {
            margin-top: 15px;
        }

        .sticky-bottom-panel {
            overflow: hidden;

            .main-grid-wrap.responsive {
                width: 100% !important;
            }
        }

        & .sticky-bottom-panel {
            height: 0;
            transform: scaleY(0);
            transform-origin: bottom;
            @include transition(all 0.5s ease);
            border-radius: 8px;

            .bulk-approve-rebate-total .bulk-approve-total-container {
                padding: 0 0 20px 0;
            }
        }

        &--show {
            .sticky-bottom-panel {
                height: auto;
                transform: scaleY(1);
                padding: 23px 0;
            }
        }
    }
}
