@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_functions";
@import "../../../../../../assets/sass/_mixins";

.compare-equipment-form {
    .controls-expander {
        flex-shrink: 0;
        width: 42px;
        cursor: pointer;

        &:hover {
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
        }

        > .icon-with-label {
            @include rotate(-90deg);
            white-space: nowrap;
            align-self: center;

            span {
                margin-left: 10px;
                font-size: 11px;
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
                pointer-events: none;

                .font-size-large & {
                    font-size: 13px;
                }

                b {
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }
            }
        }
    }

    .compare-controls-list {
        display: none;
        flex-shrink: 0;
        padding: 15px 0;
        width: 250px;

        &.expanded {
            display: block;
            padding: 15px 20px;
            @include themify($themes) {
                border-left: 1px solid themed("themeDirtyColor");
            }
        }

        .attribute-checkboxes--header {
            padding-bottom: 10px;
            margin-bottom: 10px;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }
        }

        .checkbox-wrap:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    .equipment-attribute-list-wrap {
        padding: 10px 0 0 10px;
        background: $white-light-grey;
        @include themify($themes) {
            border-left: 1px solid themed("themeLighterDirtyColor");
        }

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        @include tablet {
            padding: 20px 0 2px 20px;
        }

        .scroll-controls {
            position: relative;
            top: auto;
            margin: 0 16px 0 166px;
            height: auto;

            @include tablet-and-mobile {
                display: none;
            }

            .main-grid-wrap {
                padding: 0;
                min-width: inherit;
                max-width: inherit;
            }

            .scroll-control {
                position: relative;
                width: 36px;
                height: 36px;

                .icon-wrap {
                    top: auto;
                    left: auto;
                }

                &.left {
                    float: left;
                    left: -10px;
                }

                &.right {
                    float: right;
                    right: -10px;

                    .icon-wrap {
                        right: auto;
                    }
                }
            }
        }
    }

    .equipment-attribute-list {
        .compare-row {
            min-height: 42px;
            flex-shrink: 0;

            &:not(.compare-row-header):hover {
                > .compare-column {
                    font-weight: 700;
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                }

                .flex-row .compare-column {
                    @include themify($themes) {
                        background: themed("themeWhiteColor");
                    }
                }
            }

            > .flex-row {
                &.scroll-indicator {
                    @include webkit-scrollbar-thin-colored;
                }

                &:not(.scroll-indicator) {
                    @include scrollbar-hide;
                }

                .compare-column {
                    background: white;
                    @include themify($themes) {
                        border-right: 1px solid themed("themeLighterDirtyColor");
                    }

                    &:not(:first-child) {
                        @include themify($themes) {
                            border-left: 1px solid themed("themeLighterDirtyColor");
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 10px;

                        @include tablet {
                            margin-right: 15px;
                        }
                    }
                }
            }

            > .compare-column {
                padding: 0 15px 0 0;
                min-height: initial;
                text-align: right;
                word-break: break-word;

                @include ie {
                    word-wrap: break-word;
                }

                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                    border-right: 1px solid themed("themeLighterDirtyColor");
                }

                @include tablet {
                    min-width: 170px;
                    max-width: 170px;
                }
            }

            > .compare-column,
            > .flex-row span {
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }
            }

            &.compare-row-scroll-indicator {
                display: none;
                min-height: initial;
                height: 4px;

                @include tablet-large {
                    @include flexbox;
                }

                > .compare-column {
                    border: none;
                }
            }

            &.compare-row-header {
                > .compare-column {
                    padding-bottom: 8px;
                    border-top: none;

                    b,
                    .icon-wrap {
                        @include themify($themes) {
                            color: themed("themeColorDarkGrey");
                        }
                    }

                    .icon-wrap {
                        margin: 0 -5px 0 3px;
                    }
                }
            }
        }

        .compare-column {
            padding: 0 10px;
            min-width: 120px;
            max-width: 120px;
            min-height: 42px;
            word-break: break-word;

            @include tablet {
                min-width: 210px;
                max-width: 210px;
            }

            span {
                padding: 8px 0;
                width: 100%;
                height: 100%;
                border-bottom: 1px solid $white-grey-medium;
                text-align: center;
            }

            &.compare-column-header {
                position: relative;
                padding: 33px 20px 17px 20px;
                @include border-radius(6px 6px 0 0);
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
                font-weight: 500;
                font-size: em(16);
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
                text-align: center;

                &.sticky {
                    z-index: 100;
                }

                &:before,
                &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                &:before {
                    content: "equipment";
                    top: -1px;
                    padding-bottom: 2px;
                    width: 70px;
                    @include themify($themes) {
                        background: themed("themeBottomTabsBackgroundBase");
                    }
                    @include border-radius(0 0 5px 5px);
                    font-size: em(8);
                    line-height: 16px;
                    text-align: center;
                    color: white;
                }

                &:after {
                    content: "";
                    z-index: 1;
                    bottom: -0;
                    width: 40px;
                    height: 3px;
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                }
            }

            &.sticky {
                position: sticky;
                left: 0;
                background-color: $light-grey-medium !important;
                font-weight: 600;
            }
        }
    }

    .scrollable-block {
        overflow: overlay;
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }

        .compare-row:first-child > .compare-column {
            border-top: none;
        }
    }

    .remove-equipment-button {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .equipment-quantity {
        position: absolute;
        @include scale(0.8);
        top: 5px;
        left: 5px;
    }
}
