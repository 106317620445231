@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.create-application-from-scan {
    > .flex-one {
        padding: 20px 0;

        @include tablet-and-mobile {
            overflow-y: auto;

            .main-grid-wrap {
                height: initial;
            }
        }
    }

    .split-view & .main-grid-wrap {
        @include clearfix;
    }

    .view-switcher {
        display: none;

        .split-view & {
            display: block;
            margin: 10px 0;
        }
    }

    &__content {
        position: relative;
        background: $white-light-grey;
        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
        }

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        @include tablet-large {
            padding-bottom: 62px;
        }

        .split-view & {
            @include calc(height, "100% - 58px");
        }

        &.flex-row {
            @include tablet-and-mobile {
                @include flex-direction(column);
                // height: initial; Breaks scroll in tablet view
            }
        }
    }

    .scan-application,
    .pdf-viewer {
        @include tablet-large {
            width: 50%;
        }

        .split-view & {
            width: 100%;

            &.collapse-section {
                width: 0;
            }
        }
    }

    .pdf-viewer > div {
        max-height: 200px;

        @include tablet-large {
            height: 100%;
            max-height: 100%;
        }
    }

    @include tablet-and-mobile {
        .scan-application {
            order: 2;
        }

        .pdf-viewer {
            position: relative;
            order: 1;
            margin: 20px;
            padding: 20px;
            text-align: center;

            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
                color: themed("themeDarkColor");
            }

            &:before,
            &:after,
            p:before,
            p:after {
                content: "";
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
            }

            &:before,
            &:after {
                top: 10px;
                @include themify($themes) {
                    border-top: 2px solid themed("themeDarkColorSemiTransparent70");
                }
            }

            &:before {
                left: 10px;
                @include themify($themes) {
                    border-left: 2px solid themed("themeDarkColorSemiTransparent70");
                }
            }

            &:after {
                right: 10px;
                @include themify($themes) {
                    border-right: 2px solid themed("themeDarkColorSemiTransparent70");
                }
            }

            a {
                display: block;
                margin-top: 5px;
            }

            p {
                font-weight: 500;

                &:before,
                &:after {
                    bottom: 10px;
                    @include themify($themes) {
                        border-bottom: 2px solid themed("themeDarkColorSemiTransparent70");
                    }
                }

                &:before {
                    left: 10px;
                    @include themify($themes) {
                        border-left: 2px solid themed("themeDarkColorSemiTransparent70");
                    }
                }

                &:after {
                    right: 10px;
                    @include themify($themes) {
                        border-right: 2px solid themed("themeDarkColorSemiTransparent70");
                    }
                }
            }
        }
    }

    .new-application-form {
        &__content {
            border: none;

            @include tablet-large {
                padding: 25px 30px 30px;
            }
        }

        > .btn-group {
            padding: 20px;

            @include tablet-large {
                z-index: 2;
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 15px 30px;
                width: 100%;
                background: white;
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }
        }
    }
}
