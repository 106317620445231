@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.panel.audit {
    > .grid-controls.flex-row {
        margin-top: 0;

        @include mobile-only {
            margin-top: -34px;
            @include flexbox;
            @include flex-direction(column);

            .flex-one {
                display: none;
            }

            .audit-types {
                margin-top: 12px;
                order: 2;
            }

            .icon-with-label {
                order: 1;
                align-self: flex-end;
            }
        }
    }
}
