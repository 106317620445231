@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.dropdpwn-list-mobile-header {
    z-index: 30;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 50px 0 15px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-weight: 500;

    @include themify($themes) {
        background: themed("themeSemiWhiteColor");
        border-bottom: 1px solid themed("themeDirtyColor");
    }

    .icon-wrap {
        position: absolute;
        right: 4px;
        @include calc(top, "50% - 13px");
    }
}

.dropdown-list-wrap {
    z-index: 30;
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;

    @include tablet {
        z-index: 21;
        position: absolute;
        top: 44px;
        height: inherit;
    }

    .dropdown-list {
        padding-top: 50px;
        width: 100%;
        height: 100%;
        background-color: white;
        text-align: left;

        &__filter {
            padding: 10px;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }

            @include tablet {
                padding: 5px;
            }

            .field-group {
                margin: 0;
                padding-left: 0;
                width: 100%;
            }
        }

        @include tablet {
            overflow: hidden;
            padding-top: 0;

            @include themify($themes) {
                border: 1px solid themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            }
        }
    }

    &:not(.right) {
        left: 0;
    }

    &.right {
        right: 0;
    }

    &.alone {
        @include tablet {
            max-width: inherit;
            width: 220px;
            max-width: 440px;
            min-width: 220px;

            .dropdown-list {
                @include border-radius(4px);
            }
        }
    }

    &.fixed-width {
        @include tablet {
            width: 230px;
        }
    }

    &.with-title {
        @include tablet {
            top: -7px;
        }

        .dropdown-list-title {
            padding: 0 56px 0 10px;
            font-weight: 500;
            @include text-overflow;
            line-height: 49px;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            @include tablet {
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }
        }
    }

    &.visible {
        display: block;

        .dropdpwn-list-mobile-header {
            @include mobile-only {
                display: block;
            }
        }
    }

    &.with-parent {
        @include tablet {
            top: initial;
        }

        .dropdown-list {
            border-top: none;
            height: 100%;

            .dropdown-list__items {
                overflow: auto;
                max-height: 100%;

                @include tablet {
                    max-height: 201px;
                }
            }

            @include tablet {
                height: inherit;
                @include border-radius(0 0 4px 4px);
            }
        }

        &:not(.fixed-width) {
            width: 100%;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -8px;
            left: 2px;
            @include calc(width, "100% - 3px");
            height: 7px;
            background-color: white;
            @include themify($themes) {
                border-bottom: 1px solid themed("themeDirtyColor");
            }

            @include ie {
                top: -7px;
            }

            @include ie-edge {
                top: -7px;
            }
        }
    }
}
