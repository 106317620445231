@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.system-user-groups {
    padding: 20px 0;

    .grid-controls {
        margin-top: 0;

        @include mobile-only {
            position: relative;
            padding-top: 30px;

            .icon-with-label {
                &.with-hand-made-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
