@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.button-size-property {
    .property-list-item-inner {
        .text-label {
            font-weight: 700;
            text-transform: uppercase;
            min-height: 28px;
            line-height: 28px;
        }

        > .flex-row {
            margin: 0 20px;
            padding: 10px 0 17px;

            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }

            .select-box {
                flex: 1;
                height: 35px;
                padding: 2px 7px;
                height: 35px;
            }
        }
    }
}

.property-list-item--button-corner {
    .property-list > .property-list-item--button-corner {
        margin-left: 30px;
        margin-right: 10px;
        padding-bottom: 10px;

        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }

    .property-list-item--title {
        .property-list-item-inner {
            .text-label {
                font-weight: 700;
                text-transform: uppercase;
                min-height: 28px;
                line-height: 28px;
            }
        }
    }

    .button-corner-boxes {
        padding: 10px 0 25px;
        margin: 0 20px;

        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }

    .button-corner {
        padding: 8px 0;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }

    .corner-boxes {
        max-width: 120px;
        margin-left: auto;

        .portal-builder-properties-overlay-view & {
            max-width: 150px;
        }
    }

    .corner-box {
        position: relative;
        padding: 0;
        height: auto;
        border: none;

        &:not(:last-child) {
            margin-right: 9px;
        }

        &.selected .corner-box-border {
            @include themify($themes) {
                border-color: themed("themeBaseColor");
            }
        }

        .corner-box-border {
            position: absolute;
            width: 100%;
            height: 100%;
            @include themify($themes) {
                border: 2px solid themed("themeColorBaseGrey");
            }
            clip-path: polygon(
                0 0,
                40% 0,
                40% 2px,
                60% 2px,
                60% 0,
                100% 0,
                100% 40%,
                calc(100% - 2px) 40%,
                calc(100% - 2px) 60%,
                100% 60%,
                100% 100%,
                60% 100%,
                60% calc(100% - 2px),
                40% calc(100% - 2px),
                40% 100%,
                0 100%,
                0 60%,
                2px 60%,
                2px 40%,
                0 40%,
                0 0
            );
        }

        &--top-left .corner-box-border {
            clip-path: polygon(0 0, 40% 0, 40% 40%, 0 40%, 0 0);
        }

        &--top-right .corner-box-border {
            clip-path: polygon(60% 0, 60% 40%, 100% 40%, 100% 0);
        }

        &--bottom-right .corner-box-border {
            clip-path: polygon(60% 60%, 100% 60%, 100% 100%, 60% 100%);
        }

        &--bottom-left .corner-box-border {
            clip-path: polygon(0 60%, 40% 60%, 40% 100%, 0 100%);
        }

        &--size-1 .corner-box-border {
            border-radius: 0;
        }

        &--size-2 .corner-box-border {
            border-radius: 12.5%;
        }

        &--size-3 .corner-box-border {
            border-radius: 25%;
        }

        &--size-4 .corner-box-border {
            border-radius: 37.5%;
        }

        &--size-5 .corner-box-border {
            border-radius: 50%;
        }

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        &.selected {
            border: none;

            .corner-box-border {
                border-width: 2px;
                border-style: solid;
            }

            &:after {
                content: "";
                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;

                @include themify($themes) {
                    border: 1px solid themed("themeBoxShadowBorderColor");
                    @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                    border-radius: 5px;
                }
            }
        }
    }
}

.tab-list .tabs {
    .tab.equal-width:not(.active) {
        background-color: #ffffff !important;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }
}

.property-list-item--border {
    .property-list-item--prop-group {
        font-weight: 400 !important;
        text-transform: none !important;
    }

    .property-list > .property-list-item--border-width {
        .property-list-item--title {
            align-self: flex-start;
            width: 100%;
        }

        .button-border-width-boxes {
            margin: 0 20px;
            @include calc(width, "100% - 40px");
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }
        }
    }
}

.secondary-button-type-select {
    display: contents;
    background: #ffffff;

    .property-list-item--select-box {
        margin-left: 5px !important;
        margin-right: 5px;

        .property-list-item-inner {
            .text-label {
                margin-left: 15px;
            }

            .flex-row {
                .select-box {
                    height: 35px;
                }
            }
        }
    }
}

.color-select-icon.disabled {
    cursor: not-allowed;

    &::before {
        background: linear-gradient(
            135deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 2px),
            $red,
            rgba(0, 0, 0, 0) calc(50% + 2px),
            rgba(0, 0, 0, 0) 100%
        );
    }
}
