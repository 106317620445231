@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.revisions-section-portal-builder {
    .revision-item {
        border-radius: 5px;
        border: 1px solid $white-grey-dark;
        background-color: $white-light-grey;
        margin: 5px 0;

        &.selected {
            border: 3px solid $blue-white;
        }
    }

    .paging-goto-page {
        margin-top: auto;
    }

    .revisions-section-flex-container {
        height: 100%;
    }

    .revisions-items-container {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
    }

    .revision-item-data {
        margin-top: 10px;
        margin-left: 22px;

        .item-label {
            font-weight: bold;
        }

        .item-value {
            margin-top: 5px;
        }
    }

    .revisions-container {
        margin: 10px;
    }

    .revision-actions {
        margin: 10px 0;
    }

    .revisions-filters {
        padding: 0 15px 10px 15px;

        .field-group {
            width: 100%;
            margin-bottom: 10px;

            input {
                @include box-shadow-common($shadow-outer-hover);

                @include themify($themes) {
                    border: 1px solid themed("themeDirtyColor");
                }
            }
        }

        .btn {
            align-self: start;
            margin-top: 15px;
        }
    }

    .page-counter {
        margin-right: 15px;
    }

    .grid-list-paging {
        padding: 0 10px 0 10px;
    }

    .no-revisions-found {
        margin: 20px auto;
    }
}
