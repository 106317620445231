@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item--padding {
    .property-list > .property-list-item--padding-size {
        margin: 0 20px;
        align-items: inherit;
    }

    .property-list > .property-list-item--padding-size-all-sides {
        .padding-box-container {
            font-size: 10px;
        }
    }

    .property-list > .property-list-item--padding-side-by-side {
        .margin-side {
            padding-top: 5px;
        }
    }

    .property-list > .property-list-item--select-box {
        .select-box__text {
            font-size: 10px;
        }
    }

    .padding-side {
        padding: 8px 0;

        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }

        .text-label {
            text-transform: uppercase;
            font-size: 10px;
            width: 50px;
            margin-right: 10px;
        }
    }

    .select-box {
        .property-list-item-inner {
            border-bottom: none;
        }
    }

    .padding-boxes {
        max-width: 180px;
        margin-left: auto;
    }

    .padding-box-container {
        height: 25px;
        padding: 0;
        font-size: 8px;
        font-weight: 600;
    }
}
