@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.calculation-details-body {
    .calculation-formula-builder {
        width: 0;
        @include themify($themes) {
            border-right: 1px solid themed("themeDirtyColor");
        }

        &__title {
            padding: 10px 10px 15px;
            @include border-radius(0 0 30% 30%);
            font-weight: 500;
            text-align: center;

            @include themify($themes) {
                background: themed("themeBaseColorSemiTransparent7");
                border-bottom: 1px solid themed("themeLighterDirtyColor");
                color: themed("themeTitleColor");
            }
        }

        &__program-selection {
            padding: 10px;

            .field-group {
                min-width: auto !important;
            }
        }

        &.expanded {
            width: 250px;
        }

        &__expander {
            width: 42px;
            @include themify($themes) {
                border-right: 1px solid themed("themeDirtyColor");
            }
            cursor: pointer;

            &:hover {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
            }

            .icon-with-label {
                @include rotate(-90deg);
                white-space: nowrap;
                align-self: center;
            }
        }

        .selectlist {
            min-height: 38px;
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }

            .add-new-item-panel {
                margin: 0 10px 10px;
                padding: 5px;
            }

            .selectlist-items {
                .selectlist-item {
                    padding: 5px 10px;
                    font-size: em(12);
                    flex-shrink: 0;
                    word-break: break-word;
                    cursor: pointer;

                    .font-size-large & {
                        font-size: em(14);
                    }

                    @include ie {
                        word-wrap: break-word;
                    }

                    &:hover,
                    &.selected {
                        @include themify($themes) {
                            background: themed("themeWhiteColor");
                        }
                        font-weight: 500;
                    }
                }

                .selectlist-empty {
                    font-size: em(12);
                    padding: 5px 5px 5px 10px;

                    .font-size-large & {
                        font-size: em(14);
                    }
                }
            }
        }

        .list-header {
            position: relative;
            padding: 5px 5px 5px 10px;
            cursor: pointer;

            &:hover,
            &.expanded {
                .list-label,
                .icon-wrap {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }

            &.expanded {
                &:after {
                    content: "";
                    position: absolute;
                    left: 10px;
                    bottom: 0;
                    @include calc(width, "100% - 20px");
                    height: 1px;
                    @include themify($themes) {
                        background: themed("themeDirtyColor");
                    }
                }
            }
        }

        .list-label {
            font-size: em(12);
            font-weight: 700;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    .calculation-code {
        &__formatted {
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }
            @include border-radius(4px);

            // Styles from production
            .function {
                color: #069;
            }

            .function_start,
            .function_stop {
                color: #ff0000;
            }

            .quote_mark,
            .text {
                color: #0000ff;
            }

            .tabs {
                border-left: dotted 1px #a0a0a0;
            }

            .formula {
                padding: 20px;
                border: 1px solid #e0e0e0;
            }
        }

        .content-header {
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }

        .code-format-switcher {
            font-size: em(10);
            font-weight: 500;

            .font-size-large & {
                font-size: em(12);
            }

            span {
                &:not(.active) {
                    cursor: pointer;
                }

                &.active {
                    pointer-events: none;
                }

                &.active,
                &:hover {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }

            .icon-wrap.rotate {
                @include rotate(180deg);
            }
        }

        .calculation-code-body {
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }
            @include border-radius(4px 4px 0 0);

            .error-marker {
                position: absolute;
                background-color: rgba(255, 0, 0, 0.54);
                z-index: 2;
            }

            .ace-github .ace_gutter {
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                    color: themed("themeBaseColorDesaturatedSemiTransparent");
                }
            }
        }

        .calculation-code-verify-state {
            padding: 8px 10px;
            width: 100%;

            &.valid {
                background: $green-light;
            }

            &.not-valid {
                background: $red-light;
            }

            .icon-wrap {
                position: absolute;
                right: 4px;
                @include calc(top, "50% - 13px");

                &:hover {
                    background: transparent !important;
                }
            }
        }

        .calculation-code-actions {
            padding: 8px 10px;
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
        }

        .calculation-code-footer {
            position: relative;
            @include border-radius(0 0 4px 4px);
            overflow: hidden;

            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
                border-top: none;
            }

            .calculation-code-cursor-position {
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }
    }

    .calculation-code-area {
        margin: 5px 20px 0 20px;
        padding-bottom: 30px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        @include firefox {
            flex-grow: 1;
        }

        @include ie {
            height: 100%;
            max-height: 50%;
        }
    }

    .calculate {
        max-height: 50%;
    }

    .calculate-fields {
        position: relative;
        min-height: 90px;

        .input-fields {
            padding: 11px 20px 20px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .json-form .form-group {
            margin-bottom: 0;
        }
    }

    .calculate-actions {
        position: relative;
        margin: 0 20px 20px;
        padding: 10px 0 0;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }

        .icon-with-label {
            position: absolute;
            @include calc(top, "50% - 4px");
            right: 0;
        }
    }

    .output-fields {
        margin: -10px 20px 20px;
        padding-top: 10px;
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }

        .field-group {
            width: auto;
            text-align: center;
        }
    }

    .dropdown-list__filter {
        margin: 5px 10px;
        padding: 0 0 5px;
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }
}
