@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.forms-panel {
    > .tab-list {
        z-index: 1;
    }

    .forms-tab-lead-block {
        position: relative;
        z-index: 1;
        margin: 1px 0 0;
        padding: 10px 15px;
        min-height: 52px;
        background: white;

        @include themify($themes) {
            border-left: 1px solid themed("themeDirtyColor");
            border-right: 1px solid themed("themeDirtyColor");
        }

        .icon-with-label + .icon-with-label {
            margin-left: 20px;
        }

        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
        }

        strong {
            padding-right: 5px;
            font-weight: 500;
        }
    }

    .forms-history-tab,
    .forms-friendly-names-tab {
        position: relative;
        margin-top: -44px;
        padding-top: 44px;

        .data-grid-controls {
            @include mobile-only {
                margin-bottom: 0;
                @include flexbox;
                @include data-grid-controls-mobile;
                justify-content: flex-end;
            }

            @include tablet {
                z-index: 2;
                position: absolute;
                right: 0;
                top: 14px;
            }
        }
    }
}

.application-form-pages-form-sidenav-panel,
.all-form-fields-form-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}

.all-form-fields-form-sidenav-panel {
    .element-properties {
        overflow: initial;
    }
}
