@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";

.workflow-tasks {
    .hc-theme & {
        font-weight: 500;
    }
    &.workflow-tasks-filter-visible {
        .workflow-tasks-statuses {
            margin-left: 150px;
        }
    }

    .workflow-tasks-statuses {
        .incompleted-status {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

.workflow-tasks-history-title {
    padding: 36px 0 20px 0;

    h3 {
        margin-right: 15px;
    }

    .separator-line {
        @include themify($themes) {
            border-top: 3px dashed themed("themeDirtyColor");
        }
    }
}
