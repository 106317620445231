@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.checkbox-wrap {
    .field-group & {
        margin-top: 11px;
    }
}

.field-wrap,
.dropdown-wrap,
.numeric-wrap,
.icon-dropdown {
    position: relative;
}

.numeric-wrap {
    display: inline-block;
}

.field-wrap {
    &.with-icon {
        &:before {
            position: absolute;
            top: 0;
            line-height: 32px;
            text-align: center;
            pointer-events: none;
        }

        &.focus:before {
            top: 1px;
            height: 30px;
            line-height: 32px;
        }

        &:not(.with-two-icons):before {
            width: 32px;
            height: 32px;
            @include border-radius(4px 0 0 4px);
        }

        &.active {
            input[type="text"] {
                @include themify($themes) {
                    border-color: themed("themeBaseColor");
                }
                @include box-shadow(0, 0, 0, 0);
            }
        }

        &.frozen:before {
            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
            }
            line-height: 34px;
        }

        &.with-dropdown {
            &:focus,
            &.focus {
                &.filled {
                    &:before {
                        display: none;
                    }

                    .clickable-icon.clean {
                        display: block;
                    }
                }
            }
        }

        &.highlighted {
            &:before {
                line-height: 32px;
            }

            &:not(.with-dropdown) {
                height: 32px;

                &:focus,
                &.focus {
                    &:before {
                        line-height: 33px;
                    }
                }
            }

            &.with-dropdown {
                &:focus,
                &.focus {
                    @include border-radius(4px);

                    @include themify($themes) {
                        border: 1px solid themed("themeBoxShadowBorderColor");
                        @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                    }

                    input[type="text"],
                    input[type="password"],
                    input[type="tel"] {
                        @include box-shadow(0, 0, 0, 0);
                        border: none;
                        height: 30px;
                    }
                }
            }

            &:focus,
            &.focus {
                &:before {
                    top: 0;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }

            .autocomplete-list li:first-child {
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }
            }
        }

        &.right:not(.with-two-icons) {
            &:before {
                right: 1px;
                @include border-radius(0 4px 4px 0);
            }
        }
    }

    &.with-two-icons {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            line-height: 32px;
            pointer-events: none;
        }

        &:before {
            top: 1px;
            right: 5px;
        }

        &:after {
            top: 2px;
            right: 28px;
        }
    }
}
