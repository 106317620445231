@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.wf {
    &__sticky-panel {
        z-index: 2;
        position: relative;
        margin-top: 1px;
        background: white;
        @include box-shadow-common($shadow-outer);

        @include themify($themes) {
            border-left: 1px solid themed("themeDirtyColor");
            border-right: 1px solid themed("themeDirtyColor");
        }

        > .flex-row {
            padding: 10px 15px;
            min-height: 61px;
        }

        p {
            padding: 0 15px;
            text-align: right;
            font-weight: 500;
            @include transition(all 0.3s ease);

            &:not(.info-visible) {
                height: 0;
                overflow: hidden;
            }

            &.info-visible {
                padding: 10px 15px;
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }
            }
        }
    }

    &__search-container {
        margin-right: 80px;
    }

    &__search {
        margin-right: 20px;
    }

    &__switcher {
        margin-right: 20px;
        flex-shrink: 0;
        white-space: nowrap;
        cursor: pointer;

        &-text {
            font-size: em(12);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }
        }

        .icon-wrap {
            top: 0;
            height: auto;
            line-height: inherit;

            &:hover {
                background: transparent !important;
            }

            &.fiber-smart-record-filled {
                @include rotate(180deg);
            }
        }
    }

    &.save-panel-visible {
        padding-bottom: 44px;
    }

    &__steps {
        min-height: 15px;
    }

    &__steps-list {
        padding: 8px 4px 4px;
    }

    &__statuses-controls {
        margin: 0 20px;
        white-space: nowrap;

        + .icon-wrap {
            top: 1px;
            flex-shrink: 0;
        }

        .nothing-found-block & {
            margin-right: 0;
        }
    }

    .nothing-found-block {
        margin-top: 10px;

        > div {
            max-width: initial;
        }
    }

    &__settings-step,
    &__settings-status {
        &-buttons {
            position: relative;
            padding: 15px 20px;
            @include box-shadow-common($shadow-outer-center);

            &-cancel {
                flex: 1;
                margin-left: 20px;
            }
        }
    }
}
