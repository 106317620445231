@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.search-results {
    &__table {
        position: relative;
        width: 26px;

        &:before {
            content: "";
            position: absolute;
            left: 14px;
            top: 0;
            width: 3px;
            height: 100%;
            @include themify($themes) {
                background: themed("themeDirtyColor");
            }
        }

        span {
            margin: 10px 0;
            padding: 10px 0;
            @include rotate(-180deg);
            writing-mode: vertical-rl;
            background: white;
            align-self: center;
            white-space: nowrap;
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }

    &__item {
        position: relative;
        margin-left: -30px;
        @include calc(width, "100% + 26px");
        font-size: em(13);

        .font-size-large & {
            font-size: em(14);
        }

        &-wrap {
            @include border-radius(6px);
            background: rgba(255, 255, 255, 0.7);
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }

            @include tablet-large {
                &:hover {
                    background: white;
                    @include themify($themes) {
                        border: 1px solid themed("themeDirtyColor");
                    }

                    .search-results__item-actions {
                        .icon-wrap {
                            opacity: 1;
                        }
                    }

                    .search-results__header > div {
                        @include themify($themes) {
                            border-color: themed("themeDirtyColor");
                        }
                    }
                }
            }
        }

        @include mobile-only {
            @include flex-wrap(wrap);
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        &-actions {
            position: absolute;
            top: 3px;
            right: 3px;

            .icon-wrap {
                @include tablet-large {
                    opacity: 0;
                }
            }
        }

        &--cis {
            padding: 10px;
        }
    }

    &__header {
        padding-right: 30px;
        background: $white-light-grey;

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background: $sightline-white-light-grey;
        }

        > div {
            padding: 10px;
            @include calc(width, "100% / 5");

            &.Project {
                word-break: break-all;
            }
        }

        .search-results__field__value {
            font-size: 12px;

            .font-size-large & {
                font-size: 14px;
            }
        }
    }

    &__data {
        padding-right: 30px;
    }

    &__fields {
        &.cis-search {
            margin-left: 10px;

            > div {
                width: 25%;
            }
        }
    }

    &__field {
        padding: 10px;
        @include calc(width, "(100% / 5 * 2)");
        mark {
            background: transparent;
            font-weight: 700;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        &__title {
            margin-bottom: 2px;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
            font-size: 12px;

            .font-size-large & {
                font-size: 14px;
            }
        }

        &__value {
            &.file-name,
            &.customer-email {
                word-break: break-all;
            }
        }
    }
}
