@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";
@import "../../../../../../assets/sass/functions";

.panel.user-groups {
    .system-user-groups-panel {
        @include calc(height, "100% - 46px");
    }

    .grid-controls {
        @include mobile-only {
            position: relative;
            margin: -36px 0 12px;
            padding-top: 30px;
            flex-wrap: wrap;
            justify-content: space-between;

            .flex-one {
                display: none;
            }

            .field-group {
                padding: 12px 0;
                order: 1;
                margin: 0 0 10px;
                width: 100%;

                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }
            }

            .icon-with-label {
                &.edit-empty {
                    order: 2;
                }

                &.plus {
                    order: 3;
                }

                &.with-hand-made-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
