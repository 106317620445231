.contractor-search-modal {
    .modal-body {
        padding: 0 !important;

        .btn-group {
            padding: 0 20px 20px;
            text-align: right;
        }

        p {
            padding: 20px;
            font-size: 15px;
        }
    }

    .custom-list {
        .list-item-row {
            cursor: default;
        }

        .paging-wrap {
            padding: 15px 13px;
            text-align: center;
        }
    }
}
