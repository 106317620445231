@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.scan-queue {
    &__main-panel {
        @include tablet-and-mobile {
            width: 100%;
        }
    }

    > .flex-row {
        @include tablet-and-mobile {
            @include flex-wrap(wrap);
        }
    }

    &__side-panel {
        margin-bottom: 10px;

        @include tablet-only {
            margin: 0;
        }

        > div {
            @include tablet-and-mobile {
                @include flexbox;
            }
        }
    }

    &__widget {
        .report-for-dashboard {
            .scan-queue-report {
                min-height: 150px;
            }
        }
    }
}

.report-wrap {
    padding: 20px 0 30px;
    background-color: rgba(255, 255, 255, 0.6);
    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
    }

    .scan-queue-report {
        min-height: 400px;
    }

    @include tablet {
        padding: 25px 10px 40px 0;
    }

    @include desktop {
        @include flexbox;
        padding: 0;
        background: transparent;

        .report-lead-block {
            padding: 20px 25px;
            width: 260px;
            background-color: rgba(255, 255, 255, 0.6);
            @include themify($themes) {
                border-right: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .scan-queue-report {
            flex: 1;
            padding: 25px 10px 30px 0;
            background-color: rgba(255, 255, 255, 0.3);
            overflow: hidden;
        }
    }
}

.report-controls {
    margin: 0 60px 30px;

    @include mobile-only {
        @include flex-wrap(wrap);
    }

    @include tablet {
        margin: 20px 63px 30px;
    }

    @include desktop {
        margin: 0;
        @include flex-wrap(wrap);
    }

    .field-group {
        width: 100%;

        @include mobile-only {
            margin-top: 20px;
        }

        @include tablet {
            width: 50%;
        }

        @include desktop {
            margin-top: 20px;
            width: 100%;
        }
    }
}

.report-title {
    font-size: em(18);
    font-weight: 500;
    text-align: center;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }

    @include mobile-only {
        padding: 0 30px;
        font-size: em(16);
    }

    @include desktop {
        margin-bottom: 10px;
        text-align: left;
    }
}

.scan-queue-actions {
    position: relative;
    margin-bottom: 15px;
    padding: 15px 15px 60px;
    width: 100%;
    background-color: white;
    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
    }

    @include tablet-only {
        @include flexbox;
        align-items: center;
        padding-bottom: 15px;
    }

    @include tablet-large {
        margin: 0 20px 0 0;
        padding: 20px 15px;
        width: 300px;
        text-align: center;
    }

    > .icon-with-label {
        @include mobile-only {
            position: absolute;
            bottom: 15px;
            right: 15px;
            width: 28%;

            &:before {
                @include calc(top, "50% - 14px");
            }
        }

        @include tablet-only {
            white-space: nowrap;
        }
    }
}

.scan-totals-item {
    @include mobile-only {
        padding-right: 15px;
        width: 50%;
    }

    @include tablet-and-mobile {
        @include flexbox;
        align-items: center;
    }

    @include tablet-and-mobile {
        padding-right: 20px;
    }

    span {
        display: inline-block;
        text-transform: uppercase;
        font-size: em(12);
        font-weight: 500;

        .font-size-large & {
            font-size: em(14);
        }

        @include tablet-only {
            order: 2;

            &.value {
                order: 1;
            }
        }

        @include tablet-large {
            display: block;
        }

        &:not(.value) {
            @include tablet-and-mobile {
                word-spacing: 9999999px;
            }

            @include tablet-only {
                margin: 0 10px;
            }
        }

        &.value {
            font-size: 37px;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }

            @include mobile-only {
                margin-right: 10px;
            }

            @include tablet-and-mobile {
                line-height: 1;
            }

            @include tablet-large {
                font-size: 41px;
            }
        }
    }

    &:not(.flaw) span {
        @include mobile-only {
            order: 2;

            &.value {
                order: 1;
            }
        }
    }

    &.flaw {
        @include mobile-only {
            padding: 0 0 0 15px;
            text-align: right;
            justify-content: flex-end;
        }

        @include tablet-and-mobile {
            @include themify($themes) {
                border-left: 1px solid themed("themeLighterDirtyColor");
            }
        }

        @include tablet-large {
            margin-top: 10px;
            padding: 20px 0 10px;

            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        span.value {
            @include mobile-only {
                margin: 0 0 0 10px;
            }

            @include tablet-only {
                margin-left: 15px;
            }
        }
    }

    .btn-group {
        @include mobile-only {
            order: 3;
            position: absolute;
            bottom: 15px;
        }

        @include tablet-and-mobile {
            order: 3;
        }

        @include tablet-large {
            margin: 5px 0 26px;
        }
    }
}

.scan-queue-report {
    .recharts-legend-wrapper {
        padding-top: 15px;
        width: 100% !important;
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }
        font-size: em(12);
        font-weight: 500;

        .font-size-large & {
            font-size: em(14);
        }

        @include mobile-only {
            left: 0 !important;
        }

        @include desktop {
            left: 62px !important;
            width: calc(100% - 125px) !important;
        }

        .recharts-legend-item {
            margin: 0 20px !important;
        }
    }

    .recharts-layer {
        font-size: em(13);
        font-weight: 500;

        .font-size-large & {
            font-size: em(14);
        }
    }

    .recharts-default-tooltip {
        @include flexbox;
        align-items: center;
        padding: 5px 10px;
        border: none !important;
        @include box-shadow-common($shadow-outer);
        @include border-radius(4px);
        font-size: em(12);
        font-weight: 500;

        .font-size-large & {
            font-size: em(14);
        }

        .recharts-tooltip-label {
            position: relative;
            margin-right: 15px !important;
            padding: 5px 10px;
            background: $white-grey-medium;
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }
            @include border-radius(4px);
            font-size: 15px;
            text-align: center;

            &:before {
                content: $icon-calendar-empty;
                @include icon-iconoteka;
                display: block;
            }
        }
    }
}
