@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.panel.calculations {
    &.associations {
        padding: 20px;
    }

    .calculations-wrap {
        margin: 0 -15px;
        padding: 0 15px;
        @include calc(width, "100% + 30px");

        @include tablet-only {
            margin: 0 -20px;
            padding: 0 20px;
            @include calc(width, "100% + 40px");
        }

        @include tablet-large {
            margin: 0 -25px;
            padding: 0 25px;
            @include calc(width, "100% + 50px");
        }
    }

    .tab-list-tab-content:not(.program-calculations-tab) .grid-controls,
    .program-calculations-tab > .grid-controls {
        position: relative;
        z-index: 1;
        margin: 1px 0 0;
        padding: 10px 15px;
        min-height: 52px;
        background: white;

        @include themify($themes) {
            border-left: 1px solid themed("themeDirtyColor");
            border-right: 1px solid themed("themeDirtyColor");
        }

        .icon-with-label + .icon-with-label {
            margin-left: 20px;
        }

        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
        }
    }

    .program-calculations-tab > .grid-controls {
        > strong {
            padding-right: 5px;
            font-weight: 500;
        }

        > .grid-controls {
            margin: 0;
        }

        .utility-programs-list {
            margin: 0;
            width: auto;

            label {
                display: inline-block;
                margin: 0 10px 0 0;
            }

            .dropdown-wrap {
                display: inline-block;
                width: 250px;
            }
        }
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow-y: auto;
    }

    .grid-controls {
        .btn {
            margin-left: 10px;
        }

        &.calculations {
            margin-top: 0;

            .icon-with-label {
                white-space: nowrap;
            }
        }

        &.associations {
            display: flex;

            @include tablet-only {
                flex-direction: column;

                .btn-group {
                    .btn {
                        margin: 10px 0 10px 0;
                    }
                }
            }
        }
    }

    .bulk-assignment {
        @include mobile-only {
            @include flex-wrap(wrap);
        }

        &__label {
            font-size: em(13);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }

            @include mobile-only {
                font-size: 13px;

                .font-size-large & {
                    font-size: 15px;
                }
            }
        }
    }

    .lookups-calculations-tab {
        .grid-list-row.details {
            @include mobile-only {
                @include expanded-panel-mobile;
                align-items: flex-start;
            }

            @include tablet {
                margin-left: 15px;
                margin-right: 15px;
                @include calc(width, "100% - 30px");
            }
        }

        .lookup-table-edit-form {
            padding: 15px 10px 15px 15px;

            &.new {
                z-index: 1;
                margin: 10px 4px;
                padding: 15px 10px 15px 15px;
                @include calc(width, "100% - 8px");
                min-height: 52px;
                background: white;

                @include themify($themes) {
                    border: 1px solid themed("themeBoxShadowBorderColor");
                    @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                }
            }

            .field-group {
                margin-right: 20px;
                width: auto;

                label {
                    margin: 0 10px 0 0;
                }

                .field-wrap,
                label {
                    display: inline-block;
                    vertical-align: middle;
                }

                .field-wrap {
                    min-width: 250px;
                }
            }

            .icon-wrap.clear-close {
                margin-left: 10px;
            }
        }
    }
}

.sidenav-body.update-calculation-panel {
    @include ie {
        height: inherit;
    }
}

.remove-calculation-associations-confirmation-modal {
    ul {
        margin-left: 15px;
        list-style-type: disc;
        font-size: em(13);

        li {
            margin-top: 5px;
        }
    }
}
