.panel.location .location-view,
.sidebar-panel .location-container {
    .gm-style-mtc > div {
        &:first-child {
            padding: 0 15px !important;
            height: 32px !important;
            font-size: 15px !important;
        }

        + div {
            top: 33px !important;

            > div {
                font-size: 15px !important;

                > span {
                    margin-right: 2px !important;
                }
            }
        }
    }

    .gm-control-active.gm-fullscreen-control {
        width: 32px !important;
        height: 32px !important;

        img {
            width: 15px !important;
            height: 15px !important;
        }
    }

    .gm-svpc {
        left: 8px !important;
        width: 32px !important;
        height: 32px !important;

        img {
            &:first-child {
                width: 24px !important;
                height: 24px !important;
            }

            &:nth-of-type(2) {
                width: auto !important;
                height: 24px !important;
            }

            &:last-child {
                width: auto !important;
                height: 32px !important;
            }
        }
    }

    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
        > .gmnoprint {
            left: 8px !important;
            top: 88px !important;

            > div {
                width: 32px !important;
                height: 65px !important;
            }
        }

        .gm-control-active {
            width: 32px !important;
            height: 32px !important;

            &:first-child + div {
                margin: 0 !important;
                width: 32px !important;
            }

            img {
                width: 15px !important;
                height: 15px !important;
            }
        }
    }
}

.sidebar-panel .location-container {
    .gm-svpc {
        top: 32px !important;
    }
}
