@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_variables";

.bulk-upload {
    .dropdown-wrapper {
        width: 50%;
    }

    .json-form {
        flex: 1;
        min-width: 300px;
    }

    .spacer-helper {
        min-width: 300px;
        margin-left: 20px;
    }

    .json-form .rjsf .action-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;

        .btn:nth-child(2) {
            margin-right: 0;
        }
    }

    .form-info-message {
        margin-bottom: 10px;
    }

    .form-error-message {
        margin-bottom: 20px;
    }

    .success-block {
        border-radius: 16px;
        height: 350px;
        width: 100%;
        max-width: 450px;
        background-color: $green-pale;
        padding: 20px;
        margin: auto;
    }

    .back-button {
        margin: 20px;
    }

    .result-text {
        font-weight: 600;
        color: $green;
        text-align: center;
    }
}
