@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.panel-utility-portals-management {
    .nothing-found-block__content {
        max-width: 400px;
    }

    .tiles-wrap {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 20px;
        justify-content: space-between;
        padding-bottom: 40px;
        margin: 0;
    }

    .tile-item {
        width: auto;
        margin: 0;
    }
}

.tile-item.portal-template-tile {
    height: 250px;
    background-color: white;
    padding: 8px;
    border: none;
    overflow: hidden;

    &--new {
        background-color: transparent;
        box-shadow: none;
        border-style: dashed;
        border-width: 4px;
        @include themify($themes) {
            border-color: themed("themeLighterDirtyColor");
        }
    }

    .text-label {
        font-weight: 500;
        font-size: em(16);
        margin: 0 12px 15px;
        max-width: 100%;
        max-height: 40px;
        text-align: center;
        white-space: normal;
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}

.portal-template-tile-preview {
    height: 130px;
    position: relative;
    margin: 12px 12px 0;

    .portal-template-thumbnail {
        width: 100%;
        max-height: 100%;
        border-radius: 10px 0;
    }

    .nothing-found-block {
        width: 100%;
        border-radius: 10px 0;
        @include themify($themes) {
            background-color: themed("themeWhiteColor");
        }
    }

    .portal-template-notes {
        z-index: 1;
        position: absolute;
        top: -18px;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        white-space: normal;
        font-size: 11px;

        @include transition(height 0.3s);
        @include themify($themes) {
            background-color: themed("themeSemiWhiteColor");
        }

        &--expanded {
            height: calc(100% + 20px);
        }

        .portal-template-notes-content {
            padding: 20px;
        }

        .portal-template-note-title {
            margin-bottom: 10px;
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
        }

        .portal-template-note-text {
            font-size: 11px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;

            @include ie {
                max-height: 40px;
            }
        }

        .portal-template-usage-count {
            font-size: 22px;
            font-weight: 700;
            margin-right: 8px;
        }

        .portal-template-usage-text {
            font-size: 10px;
        }
    }
}
