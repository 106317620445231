@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.equipment-catalog {
    > .equipment-list .breadcrumb {
        padding: 0 15px 7px 10px;
        background-color: $white-light-grey;
        @include flex-wrap(wrap);

        .theme-sightline & {
            background: $sightline-white-light-grey;
        }
    }

    .sidebar-list {
        .footer {
            justify-content: space-between;
            height: 48px;
            padding-right: 10px;
            background-color: $white-light-grey;

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: $sightline-white-light-grey;
            }

            .counter {
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }
            }
        }
    }

    .categories-list {
        &:not(.no-data) {
            background: white;
        }

        .list-item-row {
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }
        }

        .category-count {
            flex-basis: 30%;
            text-align: right;

            .icon-wrap {
                right: -9px;

                &:after {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }
        }
    }

    .equipment-list {
        background: white;

        .list-item-row {
            cursor: default;

            .flip-to-front {
                visibility: hidden;
            }

            &:hover {
                .flip-to-front {
                    visibility: visible;
                }
            }
        }

        .equipment-name {
            flex-basis: 50%;
        }

        .equipment-count {
            flex-basis: 15%;
            text-align: right;
        }

        .equipment-incent {
            flex-basis: 35%;
            text-align: right;
        }
    }

    .custom-list .item-value {
        font-weight: 400;
    }

    .list-filter {
        justify-content: space-between;
    }

    .equipment-attributes-list {
        .attribute-name {
            padding-right: 20px;
        }

        .attribute-value {
            width: 102px;

            .checkbox-wrap label {
                padding-left: 30px;

                &:before {
                    @include calc(top, "50% - 6px");
                }
            }
        }

        &.equipment-details {
            .list-header-row .header,
            .list-item-row .item-value {
                width: initial;
                max-width: 50%;

                &:last-child {
                    text-align: right;
                }
            }

            .list-body .list-item-row {
                padding: 5px 13px;
                height: auto;
                min-height: 44px;

                .item-value {
                    white-space: normal;
                    text-overflow: initial;
                }
            }
        }
    }
}
