.main-grid-wrap {
    margin: 0 auto;
    width: 100%;

    &:not(.responsive) {
        padding-left: 50px;
        padding-right: 50px;
        min-width: $site-min-width-new;
        max-width: $site-max-width-new;
    }

    &.responsive {
        padding-left: 15px;
        padding-right: 15px;

        @include tablet-only {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include tablet-large {
            padding-left: 40px;
            padding-right: 40px;
        }

        @include desktop {
            padding-left: 50px;
            padding-right: 50px;
            min-width: $site-min-width-new;
            max-width: $site-max-width-new;
        }

        &.for-page-placeholder {
            height: 100%;
            text-align: center;
        }
    }

    @include desktop-large {
        .layout-full-screen & {
            min-width: initial;
            max-width: initial;
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @include desktop-large {
        padding-left: 90px;
        padding-right: 90px;
    }

    .split-view & {
        padding-left: 30px;
        padding-right: 30px;
        min-width: auto;
    }

    .flexbox-row {
        @include flexbox;
        @include flex-direction(row);
        margin-left: -10px;
        @include calc(width, "100% + 20px");

        + .flexbox-row {
            margin-top: 20px;
        }
    }

    .flexbox-col {
        margin: 0 10px;
        flex-basis: 0;
        flex-grow: 1;

        &.static {
            @include tablet-large {
                flex: 0 0 380px;
            }
        }

        &.content-center {
            text-align: center;
        }

        &.content-right {
            text-align: right;
        }
    }
}
