.accordion-property {
    .property-list-item--add-item {
        margin: 15px 0;
    }
    .property-list-item--expanded-row {
        .property-list-item-inner {
            border-top: none !important;
        }
    }
}
