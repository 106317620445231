@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.workflow-tasks-header {
    position: relative;

    .workflow-tasks-header-container {
        place-content: end;
    }

    .process-mode-label {
        font-size: 12px;
        display: inline-block;
        margin-top: 5px;

        span {
            display: inline-block;
            margin-right: 5px;
            opacity: 0.6;
        }
    }

    .workflow-tasks-header-title {
        margin-bottom: 20px;

        .app-status {
            margin-right: 15px;
            font-size: em(18);
            font-weight: 500;
            line-height: 24px;
            color: black;
        }

        .panel-small-title {
            margin-bottom: 3px;
            font-size: em(12);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }
        }
    }

    .workflow-tasks-header-actions {
        margin-bottom: 20px;

        .icon-with-label + .icon-with-label {
            margin-top: 10px;
        }
    }

    .workflow-tasks-status-info {
        z-index: 2;
        font-size: em(11);
        font-weight: 500;
        margin-left: auto;

        .font-size-large & {
            font-size: em(13);
        }

        &__date {
            margin-bottom: 5px;
        }

        .dimm {
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
        }
    }
}
