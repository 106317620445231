@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.icon-with-label {
    display: inline-block;
    font-size: em(12);
    line-height: 18px;
    @include themify($themes) {
        color: themed("themeBaseColor");
    }
    cursor: pointer;
    user-select: none;

    .font-size-large & {
        font-size: em(14);
    }

    &:not(.icon-after) {
        padding-left: 24px;

        &:before {
            left: -5px;
        }
    }

    &:before {
        position: absolute;
        top: -5px;
        width: 28px;
        height: 28px;
        @include border-radius(50%);
        line-height: 28px;
        text-align: center;
    }

    &:not(.disabled):hover:before {
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }
    }

    &.icon-after {
        padding-right: 24px;
        text-align: right;

        &:after {
            position: absolute;
            top: -5px;
            width: 28px;
            height: 28px;
            @include border-radius(50%);
            line-height: 28px;
            text-align: center;
        }

        &:before,
        &:after {
            right: -5px;
        }
    }

    &.disabled {
        color: $light-grey;
        // pointer-events: none; // This breaks title attribute usage
        cursor: default;

        &:before {
            color: $grey;
        }

        .with-hand-made-icon.document-icon {
            border-color: $grey;

            span {
                background: $grey;
            }
        }
    }
}
