@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.release-notes {
    margin: 20px;

    .release-notes-item {
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 5px;

        .font-size-large & {
            font-size: 1.2em;
        }

        @include themify($themes) {
            border: 1px solid themed("themeDropDownBorderColor");
            background-color: white;
            box-shadow: 0 0 5px themed("themeDropDownBorderColor");
        }
    }
}
