@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.grid-list-paging {
    min-height: 47px;
    background-color: $white-light-grey;
    font-size: em(12);
    font-weight: 500;

    .font-size-large & {
        font-size: em(14);
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background-color: $sightline-white-light-grey;
    }

    .grid-list:not(.audit-side-panel-grid) & {
        position: relative;
        padding-top: 88px;
        width: 100%;
        height: 100%;
        flex-grow: 0;
        text-align: center;
        justify-content: flex-end;
        align-items: stretch;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 88px;
            width: 100%;
            height: 1px;
            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
            }
        }
    }

    .paging-info {
        @include flexbox;
        align-items: center;
        margin-left: 10px;
        color: #999;

        @include tablet {
            font-size: 12px;

            .font-size-large & {
                font-size: 14px;
            }

            &.with-page-counter {
                font-size: 11px;

                .font-size-large & {
                    font-size: 13px;
                }
            }
        }

        .grid-list:not(.audit-side-panel-grid) & {
            margin-left: 0;
            padding: 10px 0;
            width: 100%;

            > div {
                @include flexbox;
                @include flex-direction(column);
                align-items: center;
            }
        }

        &.with-page-counter {
            .sidebar-panel & {
                @include flex-direction(column);
                align-items: flex-start;
            }
        }

        b {
            font-weight: 500;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        &--mobile {
            .paging-info-page {
                padding: 5px 5px 0;

                .font-size-large & {
                    @include flex-direction(column);
                }
            }

            .paging-info-page-label {
                padding-right: 5px;
                width: 28px;
                text-align: right;
                line-height: 1;
                font-size: em(8);

                .font-size-large & {
                    font-size: em(10);
                    text-align: center;
                    padding: 0 5px;
                    width: inherit;
                }
            }

            .paging-info-page-value {
                font-size: 17px;
                @include themify($themes) {
                    color: themed("themeTitleColor");
                }
            }

            .paging-info {
                margin: 0;
                padding: 2px 5px 5px !important;
                justify-content: center;
                font-size: 10px;
                line-height: 14px;

                .font-size-large & {
                    font-size: 11px;
                }
            }
        }
    }

    .paging {
        .grid-list:not(.audit-side-panel-grid) & {
            padding: 15px 0;
            @include flex-direction(column);
            min-height: 100px;
            overflow-y: hidden;
            align-items: center;

            // Fix for long paging to not overflow the grid list
            flex: 1 0 auto;
            height: 0;

            @include themify($themes) {
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
            @include webkit-scrollbar-thin;

            &:hover {
                overflow-y: auto;
            }

            .selectable-page {
                margin: 4px;
                flex-shrink: 0;
            }

            .icon-wrap {
                &.arrow-in-circle-left-empty {
                    @include rotate(90deg);
                }

                &.arrow-in-circle-right-empty {
                    @include rotate(90deg);
                }
            }
        }

        .icon-wrap {
            position: initial;
        }
    }

    .paging-goto-page {
        .grid-list:not(.audit-side-panel-grid) & {
            position: relative;
            margin-right: 0;
            margin-bottom: 10px;
            @include flex-wrap(wrap);
            justify-content: center;

            > span {
                margin-bottom: 5px;
                width: 100%;
                font-size: 10px;

                .font-size-large & {
                    font-size: 12px;
                }
            }

            .icon-wrap {
                position: absolute;
                right: 10px;
                bottom: 1px;
            }

            input {
                margin: 0;
                width: 50px;
            }
        }

        input {
            margin: 0 4px;
            padding: 0 5px !important;
            width: 60px;

            .grid-list:not(.audit-side-panel-grid) & {
                margin-left: 0;
            }
        }

        .icon-wrap-disabled {
            pointer-events: none;
        }
    }
}
