.avatar {
    width: 24px;
    height: 24px;

    --bs-blue-200: #b3c5ff;
    --bs-blue-700: #1e13a6;

    --bs-purple-200: #c5b3e6;
    --bs-purple-700: #432874;

    --bs-orange-200: #fecba1;
    --bs-orange-800: #653208;

    --bs-pink-200: #efadce;
    --bs-pink-700: #801f4f;

    --bs-teal-200: #a6e9d5;
    --bs-teal-800: #0d503c;

    --bs-cyan-200: #9eeaf9;
    --bs-cyan-800: #055160;

    &--blue {
        background: var(--bs-blue-200);

        .avatar-text {
            color: var(--bs-blue-700);
        }
    }

    &--purple {
        background: var(--bs-purple-200);

        .avatar-text {
            color: var(--bs-purple-700);
        }
    }

    &--pink {
        background: var(--bs-pink-200);

        .avatar-text {
            color: var(--bs-pink-700);
        }
    }

    &--orange {
        background: var(--bs-orange-200);

        .avatar-text {
            color: var(--bs-orange-800);
        }
    }

    &--teal {
        background: var(--bs-teal-200);

        .avatar-text {
            color: var(--bs-teal-800);
        }
    }

    &--cyan {
        background: var(--bs-cyan-200);

        .avatar-text {
            color: var(--bs-cyan-800);
        }
    }
}
