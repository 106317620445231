@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.scan-queue-management {
    background: white;

    .page-lead-block {
        padding-bottom: 60px;

        @include tablet-large {
            padding-bottom: 80px;

            .split-view & {
                padding-bottom: 60px;
            }
        }

        .view-switcher {
            z-index: 1;
            display: inline-block;
            position: relative;
            top: 25px;
            padding: 4px;
            background: white;
            @include box-shadow-common($shadow-outer-hover);
            @include border-radius(8px);

            @include tablet-large {
                display: none;

                .split-view & {
                    display: inline-block;
                }
            }

            span {
                display: inline-block;
                padding: 4px 7px 5px;
                @include border-radius(8px);
                font-size: em(12);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(14);
                }

                &:first-child {
                    margin-right: 4px;
                }

                &.active {
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                    color: white;
                }

                &:not(.active) {
                    cursor: pointer;
                }
            }
        }
    }

    > .main-grid-wrap {
        @include calc(height, "100% - 88px");

        @include tablet-only {
            @include calc(height, "100% - 110px");
        }

        @include tablet-large {
            @include calc(height, "100% - 100px");
        }

        .split-view & {
            @include calc(height, "100% - 120px");
        }
    }

    &__global-queue,
    &__user-groups {
        position: relative;
        top: -49px;
        @include calc(width, "50% - 15px");
        background: white;
        overflow: hidden;
        @include box-shadow-common($shadow-outer-hover);
        @include border-radius(8px);

        @include mobile-only {
            @include calc(height, "100% - 20px");
        }

        .main-block {
            padding: 0 15px 15px;

            @include tablet {
                padding: 0 20px 20px;
            }
        }

        &.flex-column {
            @include tablet-and-mobile {
                display: none;

                &.visible {
                    @include flexbox;
                    margin: 0;
                    width: 100%;
                }
            }

            .split-view & {
                display: none;

                &.visible {
                    @include flexbox;
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .top-block {
            @include flex-wrap(wrap);
            position: relative;
            padding: 10px 20px;
            justify-content: space-between;
            align-items: baseline;

            @include mobile-only {
                padding: 24px 15px 10px;
            }

            .split-view & {
                padding-top: 20px;
            }

            &__title {
                font-weight: 500;
                line-height: 28px;
            }

            .icon-wrap {
                vertical-align: middle;
                margin-left: 5px;

                @include mobile-only {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                }
            }
        }

        .info-block {
            padding: 10px 20px;
            max-height: 200px;
            background: $white-grey-medium;
            @include transition(all 0.3s);

            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }

            &:not(.collapse-info) {
                margin-bottom: 20px;
            }

            @include mobile-only {
                padding: 10px 15px;
                @include flex-direction(column);

                &:not(.collapse-info) {
                    margin-bottom: 15px;
                }
            }

            &.collapse-info {
                padding: 0;
                max-height: 0;
                border: none;
                overflow: hidden;
            }

            > div {
                margin: 3px 0;
                font-size: em(12);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(14);
                }

                @include tablet {
                    margin: 0 10px;
                }

                .icon-wrap {
                    vertical-align: middle;
                    margin-right: 3px;

                    &:not(.icon-wrap-theme):not(.icon-wrap-error) {
                        margin-right: 0;

                        &:before {
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }
                    }
                }
            }
        }
    }

    .grid-list-row.details {
        @include mobile-only {
            > .flex-column {
                height: 100%;
            }
        }

        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }

        > .with-form {
            height: 100%;

            .json-form {
                overflow-y: auto;
            }
        }
    }

    .with-panel-borders {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        @include calc(width, "100% + 20px");

        .action-buttons {
            .btn {
                @include mobile-only {
                    width: 50%;
                }
            }
        }
    }

    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }

    .grid-list-row.details,
    .with-panel-borders {
        @include mobile-only {
            @include expanded-panel-mobile;
        }

        .json-form {
            padding-top: 15px;

            @include mobile-only {
                @include calc(height, "100% - 52px");
            }
        }

        .action-buttons {
            padding-top: 20px;
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }
        }
    }

    &__global-queue {
        margin-right: 30px;

        .grid-list-row {
            &.is-dragging-item {
                @include dragging-item-style;
            }

            &.draggable {
                &:hover .drag-handle-icon {
                    @include themify($themes) {
                        background: themed("themeWhiteColor");
                    }
                }

                .drag-handle-icon {
                    z-index: 2;
                    top: 0;
                    height: 100%;
                    background: white;
                    @include themify($themes) {
                        border-right: 1px solid themed("themeLighterDirtyColor");
                    }

                    .icon-wrap {
                        @include border-radius(0);
                        @include flexbox;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                }

                .icon-wrap {
                    &.arrow-in-circle-up-empty,
                    &.arrow-in-circle-down-empty {
                        margin-right: 5px;
                        line-height: 29px;
                    }

                    &:not(.priority-none):not(.view-headline-list-text):hover {
                        @include rotate(180deg);
                    }
                }
            }

            &.details .action-buttons {
                @include mobile-only {
                    .btn {
                        width: 50%;

                        &.btn-light-blue {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
