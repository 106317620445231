@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.info-list {
    .info-list-row {
        margin-bottom: 5px;
        background: $white-light-grey;

        .info-list-field-value,
        .info-list-field-label {
            padding: 5px 10px;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        .info-list-field-label {
            min-width: 120px;
        }

        .info-list-field-value {
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }
    }
}
