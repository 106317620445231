@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";

.wf {
    &__settings-status {
        &-form {
            padding: 0 20px;

            &-field {
                margin: 20px 0 0 0;
            }
        }
    }
}
