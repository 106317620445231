@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.grid-list {
    &:not(.audit-side-panel-grid) {
        position: relative;
        margin: 0 -15px;
        padding: 0 15px;
        @include calc(width, "100% + 30px");

        @include tablet-only {
            margin: 0 -20px;
            padding: 0 20px;
            @include calc(width, "100% + 40px");
        }

        @include tablet-large {
            margin: 0 -25px;
            padding: 0 25px;
            @include calc(width, "100% + 50px");

            .sidenav-body & {
                margin: 0 -20px;
                padding: 0 20px;
                @include calc(width, "100% + 40px");
            }
        }

        .grid-list-row-container {
            @include themify($themes) {
                background: themed("themeWhiteColor");
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }
    }

    &.audit-side-panel-grid {
        @include flex-direction(column);
    }

    .grid-list-footer-placeholder {
        background-color: $white-light-grey;

        .theme-sightline &,
        .theme-sightlineHighContrast & {
            background-color: $sightline-white-light-grey;
        }
    }

    &:not(.audit-side-panel-grid) {
        .grid-list-left-side {
            width: 0;
            background: white;
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .grid-list-footer-placeholder {
            width: 72px;

            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
                border-left: 0;
            }
        }
    }

    &.grouped-grid {
        .grid-list-row {
            &.header {
                padding-left: 38px;
            }

            &.group-header-row {
                padding: 0 8px 0 0;
                cursor: pointer;

                .group-name {
                    padding: 0 8px;
                }

                .icon-wrap:not(.icon-wrap-disabled):before {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }

            &.group-row {
                padding: 0 8px 0 38px;
            }
        }
    }

    .only-items {
        border: none;
    }

    &--scrollable-left {
        position: relative;

        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            top: 0;
            left: 55px;
            width: 5px;
            height: 100%;
            background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));

            @include tablet-only {
                left: 60px;
            }

            @include tablet-large {
                left: 65px;
            }
        }

        &.no-data {
            &:before {
                display: none;
            }

            .grid-list-row-header .scroll-control.left {
                left: -22px;
            }
        }

        &.no-columns:before {
            height: 88px;
        }

        .grid-list-column {
            &.row-select {
                @include flexbox;
                align-items: center;
                background: rgba(249, 249, 249, 0.5);
                height: 100%;
            }
        }

        .grid-list-row-header .scroll-control.left {
            left: 16px;
        }
    }

    &--edge:before {
        display: none;
    }

    &--scrollable-right,
    &--edge {
        position: relative;

        /*

        &:after {
            content: '';
            z-index: 1;
            position: absolute;
            right: 100px;
            top: 0;
            width: 5px;
            @include calc(height, '100% - 47px');
            background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
        }

        &.no-data:after {
            height: 44px;
        }

        &.no-columns:after {
            height: 88px;
        }*/

        .grid-list-column {
            &.actions {
                @include flexbox;
                align-items: center;
                height: 100%;
                justify-content: flex-end;
            }
        }
    }
}
