@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.link-to-default-widget-styles {
    @include themify($themes) {
        color: themed("themeBaseColor");
    }

    cursor: pointer;
    font-weight: bold;
}
