@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.application-form .json-form {
    padding: 0 4px 4px 2px;

    fieldset {
        border: none;

        > legend {
            padding: 0;
            text-transform: initial;
            font-size: 14px;
        }
    }

    .form-group.section {
        padding: 0;
    }

    .page {
        padding: 0;
    }

    .section-container {
        padding: 20px 20px 15px;
        background: white;
        @include box-shadow-common($shadow-outer-hover-lighter);

        &:not(:first-child) {
            margin-top: 15px;
        }

        > .form-group > fieldset {
            > legend {
                padding: 0 0 5px;
                font-size: em(18);
            }

            > .field-description {
                padding-bottom: 5px;
                font-size: em(14);
            }
        }
    }

    .page-container,
    .section-container,
    .column-container {
        > .form-group > fieldset {
            > legend {
                font-weight: 500;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }

            > .field-description {
                @include themify($themes) {
                    color: themed("themeColorLocked");
                }
            }
        }
    }

    .page-container {
        > .form-group > fieldset {
            > legend {
                padding: 5px 0 10px;
                font-size: em(24);
            }

            > .field-description {
                padding: 0 0 15px;
            }
        }
    }

    .column-container {
        margin: 0 -15px;
        padding: 15px 20px 5px;

        @include mobile-only {
            flex: inherit !important;
            @include calc(width, "100% + 30px");
        }

        + .column-container {
            @include tablet {
                margin-left: 20px;
            }
        }

        > .form-group > fieldset {
            > legend {
                margin-left: -5px;
                padding: 5px 0 0;
                font-size: em(16);
            }

            > .field-description {
                margin-left: -5px;
                padding: 5px 0 0;
                font-size: em(12);
            }
        }
    }

    .field-container {
        margin: 5px -15px 0;
        padding: 15px 10px 10px;
        @include calc(width, "100% + 30px");

        &.field-hidden {
            @include themify($themes) {
                background: themed("themeBaseColorDesaturatedSemiTransparent5");
            }
        }

        .form-group.field {
            margin-bottom: 0;
        }

        .react-datepicker-wrapper {
            width: 300px !important;
        }
    }

    .page-container,
    .section-container,
    .column-container,
    .field-container {
        @include border-radius(8px);
    }
}
