@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.form-builder {
    &.with-sticky-bottom-panel {
        padding-bottom: 54px;
    }

    .panel-with-sidepanel__main-content {
        width: 0;
        min-width: 370px;
    }

    .sticky-sidepanel__body {
        padding: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .nothing-found-block {
            margin: 10px 5px 0;
        }
    }

    .sticky-bottom-panel {
        .switcher-text {
            font-size: em(12);
            font-weight: 500;
            cursor: pointer;
        }

        .disabled-fields-switcher {
            margin-left: 20px;

            .icon-wrap.block-not-interested-empty {
                margin: -2px 4px 0 0;
            }
        }

        .rebuild-configuration-button {
            margin-left: 10px;
        }
    }

    .form-elements {
        padding: 20px;

        &__errors,
        .selected-part-properties {
            @include calc(width, "100% + 20px");
        }

        &__errors {
            margin: -10px -10px 15px;
        }

        .form-actions {
            margin-left: 10px;

            .icon-wrap.icon-wrap-rounded-square {
                margin-left: 10px;
                background: white;
            }
        }

        .selected-part-properties {
            margin: -10px;
            background: white;
            @include box-shadow-common($shadow-outer-hover);
            @include border-radius(8px);
            border: 1px solid transparent;

            &.has-errors {
                @include box-shadow-common($red-shadow);
                border-color: $red;

                .element-properties__title-properties {
                    background: $red-gradient-to-bottom;
                }
            }
        }

        .element-properties {
            overflow: auto;
            padding: 10px 20px;

            &__title-properties {
                padding: 15px 20px;
                @include themify($themes) {
                    background: themed("themeSemiWhiteDiagonalColor");
                }
                text-transform: uppercase;

                span {
                    margin-right: 5px;
                    font-weight: 700;
                }

                .icon-with-label {
                    text-transform: initial;
                }
            }

            .json-form {
                padding: 0;

                input,
                textarea {
                    width: 100%;
                    max-width: 100%;
                    resize: vertical;
                }

                .form-group {
                    padding: 5px 0;

                    &.field-array {
                        .array-item {
                            padding: 15px;
                            @include themify($themes) {
                                background: themed("themeWhiteColor");
                            }
                            border: none;

                            &:not(:last-child) {
                                margin: 0 0 5px;
                            }

                            .form-group {
                                padding: 0;
                            }

                            > div > .schema-container > .form-group {
                                margin: 15px 0 0;
                            }
                        }
                    }

                    .array-item-remove {
                        margin: 0;
                        opacity: 1;

                        &:before {
                            content: "\F118";
                            @include icon-iconoteka;
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }

                        &:after {
                            display: none;
                        }
                    }
                }

                .schema-container > .form-group > fieldset {
                    border: none;

                    > legend {
                        position: relative;
                        padding: 15px 0;
                        width: 100%;
                        font-size: 14px;
                        @include themify($themes) {
                            color: themed("themeColorLocked");
                        }

                        span {
                            z-index: 1;
                            position: relative;
                            left: -10px;
                            padding: 0 10px;
                            background: white;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: 23px;
                            left: -20px;
                            @include calc(width, "100% + 40px");
                            height: 1px;
                            @include themify($themes) {
                                background: themed("themeDirtyColor");
                            }
                        }
                    }

                    .dictionary-widget .array-item {
                        padding: 30px 15px 15px;
                        @include themify($themes) {
                            background: themed("themeWhiteColor");
                        }

                        &:not(:last-child) {
                            margin: 0 0 5px;
                        }

                        .field-group:not(:last-child) {
                            margin-bottom: 5px;
                        }
                    }

                    .array-item-add button {
                        padding: 0;
                        font-family: $base-font-family;

                        &:after {
                            text-decoration: none;
                            font-weight: 500;
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }

                        &:before {
                            content: "\EB90";
                            @include icon-iconoteka;
                            margin-right: 5px;
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }
                        }

                        &:before,
                        &:after {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }

                .conditional-validation {
                    .array-item-move-up,
                    .array-item-move-down {
                        display: none;
                    }

                    .field-array fieldset > legend > span {
                        padding-right: 0;
                        @include themify($themes) {
                            background: themed("themeWhiteColor");
                        }

                        &.required {
                            padding-right: 5px;
                            padding-left: 0;
                        }
                    }

                    .array-item-list {
                        .error-detail {
                            display: none;
                        }

                        .array-item-list {
                            .array-item {
                                padding: 0;

                                .schema-container > .form-group {
                                    margin-top: 0;
                                    margin-bottom: 10px;
                                    padding-right: 25px;

                                    &--between {
                                        padding-right: 0;
                                    }
                                }

                                .control-label {
                                    display: none;
                                }

                                .array-item-toolbox {
                                    top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .form-pages {
        .json-form {
            margin: -10px -10px 10px;
            padding: 10px;
            @include calc(width, "100% + 10px");
            height: auto;

            .rjsf {
                flex: 1 1;

                .page-container,
                .page {
                    height: 100%;
                }
            }

            fieldset {
                border: none;

                > legend {
                    padding: 0;
                    text-transform: initial;
                    font-size: 14px;
                }
            }

            .page {
                padding: 0;
            }

            .form-group.section {
                padding: 0;
            }

            // all drop targets
            .object-properties.is-over .drop-target {
                position: relative;
                flex-grow: 0;
                z-index: 1;

                .drop-target-line {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -2px;
                    padding: 5px 0;

                    @include themify($themes) {
                        background-color: themed("themeLightColor");
                    }
                }

                .drop-target-area {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -20px;
                    padding: 24px 0;
                }

                &:first-child {
                    .drop-target-line {
                        top: -10px;
                    }

                    .drop-target-area {
                        top: -28px;
                    }
                }

                &:last-child {
                    .drop-target-line {
                        top: -5px;
                    }

                    .drop-target-area {
                        top: -23px;
                    }
                }

                &.is-over .drop-target-line {
                    @include themify($themes) {
                        background-color: themed("themeBaseColor");
                    }
                }
            }

            // override styles for Column drop targets
            .section > fieldset > .object-properties.is-over > .drop-target {
                + .column-container + .drop-target + .column-container {
                    margin-left: 20px;
                }

                + .column-container + .column-container + .drop-target + .column-container {
                    margin-left: 20px;
                }

                .drop-target-line {
                    top: 0;
                    bottom: 0;
                    left: 10px;
                    padding: 0 5px;
                }

                .drop-target-area {
                    top: 0;
                    bottom: 0;
                    left: -2px;
                    padding-right: 40px;
                }

                &:first-child {
                    .drop-target-line {
                        left: -10px;
                    }

                    .drop-target-area {
                        left: -20px;
                        padding-right: 20px;
                    }
                }

                &:last-child {
                    .drop-target-line {
                        left: 0;
                    }

                    .drop-target-area {
                        padding-right: 20px;
                    }
                }
            }

            // add some space to empty object-properties container to be able to drop elements inside
            .object-properties.empty {
                padding: 10px;
            }

            // drop target for empty section
            .section > fieldset > .object-properties.empty .drop-target {
                flex-grow: 1;

                .drop-target-line {
                    top: -10px;
                }

                .drop-target-area {
                    top: -30px;
                }
            }

            .schema-container {
                position: relative;
                cursor: pointer;

                &.section-container {
                    padding: 20px 20px 5px;
                    background: white;
                    @include box-shadow-common($shadow-outer-hover-lighter);

                    & + .section-container,
                    & + .drop-target + .section-container {
                        margin-top: 15px;
                    }

                    &.field-hovered,
                    &.field-selected {
                        @include themify($themes) {
                            border-color: themed("themeBaseColorDesaturatedSemiTransparent");
                            @include box-shadow(0, 0, 8px, 0 themed("themeBaseColorDesaturatedSemiTransparent"));
                        }
                    }

                    &.field-selected {
                        &:before {
                            content: "section selected";
                        }

                        &.field-error:before {
                            content: "Error section selected";
                        }
                    }

                    &:before {
                        content: "section";
                        @include themify($themes) {
                            background: themed("themeColorLocked");
                        }
                    }

                    &.field-error:before {
                        content: "Error section";
                    }

                    > .form-group > fieldset {
                        > legend {
                            padding: 0 0 5px 2px;
                            font-size: em(18);
                        }

                        > .field-description {
                            padding-left: 2px;
                            padding-bottom: 5px;
                            font-size: em(14);
                        }
                    }
                }

                &.page-container,
                &.section-container,
                &.column-container {
                    > .form-group > fieldset {
                        > legend {
                            font-weight: 500;
                            @include themify($themes) {
                                color: themed("themeColorDarkGrey");
                            }
                        }

                        > .field-description {
                            @include themify($themes) {
                                color: themed("themeColorLocked");
                            }
                        }

                        > .object-properties {
                            .field-container {
                                .form-group {
                                    .react-datepicker-wrapper {
                                        width: 300px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &.page-container {
                    padding: 20px 10px 10px;

                    &.field-hovered,
                    &.field-selected {
                        @include themify($themes) {
                            border-color: themed("themeDarkColorSemiTransparent70");
                            @include box-shadow(0, 0, 8px, 0 themed("themeDarkColorSemiTransparent70"));
                        }
                    }

                    &.field-selected {
                        &:before {
                            content: "page selected";
                        }

                        &.field-error:before {
                            content: "Error page selected";
                        }
                    }

                    &:before {
                        content: "page";
                        @include themify($themes) {
                            background: themed("themeDarkColor");
                        }
                    }

                    &.field-error:before {
                        content: "Error page";
                    }

                    > .form-group > fieldset {
                        > legend {
                            padding: 0 0 10px;
                            font-size: em(24);
                        }

                        > .field-description {
                            padding: 0 0 15px;
                        }
                    }
                }

                .drop-target {
                    &:not(:first-child) {
                        + .column-container {
                            margin-left: 20px;
                        }
                    }
                }

                &.column-container {
                    margin: 0 -15px;
                    padding: 15px 20px 5px;

                    &.field-hovered,
                    &.field-selected {
                        @include themify($themes) {
                            border-color: themed("themeBoxShadowBorderColor");
                            @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
                        }
                    }

                    &.field-selected {
                        &:before {
                            content: "column selected";
                        }

                        &.field-error:before {
                            content: "Error column selected";
                        }
                    }

                    &:before {
                        content: "column";
                        @include themify($themes) {
                            background: themed("themeBaseColor");
                        }
                    }

                    &.field-error:before {
                        content: "Error column";
                    }

                    > .form-group > fieldset {
                        > legend {
                            margin-left: -5px;
                            padding: 5px 0 0;
                            font-size: em(16);
                        }

                        > .field-description {
                            margin-left: -5px;
                            padding: 5px 0 0;
                            font-size: em(12);
                        }
                    }
                }

                &.field-container {
                    margin: 5px -15px 0;
                    padding: 15px 10px 0;
                    @include calc(width, "100% + 30px");

                    &.field-hovered,
                    &.field-selected {
                        @include themify($themes) {
                            border-color: themed("themeBaseColorSemiTransparent30");
                            @include box-shadow(0, 0, 8px, 0 themed("themeBaseColorSemiTransparent30"));
                        }
                    }

                    &.field-removed {
                        @include themify($themes) {
                            background: themed("themeBaseColorDesaturatedSemiTransparent5");
                        }

                        .control-label {
                            padding-right: 40px;
                        }

                        &.field-administrative {
                            .control-label {
                                padding-right: 70px;
                            }

                            &.field-hidden {
                                .control-label {
                                    padding-right: 100px;
                                }
                            }
                        }
                    }

                    &.field-administrative {
                        @include themify($themes) {
                            background: themed("themeSemiWhiteColor");
                        }

                        &.field-hidden {
                            .control-label {
                                padding-right: 70px;
                            }
                        }
                    }

                    &.field-hidden {
                        @include themify($themes) {
                            background: themed("themeBaseColorDesaturatedSemiTransparent5");
                        }

                        .control-label {
                            padding-right: 40px;
                        }
                    }

                    &.field-selected {
                        &:before {
                            content: "field selected";
                        }

                        &.field-error:before {
                            content: "Error field selected";
                        }
                    }

                    &:before {
                        content: "field";
                        @include themify($themes) {
                            background: themed("themeLightColor");
                        }
                    }

                    &.field-error {
                        padding-top: 42px;

                        &:before {
                            content: "Error field";
                        }
                    }
                }

                &.page-container,
                &.section-container,
                &.column-container,
                &.field-container {
                    border: 2px solid transparent;
                    @include border-radius(8px);

                    &.field-hovered,
                    &.field-error {
                        &:before {
                            display: block;
                        }
                    }

                    &.field-error {
                        @include box-shadow-common($red-shadow);
                        border-width: 1px;

                        &.field-hovered,
                        &.field-selected {
                            @include box-shadow-common($red-shadow);
                            border-color: $red;
                        }

                        &:before {
                            background: $red;
                        }
                    }

                    &:before {
                        display: none;
                        position: absolute;
                        top: -1px;
                        left: -1px;
                        @include border-radius(8px 0 8px 0);
                        padding: 0 7px;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: em(9);
                        line-height: 16px;
                        color: white;
                    }
                }

                &.field-selected {
                    &:before {
                        display: block;
                    }
                }

                .icon-wrap {
                    &.block-not-interested-empty,
                    &.administrative-field-icon,
                    &.removed-field-icon,
                    &.clear-close {
                        position: absolute;
                    }

                    &.block-not-interested-empty {
                        right: 45px;
                        @include themify($themes) {
                            background: themed("themeBaseColorDesaturatedSemiTransparent10");
                        }
                        @include border-radius(0 0 50% 50%);
                    }

                    &.administrative-field-icon:after {
                        left: 0;
                    }

                    &.removed-field-icon {
                        @include border-radius(0 0 50% 50%);
                        @include themify($themes) {
                            background: themed("themeBaseColorDesaturatedSemiTransparent10");
                        }
                    }

                    &.administrative-field-icon,
                    &.block-not-interested-empty,
                    &.removed-field-icon {
                        top: -1px;
                    }

                    &.clear-close {
                        top: 0;
                        right: 0;

                        &[hidden] {
                            + .administrative-field-icon {
                                right: 10px;

                                + .removed-field-icon {
                                    right: 45px;
                                }

                                + .block-not-interested-empty {
                                    right: 45px;

                                    + .removed-field-icon {
                                        right: 80px;
                                    }
                                }
                            }

                            + .block-not-interested-empty {
                                right: 10px;

                                + .removed-field-icon {
                                    right: 45px;
                                }
                            }

                            + .removed-field-icon {
                                right: 10px;
                            }
                        }

                        + .removed-field-icon {
                            right: 30px;
                        }

                        + .administrative-field-icon {
                            right: 30px;

                            + .removed-field-icon {
                                right: 65px;
                            }

                            + .block-not-interested-empty {
                                right: 65px;

                                + .removed-field-icon {
                                    right: 100px;
                                }
                            }
                        }

                        + .block-not-interested-empty {
                            right: 30px;

                            + .removed-field-icon {
                                right: 65px;
                            }
                        }
                    }
                }
            }
        }
    }

    .json-form {
        /* Wrap error message about error in schema */
        .schema-container pre {
            white-space: pre-line;
        }
    }
}
