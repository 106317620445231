@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.all-programs-main-panel {
    padding: 20px 0;
}

.all-programs-main-panel,
.panel.panel-program-criteria {
    .data-grid-controls {
        @include mobile-only {
            position: relative;
            padding-top: 30px;
            justify-content: space-between;

            .icon-with-label {
                &:last-child {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    @include tablet {
        .program-form {
            @include flexbox;
            @include flex-direction(column);
            max-height: 364px;
            flex-grow: 1;

            @include ie {
                height: 0;
            }

            .json-form {
                flex: 1;
                @include flexbox;
                padding: 0;
                overflow: hidden;

                .rjsf {
                    flex: 1;
                    @include flexbox;
                    @include flex-direction(column);
                    height: 100%;

                    > .schema-container {
                        overflow-y: auto;
                        flex: 1;
                        padding: 15px 20px 20px;
                    }

                    .action-buttons {
                        position: relative;
                        margin-top: 0;
                        padding: 20px;
                        @include box-shadow-common($shadow-outer-center);
                    }
                }
            }
        }
    }
}

.panel.panel-program-criteria {
    .data-grid-controls {
        margin: -36px 0 12px;
    }

    .program-form {
        @include tablet {
            margin-top: -5px;
        }
    }
}

.program-form {
    position: relative;
    @include expanded-panel;
    margin-top: -5px;

    @include mobile-only {
        @include expanded-panel-mobile;
    }

    .json-form {
        @include expanded-panel-json-form;

        @include mobile-only {
            overflow-y: auto;
            @include calc(height, "100% - 52px");
        }

        .object-properties {
            > .schema-container {
                @include mobile-only {
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }

                @include tablet-only {
                    @include calc(width, "(100% / 3) - 20px");
                }
            }
        }
    }
}

.program-template-file-upload-form {
    margin: 0px -10px;
    background-color: white;
    padding: 10px 10px 0px 10px;

    .json-form {
        margin-top: 15px;
    }

    .program-tempalte-file-upload-message {
        margin-bottom: 10px;
    }
}

.program-template-select-form {
    .file-template-name {
        margin: 5px;
    }
}
