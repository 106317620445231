@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.search-results {
    &__see-all.icon-with-label {
        padding: 15px 10px;
        font-size: 15px;
        text-align: center;

        &:after {
            position: relative;
            top: 3px;
        }
    }

    &__counter {
        margin: 15px 0;

        > span {
            display: inline-block;
            vertical-align: middle;
        }

        .expandable-panel + & {
            margin-top: 0;
        }

        &__value {
            font-size: em(28);
            font-weight: 700;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        &__text {
            margin: 2px 0 0 7px;
            font-size: em(11);
            font-weight: 500;
            line-height: 1.2;

            .font-size-large & {
                font-size: em(13);
            }
        }
    }
}
