@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

@mixin add-tag-input-wrapper {
    & .input-wrapper {
        border-radius: 4px;
        min-height: 32px;
        line-height: 32px;
        max-height: 300px;
        overflow: auto;

        @include themify($themes) {
            border: 1px solid themed("themeLighterDirtyColor");
            box-shadow: inset 0 1px 2px 0 themed("themeLighterDirtyColor");
        }

        &.focused {
            @include themify($themes) {
                border-color: themed("themeLightColor");
                box-shadow: 0 0 8px 0 themed("themeLightColor");
            }
        }

        & input {
            display: inline-block;
            width: 100%;
            border: none;
            box-shadow: none;
            background: transparent;
        }
    }
}

.sub-header {
    .btn-group.transparent {
        display: inline-block;
        vertical-align: middle;
    }

    &.tags-and-flags-details-expanded,
    &.tags-and-attributes-details-expanded {
        position: relative;
        @include themify($themes) {
            background: themed("themeBaseColorSemiTransparent7");
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 76px;
            background: white;
        }
    }

    & + .sub-header {
        margin-top: 10px;
    }

    .section-title {
        display: inline-block;
        width: 100%;
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeColorDarkGrey");
        }

        @include mobile-only {
            font-size: em(13);

            .font-size-large & {
                font-size: em(14);
            }
        }

        @include tablet-large {
            width: 70px;
            min-width: 70px;

            &.invoice {
                width: 80px;
                min-width: 80px;
            }

            .split-view & {
                width: 100%;
                min-width: initial;
            }
        }

        > span {
            font-size: 11px;
            @include themify($themes) {
                color: themed("themeColorBaseGrey");
            }

            .font-size-large & {
                font-size: 13px;
            }
        }
    }

    .sub-header-visible-part {
        @include flexbox;
        justify-content: space-between;

        @include mobile-only {
            padding: 0 15px;
        }

        &.app-details,
        &.invoice-details {
            padding-top: 15px;
            padding-bottom: 15px;
            height: 76px;

            &.tags-and-flags-details-expanded,
            &.tags-and-attributes-details-expanded {
                padding-top: 0;
                padding-bottom: 0;

                .header-items-list {
                    padding: 15px 125px 15px 0;
                    background: white;
                    flex: 1;
                }
            }

            .header-items-list {
                padding-right: 125px;
            }

            .scroll-control {
                @include flexbox;
                align-items: center;

                &.right {
                    right: 77px;
                    justify-content: flex-end;
                }

                &.left {
                    justify-content: flex-start;
                }

                .icon-wrap {
                    position: initial;
                }
            }
        }

        .scroll-controls {
            z-index: 1;
        }

        .scroll-control {
            &.right,
            &.left {
                width: 70px;
            }

            &.right {
                right: -5px;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 45%);

                .icon-wrap {
                    text-align: right;
                }
            }

            &.left {
                left: -9px;
                background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 45%);

                .icon-wrap {
                    text-align: left;
                }
            }

            .icon-wrap:before {
                font-size: 41px;
            }
        }

        .header-items-list {
            @include tablet-and-mobile {
                width: 100%;
            }

            @include tablet-only {
                .customer-view &,
                .utility-budget-view & {
                    width: 100%;
                    padding-right: 0;
                }
            }

            @include tablet-large {
                position: relative;
                @include flexbox;
                overflow: hidden;
                padding-right: 10px;

                .split-view .customer-view &,
                .split-view .utility-budget-view & {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        .header-items-list-wrap {
            @include tablet-and-mobile {
                width: 100%;
            }

            @include tablet-only {
                @include flex-wrap(wrap);
            }

            @include tablet {
                @include flexbox;

                .customer-view &,
                .utility-budget-view & {
                    width: 100%;
                    padding-right: 0;
                }
            }

            @include tablet-large {
                overflow: auto;
                @include scrollbar-hide;

                .split-view .customer-view &,
                .split-view .utility-budget-view & {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        .utility-view & {
            @include tablet-only {
                @include flex-wrap(nowrap);
                align-items: center;

                .section-title {
                    width: 70px;
                }
            }
        }

        .split-view & {
            position: relative;

            .header-items-list-wrap {
                @include flex-wrap(wrap);
            }

            .section-title {
                position: absolute;
                left: 0;
                top: 7px;
            }
        }

        .section-title,
        .expander-wrap {
            @include tablet-large {
                @include flexbox;
                align-items: center;
            }
        }

        .expander-wrap {
            pointer-events: none;

            @include tablet-and-mobile {
                display: none;
            }

            .split-view & {
                display: none;
            }

            &.app-flags-and-tags.left-right-layout {
                @include tablet-large {
                    justify-content: center;
                    flex-shrink: 0;
                    margin-left: 80px;

                    &:before {
                        @include calc(top, "50% - 13px");
                    }
                }

                .icon-with-label {
                    pointer-events: auto;
                }

                span {
                    display: block;
                    font-size: em(10);
                    font-weight: 500;
                    text-align: center;

                    .font-size-large & {
                        font-size: em(12);
                    }
                }
            }

            &.app-flags-and-tags.top-right-layout,
            &.invoice-flags-and-attributes {
                .info-hint {
                    font-size: em(13);
                    font-weight: 500;
                }

                .counter-box {
                    margin-right: 3px;
                    @include scale(0.9);
                }

                .tags,
                .attributes {
                    margin: 0 5px;
                }

                .icon-wrap {
                    pointer-events: auto;
                }

                &.expanded {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -76px;
                        width: 0;
                        height: 0;
                        @include themify($themes) {
                            border-bottom: 76px solid themed("themeBaseColorSemiTransparent7");
                        }
                        border-left: 76px solid transparent;
                    }
                }
            }
        }
    }

    .header-item {
        margin-top: 5px;
        padding-right: 20px;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }

        &.App.ID,
        &.Ref.ID {
            .header-value {
                word-break: break-all;
            }
        }

        &.Portal,
        &.Application,
        &.App.ID,
        &.Hostname {
            .icon-with-label {
                margin-left: 10px;
                padding-right: 14px;

                &:before {
                    top: -5px;
                    right: -10px;
                    @include scale(0.55);
                }
            }
        }

        &.Portal,
        &.Application,
        &.Hostname,
        &.Number {
            .header-value {
                @include tablet-only {
                    word-break: break-all;
                }
            }
        }

        @include tablet {
            margin: 7px 0;
        }

        @include mobile-only {
            position: relative;
            @include flexbox;
            @include flex-direction(row);
            justify-content: space-between;
            margin: 7px 0;
            padding: 0;
            width: 100%;

            &:before {
                content: "";
                position: absolute;
                top: 11px;
                left: 0;
                width: 100%;
                height: 1px;
                @include themify($themes) {
                    border-bottom: 1px dashed themed("themeDirtyColor");
                }
            }

            &.Invoice.Document {
                .header-value {
                    word-break: break-all;
                }
            }
        }

        @include tablet-only {
            @include calc(width, "100% / 3");

            .utility-view &,
            .utility-contract-view &,
            .create-new-application-page &,
            .create-application-from-scan &,
            .app-form-pages & {
                width: initial;
                position: relative;
                white-space: nowrap;
                margin-right: 20px;

                &:not(:first-child):after {
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: -21px;
                    width: 1px;
                    @include calc(height, "100% - 6px");
                    @include themify($themes) {
                        background: themed("themeDirtyColor");
                    }
                }
            }
        }

        @include tablet-large {
            position: relative;
            white-space: nowrap;
            margin-right: 20px;

            &:not(:first-child):after {
                content: "";
                position: absolute;
                top: 3px;
                left: -21px;
                width: 1px;
                @include calc(height, "100% - 6px");
                @include themify($themes) {
                    background: themed("themeDirtyColor");
                }
            }
        }

        .split-view & {
            @include calc(width, "100% / 3");
            padding-right: 40px;
            white-space: normal;
            margin-right: 0;

            &.Portal,
            &.Application,
            &.Hostname,
            &.Number {
                .header-value {
                    word-break: break-all;
                }
            }

            &:after {
                display: none;
            }
        }

        .split-view .app-form-pages &,
        .split-view .utility-view &,
        .split-view .utility-contract-view & {
            position: relative;
            white-space: nowrap;
            margin-right: 20px;
            padding-right: 20px;
            width: initial;

            &:after {
                display: block;
            }
        }

        .split-view .create-new-application-page &,
        .split-view .create-application-from-scan & {
            &:after {
                display: block;
            }
        }

        .header-item-title {
            @include themify($themes) {
                color: themed("themeBaseColorDesaturatedSemiTransparent70");
            }
            white-space: nowrap;

            @include mobile-only {
                margin-right: 20px;
                padding-right: 5px;
            }
        }

        .header-item-title,
        .header-value {
            @include mobile-only {
                position: relative;
                background: white;
            }
        }

        .header-value {
            font-weight: 500;

            @include mobile-only {
                padding-left: 5px;
                text-align: right;
            }

            @include tablet {
                display: inline-block;
                margin-top: 2px;
            }

            .clickable {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
                cursor: pointer;
            }

            &.editable {
                position: relative;
                padding-right: 18px;
            }

            > .icon-wrap {
                position: absolute;
                right: -4px;
                top: -2px;
                @include scale(0.8);
                width: inherit;
                height: inherit;
                line-height: inherit;

                @include firefox {
                    top: -3px;
                }
            }
        }
    }

    .sub-header-hidden-part {
        margin-top: 25px;

        @include mobile-only {
            padding: 25px 15px;
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }
            flex: 1;
            overflow-y: auto;

            @include ie {
                flex: 1 0 auto;
            }
        }

        @include tablet-large {
            margin-top: 15px;
        }

        @include tablet-large {
            display: none;
            margin-top: 15px;
            padding: 25px 0 7px;
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }

            .split-view & {
                display: block;
            }
        }

        &.visible {
            display: block;
        }

        .btn-group + .add-tag-group {
            margin-left: 8px;
        }

        .btn-tag {
            @include tablet-and-mobile {
                margin-top: 7px;
            }

            .split-view & {
                margin-top: 7px;
            }
        }

        &.app-flags-and-tags,
        &.invoice-flags-and-attributes {
            position: relative;
            margin-top: 0;
            padding-top: 10px;
            padding-bottom: 9px;
            border-top: none;
            white-space: nowrap;

            .section-title {
                margin-right: 8px;
                width: initial;
                min-width: initial;
            }

            .scroll-control {
                &.right,
                &.left {
                    .icon-wrap {
                        top: 0;
                        height: 100%;
                        @include flexbox;
                        align-items: center;
                    }
                }

                &.right {
                    right: 10px;

                    @include tablet-large-and-desktop-small {
                        right: 0;
                    }

                    body.layout-full-screen & {
                        @include desktop-large {
                            right: -10px;
                        }
                    }

                    .icon-wrap {
                        justify-content: flex-start;
                    }
                }

                &.left {
                    left: 10px;

                    @include tablet-large-and-desktop-small {
                        left: 0;
                    }

                    body.layout-full-screen & {
                        @include desktop-large {
                            left: -10px;
                        }
                    }

                    .icon-wrap {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    .sub-header-flags-items {
        margin-bottom: 25px;

        @include tablet-large {
            margin-bottom: 15px;
        }

        &.reset-margin {
            margin: 0 20px 0 0;
            padding-right: 20px;
            @include themify($themes) {
                border-right: 2px solid themed("themeDirtyColor");
            }
        }
    }

    .sub-header-tags-items {
        width: 100%;
        display: block;

        &.no-width {
            width: inherit;
        }

        > .add-tag-group {
            display: none;
        }

        .add-tag-group {
            position: relative;
            vertical-align: middle;

            .btn-group {
                margin-top: 10px;

                @include mobile-only {
                    width: 100%;
                    margin-top: 20px;

                    .btn {
                        @include calc(width, "50% - 5px");

                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .content-wrap-backdrop {
                .layout-left & {
                    display: none;
                }
            }

            .content-wrap-popup {
                white-space: initial;

                @include tablet {
                    left: 30px;
                    top: -36px;
                    width: 242px;
                }
            }
        }

        .btn-group {
            > .add-tag-group {
                display: inline-block;

                @include add-tag-input-wrapper;

                & .hint {
                    margin-bottom: 10px;
                }

                & .content-wrap-popup {
                    @include tablet {
                        width: 400px;
                    }
                }

                & .tags .btn {
                    margin: 5px;
                }
            }
        }

        .add-tag-group {
            .window.layout-left & {
                @include add-tag-input-wrapper;

                & .hint {
                    margin-bottom: 10px;
                }

                & .tags .btn.btn-tag {
                    margin: 5px 10px 0px 10px;
                    width: auto;
                }
            }
        }
    }
}
