@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.cis_details {
    overflow-y: scroll;
    padding: 20px;

    .modal.cis-lookup-modal & {
        padding: 0;
        overflow: auto;
    }
}

.cis-details-inline-panel {
    .cis_details {
        padding: 10px;

        @include mobile-only {
            flex: 1;

            @include ie {
                flex: 1 0 auto;
            }

            @include firefox {
                height: 0;
            }
        }

        @include tablet {
            height: 250px;
        }
    }

    .grid-details-panel-footer {
        z-index: 1;
        padding: 15px 20px;
        @include box-shadow-common($shadow-outer-center);

        @include tablet {
            padding: 18px 20px;
        }

        @include mobile-only {
            .btn-group:first-child {
                flex: 1;
                margin-right: 17px;

                @include ie {
                    flex: 1 0 auto;
                }

                .btn {
                    width: 100%;
                }
            }

            .flex-one {
                display: none;
            }
        }
    }
}

.modal.cis-lookup-modal {
    .modal-content .modal-body {
        @include tablet {
            max-height: 400px;
        }
    }
}

.cis_details.cis-search-item-details,
.cis-details-inline-panel,
.modal.cis-lookup-modal {
    .info-list {
        overflow: hidden;
    }

    .info-list-row {
        margin: 0 0 10px;
        justify-content: space-between;
        background: transparent;
        @include themify($themes) {
            border-top: 1px dashed themed("themeDirtyColor");
        }

        &:first-child {
            margin-top: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .info-list-field-label,
    .info-list-field-value {
        position: relative;
        top: -13px;
        padding: 0;
    }

    .info-list-field-label {
        margin-right: 20px;
        padding-right: 5px;
        padding-right: 5px;
        min-width: auto !important;
        max-width: 50%;
        word-break: break-word;
        background: white;
        @include themify($themes) {
            color: themed("themeCISLookupLabelColor");
        }
    }

    .info-list-field-value {
        text-align: right;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
        word-break: break-word;
        max-width: 50%;

        @include ie {
            word-wrap: break-word;
        }

        // display as inline so that only the text has background, which
        // makes the dashed line stop at the right place
        .raw-html {
            display: inline;
            background-color: white;
            padding-left: 5px;
        }
    }
}
