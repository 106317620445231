.list-properties {
    margin-left: 20px;
    .property-list {
        .property-list-item {
            margin-left: 0;
            .property-list-item-inner {
                .text-label {
                    margin-right: 25px;
                }
                .select-box {
                    height: 40px;
                    width: 60px;

                    .select-box__text {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
