@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.login-form-wrap {
    display: inline-block;
    width: 100%;

    .vision-login & {
        @include tablet {
            width: 480px;
            padding-bottom: 20px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    @include tablet-only {
        .sightline-login & {
            padding: 30px 0;
            max-width: 280px;
        }
    }

    @include tablet-large {
        .sightline-login & {
            width: 280px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    @include landscape-view-large {
        margin-top: 0;
    }

    &.expanded-width {
        max-width: 650px;

        .login-square {
            padding: 25px 25px 30px;
        }

        @include tablet-large {
            width: auto;
        }

        .login-square {
            @include tablet-only {
                margin: 0 40px;
            }
        }
    }

    .form {
        @include mobile-only {
            .sightline-login & {
                padding: 0 20px 20px;
            }
        }

        .msg-square.error-msg {
            margin: -35px -55px 35px !important;
            @include calc(width, "100% + 110px");

            @include mobile-only {
                margin: -40px -35px 35px !important;
                @include calc(width, "100% + 70px");
            }
        }

        h3 {
            @include landscape-view {
                font-size: em(15);
                line-height: 24px;

                .vision-login & {
                    text-align: center;
                }
            }

            &.text-center {
                margin-bottom: 10px;
            }

            + p {
                margin: 5px 0 20px;

                @include landscape-view {
                    text-align: center;
                }
            }
        }

        .field-group {
            margin: 20px 0 12px;

            @include landscape-view {
                margin: 10px 0 0;
            }

            .theme-mode-light & {
                margin: 10px 0 0;
            }
        }

        .captcha {
            margin: 20px 0;
        }
    }

    .login-form-link {
        font-size: em(12);
        font-weight: 500;
        @include themify($themes) {
            color: themed("themeBaseColor");
        }
        cursor: pointer;

        .font-size-large & {
            font-size: em(14);
        }

        &.password {
            float: right;
        }
    }

    .form-btn-wrap {
        margin-top: 30px;
        @include flexbox;
        justify-content: flex-end;
        align-items: center;

        @include landscape-view {
            margin-top: 20px;
        }

        .theme-mode-light & {
            margin-top: 20px;
        }

        &.password .btn {
            @include mobile-only {
                min-width: inherit;
            }
        }

        &.qr-continue {
            margin-top: 0px !important;

            @include mobile-only {
                @include flex-wrap(wrap);
            }

            @include tablet-and-mobile {
                margin-top: 20px;
            }

            .field-group {
                margin: 0 0 15px;
                width: 100%;

                @include tablet {
                    margin: 0;
                    width: 300px;
                }
            }

            .flex-one {
                height: 32px;

                @include mobile-only {
                    justify-content: start;
                }
            }

            .login-form-link {
                @include tablet {
                    margin-left: 20px;
                    text-align: right;
                }
            }
        }

        .btn {
            display: inline-block;
        }
        .next {
            width: 100%;
        }

        .login-form-link {
            margin-right: 20px;
            text-align: left;
        }
    }
}

.login-form-header.username {
    margin-bottom: 22px;
    text-align: center;

    @include landscape-view {
        margin-bottom: 12px;
    }

    .sightline-login & {
        margin-bottom: 15px;
    }

    > div {
        display: inline-block;
        padding: 2px 3px;
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
        @include border-radius(16px);

        span {
            display: inline-block;
            vertical-align: middle;
            font-weight: 500;
        }
    }

    .icon-wrap {
        position: relative;
        top: 1px;
        vertical-align: middle;

        &.account-in-circle-filled {
            margin-right: 15px;

            &:before {
                font-size: 23px;
            }
        }

        &.edit-empty {
            margin-left: 15px;
        }
    }
}
