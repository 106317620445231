@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.dashboard-panels {
    .panel-areas {
        position: relative;
    }

    .panel-column {
        display: inline-block;
        vertical-align: top;
        padding: 20px 0;

        @include tablet {
            padding-top: 30px;
        }
    }

    .small-area {
        width: 100%;

        @include tablet-large {
            margin: 40px 0 20px 25px;
            padding: 4px;
            width: 380px;
            @include calc(height, "100% - 60px");
            overflow-y: auto;
            position: fixed;
            overflow-x: hidden;

            .font-size-large & {
                width: 400px;
            }

            .split-view & {
                margin-left: 0;
                margin-top: 30px;
                padding: 0 0 30px;
                width: 100%;
                height: auto;
                overflow: inherit;
                position: initial;
            }
        }

        > div {
            @include tablet-only {
                display: inline-block;
                vertical-align: top;
                @include calc(width, "50% - 10px");

                &:not(:nth-of-type(2n)) {
                    margin-right: 20px;
                }
            }

            .split-view & {
                display: inline-block;
                vertical-align: top;
                @include calc(width, "50% - 10px");

                &:not(:nth-of-type(2n)) {
                    margin-right: 20px;
                }
            }

            &:first-child {
                margin-top: 10px;

                @include tablet-only {
                    + div {
                        margin-top: 10px;
                    }
                }

                @include tablet-large {
                    margin-top: 0;
                }

                .split-view & {
                    margin-top: 10px;

                    + div {
                        margin-top: 10px;
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &:nth-last-child(2) {
                @include tablet-only {
                    margin-bottom: 0;
                }

                .split-view & {
                    margin-bottom: 0;
                }
            }
        }
    }

    .main-area {
        width: 100%;

        &.main-area-hidden {
            @include calc(width, "100% - 405px");

            .font-size-large & {
                @include calc(width, "100% - 425px");
            }

            @include tablet-and-mobile {
                display: none;
            }

            .split-view & {
                display: none;
            }
        }

        > .content-panel {
            padding-bottom: 20px;
        }

        .project-view & > .panel {
            padding-bottom: 20px;
        }
    }

    &.with-sidebar {
        .panel-controls {
            @include tablet-large {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 9px;

                // On IE justify-content: flex-end moves button too far off the container div
                @include ie {
                    margin-right: 110px;
                }
            }

            .btn-tag.btn-rounded {
                display: inline-block;

                @include tablet-large {
                    z-index: 1;
                    position: absolute;
                }

                @include tablet-and-mobile {
                    z-index: 2;
                    position: fixed;
                    top: 50%;
                    right: -48px;
                    @include rotate(-90deg);
                    @include border-radius(12px 12px 0 0);
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                    color: white;

                    &.rotate {
                        right: -45px;

                        .icon-wrap {
                            right: 2px;
                        }
                    }

                    .icon-wrap {
                        right: 5px;
                        @include rotate(90deg);

                        &:after {
                            color: white;
                        }
                    }
                }

                .split-view & {
                    z-index: 2;
                    position: fixed;
                    top: 50%;
                    @include rotate(-90deg);
                    @include border-radius(12px 12px 0 0);
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                    color: white;

                    &.rotate {
                        .icon-wrap {
                            right: 2px;
                        }
                    }

                    .icon-wrap {
                        right: 5px;
                        @include rotate(90deg);

                        &:after {
                            color: white;
                        }
                    }
                }

                .window-view.left & {
                    @include calc(right, "50% - 47px");
                }

                .window-view.right & {
                    right: -47px;
                }

                &.rotate {
                    .window-view.left & {
                        @include calc(right, "50% - 44px");
                    }

                    .window-view.right & {
                        right: -44px;
                    }

                    @include tablet-only {
                        margin-bottom: 0;
                    }

                    .icon-wrap {
                        @include rotate(180deg);

                        @include tablet-and-mobile {
                            @include rotate(-90deg);
                        }

                        @include tablet-large {
                            line-height: 19px;
                        }

                        .split-view & {
                            @include rotate(-90deg);
                        }
                    }
                }

                .icon-wrap {
                    pointer-events: none;

                    @include tablet-large {
                        &.icon-wrap-clickable:after {
                            @include themify($themes) {
                                color: themed("themeBaseColor");
                            }

                            .split-view & {
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        @include tablet-large {
            .panel-areas {
                margin-top: -9px; // Compensate panel-controls margin
            }
        }

        .panel-column {
            .content-panel {
                @include tablet-large {
                    margin-top: 10px;
                }
            }
        }

        &.sidebar-expanded {
            .panel-controls {
                .btn-tag.btn-rounded {
                    @include tablet-large {
                        position: fixed;
                    }
                }
            }
        }
    }
}
