@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.system-config-panel {
    container-type: inline-size;
    .tab-list {
        z-index: 1;

        @container (width > 1599px) {
            @include calc(max-width, "100% - 350px");
        }

        &.fullscreenLayout {
            @container (width > 1920px) {
                max-width: 100%;
            }
        }

        .split-view & {
            z-index: initial;
        }
    }

    @include tablet {
        .tab-list,
        .tab-list-tab-content {
            margin-left: -20px;
            padding-left: 20px;
            padding-right: 20px;
            @include calc(width, "100% + 40px");

            .split-view & {
                margin-left: 0;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
    }

    .tab-list-tab-content {
        position: relative;
        margin-top: -44px;
        padding-top: 44px;

        .split-view & {
            margin-top: 0;
            padding: 0;
        }
    }

    .data-grid-controls {
        margin: 0;

        @container (max-width: 1599px) {
            @include flexbox;
            @include data-grid-controls-mobile;
            min-height: 40px;
            justify-content: right;
            .icon-with-label {
                padding-right: 20px;
            }
            .flex-one {
                display: none;
            }
        }

        @include desktop-large {
            z-index: 2;
            position: absolute;
            right: 20px;
            top: 14px;

            .split-view & {
                z-index: initial;
                position: initial;
                @include flexbox;
                @include data-grid-controls-mobile;
                min-height: 40px;
            }

            .flex-one {
                margin: 0 10px;
            }
        }
    }

    .attributes-form .json-form,
    .panel.all-hostnames .grid-list-row.details {
        @include mobile-only {
            z-index: 3;
        }

        .action-buttons {
            padding-top: 20px;
            @include themify($themes) {
                border-top: 1px solid themed("themeDirtyColor");
            }

            @include mobile-only {
                .btn {
                    width: 50%;
                }
            }
        }
    }

    .attributes-form {
        position: relative;
        @include expanded-panel;
        margin-top: -5px;

        @include mobile-only {
            @include expanded-panel-mobile;
        }

        .json-form {
            padding: 15px 20px 20px;

            @include mobile-only {
                overflow-y: auto;
                @include calc(height, "100% - 52px");
            }

            .object-properties > .schema-container {
                @include mobile-only {
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }

                @include tablet {
                    @include calc(width, "(100% / 2) - 20px");
                }
            }
        }
    }

    .panel {
        .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;
        }

        &.all-hostnames .grid-list-row.details {
            @include tablet {
                margin-left: 15px;
                margin-right: 15px;
                @include calc(width, "100% - 30px");
            }

            .with-form {
                @include mobile-only {
                    @include expanded-panel-mobile;
                }
            }
        }

        &.product-attributes,
        &.event-attributes,
        &.budget-attributes,
        &.ftp-configurations,
        &.ftp-schedules,
        &.all-hostnames {
            margin: 0 -15px;
            padding: 0 15px;
            @include calc(width, "100% + 30px");

            @include tablet-only {
                margin: 0 -20px;
                padding: 0 20px;
                @include calc(width, "100% + 40px");
            }

            @include tablet-large {
                margin: 0 -25px;
                padding: 0 25px;
                @include calc(width, "100% + 50px");
            }
        }
    }
}
