.property-list-item--image-link {
    .property-list {
        margin-right: 20px;

        .property-list-item--select-box {
            .select-box {
                width: 100%;
            }
        }
    }
}

.property-list-item--image-size {
    .property-list-item--select-box {
        .select-box {
            height: 45px;

            .select-box__text {
                text-align: center;
            }
        }
    }
}
