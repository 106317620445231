@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_functions";

.text-label {
    font-size: em(12);
    font-weight: 500;

    .font-size-large & {
        font-size: em(14);
    }
}
