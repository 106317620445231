@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item {
    &--select-box {
        .select-box {
            height: 25px;
        }

        .empty-items {
            &.property-list-item-inner,
            .text-label {
                padding-bottom: 0px !important;
            }
        }

        .select-box__text {
            font-size: 11px;
        }
    }
}
