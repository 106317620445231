@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.sidenav-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .sidenav-header {
        flex-shrink: 0;
    }

    .sidenav-body {
        overflow: auto;
    }

    .sidenav-footer {
        flex-shrink: 0;
    }
}
