@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.portal-builder-color-select {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    flex-shrink: 0;
    @include themify($themes) {
        border: 1px solid themed("themeDirtyColor");
    }

    .property-list-item-color-picker {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}

.color-select-label {
    line-height: 24px;
}

.icon-wrap.icon-wrap-big.color-select-icon {
    height: 22px;
    width: 22px;
    box-shadow: 1px 1px 5px rgba(131, 94, 94, 0.6) !important;
    border-radius: 5px;
    border: 4px solid white;
    line-height: 22px;

    &:before {
        position: relative;
        top: 0;
        left: -6px;
        font-size: 26px;
        line-height: 15px;
    }
}
