@import "../../../../../../assets/sass/variables";
@import "../../../../../../assets/sass/mixins";
@import "../../../../../../assets/sass/functions";

@mixin contact-actions-horizontal-view {
    .actions {
        position: absolute;
        top: 5px;
        right: 5px;

        @include tablet-large {
            opacity: 0;
        }
    }

    @include tablet-large {
        &:hover .actions {
            opacity: 1;
        }
    }
}

@mixin contact-actions-vertical-view {
    .actions {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        height: 100%;
        @include themify($themes) {
            border-left: 1px solid themed("themeLighterDirtyColor");
        }
        @include flex-direction(column);
        justify-content: space-between;

        @include tablet-large {
            .icon-wrap:not(.icon-wrap-active) {
                opacity: 0;
            }
        }
    }

    @include tablet-large {
        &:hover {
            .actions .icon-wrap {
                opacity: 1;
            }
        }
    }

    &:not(:hover) .actions {
        overflow: hidden;
    }
}

@mixin vertical-contact-view {
    @include flexbox;
    @include flex-direction(column);
    padding: 30px 20px 15px;
    text-align: center;

    &:before {
        top: -23px;
        left: 0;
        @include box-shadow(inset 0, -3px, 3px, 0 rgba(0, 0, 0, 0.15));
    }

    &:after {
        top: -24px;
        left: initial;
        width: 46px;
        height: 23px;
    }

    > .icon-wrap,
    &:before,
    &:after {
        right: 0;
        left: 0;
        margin: 0 auto;
    }

    > .icon-wrap {
        top: -17px;
        left: 0;
    }

    .email-container {
        position: relative;
        bottom: -15px;
        left: -10px;
        margin-top: -5px;
        padding: 10px 0;
        @include calc(width, "100% + 20px");
    }

    .header-row {
        @include flex-direction(column);
        justify-content: center;
    }

    .content {
        @include flex-direction(column);
        flex: 1;

        @include ie {
            flex: 1 0 auto;
        }

        .content-lead .contact-row-item.full-name {
            margin-bottom: 5px;
        }

        .content-body {
            @include flex-direction(column);
            justify-content: space-between;
            width: 100%;
            text-align: left;

            .contact-row-item {
                &:not(.premise-and-account):not(.email-container) {
                    @include flexbox;
                    @include flex-direction(row);
                    justify-content: space-between;
                    @include themify($themes) {
                        border-top: 1px dashed themed("themeDirtyColor");
                    }

                    &:first-child {
                        margin-top: 20px;
                    }

                    .label span,
                    .title span {
                        position: relative;
                        background: white;
                    }

                    .label,
                    .title {
                        position: relative;
                        top: -13px;
                    }

                    .label {
                        flex: 1;

                        @include ie {
                            flex: 1 0 auto;
                        }

                        &:before {
                            left: 0;
                        }

                        span {
                            margin-right: 20px;
                            padding-right: 5px;
                        }
                    }

                    .title {
                        &:before {
                            left: 0;
                        }

                        span {
                            padding-left: 5px;
                        }
                    }
                }

                &:not(.email-container) {
                    margin-top: 10px;

                    .label {
                        font-size: 13px;

                        .font-size-large & {
                            font-size: 15px;
                        }
                    }

                    .title {
                        text-align: right;
                    }
                }
            }
        }
    }

    .contact-row-item {
        &.premise-and-account {
            @include flexbox;
            @include flex-direction(column);

            > .flex-column {
                position: relative;
                @include flex-direction(row);
                justify-content: space-between;
                @include themify($themes) {
                    border-top: 1px dashed themed("themeDirtyColor");
                }

                &:first-child {
                    margin-bottom: 10px;
                }

                &:nth-child(2) {
                    margin-bottom: 10px;
                }

                .identifier {
                    margin-right: 20px;
                    padding-right: 5px;
                    font-size: 13px;

                    .font-size-large & {
                        font-size: 15px;
                    }
                }

                > div {
                    position: relative;
                    top: -13px;
                    background: white;

                    &:not(.identifier) {
                        padding-left: 5px;
                        text-align: right;
                    }
                }
            }
        }

        &.cis-verification,
        &.view-contractor {
            position: absolute;
            top: 4px;
            left: 9px;
        }
    }
}

@mixin horizontal-contact-view {
    padding-right: 50px;

    @include mobile-only {
        @include vertical-contact-view;
    }

    @include tablet-only {
        padding-right: 58px;
    }

    .header-row {
        position: absolute;
    }

    .content-lead {
        width: 150px;

        @include tablet-large {
            width: 180px;
        }

        .company {
            margin-top: 5px;

            @include mobile-only {
                display: none;
            }
        }
    }

    .content-body {
        @include flex-wrap(wrap);

        @include tablet {
            margin-left: 20px;
        }
    }

    .contact-row-item {
        @include mobile-only {
            width: 100%;
        }

        @include tablet {
            &.email-container {
                @include flexbox;
                @include flex-direction(row);
                margin-top: 10px;
                padding-top: 10px;
                width: 100%;

                > div {
                    margin-right: 20px;

                    &.email {
                        @include text-overflow;
                    }
                }
            }

            &.address {
                flex: 1;
                padding: 0 20px;
            }

            &.premise-and-account {
                @include flex-direction(column);
                align-items: flex-end;

                > div {
                    @include flex-direction(row);
                    align-items: center;
                }

                .identifier {
                    margin-right: 5px;
                }
            }
        }
    }
}

.contact-panel-item {
    position: relative;
    background: white;
    @include themify($themes) {
        border: 1px solid themed("themeLighterDirtyColor");
    }

    &:not(.edit-mode) {
        margin-top: 0 !important;
        padding: 20px 20px 20px 40px;

        &.not-assigned {
            background: rgba(255, 255, 255, 0.7);

            > .icon-wrap:before {
                @include themify($themes) {
                    color: themed("themeContactFormLabelColor");
                }
            }

            .content {
                padding: 30px 0 20px;

                @include tablet-large-and-desktop {
                    padding: 25px 0 5px;

                    p {
                        padding-left: 24px;
                    }

                    .icon-with-label.search {
                        margin: 5px 0 0 20px;
                    }
                }

                @include desktop {
                    align-items: center;
                    justify-content: center;
                }

                @include tablet-and-mobile {
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .assigned-contacts & {
            @include tablet-and-mobile {
                @include vertical-contact-view;
            }

            @include tablet {
                @include contact-actions-horizontal-view;
            }

            @include tablet-large-and-desktop {
                @include contact-actions-vertical-view;
            }

            @include desktop {
                @include vertical-contact-view;
            }
        }

        @include tablet-large-and-desktop {
            @include horizontal-contact-view;
        }

        .other-contacts & {
            @include horizontal-contact-view;

            @include tablet {
                @include contact-actions-vertical-view;
            }
        }

        &:before {
            content: "";
            left: -23px;
            width: 46px;
            height: 46px;
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
            @include box-shadow(inset -3px, 0, 3px, 0 rgba(0, 0, 0, 0.15));
            @include border-radius(50%);
        }

        &:after {
            content: "";
            left: -24px;
            width: 23px;
            height: 46px;
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
        }

        > .icon-wrap,
        &:before,
        &:after {
            position: absolute;
        }

        &:before,
        &:after {
            @include calc(top, "50% - 23px");
        }

        > .icon-wrap {
            z-index: 1;
            @include calc(top, "50% - 18px");
            left: -17px;

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }
    }

    @include mobile-only {
        @include contact-actions-horizontal-view;
    }

    .contact-row-item {
        &:not(.cis-verification, .view-contractor) {
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }

        &.type {
            text-transform: uppercase;
            font-weight: 700;
            @include themify($themes) {
                color: themed("themeColorDarkGrey");
            }
        }

        &.full-name {
            font-size: 15px;
        }

        .label,
        .identifier {
            display: block;
            @include themify($themes) {
                color: themed("themeContactFormLabelColor");
            }
            font-size: 11px;
            font-weight: 500;

            .font-size-large & {
                font-size: 13px;
            }
        }

        &.email-container {
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }

            .icon-wrap {
                margin-right: 7px;
            }

            a {
                @include text-overflow;
            }
        }

        &.email-container,
        &.equal {
            .icon-wrap {
                @include themify($themes) {
                    color: themed("themeContactFormLabelColor");
                }
            }
        }

        &.equal {
            position: absolute;
            top: -30px;
            left: -11px;
            width: 36px;
            height: 36px;
            line-height: 39px;
            text-align: center;

            @include tablet {
                top: -45px;
                left: 0;
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }
                @include box-shadow(inset 0, 0, 5px, 0 rgba(0, 0, 0, 0.15));
                @include border-radius(50%);
            }

            @include tablet-only {
                left: -29px;
                top: 50px;
            }

            @include desktop {
                left: -29px;
                top: 50px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 14px;
                left: 0;
                width: 36px;
                height: 10px;
                @include themify($themes) {
                    background: themed("themeWhiteColor");
                }

                @include tablet-only {
                    @include calc(top, "50% - 18px");
                    left: 8px;
                    width: 20px;
                    height: 36px;
                }

                @include desktop {
                    @include calc(top, "50% - 18px");
                    left: 8px;
                    width: 20px;
                    height: 36px;
                }
            }

            .icon-wrap {
                @include themify($themes) {
                    color: themed("themeContactFormLabelColor");
                }
                @include rotate(90deg);
            }
        }

        @include tablet-only {
            &.view-contractor {
                text-align: initial;
                width: min-content;
            }
        }

        &.cis-verification,
        .view-contractor {
            .icon-with-label {
                margin: 3px 0;
            }
        }
    }

    .assigned-contacts & {
        .content-lead .contact-row-item {
            &.type,
            &.company {
                display: none;

                @include tablet-large-and-desktop {
                    display: block;
                }
            }
        }

        @include tablet-large-and-desktop {
            &:not(.not-assigned) .header-row .contact-row-item.type,
            .header-row .contact-row-item.cis-verification,
            .header-row .contact-row-item.view-contractor,
            .content-body .contact-row-item.company {
                display: none;
            }

            .content-lead .contact-row-item.cis-verification,
            .contact-row-item.view-contractor {
                margin-top: 10px;

                .icon-with-label {
                    left: -4px;
                }
            }
        }
    }

    .other-contacts & {
        .header-row {
            .contact-row-item.type {
                display: none;
            }
        }

        .content-body .contact-row-item.company {
            display: none;
        }
    }

    &.edit-mode {
        @include expanded-panel;
        margin: 10px -10px 10px -10px;

        @include tablet {
            .assigned-contacts & {
                flex: auto !important;
                margin: 10px -10px 10px -10px !important;
            }
        }

        @include mobile-only {
            @include expanded-panel-mobile;
        }
    }
}
