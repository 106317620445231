@import "../../../../../assets/sass/_mixins";

.image-properties-wrapper {
    .text-label {
        font-weight: 400;
        font-size: 13px !important;
    }

    .alt-info-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .property-list-item {
        margin: 5px 0;
        border-bottom: none;

        @include firefox {
            &--input-range {
                width: 75%;
            }
        }

        &--select-box {
            .flex-row {
                width: 100%;
                .select-box {
                    flex: 1 1;
                    height: 30px;
                    width: 100%;
                }
            }
        }

        &--image-link {
            padding-bottom: 10px;
        }
    }

    .percentage-input-field {
        width: 50px !important;
    }
}
