@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.widget-info-block {
    position: relative;
    margin: 0 20px 15px;
    padding: 15px;
    overflow: hidden;

    @include themify($themes) {
        background: themed("themeSemiWhiteColor");
    }

    .info-empty {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0.1;
        @include scale(4);

        @include themify($themes) {
            color: themed("themeBaseColor");
        }
    }

    p {
        z-index: 1;
        position: relative;

        + p {
            margin-top: 5px;
            padding-right: 20px;
        }
    }
}
