@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.status-tag {
    display: inline-block;
    min-width: 75px;
    height: 24px;
    padding: 0 10px;
    @include border-radius(12px);
    font-size: em(12);
    line-height: 22px;
    text-align: center;

    .font-size-large & {
        font-size: em(14);
    }

    &.with-icon {
        padding-right: 26px;

        &:after {
            position: absolute;
            top: 0;
            right: 3px;
        }
    }

    &.status-success {
        background-color: $green-light;
        border: 1px solid $green-medium;

        &:after {
            color: $green;
        }
    }

    &.status-error {
        background-color: $red-light;
        border: 1px solid $red-medium;

        &:after {
            color: $red;
        }
    }
}
