@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.grid-list-resize-handle {
    opacity: 0;
    @include flexbox;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -8px;
    width: 20px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    @include themify($themes) {
        color: themed("themeBaseColor");
    }
    cursor: col-resize;
    z-index: 1;
    user-select: none;

    @include tablet-large {
        right: -2px;
        width: 6px;
    }
}
