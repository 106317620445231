.pdf-rebate-table {
    width: 310px;
    margin: 0 auto;
    color: #5f6368;
    font-size: 11px;
    tbody {
        border-top: 1px solid #5f6368;
        border-bottom: 1px solid #5f6368;
    }
    th {
        font-weight: bold;
        text-align: left;
    }
    td {
        padding: 5px 0 5px 0;
    }
}
