@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.inline-panel-header {
    position: relative;
    padding: 15px 20px;
    background-color: $white-light-grey;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor");
    }

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background-color: $sightline-white-light-grey;
    }

    @include mobile-only {
        padding-right: 40px;
    }

    .inline-panel-header-lead {
        > .icon-wrap {
            display: inline-block;

            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            + h3 {
                display: inline-block;
            }

            &.clear-close {
                position: absolute;
                top: 3px;
                right: 3px;
                z-index: 1;
            }
        }
    }

    .inline-header-body {
        .item-label {
            margin-bottom: 8px;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }
        }
    }
}
