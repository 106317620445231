@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.panel.documents {
    .grid-list {
        .group-header-row {
            .group-name {
                font-weight: 500;
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }
            }

            .actions {
                .icon {
                    visibility: hidden;
                }
            }

            &:hover,
            &.expanded {
                .actions {
                    .icon {
                        visibility: visible;
                    }
                }
            }
        }

        .grid-list-row {
            .add-group-filled {
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
            }

            .grid-list-column .btn-rounded {
                min-width: initial;
            }
        }
    }

    > .flex-column {
        > .grid-list {
            @include data-grid-sticky-header;
            overflow: hidden;

            .grid-list-row.details {
                margin: 10px;
                @include calc(width, "100% - 20px");

                @include mobile-only {
                    z-index: 3;
                }
            }
        }
    }
}

.sidenav-panel-correspondence {
    .sidenav-footer {
        .content-label {
            margin-right: 10px;
            font-size: $base-font-size;
        }
    }
}

.file-upload-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
