@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.field-group-dropdown-popup__container {
    z-index: 100;

    .dropdown-list-wrap {
        right: initial;
        top: initial;
        position: initial;

        &:before {
            top: -6px;
            height: 5px;
        }
    }

    &.filled.multiple-choice {
        .dropdown-list-wrap {
            &:before {
                background-color: $white-light-grey;
            }
        }
    }

    @include mobile-only {
        transform: initial !important;
        position: inherit !important;

        .dropdown-list-wrap {
            position: fixed !important;
            top: 0;
        }
    }

    &[data-popper-placement="top-start"] {
        .with-parent {
            .dropdown-list {
                @include themify($themes) {
                    border-top: 1px solid themed("themeBoxShadowBorderColor");
                }

                @include tablet {
                    @include border-radius(4px 4px 0 0);
                }

                &__header {
                    order: 2;
                }

                &__items {
                    order: 1;
                }

                &__filter {
                    @include themify($themes) {
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }
                    border-bottom: 0;
                }
            }

            &:before {
                top: initial;
                bottom: -7px;
                left: 1px;
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
                border-bottom: 0;
            }
        }
    }

    &[data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    }
}
