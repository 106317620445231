@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.select-budget-lines-actions {
    @include flexbox;
    justify-content: space-between;

    > .icon-with-label:first-child {
        margin-right: 15px;
    }
}

.ReactModal__Overlay.modal-styled .budget-lines-select-modal {
    .modal-content {
        .modal-body {
            overflow: hidden;
            padding: 0;

            & > .flex-column {
                height: 100%;
            }
        }
    }
}
