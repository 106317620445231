@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.paging {
    position: relative;
    display: inline-block;
    text-align: center;

    .icon-wrap {
        position: absolute;

        &.arrow-in-circle-left-empty {
            left: -30px;
        }

        &.arrow-in-circle-right-empty {
            right: -30px;
        }
    }
}

.selectable-page {
    display: inline-block;
    margin: 0 8px;
    height: 18px;
    @include border-radius(18px);
    line-height: 18px;

    &:not(.spacer) {
        cursor: pointer;
    }

    @include tablet-large {
        &:not(.spacer):hover {
            @include themify($themes) {
                background: themed("themeDirtyColor");
                color: themed("themeBaseColor");
            }
        }
    }

    &.active {
        pointer-events: none;

        @include themify($themes) {
            background: themed("themeBaseColor");
            color: white;
        }
    }

    span {
        padding: 0 5px;
    }
}
