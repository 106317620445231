@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";

.qc-data-grid {
    .raw-html.qc-description {
        @include text-overflow;

        ol,
        ul,
        dl {
            padding-inline-start: 40px;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }

        ul,
        dl {
            list-style: initial;
        }

        * {
            font-size: 13px;
            @include themify($themes) {
                color: themed("themeColorBaseGrey") !important;
            }

            .font-size-large & {
                font-size: 15px;
            }
        }
    }

    .grid-list-row.details {
        @include tablet {
            margin-left: 15px;
            margin-right: 15px;
            @include calc(width, "100% - 30px");
        }
    }

    .grid-list-row-container {
        .grid-list-row {
            height: auto;
        }
    }
}
