@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.border {
    border-width: 1px !important;
    border-style: solid !important;
    @include themify($themes) {
        border-color: themed("themeLighterDirtyColor") !important;
    }
}

.border-0 {
    border-width: 0 !important;
}

.border-2 {
    border-width: 2px !important;
}

.border-top {
    @include themify($themes) {
        border-top: 1px solid themed("themeLighterDirtyColor") !important;
    }
}

.border-bottom {
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor") !important;
    }
}

.border-right {
    @include themify($themes) {
        border-right: 1px solid themed("themeLighterDirtyColor") !important;
    }
}

.border-left {
    @include themify($themes) {
        border-left: 1px solid themed("themeLighterDirtyColor") !important;
    }
}

.border-danger {
    @include themify($themes) {
        border-color: var(--color-danger) !important;
    }
}

.border-primary {
    @include themify($themes) {
        border-color: themed("themeBaseColor") !important;
    }
}

.border-grey-400 {
    @include themify($themes) {
        border-color: var(--color-grey-400) !important;
    }
}

.border-primary-light,
.hover\:border-primary-light:hover,
.focus\:border-primary-light:focus,
.active\:border-primary-light:active {
    @include themify($themes) {
        border-color: themed("themeBaseColorLightVersion") !important;
    }
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-2 {
    border-radius: 8px !important;
}

.rounded-6 {
    border-radius: 6px !important;
}

.border-dashed {
    border-style: dashed !important;
}

.rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.rounded-start-6 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.rounded-end-6 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.box-shadow,
.hover\:box-shadow:hover,
.focus\:box-shadow:focus,
.active\:box-shadow:active {
    @include themify($themes) {
        box-shadow: 0px 0px 8px 0px themed("themeBoxShadowColor");
    }
}
