@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.grid-list-row.grid-list-row-header {
    height: 44px;
    background-color: $white-light-grey;
    border: none;

    .theme-sightline &,
    .theme-sightlineHighContrast & {
        background-color: $sightline-white-light-grey;
    }

    .grouped-grid & {
        padding-left: 38px;
    }

    + div:not(.filter) {
        @include themify($themes) {
            border-top: 1px solid themed("themeLighterDirtyColor");
        }
    }

    .grid-list-column {
        @include flexbox;
        align-items: center;
        height: 100%;
        overflow: hidden;

        &:not(.actions) {
            position: relative;
        }

        &.actions {
            z-index: 1;
            right: -72px;
            top: 7px;
            margin: 0;
            padding: 40px 0 0 !important;
            align-items: flex-start;
            justify-content: flex-end;
            width: 72px;
            height: auto;

            .grid-settings-dropdown,
            .wait-icon {
                position: absolute;
                right: 0;
            }

            .grid-settings-dropdown {
                top: 0;
            }

            .wait-icon {
                top: 7px;
            }

            .icon-wrap {
                margin-left: 0;
            }
        }

        &.sortable {
            cursor: pointer;

            &:hover {
                background: $white-grey;

                .column-content > .swap-vertical {
                    display: inline-block;
                }

                .column-sorting-hint {
                    background: $white-grey;
                }
            }
        }

        .column-content {
            @include flexbox;
            align-items: center;
            width: 100%;
            overflow: hidden;

            .column-name {
                font-weight: 500;
                @include text-overflow;
            }

            > .icon-wrap.swap-vertical {
                display: none;
            }

            .sorted {
                position: relative;

                &.asc .icon-wrap.swap-vertical {
                    transform: scaleX(-1);
                    top: -2px;
                }

                &.desc .icon-wrap.swap-vertical {
                    left: -1px;
                }
            }

            .column-sorting-hint {
                position: absolute;
                right: 4px;
                top: 4px;
                word-break: break-all;
                width: 9px;
                padding-left: 2px;
                background: #f8f9f9;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 6px;
                line-height: 1.1;
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        .icon-wrap:not(.icon-wrap-clickable) {
            &:before {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }
    }

    .grid-list-resize-handle {
        opacity: 0.4;
    }

    &:hover .grid-list-resize-handle {
        opacity: 1;
    }
}
