@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.grid-details-panel-footer {
    .btn {
        & + .btn {
            margin-left: 10px;
        }

        button {
            text-transform: capitalize;
        }
    }
}
