@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.application-document-queue {
    .window-body-header {
        .dropdown-label {
            margin-right: 10px;
            font-size: em(12);

            .font-size-large & {
                font-size: em(14);
            }

            + .field-group {
                width: 220px;
            }
        }

        .header-wrap {
            padding: 12px 0;

            .view-with-submenu & {
                padding: 9px 0 12px;

                .theme-mode-light & {
                    padding: 12px 0;
                }

                @include tablet {
                    padding: 8px 0 12px;

                    .theme-mode-light & {
                        padding: 12px 0;
                    }
                }
            }
        }
    }

    .nothing-found-block {
        @include mobile-only {
            margin-top: 10px;
        }

        > div {
            max-width: 250px;

            > span {
                .link {
                    font-weight: 500;
                }

                b {
                    @include tablet-and-mobile {
                        display: none;
                    }
                }

                .hidden-on-desktop {
                    @include tablet-large {
                        display: none;
                    }
                }
            }
        }
    }

    &__container {
        padding: 70px 0 20px;

        @include tablet-large {
            padding: 20px 0;
        }

        .split-view & {
            padding: 70px 0 20px;
        }

        &.flex-row {
            @include tablet-and-mobile {
                @include flex-direction(column);
            }

            .split-view & {
                @include flex-direction(column);
            }
        }
    }

    &__dq-grid-programs {
        .application-document-queue__entry--archived & {
            border-bottom: none;
        }
    }

    .view-switcher-wrap {
        z-index: 1;
        text-align: center;

        @include tablet-and-mobile {
            position: fixed;
            top: 96px;
            left: 0;
            padding: 10px;
            width: 100%;

            @include themify($themes) {
                background: themed("themeWhiteColor");
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }

            .view-with-submenu & {
                top: 147px;
            }
        }

        .split-view & {
            position: fixed;
            top: 96px;
            left: 1px;
            padding: 10px;
            @include calc(width, "50% - 2px");

            @include themify($themes) {
                background: themed("themeWhiteColor");
                border-bottom: 1px solid themed("themeLighterDirtyColor");
            }
        }

        .split-view .window-view.right & {
            left: initial;
            right: 1px;
        }

        @include tablet-large {
            display: none;

            .split-view & {
                display: inline-block;
            }
        }
    }

    .view-switcher {
        display: inline-block;
        padding: 4px;
        background: white;
        @include box-shadow-common($shadow-outer-hover);
        @include border-radius(8px);

        span {
            display: inline-block;
            padding: 4px 10px 5px;
            @include border-radius(8px);
            font-size: em(12);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }

            &:first-child {
                margin-right: 4px;
            }

            &.active {
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
                color: white;
            }

            &:not(.active) {
                cursor: pointer;
            }
        }
    }

    &__entry {
        @include tablet-and-mobile {
            flex: 1;
            overflow: hidden;

            &.hidden-on-mobile {
                display: none;
            }

            &.items-selected {
                margin-bottom: 80px;
            }
        }

        .split-view & {
            width: 100%;
            flex: 1;
            overflow: hidden;

            &.hidden-on-mobile {
                display: none;
            }

            &.print-batches {
                margin-left: 0;
            }

            &.items-selected {
                margin-bottom: 80px;
            }
        }

        &--archived {
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }
        }

        &--sent-to-printer {
            @include tablet-and-mobile {
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }

            .split-view & {
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }
        }

        @include tablet-large {
            width: 50%;

            &.print-batches {
                margin-left: 20px;
            }
        }

        &--lead-title {
            margin: 10px 0;

            @include mobile-only {
                &.flex-row {
                    align-items: flex-end;
                }

                h3 {
                    font-size: em(16);
                }

                .icon-with-label {
                    white-space: nowrap;
                }

                + .flex-column .nothing-found {
                    margin-top: 0;
                }
            }

            .counter-box {
                margin-right: 5px;
            }
        }
    }
}
