@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_functions";
@import "../../../../../assets/sass/mixins";

.checkboxes--select-all {
    padding-bottom: 10px;
    margin-bottom: 10px;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeDirtyColor");
    }
}
