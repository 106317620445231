@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.file-name-with-type-icon {
    margin-left: 15px;
}

.files-grid-edit-form {
    padding: 10px;

    @include mobile-only {
        @include expanded-panel-mobile;
        padding: 0;
    }

    .form-item {
        margin-right: 10px;

        > label {
            margin-right: 10px;
        }
    }

    .btn {
        margin-right: 10px;
    }

    .btn-group {
        z-index: 1;
        padding: 15px 20px;
        @include box-shadow-common($shadow-outer-center);

        @include tablet {
            padding: 18px 20px;
        }

        @include mobile-only {
            .btn-group:first-child {
                flex: 1;
                margin-right: 17px;

                @include ie {
                    flex: 1 0 auto;
                }

                .btn {
                    width: 100%;
                }
            }
        }
    }
}

.files-grid-file-preview-sidenav-panel {
    .sidenav-header__title {
        word-break: break-all;
    }
}
