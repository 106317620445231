@import "../../../../../../assets/sass/_variables";
@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/_functions";

.panel.applications {
    .panel-header-large {
        margin-bottom: 15px;

        .title-breadcrumbs {
            @include tablet-and-mobile {
                margin-bottom: 0;
            }

            + h2 {
                display: none;
            }
        }
    }

    .sticky-bottom-panel {
        @include transition(all 0s ease);
    }

    .application-form-wrap {
        @include flexbox;
        @include flex-direction(column);

        @include ie {
            flex: 1 0 auto;
        }

        @include firefox {
            flex: 1 0 auto;
        }

        > .application-form-scrollable-part {
            overflow-y: auto;
            padding-bottom: 20px;

            @include firefox {
                padding-bottom: 80px;
            }

            > .application-form {
                @include ie {
                    overflow: hidden;
                }
            }
        }

        > .application-form {
            @include ie {
                height: 100%;
            }
        }
    }

    .application-form-info {
        padding: 20px 0;

        .status-msg {
            .icon-wrap {
                vertical-align: middle;
            }
        }
    }

    .application-form {
        .action-buttons {
            align-items: center;
            justify-content: space-between;
            position: sticky;
            bottom: 0;
            margin: 20px -20px -4px;
            padding: 10px 20px;
            @include calc(width, "100% + 40px");
            min-height: 62px;
            background: white;
            @include box-shadow-common($shadow-outer);

            @include mobile-only {
                left: 0;
            }

            @include tablet {
                margin: 20px 0 -4px;
                padding: 15px 20px;
                width: 100%;
                justify-content: center;
                @include border-radius(8px 8px 0 0);
            }

            .form-name {
                padding-right: 15px;

                @include mobile-only {
                    @include flex-direction(column);
                    flex: 1;
                    font-size: em(12);
                }

                span {
                    margin-right: 5px;
                }

                b {
                    font-weight: 500;
                }
            }

            .btn {
                margin: 0 0 0 7px;

                @include mobile-only {
                    min-width: 67px;
                }

                @include tablet {
                    margin: 0 7px;
                }

                &.btn-blue {
                    order: 2;
                }

                &.btn-light-blue {
                    order: 3;
                }
            }
        }
    }
}
