@import "../../../../../assets/sass/mixins";
@import "../../../../../assets/sass/variables";

.wf {
    .program-wf__management-statuses:not(:last-of-type) {
        margin-bottom: 10px;
    }

    &__status {
        position: relative;
        height: 54px;

        &--steps-visible {
            min-height: 54px;
            height: initial;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }

        > .drag-handle-icon,
        .not-orderable {
            z-index: 1;
            position: absolute;
            top: 14px;
            right: 13px;
        }

        .not-orderable {
            cursor: no-drop;

            &:hover {
                background: transparent !important;
            }

            &.view-headline-list-text:before {
                color: $grey;
            }
        }

        &-head {
            position: absolute;
            left: 0;
            top: 0;
            width: 95px;
            height: 54px;
            font-size: em(9);
            font-weight: 700;

            .font-size-large & {
                font-size: em(11);
            }

            &-counter {
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
                color: white;
                text-transform: uppercase;

                &.error {
                    background: $red;
                }

                .wf-status-disabled & {
                    @include themify($themes) {
                        background: themed("themeColorLocked");
                    }
                }
            }

            &-counter,
            &-state {
                padding: 3px 10px;
            }

            &-state {
                background: $green-white;
                color: $green;

                @include themify($themes) {
                    border-left: 1px solid themed("themeDirtyColor");
                    border-bottom: 1px solid themed("themeDirtyColor");
                }

                .wf-status-disabled & {
                    background: $white-grey;
                    color: $light-grey;
                }
            }

            &-id {
                width: 120px;

                &-text {
                    font-size: em(12);
                    @include themify($themes) {
                        color: themed("themeColorDarkGrey");
                    }
                    padding-right: 20px;

                    .font-size-large & {
                        font-size: em(14);
                    }

                    span {
                        margin-right: 5px;
                        @include themify($themes) {
                            color: themed("themeBaseColorDesaturatedSemiTransparent70");
                        }
                    }
                }
            }

            &-process-mode-text {
                font-size: em(12);
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .font-size-large & {
                    font-size: em(14);
                }

                span {
                    margin-right: 5px;
                    @include themify($themes) {
                        color: themed("themeBaseColorDesaturatedSemiTransparent70");
                    }
                }
            }
        }

        &-info {
            position: relative;
            margin-left: 95px;
            height: 54px;
            cursor: pointer;

            &:hover .wf__status-title-text {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            &--no-steps {
                &:after {
                    @include themify($themes) {
                        border-bottom: 51px solid themed("themeWhiteColor");
                    }
                }
            }

            &-wrap {
                z-index: 1;
                position: relative;
                padding: 15px 100px 15px 10px;
                height: 100%;

                &--new-status-placeholder {
                    font-weight: 700;
                    font-size: em(18);
                    @include themify($themes) {
                        color: themed("themeBaseColorSemiTransparent30");
                    }
                }

                &.status-not-saved {
                    padding-right: 132px;
                }
            }

            &:before,
            &:after {
                box-sizing: content-box;
                content: "";
                position: absolute;
                border-left: 25px solid transparent;
                height: 0;
            }

            &:before {
                top: 0;
                left: -35px;
                @include calc(width, "100% + 10px");
                @include themify($themes) {
                    border-bottom: 54px solid themed("themeDirtyColor");
                }
            }

            &:after {
                top: 2px;
                left: -33px;
                @include calc(width, "100% + 7px");
                border-bottom: 51px solid white;
            }

            &--selected {
                &:before {
                    @include themify($themes) {
                        border-bottom: 54px solid themed("themeBoxShadowBorderColor");
                    }
                }

                &:after {
                    @include themify($themes) {
                        border-bottom: 52px solid themed("themeSemiWhiteColor");
                    }
                }

                &.new {
                    &:after {
                        @include themify($themes) {
                            border-bottom: 51px solid themed("themeSemiWhiteColor");
                        }
                    }
                }
            }

            .wf-status-disabled & {
                &:after {
                    border-bottom: 51px solid $white-grey-medium;
                }

                &--selected {
                    &:after {
                        border-bottom: 52px solid $white-grey-medium;
                    }
                }
            }

            &--error:before {
                border-bottom: 54px solid $red !important;
            }
        }

        &-lead-text.flex-row {
            @include tablet-and-mobile {
                @include flex-direction(column);
                align-items: flex-start;
            }
        }

        &-title {
            &-text {
                position: relative;
                max-width: 600px;
                font-size: em(16);
                font-weight: 500;
                color: black;

                &--selected {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }
        }

        &-steps-wrap {
            width: 115px;
        }

        .select-box {
            padding-right: 0;

            .counter-box {
                margin-right: 5px;
            }

            &.selected .counter-box {
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }
                color: white;
            }
        }

        &-steps {
            font-size: em(12);
            font-weight: 700;
            cursor: pointer;

            .font-size-large & {
                font-size: em(14);
            }

            span {
                padding-left: 5px;
                width: 38px;
            }
        }

        &-steps-list-wrap {
            display: none;
            position: relative;
            background: white;
            padding: 5px 10px 10px;

            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
                border-top: none;
            }

            &--visible {
                display: block;
            }

            &--selected {
                @include themify($themes) {
                    border: 1px solid themed("themeBaseColor");
                }
            }

            .add-new-item-panel {
                margin-top: 10px;
            }
        }

        &-actions {
            position: absolute;
            right: 45px;
            top: 14px;
            padding-left: 12px;
            @include themify($themes) {
                border-left: 1px solid themed("themeDirtyColor");
            }

            .icon-wrap.delete-trash-empty {
                margin-left: 7px;
            }
        }
    }
}
