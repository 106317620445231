@import "../../../../../../assets/sass/mixins";

.application-document-queue {
    &__archived-batch-grid-item {
        display: flex;
        width: 100%;
        overflow: hidden;

        &-field {
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }

            &--user {
                margin-left: 15px;
                font-size: em(10);

                .font-size-large & {
                    font-size: em(12);
                }

                @include tablet {
                    margin-left: 30px;
                }

                span {
                    display: block;
                    font-size: 10px;
                    opacity: 0.6;

                    .font-size-large & {
                        font-size: 12px;
                    }

                    @include tablet {
                        font-size: 11px;

                        .font-size-large & {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}
