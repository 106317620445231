@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.submenu {
    position: relative;

    @include ie {
        height: 36px;
    }

    @include themify($themes) {
        background: themed("themeSubMenuBackgroundColor");
        border-top: 1px solid themed("themeLighterDirtyColor");
        @include box-shadow(0, 2px, 4px, 0 themed("themeBaseColorDesaturatedSemiTransparent"));
    }

    body:not(.theme-mode-light) & {
        border-top: none;
        border-bottom: 4px solid white;
        box-shadow: none;
    }

    .theme-mode-light & {
        z-index: 2;
        position: relative;
        background: white;

        @include tablet {
            z-index: 3;
        }
    }

    > .main-grid-wrap.responsive {
        @include themify($themes) {
            background: themed("themeSubMenuBackgroundColorLight");
        }

        @include tablet-and-mobile {
            padding: 0;
        }

        @include tablet-large {
            position: relative;
        }

        body:not(.theme-mode-light) & {
            background: transparent;
        }
    }

    .submenu-placeholder {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        @include themify($themes) {
            background: themed("themeSubMenuBackgroundColorLight");
        }

        .theme-mode-light & {
            display: block;
        }
    }

    .submenu-row {
        min-height: 32px;

        @include tablet-and-mobile {
            position: relative;
        }
    }

    .submenu-item-list {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;

        @include mobile-only {
            padding: 0 10px;
        }

        @include tablet-only {
            padding: 0 20px;
        }

        @include tablet-and-mobile {
            overflow-x: auto;

            .home-dashboard-view & {
                padding-right: 85px;
            }
        }

        @include tablet-large {
            overflow: hidden;

            .home-dashboard-view & {
                margin-right: 15px;
            }
        }
    }

    &.submenu-with-settings .scroll-controls .scroll-control.right {
        @include tablet-large-and-desktop-small {
            right: 82px;
        }

        @include desktop {
            right: 92px;
        }

        @include desktop-large {
            .layout-full-screen & {
                right: 72px;
            }
        }
    }

    .scroll-controls {
        @include tablet-and-mobile {
            display: none;
        }

        .scroll-control {
            width: 40px;

            &.left {
                left: 40px;
                @include themify($themes) {
                    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), themed("themeBaseColor") 50%);
                }

                .theme-mode-light & {
                    background: transparent;
                }

                @include desktop {
                    left: 50px;
                }

                @include desktop-large {
                    .layout-full-screen & {
                        left: 30px;
                    }
                }

                .icon-wrap {
                    @include themify($themes) {
                        background-image: linear-gradient(
                            to left,
                            rgba(0, 0, 0, 0),
                            themed("themeSubMenuSettingsIconBackgroundColorLight") 50%
                        );
                    }
                    text-align: left;

                    body:not(.theme-mode-light) & {
                        background: transparent;
                    }
                }
            }

            &.right {
                right: 40px;
                @include themify($themes) {
                    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), themed("themeBaseColor") 50%);
                }

                .theme-mode-light & {
                    background: transparent;
                }

                @include desktop {
                    right: 50px;
                }

                @include desktop-large {
                    .layout-full-screen & {
                        right: 30px;
                    }
                }

                .icon-wrap {
                    @include themify($themes) {
                        background-image: linear-gradient(
                            to right,
                            rgba(0, 0, 0, 0),
                            themed("themeSubMenuSettingsIconBackgroundColorLight") 50%
                        );
                    }
                    text-align: right;

                    body:not(.theme-mode-light) & {
                        background: transparent;
                    }
                }
            }

            .icon-wrap {
                top: 0;
                height: 32px;
                line-height: 32px;

                .theme-mode-light & {
                    width: 100%;
                }

                &:before {
                    font-size: 18px;
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }

                    body:not(.theme-mode-light) & {
                        color: white;
                    }
                }
            }
        }
    }

    .dashboard-settings-dropdown {
        @include mobile-only {
            width: 65px;
            padding-right: 7px;

            .theme-mode-light & {
                padding-right: 0;

                .icon-dropdown {
                    padding-right: 7px;
                }
            }
        }

        @include tablet-and-mobile {
            position: absolute;
            top: 0;
            right: 0;
            height: 32px;
            @include themify($themes) {
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0), themed("themeSubMenuSettingsIconBackgroundColor") 50%);
            }
            text-align: right;

            .theme-mode-light & {
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white 50%);
            }

            .icon-dropdown {
                @include themify($themes) {
                    background-image: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0),
                        themed("themeSubMenuSettingsIconBackgroundColorLight") 50%
                    );
                }

                body:not(.theme-mode-light) & {
                    background: transparent;
                }
            }

            .icon-wrap {
                height: 32px;
                line-height: 32px;
                @include themify($themes) {
                    color: themed("themeBaseColor") !important;
                }
            }
        }

        @include tablet-only {
            width: 85px;
            padding-right: 17px;

            .theme-mode-light & {
                padding-right: 0;

                .icon-dropdown {
                    padding-right: 17px;
                }
            }
        }

        .dropdown-list__items {
            overflow-y: auto;
            max-height: 100%;

            @include tablet {
                @include calc(max-height, "100vh - 190px");
            }
        }

        .icon-wrap.settings-empty:before {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            body:not(.theme-mode-light) & {
                color: white;
            }
        }
    }

    .submenu-item {
        position: relative;
        display: inline-block;
        padding: 0 36px 0 8px;
        height: 32px;
        cursor: pointer;
        user-select: none;

        .theme-mode-light & {
            padding: 0 40px 0 0;
        }

        .icon-wrap {
            opacity: 0;
            position: absolute;
            top: 3px;
            right: 7px;

            .theme-mode-light & {
                right: 12px;
            }

            &:focus {
                opacity: 1;
            }

            &:before {
                .theme-mode-light & {
                    font-size: 14px;
                }
            }
        }

        &:not(.active) {
            .icon-with-label {
                @include themify($themes) {
                    color: themed("themeSubMenuTextColor");
                }

                body:not(.theme-mode-light) & {
                    color: rgba(255, 255, 255, 0.8);
                }

                @include tablet-large {
                    .theme-mode-light & {
                        &:hover {
                            color: black;
                        }
                    }
                }

                &:before {
                    body:not(.theme-mode-light) & {
                        color: white;
                    }
                }
            }

            body:not(.theme-mode-light) & {
                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 8px;
                    width: 1px;
                    height: 16px;
                    background-color: rgba(255, 255, 255, 0.15);
                }

                &:before {
                    left: 0;
                }

                &:after {
                    display: none;
                    right: 0;
                }

                &:last-child:after {
                    display: block;
                }
            }

            &:hover {
                @include tablet-large {
                    .icon-wrap {
                        opacity: 1;
                    }
                }

                .icon-wrap {
                    &:hover {
                        background: transparent;
                    }

                    &:before {
                        @include themify($themes) {
                            color: themed("themeBaseColor");
                        }

                        body:not(.theme-mode-light) & {
                            color: white;
                        }
                    }
                }

                .icon-with-label:before {
                    background: transparent !important;
                }
            }
        }

        .icon-with-label {
            line-height: 32px;

            &:hover:before {
                background: transparent;
            }

            &:before {
                top: 0;
                line-height: 32px;

                .theme-mode-light & {
                    font-size: 14px;
                }
            }
        }

        &.active {
            body:not(.theme-mode-light) & {
                background-color: white;
            }

            .icon-with-label {
                font-weight: 700;
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }

                .theme-mode-light & {
                    color: black;
                }
            }

            .icon-wrap {
                opacity: 1;

                &:before {
                    @include themify($themes) {
                        color: themed("themeBaseColor");
                    }
                }
            }

            + div:before {
                display: none;
            }
        }
    }

    &--loading {
        justify-content: center;

        .wait-icon {
            align-self: center;
        }
    }
}
