@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.property-list-item-inner {
    padding: 8px 0;

    &.full-width-property {
        border-bottom: none;

        .property-list-item-value {
            width: 100%;
        }

        .flex-row {
            .select-box {
                flex: 1;
                height: 30px;
            }
        }

        .text-label {
            width: 100%;
            padding-bottom: 10px;
            margin-right: 0;
        }
    }

    .text-label {
        margin-right: 10px;
        font-weight: 400;
    }
}

.property-list-item {
    margin: 0 20px;
    align-items: center;

    @include themify($themes) {
        border-bottom: 1px solid themed("themeDirtyColor");
    }

    &--no-border-bottom {
        @include themify($themes) {
            border-bottom: none;
        }
    }

    &--border-top {
        @include themify($themes) {
            border-top: 1px solid themed("themeDirtyColor");
        }
    }

    &--inner-content {
        padding: 8px 0;
    }

    .property-list-item {
        margin: 0;
        &--add-item {
            margin: 15px 20px;
        }
    }

    &--expanded-title {
        .property-list-item-inner {
            border-bottom: none;
        }
    }

    &--text {
        .property-list-item-value {
            width: 120px;
        }

        .property-list-item-inner {
            .property-list-item-title-with-switch {
                .switch {
                    .icon-wrap {
                        line-height: 1;
                    }
                }
            }
        }
    }

    &--select {
        .property-list-item-value {
            min-width: 155px;

            &.no-margin-left {
                margin-left: 0px;
            }
        }
    }

    &--select-panel {
        .extra-margin {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .property-list-item-selected-value {
            margin-top: 5px;
        }
    }

    &--config-panel {
        padding-bottom: 17px;
        cursor: pointer;

        .icon-with-label:before {
            right: -6px;
        }
    }

    &.property-list-item {
        &--widget,
        &--add-item {
            border-bottom: none;
        }

        &--html {
            &.error {
                .cke {
                    @include box-shadow(inset 0, 0, 0, 0 $red-field-error-light !important);
                    @include box-shadow(0, 0, 0, 0 $red-field-error-light !important);
                    @include transition(box-shadow 0.5s !important);
                }
            }

            &.light-up-error {
                .cke {
                    @include box-shadow(inset 0, 0, 0, 0 $red-field-error-light !important);

                    @include box-shadow(0, 0, 15px, 0 $red-field-error !important);
                }
            }
        }

        &--notification {
            .status-msg {
                margin-top: 10px;
            }

            .property-list-item {
                &--type-selector {
                    .property-list-item-inner {
                        padding: 15px 0;
                    }
                }

                &--html {
                    .property-list-item-inner {
                        padding: 15px 0;
                    }
                }
            }
        }

        &--shadow {
            .property-list-item {
                &--select-box.shadow-size {
                    .property-list-item-inner {
                        .select-box {
                            padding: 2px 5px;
                        }
                    }
                }
            }
        }
    }

    &--row {
        > .property-list {
            > .property-list-item:first-child {
                > .property-list-item-inner {
                    @include themify($themes) {
                        border-top: 1px solid themed("themeDirtyColor");
                    }
                }
            }

            > .property-list-item:last-child {
                @include themify($themes) {
                    border-bottom: none;
                }

                > .property-list-item-inner {
                    border-bottom: none;
                }
            }
        }
    }

    &--icon-selector {
        .flex-row {
            .select-box {
                width: auto;
                padding: 2px;
            }
        }
    }

    &--row > &--title,
    &--widget > &--title,
    &--prop-group:not(.ignore-bold-uppercase) > &--title {
        .text-label {
            font-weight: 700;
            text-transform: uppercase;
            min-height: 28px;
            line-height: 28px;
        }
    }

    &--color {
        .property-list-item-value {
            width: 25px;
            height: 25px;
            border-radius: 4px;
            flex-shrink: 0;
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }

            .property-list-item-color-picker {
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }

    &--spacing {
        .property-list-item-value {
            width: 52px;
            margin-left: 5px;
        }
    }

    &--add-item {
        margin: 15px 20px;
    }

    &--expanded-auto-custom {
        > .property-list-item-inner {
            border-bottom: none;
        }

        > .property-list-item {
            margin: 0 20px 17px;
            border-bottom: none;

            .property-list-item-inner {
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }
            }

            .text-label {
                margin-right: 5px;
            }
        }
    }

    .status-msg.error-message {
        margin-bottom: 10px;
    }

    &--size-md {
        input.property-list-item-value {
            width: 155px;
        }
    }
}
