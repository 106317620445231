@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.file-viewer-container {
    position: relative;
    height: 100%;
    overflow: hidden;

    .file-viewer {
        object-fit: contain; // Not supported by IE11
    }

    &--image {
        @include ie {
            overflow: auto;

            .file-viewer {
                position: absolute;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                right: 50%;
                transform: translate(50%, 0);
            }
        }
    }
}
