@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";

.calculation-edit-form {
    .json-form {
        height: auto;
    }

    .calculation-actions {
        position: relative;
        padding: 15px 20px;
        @include box-shadow-common($shadow-outer-center);

        .btn:not(:first-child) {
            margin-left: 17px;
        }
    }
}
