@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.new-application-form {
    &.from-scan,
    .split-view & {
        .home-business-info,
        .your-contact-info,
        .search-contractor {
            .json-form .object-properties {
                .schema-container {
                    @include calc(width, "(100% / 2) - 20px");

                    @include desktop-large {
                        @include calc(width, "(100% / 3) - 20px");
                    }
                }
            }
        }
    }

    .new-app-section {
        &:not(:last-child) {
            margin-bottom: 40px;

            @include tablet {
                margin-bottom: 50px;
            }
        }

        &.customer-search {
            .new-app-section__form {
                @include flexbox;
                @include flex-wrap(wrap);
            }

            h5 {
                font-size: em(14);
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }

                @include tablet-and-mobile {
                    margin-bottom: 15px;
                }
            }

            .section-left-block {
                position: relative;

                @include tablet-and-mobile {
                    width: 100%;
                }

                .json-form {
                    @include tablet-only {
                        .object-properties {
                            @include flexbox;

                            .schema-container {
                                @include calc(width, "(100% / 3) - 10px");

                                &:not(:nth-of-type(2n)) {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }

                    .action-buttons {
                        @include tablet-large {
                            @include flexbox;
                            justify-content: flex-end;

                            .btn:first-child:not(:last-child) {
                                flex: 1;
                            }
                        }
                    }
                }

                @include tablet-large {
                    padding-right: 60px;
                    @include calc(width, "(100% / 5) * 2");
                    @include themify($themes) {
                        border-right: 1px solid themed("themeLighterDirtyColor");
                    }
                }

                &.split-view {
                    @include tablet-large {
                        border-right: none;
                    }
                }

                &:after {
                    content: "OR";
                    position: absolute;
                    padding: 10px;
                    background-color: white;
                    font-weight: 700;
                    font-size: em(12);
                    color: $grey;

                    @include mobile-only {
                        margin-top: 34px;
                        @include calc(left, "(50% - 18px)");
                    }

                    @include tablet-only {
                        margin-top: 34px;
                        @include calc(left, "(30%)");
                    }

                    @include tablet-large {
                        right: -20px;
                        @include calc(top, "(50% - 18px)");
                    }
                }

                &.split-view {
                    &:after {
                        @include tablet-large {
                            margin-top: 34px;
                            @include calc(left, "(50% - 18px)");
                            top: auto;
                            right: auto;
                        }
                    }
                }
            }

            .section-right-block {
                width: 100%;

                .status-msg {
                    margin: 10px 0;

                    @include tablet-only {
                        margin: 15px 0;
                    }

                    @include tablet-large {
                        margin: 20px 0 0;
                    }
                }

                @include tablet-and-mobile {
                    margin-top: 50px;
                    padding-top: 40px;
                    @include themify($themes) {
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }
                }

                @include tablet-only {
                    @include calc(width, "(100% / 3) * 2");
                }

                @include tablet-large {
                    padding-left: 60px;
                    @include calc(width, "(100% / 5) * 3");
                }

                &.no-cis {
                    padding-left: 0;
                    @include tablet-large {
                        width: 75%;
                    }
                    @include tablet-and-mobile {
                        margin-top: 0;
                        padding-top: 0;
                        border-top: none;
                    }
                }

                .json-form {
                    .object-properties {
                        @include flexbox;
                        @include flex-wrap(wrap);

                        .schema-container {
                            width: 100%;

                            @include tablet {
                                @include calc(width, "50% - 10px");

                                &:not(:nth-of-type(2n)) {
                                    margin-right: 20px;
                                }
                            }
                        }

                        .btn-square {
                            min-width: 32px;
                        }
                    }

                    .action-buttons {
                        .btn {
                            &:first-child {
                                order: 2;
                                margin: 0 0 0 10px;
                            }

                            &:last-child {
                                order: 1;
                            }

                            &.btn-disabled {
                                pointer-events: auto;
                                cursor: default;

                                &:hover {
                                    @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
                                }

                                button {
                                    cursor: default;
                                }
                            }
                        }
                    }
                }

                .customer-search-form-actions {
                    margin-top: -15px;

                    .icon-with-label {
                        margin-left: 10px;
                    }

                    .wait-icon {
                        margin-bottom: 15px;
                    }

                    .status-msg {
                        margin: 0 0 15px 0;
                        .action-label {
                            font-size: inherit;
                        }
                    }
                }
            }

            .json-form {
                .action-buttons {
                    @include tablet-and-mobile {
                        margin-top: 15px;
                    }
                }

                .object-properties .schema-container {
                    @include tablet-large {
                        margin-top: 20px;
                        margin-bottom: 0;

                        .form-group {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.from-scan,
            .split-view & {
                .section-left-block {
                    padding: 0;
                    width: 100%;

                    .json-form {
                        .action-buttons {
                            @include tablet {
                                justify-content: flex-start;

                                .btn:first-child {
                                    flex: inherit;
                                }
                            }
                        }
                    }
                }

                .section-right-block {
                    margin: 50px 0 0;
                    padding: 40px 0 0;
                    width: 100%;
                    border: none;
                    @include themify($themes) {
                        border-top: 1px solid themed("themeLighterDirtyColor");
                    }

                    .status-msg {
                        margin: 15px 0;
                    }

                    &:before {
                        top: -20px;
                        left: 0;
                        padding-left: 0;
                    }
                }

                @include tablet {
                    .json-form {
                        .object-properties {
                            @include flexbox;

                            .schema-container {
                                @include calc(width, "(100% / 2) - 10px");

                                &:not(:nth-of-type(2n)) {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.your-contact-info {
            .icon-with-label {
                margin-bottom: 30px;
            }
        }

        &.customer-search,
        &.your-contact-info,
        &.search-contractor,
        &.home-business-info {
            @include mobile-only {
                .json-form .action-buttons {
                    @include flexbox;
                    justify-content: flex-end;

                    .btn:first-child:not(:last-child) {
                        flex: 1;
                    }
                }
            }
        }

        &.your-contact-info,
        &.search-contractor,
        &.home-business-info {
            .json-form {
                .object-properties {
                    .schema-container {
                        margin-top: 20px;
                        margin-bottom: 0;

                        @include mobile-only {
                            margin-top: 15px;
                            width: 100%;

                            &:first-child {
                                margin-top: 10px;
                            }
                        }

                        @include tablet-only {
                            @include calc(width, "(100% / 3) - 20px");
                        }

                        .form-group {
                            margin-bottom: 0;
                        }
                    }
                }

                .action-buttons {
                    margin-top: 40px;
                }
            }
        }

        &.search-contractor {
            .new-app-section__form {
                padding-bottom: 15px;

                @include tablet {
                    padding-bottom: 30px;
                }
            }

            .new-app-contractor {
                .btn-group {
                    position: relative;

                    @include tablet {
                        display: inline-block;
                    }

                    .btn {
                        @include mobile-only {
                            width: 100%;
                        }

                        .required {
                            padding: 0;
                        }
                    }

                    + .json-form,
                    + .status-msg {
                        margin-top: 30px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .json-form .account-number-widget {
            @include flexbox;

            .btn {
                margin-left: 10px;
            }
        }

        .existing-contacts {
            .field-group {
                position: relative;
                display: inline-block;
                padding-bottom: 45px;
                width: auto;
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }

                @include tablet {
                    label {
                        vertical-align: middle;
                        margin: 0 10px 0 0;
                        max-width: 154px;
                    }

                    .dropdown-wrap {
                        display: inline-block;
                        vertical-align: middle;
                        width: 200px;
                    }
                }

                &:after {
                    content: "OR";
                    position: absolute;
                    bottom: -18px;
                    left: 0;
                    padding: 10px 10px 10px 0;
                    background-color: white;
                    font-weight: 700;
                    font-size: em(12);
                    color: $grey;
                }
            }

            p {
                margin: 40px 0 20px;
                font-weight: 500;
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
            }
        }
    }

    .new-contact {
        .use-existing {
            margin-bottom: 25px;

            > span {
                font-size: em(12);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(14);
                }
            }

            .button-group-container {
                display: flex;
                margin-top: 8px;

                .btn {
                    width: auto !important;
                }
            }
        }

        .json-form {
            .account-number-widget {
                display: flex;
                flex-direction: row;

                .btn {
                    margin-left: 10px;
                }
            }
        }
    }
}
