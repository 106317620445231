@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.list-item-category {
    @include flexbox;
    align-items: center;
    padding: 0 46px 0 13px;
    height: 44px;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor");
    }
    font-size: em(12);
    font-weight: 500;
    @include themify($themes) {
        color: themed("themeColorDarkGrey");
    }

    .font-size-large & {
        font-size: em(14);
    }

    &.with-arrow {
        cursor: pointer;

        &:after {
            position: absolute;
            top: 0;
            right: 14px;
            line-height: 44px;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }

    &:hover {
        @include themify($themes) {
            background: themed("themeWhiteColor");
            color: themed("themeBaseColor");
        }
    }

    &.list-item-selected {
        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
            color: themed("themeBaseColor");
        }
    }
}
