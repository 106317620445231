@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";

.mimic-user-modal {
    .mimic-actions {
        margin-top: 20px;
        justify-content: flex-end;

        @include mobile-only {
            @include flex-wrap(wrap);
        }

        @include tablet {
            justify-content: space-between;
        }

        .btn {
            &:first-child {
                @include mobile-only {
                    margin-right: 10px;
                }
            }

            &:not(:last-child) {
                @include mobile-only {
                    @include calc(width, "50% - 5px");
                    margin-bottom: 20px;
                }

                @include tablet {
                    margin-right: 10px;
                }
            }
        }
    }
}
