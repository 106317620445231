@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/mixins";

.wf {
    &__settings {
        margin-left: 20px;
        width: 320px;
        background: white;

        &-container {
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
                border-top: none;
            }

            &--no-content {
                min-height: auto;
            }

            &-buttons {
                position: relative;
                padding: 15px 20px;
                @include box-shadow-common($shadow-outer-center);
            }
        }

        &-title {
            z-index: 1;
            margin-top: 1px;
            padding: 21px 15px;
            background: white;
            @include box-shadow-common($shadow-outer);

            @include themify($themes) {
                border-left: 1px solid themed("themeDirtyColor");
                border-right: 1px solid themed("themeDirtyColor");
            }

            &-text {
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
}
