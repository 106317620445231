@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

textarea {
    padding: 10px;
    min-width: 100%;
    max-width: 100%;
    min-height: 64px;

    &:disabled {
        max-height: 64px;
    }

    // Set default height if rows are not defined.
    &:not([rows]) {
        height: 64px;
    }

    &:not(.error):not(.textfield-read-only) {
        &:focus,
        &.focus {
            @include themify($themes) {
                border-color: themed("themeBoxShadowBorderColor");
                @include box-shadow(0, 0, 8px, 0 themed("themeBoxShadowColor"));
            }
        }
    }

    &.error {
        border-color: $red !important;
        @include box-shadow(inset 0, 1px, 2px, 0 $red-field-error-light !important);
        @include box-shadow(0, 0, 0, 0 $red-field-error-light !important);
        color: $red;
        @include transition(box-shadow 0.5s !important);
    }

    &.light-up-error {
        @include box-shadow(inset 0, 0, 0, 0 $red-field-error-light !important);
        @include box-shadow(0, 0, 15px, 0 $red-field-error !important);
    }

    &.textfield-read-only {
        box-shadow: none !important;
        pointer-events: none;
        color: $blue-grey;

        @include themify($themes) {
            background: themed("themeWhiteColor");
            border-color: themed("themeLighterDirtyColor");
        }
    }
}
