@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-properties-section-header {
    padding: 15px 20px;

    &.contains-errors {
        background: $red-gradient-to-bottom;
    }

    .black-text {
        color: black;
    }

    .non-transformed-text {
        text-transform: none;
    }

    .portal-builder-section-icon {
        margin-right: 5px;
        width: auto;
        height: 15px;
        @include themify($themes) {
            fill: themed("themeBaseColorDesaturated");
        }
    }

    .portal-row-layout {
        margin-top: 15px;
    }

    .portal-builder-properties-section-header-title {
        font-size: em(14);
        font-weight: 700;
        text-transform: uppercase;
        @include themify($themes) {
            color: themed("themeColorBaseGrey");
        }
    }

    .portal-builder-properties-section-header-subtitle {
        margin-top: 5px;
        font-size: em(11);
        font-weight: 700;
        text-transform: uppercase;
        color: $light-grey;
        line-height: 1.5;

        strong {
            @include themify($themes) {
                color: themed("themeTitleColor");
            }
        }

        .page-title {
            text-transform: none;
            @include themify($themes) {
                color: themed("themeTitleColor");
            }
        }
    }

    .portal-builder-properties-section-header-controls {
        font-weight: 700;
    }

    &--underline {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }
    }
}
