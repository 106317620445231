@import "src/assets/sass/_variables.scss";

.image-widget-picture {
    object-fit: cover;

    &.img-fluid {
        max-width: 100%;
    }

    &.auto {
        height: auto;
        width: auto;
        max-width: 100% !important;
    }

    &.fill-height {
        min-height: 100%; // height: 100% with big images does not work properly on iOS devices
    }

    &.fill-width {
        width: 100%;
    }
}
