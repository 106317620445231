@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.tiles-wrap {
    @include mobile-only {
        justify-content: flex-start !important;
    }

    @include tablet {
        margin: 0 -10px;
        @include calc(width, "100% + 20px");
    }
}
