@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.global-search__criteria {
    position: relative;
    margin: 0 auto;
    padding: 15px 0 10px;
    max-width: 830px;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeLighterDirtyColor");
    }

    &.with-remove-all-icon {
        padding-right: 100px;
    }

    > .icon-with-label {
        position: absolute;
        top: 24px;
        right: 0;
    }

    &-item {
        padding: 0 5px;
        @include border-radius(12px);
        margin: 0 5px 5px 0;

        @include themify($themes) {
            background: themed("themeSemiWhiteColor");
            border: 1px solid themed("themeBaseColorSemiTransparent7");
        }

        .btn-tag {
            margin: 4px;
            line-height: 24px;

            @include themify($themes) {
                background: themed("themeBaseColorSemiTransparent10");
                border: none;
            }

            strong {
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }
        }
    }

    &-category {
        margin: 0 10px;
        font-size: em(12);

        .font-size-large & {
            font-size: em(14);
        }
    }

    &-actions {
        margin-left: 10px;
        @include scale(0.9);

        .icon-wrap {
            &:hover {
                background: transparent !important;
            }
        }
    }

    &-separator {
        font-weight: 700;
        font-size: 18px;
        margin: 0 10px 0 5px;
    }

    &-add {
        margin-left: 5px;
    }

    > .remove {
        margin-left: 10px;
    }
}
