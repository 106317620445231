@import "../../../../../assets/sass/variables";
@import "../../../../../assets/sass/mixins";

.application-document-queue {
    &__grid-document-row {
        height: 40px;
        padding: 10px 15px 10px 40px;
        cursor: default;

        &:not(:first-child) {
            @include themify($themes) {
                border-top: 1px solid themed("themeLighterDirtyColor");
            }
        }

        &--selected {
            @include themify($themes) {
                background: themed("themeSemiWhiteColor");
            }
        }

        &:hover:not(&--selected) {
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
        }

        > .checkbox-wrap {
            margin-right: 10px;
        }

        &--without-selection {
            padding-left: 44px;
        }

        &-field {
            width: 25%;
            font-size: 11px;

            @include tablet {
                font-size: 12px;

                .font-size-large & {
                    font-size: 14px;
                }
            }

            &:not(:last-child) {
                padding-right: 10px;
            }

            &--link {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
                word-break: break-all;
                cursor: pointer;

                @include mobile-only {
                    min-width: 80px;
                    max-width: 80px;
                }
            }

            @include mobile-only {
                &--document {
                    min-width: 65px;
                    max-width: 65px;
                }

                &--date {
                    text-align: right;
                }
            }
        }
    }
}
