@import "../../../assets/sass/_mixins";

.label-with-dropdown {
    .icon-with-label.active:after {
        @include rotate(180deg);
    }

    .dropdown-list-wrap {
        z-index: 1000;
    }
}
