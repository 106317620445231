@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";

.panel.invoice-workflow-panel {
    padding-bottom: 20px;
}

.panel.contract-invoices,
.panel.invoice-documents,
.panel.invoice-history,
.panel.invoice-notes,
.panel.invoices {
    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;
    }
}

.utility-invoice-sidenav-panel,
.invoice-notes-sidenav-panel,
.invoice-documents-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}

.panel.invoice-history,
.panel.invoice-documents {
    .grid-controls {
        margin: 0 0 12px;
    }
}

.panel.invoices {
    .grid-controls {
        margin: -36px 0 12px;
    }
}

.panel.invoice-notes {
    .data-grid-controls {
        margin: -36px 0 12px;
    }
}

.utility-invoice-sidenav-panel {
    .cost-code-value-widget > .field-group {
        margin-bottom: 10px;
    }
}
