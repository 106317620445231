@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.user-audit-footer {
    padding: 15px 20px;
    background-color: white;
    @include box-shadow-common($shadow-outer);

    .btn + .btn {
        margin-left: 20px;
    }
}

.user-audit-panel {
    padding-top: 20px;
    container-type: inline-size;

    .tab-list .tabs .tab {
        padding: 7px 19px;
    }

    .data-grid-container {
        position: relative;
    }

    .grid-list {
        max-height: 100%;

        @include tablet-and-mobile {
            max-height: calc(100% - 40px);
        }

        .grid-list-left-side {
            .grid-list-row-container {
                overflow-y: auto;
            }
        }
    }

    .data-grid-controls {
        margin: 0;
        padding: 10px;

        @include tablet-and-mobile {
            @include flexbox;
            @include data-grid-controls-mobile;
            min-height: 40px;
        }
    }

    @container (min-width: 1153px) {
        .data-grid-controls {
            z-index: 2;
            position: absolute;
            right: 0px;
            top: -41px;

            .split-view & {
                z-index: initial;
                position: initial;
                @include flexbox;
                @include data-grid-controls-mobile;
                min-height: 40px;
            }

            .flex-one {
                margin: 0 10px;
            }
        }
    }
}

.supplemental-data-block {
    justify-content: space-between;
    align-items: top;
    margin: 20px 0;
    padding: 10px 30px;
    background-color: white;
    overflow: overlay;

    .supplemental-data-block-item {
        padding: 10px;

        .text-label {
            margin-bottom: 8px;
            color: $light-grey;
        }

        .supplemental-data-block-value {
            overflow: hidden;
            overflow-wrap: break-word;
        }

        .field-group {
            min-width: 150px;
        }
    }
}
