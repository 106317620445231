@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.users-management {
    .window-body-header {
        @include mobile-only {
            width: 100%;

            body:not(.theme-mode-light) & {
                &:before {
                    content: "";
                    position: absolute;
                    top: -2px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: white;
                }
            }

            .view-with-submenu & {
                &:before {
                    top: -7px;
                    height: 7px;
                }

                > .main-grid-wrap {
                    .theme-mode-light & {
                        background: white;
                    }
                }
            }
        }

        .header-wrap {
            @include mobile-only {
                @include themify($themes) {
                    border-top: 1px solid themed("themeLighterDirtyColor");
                }
            }

            .view-with-submenu & {
                padding: 11px 0 16px;

                .theme-mode-light & {
                    padding: 16px 0;
                }

                @include tablet {
                    padding: 8px 0 12px;

                    .theme-mode-light & {
                        padding: 12px 0;
                    }
                }
            }
        }
    }

    .table-block {
        padding: 15px 0 10px;

        @include tablet {
            padding: 30px 0 20px;
        }
    }

    .filters {
        justify-content: space-between;

        @include mobile-only {
            @include flex-wrap(wrap);
        }

        @include tablet-large {
            justify-content: center;
        }

        .filter-block {
            @include flexbox;
            align-items: center;

            @include mobile-only {
                justify-content: space-between;
                width: 100%;
            }

            .split-view & {
                @include flex-direction(column);
                align-items: flex-end;
            }

            .radio-wrap {
                margin-right: 10px;
            }

            .block-label {
                margin-right: 10px;
                font-size: em(12);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(14);
                }

                @include mobile-only {
                    width: 106px;

                    + div {
                        flex: 1;
                    }
                }

                @include tablet {
                    font-size: em(13);

                    .font-size-large & {
                        font-size: em(14);
                    }
                }
            }

            &.status {
                .split-view & .block-label {
                    position: relative;
                    top: -9px;
                }
            }

            &.client {
                @include mobile-only {
                    margin-top: 15px;
                }

                @include tablet-large {
                    position: relative;
                    margin-left: 25px;
                    padding-left: 33px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 3px;
                        height: 20px;
                        @include themify($themes) {
                            background: themed("themeDirtyColor");
                        }
                    }
                }

                .split-view & {
                    align-items: flex-start;
                    margin-left: 6px;
                    padding-left: 20px;

                    &:before {
                        top: 3px;
                        height: 53px;
                    }

                    .block-label {
                        margin-bottom: 5px;
                    }
                }

                .field-group {
                    width: 220px;
                }
            }
        }
    }

    .data-grid-pre-header {
        margin-bottom: 15px;
        align-items: center;

        .btn {
            margin-left: 10px;
            @include mobile-only {
                margin-left: 0;
            }
        }

        .icon-with-label.with-hand-made-icon {
            white-space: nowrap;

            @include mobile-only {
                bottom: 8px;
            }
        }

        @include mobile-only {
            margin-bottom: 10px;
            align-items: flex-end;

            .btn.add-user-add-identity-add-person-empty {
                min-width: 32px;

                button {
                    padding-left: 21px;
                    font-size: 0;
                }
            }
        }
    }

    .bulk-assignment {
        @include tablet-and-mobile {
            @include flex-wrap(wrap);
        }

        &__label {
            font-size: em(13);
            font-weight: 500;

            .font-size-large & {
                font-size: em(14);
            }

            @include mobile-only {
                font-size: 13px;

                .font-size-large & {
                    font-size: 15px;
                }
            }
        }

        .select-condition {
            position: relative;
            font-size: em(10);

            .font-size-large & {
                font-size: em(12);
            }

            @include tablet-and-mobile {
                margin-top: 5px;
            }

            &.warning {
                color: $warning;

                @include tablet {
                    font-weight: 500;
                }
            }

            span,
            .icon-wrap {
                vertical-align: middle;
            }

            span {
                display: inline-block;
                margin-left: 5px;
                max-width: 140px;

                @include tablet {
                    max-width: 145px;

                    .font-size-large & {
                        max-width: 185px;
                    }
                }
            }
        }

        .btn {
            margin-right: 5px;

            @include tablet {
                margin: 0 10px;
            }
        }
    }
}

.user-external-apps {
    .sidenav-body {
        padding: 20px 11px 0px 11px;
        border-radius: 5px;
    }

    .tile {
        width: 100%;
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }

        &:hover {
            @include themify($themes) {
                background: themed("themeLighterDirtyColor");
            }
        }

        &:not(:first-child) {
            border-top: none;
            cursor: pointer;
        }

        .btn:not(:last-child) {
            margin-right: 10px;
        }

        small {
            color: black;
        }
    }

    h4 {
        margin-top: 25px;
        margin-left: 11px;
    }
}
