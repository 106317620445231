.analytics-date-range-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    &.field-group {
        width: auto;
    }

    label {
        margin: 0;
    }

    .dropdown-wrap {
        min-width: 160px;
    }
}
