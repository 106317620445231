@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.selected-items-expandable-panel {
    max-height: 200px;

    .selected-items-list {
        flex: 1;
        margin-left: -20px;
        padding: 15px 20px 15px 44px;
        @include calc(width, "100% + 40px");
        list-style-type: disc;
        overflow-y: auto;
        @include box-shadow(inset 0, 0, 3px, 2px rgba(0, 0, 0, 0.1));
        @include themify($themes) {
            background: themed("themeWhiteColor");
        }

        @include ie {
            flex: 1 0 auto;
        }

        li:not(:last-child) {
            margin-bottom: 5px;
            font-size: em(13);
        }
    }
}
