@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.budget-lines-select-form {
    padding: 20px;

    @include ie {
        max-height: 80vh;
    }

    .budget-line-name {
        position: relative;
        margin: 0;
        padding: 12px 16px;
        @include border-radius(4px);
        overflow: hidden;
        font-size: em(12);
        cursor: pointer;
        line-height: 1.5;

        .font-size-large & {
            font-size: em(14);
        }

        @include themify($themes) {
            background: themed("themeWhiteColor");
            color: themed("themeBaseColor");
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        > span {
            z-index: 1;
            position: relative;
            line-height: 1.4;
        }

        &:hover {
            @include themify($themes) {
                background: themed("themeHoverGridListRowBackgroundColor");
            }

            @include tablet-large {
                .btn.with-icon {
                    opacity: 1;
                }
            }
        }

        .document-icon {
            @include calc(top, "50% - 10px");
            left: 15px;
        }

        .file-watermark {
            position: absolute;
            top: -16px;
            right: 0;
            @include rotate(-10deg);
            font-size: 61px;
            font-weight: 700;
            line-height: 1;
            @include themify($themes) {
                color: themed("themeWatermarkColor");
            }
        }

        .btn.with-icon {
            margin-left: 50px;
            min-width: auto;

            @include tablet-large {
                opacity: 0;
            }
        }
    }
}

.budgetline-list__filter {
    padding: 0px 0px 10px 0px;
    margin-bottom: 10px;
    @include themify($themes) {
        border-bottom: 1px solid themed("themeDirtyColor");
    }
}
