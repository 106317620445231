.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

.flex-one {
    flex: 1;
}

.flex-one-in-column {
    flex: 1;
    height: 0;
}

.flex-one-in-row {
    flex: 1 1 0;

    @include tablet {
        @include ie {
            width: 0;
        }
    }
}

.flex-two {
    flex: 2 1;
}

.flex-three {
    flex: 3 1;
}

.flex-grow {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100%;
}

.flex-margin {
    margin: 10px;
}

.flex-padding {
    padding: 10px;
}

.align-content-start {
    align-content: start;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-self-stretch {
    align-self: stretch;
}

.align-self-center {
    align-self: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.no-shrink,
.shrink-0 {
    flex-shrink: 0;
}

@include ie {
    .flex-one {
        flex: 1 0 auto;
    }

    .flex-one-in-column {
        flex: 1 0 auto;
        height: 0;
    }
}

@include firefox {
    .flex-one {
        flex: 1 0 auto;
    }

    .flex-one-in-column {
        flex: 1 0 auto;
        height: 0;
    }
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 1rem !important;
}

.gap-4 {
    gap: 1.5rem !important;
}

.gap-5 {
    gap: 2rem !important;
}
