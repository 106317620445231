@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.create-application {
    height: 100%;
    background-color: white;

    @include mobile-only {
        .page-lead-block {
            padding: 30px 20px 30px 75px;
            text-align: left;

            &:before {
                z-index: 1;
                left: 20px;
                bottom: 20px;
                right: auto;
                text-align: center;
            }
        }
    }

    &__head {
        background: white;

        .main-grid-wrap.responsive {
            margin: 0 auto;
            padding: 30px 30px 30px 20px;

            @include tablet {
                padding: 50px 30px;
            }

            > .field-group {
                &:before {
                    content: $icon-touch-empty;
                    @include icon-iconoteka;
                    display: block;
                    width: 30px;
                    height: 30px;
                    @include border-radius(50%);
                    text-align: center;
                    line-height: 30px;

                    @include themify($themes) {
                        background: themed("themeSemiWhiteColor");
                        color: themed("themeBaseColor");
                    }

                    @include mobile-only {
                        z-index: 1;
                        position: absolute;
                        top: -7px;
                        left: 5px;
                        @include box-shadow-common($shadow-outer-hover);
                    }

                    @include tablet {
                        margin: 0 auto 5px;
                    }
                }

                &.selected:before {
                    content: $icon-check-done;
                    background: $green-light;
                    line-height: 32px;
                    font-weight: 500;
                    color: $green;
                }
            }
        }

        .field-group {
            position: relative;
            display: block;
            margin: 0 0 40px;

            @include mobile-only {
                padding-left: 55px;

                &:after {
                    content: "";
                    position: absolute;
                    left: 20px;
                    top: -80px;
                    display: block;
                    width: 1px;
                    @include calc(height, "100% + 30px");
                    @include themify($themes) {
                        background: themed("themeDirtyColor");
                    }
                }
            }

            @include tablet {
                margin: 0 auto 30px;
                width: 315px;
            }

            label {
                display: block;

                @include tablet {
                    text-align: center;
                }
            }
        }

        .application-form-warning-msg {
            position: relative;
            display: block;
            margin: 0 0 40px;

            @include mobile-only {
                padding-left: 55px;
            }

            @include tablet {
                margin: 0 auto 30px;
                width: 315px;
                text-align: center;

                .status-msg {
                    padding: 23px 0 0;

                    &:after {
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }
            }
        }

        .btn-group {
            @include mobile-only {
                justify-content: flex-end;

                &:not(.hidden-group) {
                    @include flexbox;
                }

                > .btn:first-child {
                    flex: 1;
                }
            }

            @include tablet {
                margin-top: 45px;
                text-align: center;
            }
        }

        @include mobile-only {
            .btn-group-wrapper {
                display: none;
                padding-top: 20px;
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }

                &.first-selection {
                    display: block;
                }

                &.last-step {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 20px;
                        top: -85px;
                        display: block;
                        width: 1px;
                        height: 85px;
                        @include themify($themes) {
                            background: themed("themeDirtyColor");
                        }
                    }
                }
            }
        }
    }
}
.file-upload-sidenav-panel {
    .json-form {
        height: auto;

        .object-properties .schema-container {
            margin-bottom: 20px;
        }
    }
}
