@import "../../../assets/sass/_mixins";

.xml-process-details {
    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;

        @include ie {
            height: auto;
        }

        .grid-list-left-side {
            .grid-list-row-container {
                .grid-list-row {
                    height: auto;
                }
            }
        }
    }

    .context-preview {
        font-size: 13px;

        ol,
        ul,
        dl {
            list-style: initial;
            padding-inline-start: 40px;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }

        p {
            font-size: 13px;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
    }
}
