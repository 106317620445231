@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.tab-list {
    position: relative;
    width: 100%;

    .tabs {
        overflow: auto;

        @include tablet-large {
            overflow: hidden;
        }

        .tab {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 7px 20px;
            min-height: 44px;
            border-bottom: 2px solid transparent;
            font-size: em(12);
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;

            @include ie {
                flex-shrink: 0;
            }

            .font-size-large & {
                font-size: em(14);
            }

            &:not(.equal-width) {
                white-space: nowrap;
            }

            &:hover {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }

            &.active {
                background: white;

                @include themify($themes) {
                    border-bottom: 2px solid themed("themeBaseColor");
                    color: themed("themeBaseColor");
                }
            }

            &:not(.active) {
                background-color: transparent;
            }

            &.equal-width {
                width: 50%;

                &:not(.active) {
                    background-color: $white-light-grey;

                    .theme-sightline &,
                    .theme-sightlineHighContrast & {
                        background: $sightline-white-light-grey;
                    }
                }
            }
        }
    }

    &.tab-list-ghost {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .tabs {
            position: relative;
            top: 2px;

            .tab {
                padding: 0;
                min-height: 30px;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                &.active {
                    background: transparent;
                }
            }
        }

        .scroll-controls .scroll-control {
            top: -30px;

            .icon-wrap {
                height: 30px;
                line-height: 30px;
            }
        }
    }

    &.tab-list-wrapped {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeDirtyColor");
        }

        .tabs {
            padding-bottom: 8px;
        }

        .tab {
            @include border-radius(6px);
            min-height: 35px;
            font-weight: 500;
            @include themify($themes) {
                border: 1px solid themed("themeLighterDirtyColor");
            }

            &:not(.active) {
                background: white;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.active {
                position: relative;
                font-weight: 700;
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeLighterDirtyColor");
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -9px;
                    width: 100%;
                    height: 2px;
                    @include themify($themes) {
                        background: themed("themeBaseColor");
                    }
                }
            }

            &.form-with-data {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    right: 0;
                    width: 0;
                    height: 0;
                    @include themify($themes) {
                        border-bottom: 9px solid themed("themeBaseColor");
                    }
                    border-left: 9px solid transparent;
                }
            }

            &.disabled {
                position: relative;
                padding-right: 30px;

                .icon-wrap.block-not-interested-empty {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    opacity: 0.6;

                    &:before {
                        @include themify($themes) {
                            color: themed("themeColorDarkGrey");
                        }
                    }
                }
            }
        }

        .scroll-controls .scroll-control {
            top: -49px;

            .icon-wrap {
                height: 49px;
                line-height: 49px;
            }
        }
    }

    &.tab-list-bordered {
        @include themify($themes) {
            border-bottom: 1px solid themed("themeLighterDirtyColor");
        }

        .tabs {
            overflow: visible;
        }

        .tab {
            font-size: 16px;
            font-weight: 500;
            text-transform: none;
            padding: 8px 16px;
            margin-bottom: -1px;
            background-color: white;
            border: 1px solid transparent;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .tab.active {
            @include themify($themes) {
                border-color: themed("themeLighterDirtyColor");
                border-bottom-color: transparent;
                border-bottom-width: 1px;
                color: themed("themeBaseColor");
            }
        }
    }

    .scroll-controls {
        z-index: inherit;
        top: auto;
        height: auto;

        .main-grid-wrap {
            padding: 0;
            min-width: inherit;
            max-width: inherit;
        }

        .scroll-control {
            position: relative;
            top: -44px;
            width: 64px;

            .icon-wrap {
                position: initial;
                width: 30px;
                height: 44px;
                line-height: 44px;
                font-size: 18px;
            }

            &.left {
                float: left;
                left: -8px;
                @include themify($themes) {
                    background: themed("themeTransparentWhiteColorLeft30");
                }
                text-align: left;
            }

            &.right {
                float: right;
                right: -8px;
                @include themify($themes) {
                    background: themed("themeTransparentWhiteColorRight30");
                }
                text-align: right;

                .icon-wrap {
                    text-align: right;
                    right: auto;
                }
            }
        }
    }
}
