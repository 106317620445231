@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.portal-builder-properties-accessibility {
    > .wait-icon {
        align-self: center;
        padding: 20px;
    }

    .pages-list {
        padding: 0 20px 20px;
    }

    .pages-list-item {
        padding: 0;
        font-size: 16px;
        cursor: initial;

        &.list-item-row:hover {
            background: none;
        }

        .action-label {
            color: inherit;
        }

        &__counts {
            .status-mark {
                margin: 0 3px;
            }
        }

        .wait-icon {
            align-self: center;
        }
    }
}

.page-accessibility-details {
    .wait-icon {
        align-self: center;
    }
}
