@import "../../../../../../assets/sass/_mixins";
@import "../../../../../../assets/sass/variables";

.rebate-approvals {
    &__item {

        @include ie {
            flex-shrink: 0;
        }

        >.status {
            margin-right: 5px;
            width: 30px;
            background: $grey;
            @include border-radius(8px 0 0 8px);

            &.pending {
                @include themify($themes) {
                    background: themed("themeBaseColor");
                }

                color: white;
            }

            &:not(.pending) {
                background: white;

                @include themify($themes) {
                    border: 1px solid themed("themeDirtyColor");
                }

                color: $green;
            }

            span {
                @include rotate(-90deg);
                font-size: 13px;
                font-weight: 500;
                white-space: nowrap;
                align-self: center;

                .font-size-large & {
                    font-size: 15px;
                }

                .icon-wrap {
                    @include rotate(90deg);
                    @include scale(0.8);
                }
            }
        }

        &-head {
            padding: 10px 15px;
            background: white;
            @include border-radius(0 8px 0 0);

            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
                border-bottom: none;
            }

            .cell-label {
                margin-right: 5px;

                @include themify($themes) {
                    color: themed("themeBaseColorDesaturatedSemiTransparent70");
                }

                font-size: em(12);
                font-weight: 500;

                .font-size-large & {
                    font-size: em(14);
                }
            }

            .cell-content {
                font-size: em(14);
                font-weight: 700;

                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }

                .currency {
                    font-size: 15px;
                }
            }

            &-entry {
                &--sum {
                    .cell-content {
                        font-size: 23px;
                    }
                }
            }
        }

        .flex-one-in-row {
            width: 0;
        }

        .currency {
            @include currency-sign-styles;
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }
        }

        &-columns {
            display: flex;
            flex-direction: row;
            padding: 10px 15px;
            background: rgba(255, 255, 255, 0.6);

            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }

            overflow: auto;

            .rebate-approvals__item-column {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .rebate-approvals__item-body-cell-sub {
                    display: flex;
                    flex-direction: column;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-right: 10px;

                    &:not(:first-child) {
                        @include themify($themes) {
                            border-top: 1px solid themed("themeDirtyColor");
                        }
                    }

                    .cell-label {
                        font-size: 11px;
                        line-height: 1;

                        @include themify($themes) {
                            color: themed("themeBaseColorDesaturatedSemiTransparent70");
                        }

                        text-align: right;

                        .font-size-large & {
                            font-size: 13px;
                        }
                    }

                    .cell-value,
                    .link {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .cell-value {
                        font-size: 15px;

                        @include themify($themes) {
                            color: themed("themeColorDarkGrey");
                        }

                        text-align: right;
                    }
                }

                &--received-date,
                &--bill-account,
                &--project-name,
                &--measure {
                    .rebate-approvals__item-body-cell-sub {

                        .cell-label,
                        .cell-value {
                            text-align: left;
                        }
                    }
                }

                &--project-name,
                &--measure {
                    .rebate-approvals__item-body-cell-sub {
                        .cell-value {
                            font-size: 13px;
                        }
                    }
                }

                &--received-date,
                &--bill-account,
                &--project-name,
                &--project-incentive {
                    .rebate-approvals__item-body-cell-sub {
                        justify-content: center;
                    }
                }

                &--project-incentive {
                    .rebate-approvals__item-body-cell-sub {
                        padding-right: 0;
                    }
                }
            }
        }

        &-column--received-date,
        &-column--bill-account {
            width: 8%;
        }

        &-column--project-name {
            width: 14%;
        }

        &-column--measure,
        &-column--kw-impact,
        &-column--kwh-impact,
        &-column--therm-impact,
        &-column--peak-therm-impact,
        &-column--measure-incentive,
        &-column--project-incentive {
            width: 10%;
        }

        // Styles for measures in rows
        &-column--measure-rows {
            width: 70%;
        }

        &-row {
            display: flex;
            flex-direction: row;

            &:not(:first-child) {
                @include themify($themes) {
                    border-top: 1px solid themed("themeDirtyColor");
                }
            }

            .rebate-approvals__item-column {

                &--kw-impact,
                &--kwh-impact,
                &--therm-impact,
                &--peak-therm-impact,
                &--measure-incentive,
                &--project-incentive {
                    flex: 1;
                    width: 0; // for all columns to take same size
                }

                &--measure {
                    flex-grow: 1;

                    .rebate-approvals__item-body-cell-sub {

                        .cell-label,
                        .cell-value {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
