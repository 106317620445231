@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.global-search-view {
    .view-placeholder {
        min-height: inherit;
    }

    .global-search__header {
        position: relative;
        z-index: 1;
        background: white;
        @include box-shadow-common($shadow-outer);

        .global-search {
            &__options {
                @include flexbox;

                @include tablet {
                    display: block;
                }
            }

            &__criteria {
                @include themify($themes) {
                    border-bottom: none;
                }
            }

            &__search-area-expander {
                display: none;
            }
        }
    }

    .search-results {
        padding: 20px 40px;
        width: 910px;

        @include tablet {
            margin: 0 auto;
        }

        .nothing-found-block {
            height: 100%;
        }

        &__table span {
            @include themify($themes) {
                background: themed("themeWhiteColor");
            }
        }

        &__header {
            padding-right: 30px;
            background: $white-light-grey;

            .theme-sightline &,
            .theme-sightlineHighContrast & {
                background: $sightline-white-light-grey;
            }
        }

        &__list {
            margin-left: -17px;
            padding-left: 22px;
        }

        &__item {
            &-wrap {
                overflow: hidden;
            }

            &-actions {
                position: absolute;
                top: 3px;
                right: 3px;
            }
        }

        &__counter {
            margin: 0 0 10px;

            &__text {
                width: 68px;
            }
        }

        .paging-info {
            @include flexbox;
            margin-left: 10px;

            b {
                font-weight: 700;
                @include themify($themes) {
                    color: themed("themeColorDarkGrey");
                }
            }

            .paging-info-page {
                padding: 0;
                font-weight: 500;

                &-label {
                    padding-right: 5px;
                    text-align: right;
                    line-height: 1;
                    font-size: em(8);

                    .font-size-large & {
                        font-size: em(10);
                    }
                }

                &-value {
                    font-size: em(17);
                    @include themify($themes) {
                        color: themed("themeTitleColor");
                    }
                }
            }

            .paging-info-counter {
                margin: 0 20px 0 0;
                padding: 0 !important;
                justify-content: center;
                font-size: 12px;

                .font-size-large & {
                    font-size: 14px;
                }
            }
        }

        &__paging {
            margin-right: -10px;
            font-size: 12px;
            font-weight: 500;

            .font-size-large & {
                font-size: 14px;
            }

            .paging {
                @include flex-direction(column);
                overflow: hidden;
                @include webkit-scrollbar-thin;

                &:hover {
                    overflow: overlay;
                }
            }

            .selectable-page {
                margin: 4px 10px;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .search-results-container {
            margin: 0 -40px;
            padding: 0 40px;
            .wait-icon {
                align-self: center;
            }
        }
    }
}
