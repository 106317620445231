@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.global-search {
    &__main-criteria-list {
        .select-box {
            font-weight: 500;

            &__text {
                margin: 0 5px;
                text-transform: none;
                font-weight: 500;
            }

            &:not(.selected):hover {
                @include themify($themes) {
                    background: themed("themeBaseColorDesaturatedSemiTransparent10");
                }
            }
        }
    }

    &__criteria-dropdown {
        margin: 0 7px 7px 0;
        min-width: 180px;

        .dropdown-field:not(.active) {
            box-shadow: none !important;
            font-size: 13px;
            font-weight: 500;

            .font-size-large & {
                font-size: 14px;
            }
        }

        &--active {
            .dropdown-field {
                .dropdown-field-text {
                    font-style: normal;
                }

                &:not(.active) {
                    box-shadow: none !important;

                    @include themify($themes) {
                        background: themed("themeBaseColorSemiTransparent7");
                        border: 1px solid themed("themeBaseColorSemiTransparent30") !important;
                    }

                    .dropdown-field-text {
                        @include themify($themes) {
                            color: themed("themeBaseColor") !important;
                        }
                    }
                }
            }
        }
    }
}
