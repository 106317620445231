@import "../../../../../assets/sass/_variables";
@import "../../../../../assets/sass/_mixins";
@import "../../../../../assets/sass/_functions";

.property-list-item--button-link {
    .field-group {
        &.no-padding {
            padding: 0px;
        }

        padding: 8px 0;
        width: 100%;
    }

    .property-list-item-value {
        .status-msg.msg-field-status {
            margin-top: 8px;
            margin-bottom: 2px;
        }
    }

    > .property-list-item {
        width: 100%;
    }
    .property-list-item--switch {
        border-bottom: none;
    }
}

.property-list-item--button-styles {
    > .property-list-item--select-box {
        width: 100%;
    }
}
.property-list-item--button-type {
    .secondary-type {
        margin: 0 20px;
    }
}
