@import "../../../../../../assets/sass/mixins";
@import "../../../../../../assets/sass/variables";

.wf {
    &__settings-step-assignments {
        padding: 20px;
        border-top: 1px solid $grey;

        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 30px;

            &-text {
                text-transform: uppercase;
                font-weight: 500;
            }

            & .edit {
                @include themify($themes) {
                    color: themed("themeBaseColor");
                }
            }
        }

        &-selected {
            margin-top: 10px;
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }
        }

        &-checkboxes {
            margin-top: 20px;
            padding-bottom: 10px;
            @include themify($themes) {
                border: 1px solid themed("themeDirtyColor");
            }

            &-title {
                padding: 15px;
                background: $white-light-grey;
                @include themify($themes) {
                    border-bottom: 1px solid themed("themeDirtyColor");
                }
            }

            &-item {
                margin-top: 10px;
                padding: 0 15px;
            }

            &-buttons {
                display: flex;
                margin-top: 20px;
                padding: 0 20px;

                &-cancel {
                    margin-left: 20px;
                }
            }
        }
    }
}
