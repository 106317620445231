@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.btn {
    cursor: pointer;

    &.btn-standard:not(.btn-square) {
        min-width: 80px;
    }

    &.btn-hidden {
        display: none;
    }

    &.btn-standard,
    &.btn-square {
        height: 32px;
        @include border-radius(6px);

        button,
        span,
        a {
            line-height: 32px;
        }
    }

    &.btn-small {
        height: 24px;
        @include border-radius(4px);

        &.with-icon:not(.btn-tag) {
            &:before {
                left: 2px;
                width: 24px;
                line-height: 24px;
                font-size: 14px;
            }

            button,
            span,
            a {
                padding-left: 27px !important;
            }
        }

        &.btn-standard:not(.btn-square) {
            min-width: 60px;
        }

        button,
        span,
        a {
            line-height: 24px;
            font-size: 13px;

            .font-size-large & {
                font-size: 15px;
            }
        }
    }

    &.with-icon:not(.btn-square):not(.btn-tag) {
        min-width: 112px;
    }

    &.with-icon:not(.btn-tag) {
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            width: 32px;
            line-height: 32px;
            text-align: center;
        }

        &:not(.right) {
            button,
            span,
            a {
                padding-left: 37px;
            }

            &:before {
                left: 0;
            }
        }

        &.right {
            button,
            span,
            a {
                padding-right: 37px;
            }

            &:before {
                right: 0;
            }
        }
    }

    &.btn-upload-files {
        display: inline-block;

        input[type="file"] {
            display: none;

            + label {
                display: block;
                line-height: 32px;
                font-weight: 700;
                cursor: pointer;
            }
        }

        + .file-list {
            margin-top: 8px;

            li {
                font-size: em(12);

                .font-size-large & {
                    font-size: em(14);
                }

                &:before {
                    margin: -1px 5px 0 0;
                    vertical-align: middle;
                }

                .icon-wrap {
                    position: relative;
                    top: 1px;
                    margin-left: 3px;
                }
            }
        }
    }

    &.btn-blue {
        @include themify($themes) {
            background: themed("themePrimaryButtonBackgroundColor");
        }
        @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
        color: white;

        &:hover {
            @include themify($themes) {
                background: themed("themePrimaryButtonBackgroundColorHover");
            }
            @include box-shadow(0, 0, 0, 0);
        }

        &.focus {
            @include themify($themes) {
                background: themed("themeBaseColorDesaturated");
            }
            @include box-shadow(inset 0, 0, 3px, 1px rgba(0, 0, 0, 0.25));
        }

        button,
        span,
        a {
            color: white;
        }
    }

    &.btn-light-blue {
        @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));

        @include themify($themes) {
            background: themed("themeWhiteDiagonalColor");
            color: themed("themeBaseColor");
        }

        &:hover {
            @include box-shadow(0, 0, 0, 0);
        }

        &.focus {
            @include box-shadow(inset 0, 0, 2px, 0 rgba(0, 0, 0, 0.25));
        }

        button,
        span,
        a {
            @include themify($themes) {
                color: themed("themeBaseColor");
            }
        }
    }

    &.btn-success {
        @include themify($themes) {
            background: $green-success;
            color: white;
        }

        button,
        span,
        a {
            color: white;
        }
    }

    &.btn-disabled {
        pointer-events: none;

        &.btn-blue,
        &.btn-light-blue,
        &.btn-success {
            background: $white-grey-medium;
            color: $light-grey;

            button,
            span,
            a {
                color: $light-grey;
            }
        }
    }

    &.btn-rounded {
        padding: 0 10px;
        min-width: 55px;
        height: 24px;
        @include border-radius(12px);
        font-size: em(12);
        line-height: 22px;

        .font-size-large & {
            font-size: em(14);
        }

        &.active {
            color: white;
        }

        &.btn-trigger.active {
            color: white;
        }
    }

    &.btn-trigger {
        text-align: center;

        @include themify($themes) {
            border: 1px solid themed("themeLightColor");
            color: themed("themeBaseColor");
        }

        &:hover:not(.disabled):not(.active) {
            @include themify($themes) {
                border-color: themed("themeBaseColor");
            }
        }

        &.active {
            @include themify($themes) {
                background: themed("themeBaseColorDesaturated");
                border-color: themed("themeBaseColorDesaturated");
            }
        }

        &.disabled {
            background-color: $white-grey-medium;
            border: none;
            @include box-shadow(0, 1px, 2px, 0 rgba(0, 0, 0, 0.25));
            color: $light-grey;
            pointer-events: none;
        }
    }

    &.btn-tag {
        @include themify($themes) {
            border: 1px solid themed("themeDirtyColor");
        }
        white-space: nowrap;

        &.active {
            @include themify($themes) {
                background: themed("themeBaseColor");
                border-color: themed("themeBaseColor");
            }
        }

        &:not(.active):not(.btn-view):not(.without-icon) {
            position: relative;
            padding-right: 26px;
            text-align: left;
        }

        &:not(.active):not(.btn-view):not(.selectable) {
            &:hover {
                @include themify($themes) {
                    border-color: themed("themeBaseColorDesaturated");
                }
            }
        }

        &.selectable:hover {
            @include themify($themes) {
                border-color: themed("themeBaseColor");
            }
        }

        .icon-wrap {
            position: absolute;
            right: 3px;
            top: 0;
            width: inherit;
            height: auto;
            line-height: 22px;

            &:after {
                @include themify($themes) {
                    color: themed("themeColorBaseGrey");
                }
            }

            &:hover {
                background: transparent !important;
            }
        }

        &.edit-type {
            min-width: 60px;

            &:not(.btn-tag-not-removable):not(.btn-tag-clickable) {
                cursor: default;
            }
        }

        &.btn-view {
            text-align: center;
            cursor: default;
            overflow: hidden;
        }

        &.btn-tag-hidden {
            display: none;
        }

        &.with-icon:not(.btn-tag-add) {
            &:before {
                position: relative;
                top: -1px;
                vertical-align: middle;
                margin-right: 5px;
                font-size: 12px;

                .font-size-large & {
                    font-size: 14px;
                }
            }

            &.offer-empty:before {
                position: relative;
                top: -1px;
                @include rotate(-45deg);
            }
        }

        &.btn-tag-add {
            padding: 0 2px !important;
            min-width: auto;
            @include themify($themes) {
                color: themed("themeBaseColor");
            }

            &:hover {
                @include themify($themes) {
                    border-color: themed("themeBaseColor");
                }
            }
        }

        &.btn-tag-multiline {
            padding-top: 2px;
            padding-bottom: 3px;
            white-space: normal;
            height: initial;
            line-height: inherit;
        }

        &.btn-tag-disabled {
            pointer-events: none;
            color: $light-grey !important;

            .icon-wrap:after {
                color: $light-grey !important;
            }
        }

        &.btn-tag-clickable {
            cursor: pointer;

            @include themify($themes) {
                border-color: themed("themeBaseColorSemiTransparent20");
                color: themed("themeBaseColor");
            }

            &:hover {
                @include themify($themes) {
                    background: themed("themeBaseColorSemiTransparent7");
                    border-color: themed("themeBaseColorSemiTransparent20") !important;
                }
            }
        }
    }

    &.btn-square {
        width: 32px;
        text-align: center;

        &:before {
            line-height: 32px;
        }

        //to be reviewed
        button {
            display: none;
        }

        &.plus:before {
            content: $icon-plus;
        }

        &.edit:before {
            content: $icon-edit;
        }

        &.delete:before {
            content: $icon-delete-trash-filled;
        }

        &.view:before {
            content: $icon-eye-visibility-filled;
        }

        &.settings:before {
            content: $icon-settings-empty;
        }

        &.close:before {
            content: $icon-cancel-clear-circle-highlight-off-empty;
        }

        &.download:before {
            content: $icon-download;
        }
        //to be reviewed
    }

    span,
    button,
    a {
        padding: 0 10px;
        text-align: center;
        font-weight: 700;
    }

    span,
    a {
        display: block;
    }

    button {
        width: 100%;
        background: none;
        border: none;
        font-size: em(14);
        font-family: $base-font-family;
        cursor: pointer;
        white-space: nowrap;
    }
}

.btn-group {
    &:not(.side-by-side):not(.transparent) {
        display: inline-block;
        padding: 6px;
        @include themify($themes) {
            background: themed("themeLighterDirtyColor");
        }
        @include border-radius(8px);

        .btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &:not(.not-equal-btn-width):not(.transparent) {
        .btn {
            &.btn-standard:not(.btn-square) {
                @include calc(width, "(100% / 2) - 6px");
            }
        }
    }

    &.side-by-side {
        > div:last-of-type {
            float: right;
        }
    }

    .btn:not(.btn-hidden) {
        display: inline-block;
        vertical-align: top;
    }

    &.transparent {
        .btn:not(:last-child) {
            margin-right: 17px;
        }

        .icon-with-label:not(:last-child) {
            margin-right: 17px;
        }

        .btn-tag:not(:last-child) {
            margin-right: 8px;
        }
    }
}
