@import "../../../assets/sass/_variables";
@import "../../../assets/sass/_mixins";
@import "../../../assets/sass/_functions";

.crm {
    height: 100%;
    overflow: hidden;

    .theme-mode-light & {
        position: relative;
    }

    iframe {
        border: none;
        width: 100%;
        height: 100%;
    }

    .global-nav-shadow-imitation {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        @include themify($themes) {
            background: linear-gradient(to bottom, themed("themeBaseColorDesaturatedSemiTransparent"), rgba(0, 0, 0, 0));
        }

        .theme-mode-light & {
            display: block;
        }
    }
}
