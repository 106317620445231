@import "~assets/sass/_variables";

.system-user-info-block {
    justify-content: space-between;
    align-items: top;
    margin: 20px 0;
    padding: 10px 30px;
    background-color: white;
    overflow: overlay;

    .system-user-info-block-item {
        padding: 10px;

        .text-label {
            margin-bottom: 8px;
            color: $light-grey;
        }

        .system-user-info-block-value {
            overflow: hidden;
            overflow-wrap: break-word;
        }

        .field-group {
            min-width: 150px;
        }
    }
}
