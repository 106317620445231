@import "../../../../assets/sass/_variables";
@import "../../../../assets/sass/_mixins";
@import "../../../../assets/sass/_functions";

.window-header-notification {
    @include themify($themes) {
        background: themed("themeWhiteColor");
    }
    @include box-shadow(inset 0, -2px, 10px, 0 rgba(0, 0, 0, 0.15));

    &--activity {
        color: $red;
        font-weight: 500;

        p {
            padding: 7px 0;

            @include mobile-only {
                line-height: 1.1;
            }
        }

        .activity-icon {
            margin-right: 10px;
            flex-shrink: 0;
        }
    }
}
