@import "../../../../../../assets/sass/mixins";

.rebates-panel {
    .grid-list {
        @include data-grid-sticky-header;
        overflow: hidden;

        @include ie {
            height: auto;
        }

        .grid-list-left-side {
            .grid-list-row-container {
                .grid-list-row {
                    height: auto;
                }
            }
        }
    }
}
